import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { RiFileExcel2Fill } from "react-icons/ri";
import ReactExport from "react-export-excel";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CashoutTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const today = new Date();
  const [dateRange4, setDateRange4] = useState([
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
    new Date(),
  ]);
  const [startDate4, endDate4] = dateRange4;
  const selectionRange4 = {
    start: dateRange4[0],
    end: dateRange4[1],
    page,
  };

  const { t } = useTranslation();

  const getTransactions = () => {
    api({
      url: `/user/cashouts`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: selectionRange4,
    })
      .then((res) => {
        if (res.data.status === 1) {
          console.log(res);
          const editedTransactions = res.data.data.cashouts.map((tran) => {
            return { ...tran, id: tran._id, currency: tran.currancy[0] };
          });
          setTransactions(editedTransactions);
          setCount(res.data.data.count);
        } else {
          cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: t("TIMESTAMP"),
      width: 170,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
      renderCell: (params) => (
        <p style={{ marginBottom: "0px" }}>
          {moment(params.row.createdAt).format("LL")}
          {/*moment(params.row.createdAt).format("LT")*/}
        </p>
      ),
    },
    {
      field: "amount",
      headerName: t("amount").toUpperCase(),
      width: 100,
      cellClassName: `MuiDataGrid-cellCenter MuiDataGrid-colCellCenter`,
    },
    {
      field: "currancy",
      headerName: t("currency").toUpperCase(),
      width: 120,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
      valueGetter: (params) => t(params.row.currancy),
    },
    {
      field: "cashoutType",
      headerName: t("METHOD"),
      width: 120,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
    },
    {
      field: "bankName",
      headerName: t("Bank"),
      width: 170,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
    },
    {
      field: "bankAccountName",
      headerName: t("ACCOUNT Name"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
    },
    {
      field: "bankAccountNumber",
      headerName: t("Phone/Account Number"),
      width: 180,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
      valueGetter: (params) =>
        params.row.cashoutType === "BANK"
          ? params.row.bankAccountNumber
          : params.row.phoneNumber,
    },
    {
      field: "status",
      headerName: t("status").toUpperCase(),
      width: 120,
      cellClassName: "MuiDataGrid-cellCenter cellSTart",
      renderCell: (params) => params.row.status,
    },
  ];

  const lastPage = Math.ceil(count / 10);

  const first = () => 1 + (page * 10 - 10);

  const last = () => {
    const number = 10 * page;
    return number > count ? count : number;
  };

  const nextPage = () => {
    page === lastPage ? setPage(lastPage) : setPage(page + 1);
  };

  const previousPage = () => {
    page === 1 ? setPage(1) : setPage(page - 1);
  };

  return (
    <div className="mainPage wallet" style={{ width: "100%" }}>
      <div className="container-fluid">
        <div
          className="col-12 expense-chart-head"
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <p className="h3" style={{ display: "inline-block" }}>
            {t("Latest Wallet Transactions")}
          </p>
          <div className="date-export">
            <DatePicker
              dateFormat="dd/MM/yyy"
              selectsRange={true}
              startDate={startDate4}
              endDate={endDate4}
              onChange={(update) => {
                setDateRange4(update);
              }}
              onCalendarClose={() => {
                getTransactions();
              }}
              isClearable={false}
            />
            <ExcelFile
              element={
                <button className="btn btnPrimary">
                  <RiFileExcel2Fill /> {t("Export CSV")}
                </button>
              }
              filename={t("Latest Wallet Transactions")}
            >
              <ExcelSheet data={transactions} name="Transactions">
                <ExcelColumn label="TIMESTAMP" value="createdAt" />
                <ExcelColumn label="AMOUNT" value="amount" />
                <ExcelColumn label="CURRANCY" value="currency" />
                <ExcelColumn label="METHOD" value="cashoutType" />
                <ExcelColumn label="BANK" value="bankName" />
                <ExcelColumn label="ACCOUNT NAME" value="bankAccountName" />
                <ExcelColumn
                  label="Phone/Account Number"
                  value="bankAccountNumber"
                />
                <ExcelColumn label="STATUS" value="status" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
        <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={transactions}
            columns={columns}
            pageSize={10}
            disableColumnMenu
            hideFooterSelectedRowCount
            sortingOrder="asc"
            hideFooter
          />
        </div>
        <div className="team-pagination">
          <p>
            {first()} - {last()} of {count}
          </p>
          <MdKeyboardArrowLeft
            className="pagination-icon"
            onClick={() => previousPage()}
          />
          <p>{page}</p>
          <MdKeyboardArrowRight
            className="pagination-icon"
            onClick={() => nextPage()}
          />
        </div>
      </div>
    </div>
  );
};

export default CashoutTransactions;
