import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  addCategory,
  editCategory,
  deleteCategory,
  getCases,
  addCase,
  editCase,
  deleteCase
} from "../../../../../actions/categoryActions";
import { useTranslation } from "react-i18next";
import cogoToast from "cogo-toast";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import edit from "../../../../../assets/images/edit-cat.png";
import trash from "../../../../../assets/images/trash-cat.png";

import "./categories.css";

const Categories = () => {
  const categories = useSelector(state => state.categories.categories);
  const cases = useSelector(state => state.categories.cases) || [];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);
  const [modalThreeIsOpen, setModalThreeIsOpen] = useState(false);
  const [modalFourIsOpen, setModalFourIsOpen] = useState(false);
  const [modalFiveIsOpen, setModalFiveIsOpen] = useState(false);
  const [modalSixIsOpen, setModalSixIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [newName, setNewName] = useState("");
  const [category, setCategory] = useState({});

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCases());
  }, []);

  const addCategoryHandler = () => {
    if (name === "") {
      cogoToast.error("Enter Category Name");
    } else {
      dispatch(addCategory({ name: name }));
      setModalIsOpen(false);
      setName("");
    }
  };

  const editCategoryHandler = () => {
    if (newName === "") {
      cogoToast.error("Enter Category Name");
    } else {
      dispatch(editCategory({ id: category.id, name: newName }));
      setModalTwoIsOpen(false);
      setNewName("");
    }
  };

  const deleteCategoryHandler = () => {
    dispatch(deleteCategory(category.id));
    setModalThreeIsOpen(false);
  };

  const addCaseHandler = () => {
    if (name === "") {
      cogoToast.error("Enter Case Name");
    } else {
      dispatch(addCase({ name: name }));
      setModalFourIsOpen(false);
      setName("");
    }
  };

  const editCaseHandler = () => {
    if (newName === "") {
      cogoToast.error("Enter Case Name");
    } else {
      dispatch(editCase({ id: category.id, name: newName }));
      setModalFiveIsOpen(false);
      setNewName("");
    }
  };

  const deleteCaseHandler = () => {
    dispatch(deleteCase(category.id));
    setModalSixIsOpen(false);
  };

  const customStyle = {
    content: {
      borderRadius: "20px",
      width: "45%",
      height: "fit-content",
      margin: "auto",
      textAlign: localStorage.getItem("i18nextLng") == "ar" ? "right" : "left"
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)"
    }
  };

  return (
    <Fragment>
      <h2 className="category-title">{t("Requests categories & Cases")}</h2>
      <div className="mainPage" style={{ marginBottom: "50px" }}>
        <div className="requests-head">
          <h2>{t("Requests categories")}</h2>
          <button
            className="btn btnGray btn-new-req"
            onClick={() => setModalIsOpen(true)}
          >
            <img
              src="/images/apps-add.png"
              alt="add-image"
              className="add-sign"
              style={{ marginInlineEnd: "10px", width: "20px" }}
            />
            {t("Add New Category")}
          </button>
        </div>
        <div className="categories-container">
          {categories.length > 0 ? (
            categories.map((cat, i) => {
              return (
                <div className="category-card" key={i}>
                  <h4>{cat.name}</h4>
                  <div className="cat-card-actions">
                    <img
                      src={edit}
                      alt="edit"
                      id={`cat-card-edit-${i}`}
                      onClick={() => {
                        setCategory(cat);
                        setModalTwoIsOpen(true);
                      }}
                    />
                    <span>|</span>
                    <img
                      src={trash}
                      alt="trash"
                      id={`cat-card-trash-${i}`}
                      onClick={() => {
                        setCategory(cat);
                        setModalThreeIsOpen(true);
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <h1>{t("You don't have any categories")}</h1>
          )}
        </div>
      </div>

      <div className="mainPage">
        <div className="requests-head">
          <h2>{t("Cases")}</h2>
          <button
            className="btn btnGray btn-new-req"
            onClick={() => setModalFourIsOpen(true)}
          >
            <img
              src="/images/apps-add.png"
              alt="add-image"
              className="add-sign"
              style={{ marginInlineEnd: "10px", width: "20px" }}
            />
            {t("Add New Case")}
          </button>
        </div>
        <div className="categories-container">
          {cases.length > 0 ? (
            cases.map((cat, i) => {
              return (
                <div className="category-card" key={i}>
                  <h4>{cat.name}</h4>
                  <div className="cat-card-actions">
                    <img
                      src={edit}
                      alt="edit"
                      id={`cat-card-edit-${i}`}
                      onClick={() => {
                        setCategory(cat);
                        setModalFiveIsOpen(true);
                      }}
                    />
                    <span>|</span>
                    <img
                      src={trash}
                      alt="trash"
                      id={`cat-card-trash-${i}`}
                      onClick={() => {
                        setCategory(cat);
                        setModalSixIsOpen(true);
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <h1>{t("You don't have any cases")}</h1>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyle}
      >
        <div className="cat-modal">
          <h4>{t("Add new category")}</h4>
          <h6>{t("Type your new category name")}</h6>
          <input
            className="form-control"
            value={name}
            placeholder={t("Travelling")}
            onChange={e => setName(e.target.value)}
          />
          <div className="add-category-btn">
            <button
              className="btn btnGray"
              onClick={() => addCategoryHandler()}
            >
              {t("Add new category")}
            </button>
          </div>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setModalIsOpen(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "15px", top: "15px" }
                : { right: "15px", left: "auto", top: "15px" }
            }
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalTwoIsOpen}
        onRequestClose={() => setModalTwoIsOpen(false)}
        style={customStyle}
      >
        <div className="cat-modal">
          <h4>{t("Edit category name")}</h4>
          <h6>{t("Type your new category name")}</h6>
          <input
            className="form-control"
            defaultValue={category.name}
            placeholder={t("Bills")}
            onChange={e => setNewName(e.target.value)}
          />
          <div className="add-category-btn">
            <button
              className="btn btnGray"
              onClick={() => editCategoryHandler()}
            >
              {t("Confirm")}
            </button>
          </div>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setModalTwoIsOpen(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "15px", top: "15px" }
                : { right: "15px", left: "auto", top: "15px" }
            }
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalThreeIsOpen}
        onRequestClose={() => setModalThreeIsOpen(false)}
        style={customStyle}
      >
        <h4>
          {t("Please confirm to delete ")} {category.name}
        </h4>
        <div className="add-category-btn">
          <button
            className="btn btnGray"
            onClick={() => deleteCategoryHandler()}
          >
            {t("Confirm")}
          </button>
        </div>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setModalThreeIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>

      <Modal
        isOpen={modalFourIsOpen}
        onRequestClose={() => setModalFourIsOpen(false)}
        style={customStyle}
      >
        <div className="cat-modal">
          <h4>{t("Add new case")}</h4>
          <h6>{t("Type your new case name")}</h6>
          <input
            className="form-control"
            value={name}
            placeholder={t("Travelling")}
            onChange={e => setName(e.target.value)}
          />
          <div className="add-category-btn">
            <button className="btn btnGray" onClick={() => addCaseHandler()}>
              {t("Add new case")}
            </button>
          </div>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setModalFourIsOpen(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "15px", top: "15px" }
                : { right: "15px", left: "auto", top: "15px" }
            }
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalFiveIsOpen}
        onRequestClose={() => setModalFiveIsOpen(false)}
        style={customStyle}
      >
        <div className="cat-modal">
          <h4>{t("Edit case name")}</h4>
          <h6>{t("Type your new case name")}</h6>
          <input
            className="form-control"
            defaultValue={category.name}
            placeholder={t("Bills")}
            onChange={e => setNewName(e.target.value)}
          />
          <div className="add-category-btn">
            <button className="btn btnGray" onClick={() => editCaseHandler()}>
              {t("Confirm")}
            </button>
          </div>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setModalFiveIsOpen(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "15px", top: "15px" }
                : { right: "15px", left: "auto", top: "15px" }
            }
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalSixIsOpen}
        onRequestClose={() => setModalSixIsOpen(false)}
        style={customStyle}
      >
        <h4>
          {t("Please confirm to delete ")} {category.name}
        </h4>
        <div className="add-category-btn">
          <button className="btn btnGray" onClick={() => deleteCaseHandler()}>
            {t("Confirm")}
          </button>
        </div>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setModalSixIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>
    </Fragment>
  );
};

export default Categories;
