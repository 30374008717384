import React, { useEffect } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/authActions";
import Index from "./_HomeMap_";
import Requests from "./_MyRequestsMap_";
import Suppliers from "./pages/newStr/Suppliers/index";
import MySuplierRequests from "./pages/newStr/Suppliers/my-requests/my-requests";

import Profile from "./pages/newStr/Profile/index";
import Payroll from "./pages/newStr/Payroll/index";
import ProfileSettings from "./pages/newStr/profileSettings/profileSettings";
import AccountSettings from "./pages/newStr/accountSettings/accountSettings";
import NotificationsSettings from "./pages/newStr/notificationsSettings/notificationsSettings";

import Team from "./pages/newStr/Teams/team/index";
import Invite from "./pages/newStr/Teams/invite/invite";
import CreateTeam from "./pages/newStr/Teams/createTeam/createTeam";

import Expense from "./_ExpensesMap_";
import CompanyWallet from "./pages/newStr/CompanyWallet";
import CompanyProfile from "./pages/newStr/companyProfile/companyProfile";
import MainNavbar from "./components/navbar/mainNavbar";
import UnderConstructionPage from "./pages/newStr/underConstructionPage/underConstructionPage";
import BillingPlan from "./pages/newStr/billingPlan/billingPlan";
import PlanConfiguration from "./pages/newStr/billingPlan/planConfiguration/planConfiguration";
import PlanUpgrade from "./pages/newStr/billingPlan/planUpgrade/planUpgrade";

import history from "../../history";
import "./dashboard.css";
import api from "../../apis/api";

const Dashboard = props => {
  let { auth } = props;

  const checkToken = () => {
    api({
      url: "/auth/checkToken",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        if (res.data.status === 0) {
          props.logout();
          history.push("/");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    // const token = localStorage.getItem('token');
    // if (token)
    checkToken();
  }, [window.location.pathname]);

  let routes;
  if (auth.user && auth.user.role === "accountant") {
    routes = (
      <Router history={history}>
        <MainNavbar />
        <Switch>
          <Route path="/dashboard/payroll" component={Payroll} />

          <Route path="/dashboard/team" component={Index} />
          <Route path="/dashboard/expense" component={Expense} />
          <Route path="/dashboard/profile" component={Profile} />
          <Route
            path="/dashboard/profileSettings"
            component={ProfileSettings}
          />
          <Route
            path="/dashboard/accountSettings"
            component={AccountSettings}
          />
          <Route
            path="/dashboard/suppliers/my-requests"
            component={MySuplierRequests}
          />
          <Route path="/dashboard/suppliers" component={Suppliers} />
          <Route path="/dashboard/requests" component={Requests} />
          <Route path="/dashboard/wallet" component={CompanyWallet} />
          <Route path="/dashboard/companyProfile" component={CompanyProfile} />
          <Route
            path="/dashboard/pageUnderConstruction"
            component={UnderConstructionPage}
          />
          <Route
            path="/dashboard/notificationSettings"
            component={NotificationsSettings}
          />
          <Route exact path="/dashboard/billingPlan" component={BillingPlan} />
          <Route
            path="/dashboard/billingPlane/:id"
            component={PlanConfiguration}
          />
          <Route
            exact
            path="/dashboard/billingPlan/upgrade/:id"
            component={PlanUpgrade}
          />

          <Redirect to="/dashboard/team" />
        </Switch>
      </Router>
    );
  } else if (auth.user && auth.user.team.name === "HR") {
    routes = (
      <Router history={history}>
        <MainNavbar />
        <Switch>
          <Route path="/dashboard/payroll" component={Payroll} />

          <Route path="/dashboard/profile" component={Profile} />
          <Route
            path="/dashboard/profileSettings"
            component={ProfileSettings}
          />
          <Route path="/dashboard/expense" component={Expense} />
          <Route
            path="/dashboard/accountSettings"
            component={AccountSettings}
          />
          <Route
            path="/dashboard/suppliers/my-requests"
            component={MySuplierRequests}
          />
          <Route path="/dashboard/suppliers" component={Suppliers} />
          <Route path="/dashboard/requests" component={Requests} />
          <Route
            path="/dashboard/pageUnderConstruction"
            component={UnderConstructionPage}
          />
          <Route
            path="/dashboard/notificationSettings"
            component={NotificationsSettings}
          />
          <Route path="/dashboard/team/home" exact component={Team} />
          <Route path="/dashboard/team/invite" exact component={Invite} />
          <Route path="/dashboard/team/create" exact component={CreateTeam} />

          <Redirect to="/dashboard/requests" />
        </Switch>
      </Router>
    );
  } else if (auth.user && auth.user.role === "manager") {
    routes = (
      <Router history={history}>
        <MainNavbar />
        <Switch>
          <Route path="/dashboard/team" component={Index} />
          <Route path="/dashboard/expense" component={Expense} />
          <Route path="/dashboard/requests" component={Requests} />
          <Route path="/dashboard/wallet" component={CompanyWallet} />
          <Route
            path="/dashboard/suppliers/my-requests"
            component={MySuplierRequests}
          />
          <Route path="/dashboard/suppliers" component={Suppliers} />
          <Route path="/dashboard/profile" component={Profile} />
          <Route path="/dashboard/payroll" component={Payroll} />
          <Route
            path="/dashboard/profileSettings"
            component={ProfileSettings}
          />
          <Route
            path="/dashboard/accountSettings"
            component={AccountSettings}
          />
          <Route path="/dashboard/companyProfile" component={CompanyProfile} />
          <Route
            path="/dashboard/pageUnderConstruction"
            component={UnderConstructionPage}
          />
          <Route
            path="/dashboard/notificationSettings"
            component={NotificationsSettings}
          />
          <Route exact path="/dashboard/billingPlan" component={BillingPlan} />
          <Route
            path="/dashboard/billingPlane/:id"
            component={PlanConfiguration}
          />
          <Route
            exact
            path="/dashboard/billingPlan/upgrade/:id"
            component={PlanUpgrade}
          />

          <Redirect to="/dashboard/team" />
        </Switch>
      </Router>
    );
  } else if (auth.user && auth.user.role === "team-manager") {
    routes = (
      <Router history={history}>
        <MainNavbar />
        <Switch>
          <Route path="/dashboard/expense" component={Expense} />
          <Route path="/dashboard/requests" component={Requests} />
          <Route path="/dashboard/profile" component={Profile} />
          <Route
            path="/dashboard/profileSettings"
            component={ProfileSettings}
          />
          <Route
            path="/dashboard/accountSettings"
            component={AccountSettings}
          />
          <Route
            path="/dashboard/suppliers/my-requests"
            component={MySuplierRequests}
          />
          <Route path="/dashboard/suppliers" component={Suppliers} />
          <Route
            path="/dashboard/pageUnderConstruction"
            component={UnderConstructionPage}
          />
          <Route
            path="/dashboard/notificationSettings"
            component={NotificationsSettings}
          />

          <Redirect to="/dashboard/expense" />
        </Switch>
      </Router>
    );
  } else if (auth.user && auth.user.role === "user") {
    routes = (
      <Router history={history}>
        <MainNavbar />
        <Switch>
          <Route path="/dashboard/requests" component={Requests} />
          <Route
            path="/dashboard/suppliers/my-requests"
            component={MySuplierRequests}
          />
          <Route path="/dashboard/suppliers" component={Suppliers} />
          <Route path="/dashboard/profile" component={Profile} />
          <Route
            path="/dashboard/profileSettings"
            component={ProfileSettings}
          />
          <Route
            path="/dashboard/accountSettings"
            component={AccountSettings}
          />
          <Route
            path="/dashboard/pageUnderConstruction"
            component={UnderConstructionPage}
          />
          <Route
            path="/dashboard/notificationSettings"
            component={NotificationsSettings}
          />

          <Redirect to="/dashboard/requests" />
        </Switch>
      </Router>
    );
  }

  return <div className="dashboard-pages">{routes}</div>;
};

const mapStateToProps = state => {
  return {
    loading: state.loading.loading,
    auth: state.auth
  };
};

export default connect(mapStateToProps, { logout })(Dashboard);
