import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { RiQuestionFill } from 'react-icons/ri';

import './helpMenu.css'

const HelpMenu = () => {

  const { t } = useTranslation();

  const menu = (
    <div className='help-menu'>
      <img src='/images/help.png' alt='help'/>
      <h3>{t('Do You Need Help or Support?')}</h3>
      <h5>{t('Send us a message and our technical support will contact you the soonest')}</h5>
      <a
        className="btn"
        onClick={() => {
          window.open('mailto:cx@hollydesk.com?subject=Subject&body=Body%20goes%20here')
        }}
      >
        {t('contactUs')}
      </a>
    </div>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a className="ant-dropdown-link " onClick={e => e.preventDefault()}>
        <RiQuestionFill className='nav-action'/>
      </a>
    </Dropdown>
  );
};

export default HelpMenu;