import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ItemRequest from "../item-requests/item-request";
import moment from "moment";
import { approveRequests } from "../../../../../../../../actions/pendingRequestsActions";
import { Menu, Dropdown } from "antd";
import { GoChevronDown } from "react-icons/go";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExpenseItem = (props) => {
  const [open, setOpen] = useState(false);
  const [exel, setExel] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setExel(props.pending.requests);
  }, []);

  const renderRequests = (requests) => {
    if (requests && requests.length > 0) {
      return requests.map((request) => {
        return <ItemRequest key={request._id} request={request} />;
      });
    } else {
      return <div>You don't have any requests</div>;
    }
  };

  const menu = () => {
    const { pending } = props;
    return (
      <Menu>
        <Menu.Item key="0">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/csv.png" alt="csv" /> &nbsp; CSV
              </p>
            }
            filename={
              moment(pending.month, "M")
                .format("MMM")
                .toUpperCase() +
              " " +
              pending.year +
              " Your Supplier Expenses"
            }
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Paid On" value="paidOn" />
              <ExcelColumn label="Paid For" value="paidFor" />
              <ExcelColumn label="Paid To" value="paidTo" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Images"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="1">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/QuickBooks.png" alt="csv" /> &nbsp; QuickBooks
              </p>
            }
            filename={
              moment(pending.month, "M")
                .format("MMM")
                .toUpperCase() +
              " " +
              pending.year +
              " Your Supplier Expenses"
            }
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Product/Category" value="paidTo" />
              <ExcelColumn
                label="Product/Service Description"
                value="paidFor"
              />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="Rate" value="rate" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Tax Code" value="taxCode" />
              <ExcelColumn label="Tax Amount" value="taxAmount" />
              <ExcelColumn label="Class" value="category" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Memo"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="2">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/Xero.png" alt="csv" /> &nbsp; Xero
              </p>
            }
            filename={
              moment(pending.month, "M")
                .format("MMM")
                .toUpperCase() +
              " " +
              pending.year +
              " Your Supplier Expenses"
            }
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Number" value="number" />
              <ExcelColumn label="Reference" value="paidFor" />
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Account" value="paidTo" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn
                label="Memo"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="3">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/FreshBooks.png" alt="csv" /> &nbsp; FreshBooks
              </p>
            }
            filename={
              moment(pending.month, "M")
                .format("MMM")
                .toUpperCase() +
              " " +
              pending.year +
              " Your Supplier Expenses"
            }
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Merchant" value="paidTo" />
              <ExcelColumn label="Description" value="paidFor" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Memo"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>
      </Menu>
    );
  };

  const { pending } = props;
  const { currency } = props;

  return (
    <div className="item mb-4">
      <h4
        className="m-4"
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { textAlign: "right" }
            : { textAlign: "left" }
        }
      >
        {moment(pending.month, "M")
          .format("MMM")
          .toUpperCase()}
        , {pending.year}
      </h4>
      <div className={`details ${open ? "active" : ""}`}>
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="row align-items-center">
              <div className="col-md-2 text-center">
                <i
                  className={`fas fa-lg fa-file-alt requests ${
                    open ? "active" : ""
                  }`}
                ></i>
              </div>
              <div className="col-md-10">
                <h5
                  className="mb-1 "
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                >
                  {localStorage.getItem("i18nextLng") == "en" ? (
                    <b>
                      {pending.count} Settled Supplier Invoice
                      {pending.count > 1 ? "s" : ""}
                    </b>
                  ) : (
                    <b>{pending.count} طلبات مقبولة</b>
                  )}
                </h5>
                <h5
                  className="text-muted mt-1"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                >
                  {localStorage.getItem("i18nextLng") == "en"
                    ? "Submitted to Hollydesk"
                    : "مرسلة الى هولى ديسك"}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="row align-items-center">
              <div className="col-md-4">
                <p className="mb-0">
                  {localStorage.getItem("i18nextLng") == "en" ? (
                    <b>
                      {" "}
                      <b>
                        Total {currency} {pending.totalAmount}
                      </b>
                    </b>
                  ) : (
                    <b>
                      {" "}
                      <b>
                        المجموع {t(currency)} {pending.totalAmount}
                      </b>
                    </b>
                  )}
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7">
                <div className="row ">
                  <div className="col-md-11 row">
                    <div className="container-fluid mt-1 ml-4  col-3 row here"></div>
                    <div className="container-fluid mt-1 ml-4  col-9 row here">
                      <Dropdown overlay={menu()} trigger={["click"]}>
                        {localStorage.getItem("i18nextLng") == "en" ? (
                          <button className="btn btn-success btnPrimary">
                            Export To{" "}
                            <GoChevronDown style={{ fontSize: "16px" }} />
                          </button>
                        ) : (
                          <button className="btn btn-success btnPrimary">
                            إستخراج إلى{" "}
                            <GoChevronDown style={{ fontSize: "16px" }} />
                          </button>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-md-1">
                    {open ? (
                      <i
                        className="fas fa-angle-up float-right openRequests"
                        onClick={() => setOpen(!open)}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-angle-down float-right openRequests"
                        onClick={() => setOpen(!open)}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open ? (
        <div>
          <div className="item-requests">
            {renderRequests(pending.requests)}
          </div>
        </div>
      ) : (
        <div>
          <div className="hidden"></div>
          <div className="hidden hidden2"></div>
        </div>
      )}
    </div>
  );
};

const mapToStateProps = (state) => {
  return {
    pendings: state.pending.pendings,
    lang: state.lang.lang,
    currency: state.auth.user.currency[0],
  };
};

export default connect(mapToStateProps, { approveRequests })(ExpenseItem);
