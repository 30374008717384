import { Space, Spin } from "antd";
import React from "react";

const Spinner = ({ height }) => {
  return (
    <Space
      style={{
        height: height || "80vh",
        display: "grid",
        placeItems: "center"
      }}
    >
      <Spin size="large" />
    </Space>
  );
};

export default Spinner;
