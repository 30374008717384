import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import { setLoading, removeLoading } from "./loadingActions";
import { tokenConfig } from "./tokenActions";
import {
  loadingApprovedRequests,
  loadingApprovedInAdvanceRequests,
  loadingReconciledInAdvanceRequests,
  loadingDecilnedRequests,
  loadingDecilnedInAdvanceRequests
} from "./pendingRequestsActions";
import cogoToast from "cogo-toast";
import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  EDIT_REQUEST_CATEGORY,
  EDIT_REQUEST_CATEGORY_INADVANCE
} from "./types";
import i18n from "../translations/i18n";
export const getCategories = () => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .get(`/category/`, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: GET_CATEGORIES, payload: res.data.categories });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const addCategory = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .post("/category/add", data, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: ADD_CATEGORY, payload: res.data.category });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Category added"));
        dispatch(getCategories());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const editCategory = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  const id = data.id;
  const name = { name: data.name };

  api
    .post(`/category/edit/${id}`, name, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: EDIT_CATEGORY, payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Category edited"));
        dispatch(getCategories());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const deleteCategory = id => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .post(`/category/delete/${id}`, {}, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: DELETE_CATEGORY, payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Category deleted"));
        dispatch(getCategories());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const editRequestCategory = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .post(`requests/edit-category`, data, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: EDIT_REQUEST_CATEGORY, payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Category changed"));
        dispatch(loadingApprovedRequests());
        dispatch(loadingDecilnedRequests());
        dispatch(loadingDecilnedInAdvanceRequests());
        dispatch(getCategories());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
        cogoToast.error(i18n.t(res.data.message));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const editRequestCategoryInAdvance = data => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .post(`inadvance/edit-category`, data, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: EDIT_REQUEST_CATEGORY_INADVANCE,
          payload: res.data.data
        });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Category changed"));
        dispatch(loadingApprovedInAdvanceRequests());
        dispatch(loadingReconciledInAdvanceRequests());
        dispatch(loadingDecilnedInAdvanceRequests());
        dispatch(getCategories());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
        cogoToast.error(i18n.t(res.data.message));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const getCases = () => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .get(`/case/`, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: "GET_CASES", payload: res.data.cases });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const addCase = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .post("/case/", data, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: "ADD_CASE", payload: res.data.cases });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Category added"));
        dispatch(getCases());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const editCase = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  const id = data.id;
  const name = { name: data.name };

  api
    .put(`/case/${id}`, name, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: "EDIT_CASE", payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Case edited"));
        dispatch(getCases());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const deleteCase = id => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .delete(`/case/${id}`, tokenConfig(getState))
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: DELETE_CATEGORY, payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Case deleted"));
        dispatch(getCases());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};
