import React, { useEffect, useState } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import Create from "./create/Create";
import Dashboard from "./dashboard/Dashboard";
import Website from "./website/website";
import Invitation from "./create/components/invitation/invitation";
import BoardMemberInvitation from "./create/components/boardMemberInvitation/boardMemberInvitation";
import { setLoading, removeLoading } from "../actions/loadingActions";
import Loader from "./website/components/loader/loader";
import { connect, useDispatch } from "react-redux";
import history from "../history";

import "./App.css";
import "./MediaQueries.css";
import Joi from "joi";
import toast from "cogo-toast";
import { getUserInfo } from "../actions/authActions";

const App = props => {
  const [error, setError] = useState(false);
  document.addEventListener("wheel", function(event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  const dispatch = useDispatch();

  useEffect(() => dispatch(getUserInfo()), []);

  useEffect(async () => {
    localStorage.setItem("i18nextLng", "en");
    localStorage.setItem("lang", "en");
  }, []);

  useEffect(async () => {
    navigator.serviceWorker.addEventListener("message", message =>
      console.log(message.data)
    );
  }, [
    navigator.serviceWorker.addEventListener("message", message => message.data)
  ]);

  useEffect(() => {
    const schema = Joi.object({
      firstName: Joi.string().required(),
      role: Joi.string().required(),
      lastName: Joi.string().required(),
      fullname: Joi.string(),
      email: Joi.string().required(),
      phone: Joi.array().required(),
      credit: Joi.any().required(),
      jobTitle: Joi.string()
        .allow("")
        .required(),
      image: Joi.string()
        .allow("")
        .required(),
      company: Joi.object().required(),
      team: Joi.object().required(),
      currency: Joi.array().required(),
      ewallets: Joi.array(),
      deviceTokenID: Joi.string()
    });

    let err = schema.validate(props.user).error;
    if (err) {
      toast.warn("We missed you, Please login again!");
      console.log(err.details);
      setError(true);
    } else {
      setError(false);
    }
  }, [props.user]);

  let routes = (
    <Router history={history}>
      {/*{props.loading == true ? <Loader /> : ''}*/}
      {props.user && !error ? (
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Redirect to="/dashboard" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/create" component={Create} />
          <Route path="/invitation" component={Invitation} />
          <Route
            path="/boardMemberInvitation"
            component={BoardMemberInvitation}
          />
          <Route path="/" component={Website} />
          <Redirect to="/" />
        </Switch>
      )}
    </Router>
  );

  return <div>{routes}</div>;
};

const mapStateToProps = state => ({
  lang: state.lang.lang,
  loading: state.loading.loading,
  token: state.auth.token,
  user: state.auth.user
});

export default connect(mapStateToProps, { setLoading, removeLoading })(App);
