import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cogoToast from "cogo-toast";
import api from "../../../../../../apis/api";
import { MdVerifiedUser } from "react-icons/md";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AuthCode from "react-auth-code-input";
import { Statistic } from "antd";
import "antd/dist/antd.css";
import { changeUserEmail } from "../../../../../../actions/authActions";
import { BiHide } from "react-icons/bi";
import { useForm } from "react-hook-form";

const ChangeEmail = () => {
  const oldEmail = useSelector((state) => state.auth.user.email);

  const { handleSubmit } = useForm();
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [code, setCode] = useState("");
  const [style, setStyle] = useState({
    color: "#268c55",
    pointerEvents: "none",
    cursor: "wait",
  });
  const [showPass, setShowPass] = useState(false);

  const { Countdown } = Statistic;
  const deadline = Date.now() + 1000 * 60 * 10;
  const onFinish = () =>
    setStyle({ color: "#268c55", pointerEvents: "auto", cursor: "pointer" });

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const customStyle = {
    content: {
      borderRadius: "20px",
      width: "50%",
      height: "fit-content",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)",
    },
  };

  const verifyEmailHandler = () => {
    if (newEmail === "") {
      cogoToast.error("Please, Enter new email");
    } else if (password === "") {
      cogoToast.error("Please, Enter your password");
    } else {
      api({
        url: "user/verifyEmail",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        },
        data: { email: newEmail, password },
      })
        .then((res) => {
          if (res.data.status === 1) {
            setIsOpen(true);
          } else {
            cogoToast.error(
              res.data.message.details
                ? res.data.message.details[0].message
                : res.data.message
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const ChangeEmailHandler = () => {
    api({
      url: "user/changeEmail",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { email: newEmail, code: code },
    })
      .then((res) => {
        if (res.data.status === 1) {
          setIsOpen(false);
          dispatch(changeUserEmail(newEmail));
          cogoToast.success("Email changed successfully");
          setNewEmail("");
          setPassword("");
        } else {
          cogoToast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="account-settings-form">
      <form onSubmit={handleSubmit(verifyEmailHandler)}>
        <div className="form-row wide">
          <label>{t("email")}</label>
          <p className="form-control mail-disables">
            <span>{oldEmail}</span> <MdVerifiedUser className="verify-shield" />
          </p>
        </div>
        <div className="form-row wide">
          <label>{t("New Email")}</label>
          <input
            type="text"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            title="Enter in the format: example@email.com"
            oninvalid='this.setCustomValidity(
              "Enter in the format: example@email.com"
            )'
            className="form-control"
            name="email"
            onChange={(e) => setNewEmail(e.target.value)}
            value={newEmail}
            placeholder={t("Write your new email")}
          />
        </div>
        <div className="form-row wide" style={{ position: "relative" }}>
          <label>{t("Password (to change email)")}</label>
          <input
            type={showPass ? "text" : "password"}
            className="form-control"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder={t("Write your password")}
          />
          <BiHide
            className="passShowHide"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { left: "10px", top: "42px" }
                : { right: "10px", top: "42px" }
            }
            onClick={() => setShowPass(!showPass)}
          />
        </div>
        <button
          type="submit"
          className="btn btnPrimary"
          // onClick={(e) => verifyEmailHandler(e)}
        >
          {t("Change Email")}
        </button>
      </form>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyle}
      >
        <h4>{t("Verify New Email")}</h4>
        <h6>
          {t("We have sent you a verification code to your new email")}{" "}
          <b>{newEmail}</b> {t("to verify it’s you")}.{" "}
          {t("Please enter the code")}
        </h6>
        <AuthCode
          characters={4}
          onChange={(auth) => setCode(auth)}
          containerClassName="otp-container"
          inputClassName="input otp-cell"
        />
        <p className="counter-line">
          {" "}
          {t("session")} &nbsp;{" "}
          {<Countdown value={deadline} onFinish={onFinish} format="mm:ss" />}{" "}
          &nbsp; {t("minutes")}{" "}
        </p>
        <p>
          {t("dontReceive")} &nbsp;
          <span
            style={style}
            onClick={() => {
              setStyle({
                color: "#268c55",
                pointerEvents: "none",
                cursor: "not-allowed",
              });
            }}
          >
            {t("Resend")}
          </span>
        </p>
        <button
          className="btn btnPrimary verify-button"
          onClick={ChangeEmailHandler}
        >
          {t("Verify My New Email")}
        </button>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>
    </div>
  );
};

export default ChangeEmail;
