import React from 'react';
import { Link } from 'react-router-dom';

import './verifyFail.css';

const verifyFail = () => {
  return (
    <div className="checkMail">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 text-center">
            <Link href="#" className="btn">
              <h2>
                Holly<span className="green-color">desk.</span>
              </h2>
            </Link>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 text-center">
            <img className="img-fluid" src="images/check.png" alt="check" />
            <h1>Oops, You haven't verified your email!</h1>
            <p>
              We've Sent you an email verification link, give it a click to make
              sure it's you !
              <Link href="#" className="btn">
                Resend Email
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default verifyFail;
