import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  uploadPictures,
  resetUpload,
  removePic,
  initialPics,
} from "../../../../../actions/uploadActions";
import { reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";

import "./uploadImages.css";
import Loader from "react-loader-spinner";
import fileUploaded from "../../../../../assets/images/fileUploaded.png";

const UploadImagesWithEdit = (props) => {
  const [files, setfiles] = useState([]);
  const { t } = useTranslation();
  //   const [src, setSrc] = useState(file.path)
  //   const [errored, setErrored] = useState(false)

  useEffect(() => {
    props.resetUpload();
    props.initialPics(props.draftImages);
  }, []);

  const deleteImage = (index) => {
    props.removePic(index);
  };

  const handleChange = (event) => {
    let files_ = event.target.files;
    if (files_.length > 0) {
      setfiles([...files, ...files_]);
      let formData = new FormData();
      Array.from(files_).forEach((file) => {
        formData.append("file", file);
      });
      props.uploadPictures(formData, props.name);
    }
  };

  const renderEmptyImages = (count) => {
    let emptyImages = [];
    for (var i = 0; i < count; i++) {
      emptyImages.push(
        <div className="col-md-2">
          <div className="imagePreview"></div>
        </div>
      );
    }
    return emptyImages;
  };

  //   const onErrorHandler = () => {
  //     if (!errored) {
  //         setSrc(fileUploaded)
  //         setErrored(true)
  //     }
  // }

  const renderImages = () => {
    let { files } = props.upload;
    let images = [];
    if (files && files.length > 0) {
      files.map((file, index) => {
        const fileType = file.name.split(".")[1];
        let source = fileUploaded;
        if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
          source = file.path;
        }
        images.push(
          <div className="col-md-2 image-area">
            <div className="imagePreview ">
              <img
                src={source}
                className="img-fluid"
                alt="pdf file" /*onError={onErrorHandler}*/
              />
            </div>
            <button
              id={index}
              className="remove-image"
              onClick={() => {
                deleteImage(index);
              }}
              style={{ display: "inline", zIndex: "100" }}
            >
              &#215;
            </button>
          </div>
        );
      });
      return (images = [...images, ...renderEmptyImages(6 - images.length)]);
    } else if (files && files.length === 0) {
      return renderEmptyImages(6);
    }
  };

  return (
    <div className="row uploadImages">
      <h2
        className="mb-4"
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { width: "100%", textAlign: "right" }
            : { width: "100%", textAlign: "left" }
        }
      >
        {t("fillRI2")}
      </h2>

      <div className="col-md-12">
        <div className="image-upload text-center">
          {props.upload.loading ? (
            <div className="loading">
              <Loader type="Rings" />
            </div>
          ) : props.upload.files && props.upload.files.length > 0 ? (
            <React.Fragment>
              <label htmlFor="file-input-success">
                <img
                  src="/images/icons8-checkmark.svg"
                  alt=""
                  className="mb-5"
                />
                <h4 className="mb-3">{t("imgUploaded")}</h4>
                <span className="upload-text">{t("imgUploaded2")} </span>
              </label>
              <input
                id="file-input-success"
                multiple={true}
                type="file"
                name="image"
                accept=".jpg, .jpeg, .png, .csv, .pdf"
                onChange={(e) => handleChange(e)}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <label htmlFor="file-input">
                <img
                  src="/images/icons8-pictures-folder.svg"
                  alt="upload"
                  className="mb-5"
                />
                <h4 className="mb-3">{t("imgUploaded3")} </h4>
                <span className="text-muted">{t("imgUploaded4")} </span>
              </label>
              <input
                id="file-input"
                multiple={true}
                type="file"
                name="image"
                accept=".jpg, .jpeg, .png, .csv, .pdf"
                onChange={(e) => handleChange(e)}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="row">{renderImages()}</div>
      </div>
    </div>
  );
};

const reduxMiddleware = reduxForm({
  form: "uploadImages",
  enableReinitialize: true,
})(UploadImagesWithEdit);

const mapStateToProps = (state) => {
  return {
    upload: state.upload,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, {
  uploadPictures,
  resetUpload,
  removePic,
  initialPics,
})(reduxMiddleware);
