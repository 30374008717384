import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../components/Spinner";
import { links } from "../utils/links";

import PayAsYouGo from "../containers/payAsYouGo/Home";
const CreatePAYG = lazy(() => import("../containers/payAsYouGo/Create"));
const Contacts = lazy(() => import("../containers/payAsYouGo/Contacts"));
// const EditPAYG = lazy(() => import("./containers/PayrollDetails"));
const PAYGDetails = lazy(() => import("../containers/payAsYouGo/Details"));

const PayAsYOuGo = () => {
  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            path={links.payAsYouGoLinks.home}
            exact
            component={PayAsYouGo}
          />
          <Route
            path={links.payAsYouGoLinks.create}
            exact
            component={CreatePAYG}
          />
          <Route path={links.payAsYouGoLinks.contacts} component={Contacts} />
          <Route
            path={links.payAsYouGoLinks.detailsRoute}
            exact
            component={PAYGDetails}
          />
          <Redirect to={links.payAsYouGoLinks.home} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default PayAsYOuGo;
