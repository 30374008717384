import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import { setLoading, removeLoading } from "./loadingActions";
import { tokenConfig } from "./tokenActions";
import {
  LIST_PENDING_REQUESTS,
  APPROVE_REQUESTS,
  LIST_APPROVED_REQUESTS,
  LIST_DECLINED_REQUESTS,
  DECLINE_REQUESTS,
  LIST_PENDING_INADVANCE_REQUESTS,
  LIST_APPROVED_INADVANCE_REQUESTS,
  LIST_DECLINED_INADVANCE_REQUESTS,
  APPROVE_INADCANCE_REQUESTS,
  DECLINE_INADCANCE_REQUESTS,
  APPROVE_RECONCILE_REQUESTS,
  DECLINE_RECONCILE_REQUESTS,
  LIST_RECONCILED_INADVANCE_REQUESTS
} from "./types";
import cogoToast from "cogo-toast";
import { loadRequestCount, loadInadvanceRequestCount } from "./authActions";
import i18n from "../translations/i18n";
export const loadingRequests = query => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .get(`/requests/pending?${query}`, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: LIST_PENDING_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const loadingApprovedRequests = query => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/requests/approved?${query}`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_APPROVED_REQUESTS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const loadingDecilnedRequests = query => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadInadvanceRequestCount();
    loadRequestCount();

    api
      .get(`/requests/declined?${query}`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_DECLINED_REQUESTS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const approveRequests = (requests, categoryID) => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  const requestsIDs = requests.map(req => req._id);
  let data;

  let url = "";
  if (getState().auth.user.role === "team-manager") {
    url = "approve-manager";
    data = { requestsIDs };
  } else if (getState().auth.user.role === "accountant") {
    url = "approve-accountant";
    data = { requestsIDs, categoryID };
  }

  api
    .post(`/requests/${url}`, JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: APPROVE_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
        dispatch(loadingRequests());
        cogoToast.success(i18n.t("Request Approved successfully"));
      } else if (res.data.status === 0) {
        dispatch(removeLoading());
        dispatch(loadingRequests());
        cogoToast.error(i18n.t(res.data.message));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "APPROVE_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const declineRequests = (requests, callback) => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  const requestsIDs = requests.map(req => req._id);
  const data = { requestsIDs };

  let url = "";
  if (getState().auth.user.role === "team-manager") {
    url = "decline-manager";
  } else if (getState().auth.user.role === "accountant") {
    url = "decline-accountant";
  }

  api
    .post(`/requests/${url}`, JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: DECLINE_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
        dispatch(loadingRequests());
        callback && callback();
        cogoToast.success(i18n.t("Request declined successfully"));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "APPROVE_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

//------------------------------------------------------------------------------

export const loadingSuppliersRequests = () => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .get(`/supplier/pending`, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: LIST_PENDING_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const loadingSuppliersApprovedRequests = () => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/supplier/approved`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_APPROVED_REQUESTS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const loadingSuppliersSatRequests = () => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/supplier/setaled`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_APPROVED_REQUESTS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const loadingSuppliersDecilnedRequests = () => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/supplier/declined`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_DECLINED_REQUESTS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

//---------------------------------------------------------------------------

export const loadingInAdvanceRequests = query => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .get(`/inadvance/pending?${query}`, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: LIST_PENDING_INADVANCE_REQUESTS,
          payload: res.data.data
        });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const loadingApprovedInAdvanceRequests = query => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/inadvance/approved?${query}`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({
            type: LIST_APPROVED_INADVANCE_REQUESTS,
            payload: res.data.data
          });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const loadingReconciledInAdvanceRequests = query => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/inadvance/reconciled?${query}`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({
            type: LIST_RECONCILED_INADVANCE_REQUESTS,
            payload: res.data.data
          });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const loadingDecilnedInAdvanceRequests = query => {
  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(`/inadvance/declined?${query}`, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({
            type: LIST_DECLINED_INADVANCE_REQUESTS,
            payload: res.data.data
          });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "LIST_PENDING_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const approveInAdvanceRequests = (requests, categoryID) => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  let requestsIDs = requests.map(req => req._id);
  let mony;
  let data;

  let url = "";
  if (getState().auth.user.role === "team-manager") {
    url = "approve-manager";
    data = { requestsIDs, mony };
  } else if (getState().auth.user.role === "accountant") {
    url = "approve-accountant";
    mony = requests.map(req => req.mony);
    data = { requestsIDs, mony, categoryID };
  }

  api
    .post(`/inadvance/${url}`, JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: APPROVE_INADCANCE_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
        dispatch(loadingInAdvanceRequests());
        cogoToast.success(i18n.t("Request Approved successfully"));
      } else if (res.data.status === 0) {
        dispatch(removeLoading());
        dispatch(loadingInAdvanceRequests());
        cogoToast.error(i18n.t(res.data.message));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "APPROVE_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const declineInAdvanceRequests = (
  requests,
  categoryID,
  caseID,
  callback
) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  const requestsIDs = requests.map(req => req._id);
  const data = { requestsIDs, categoryID, caseID };

  let url = "";
  if (getState().auth.user.role === "team-manager") {
    url = "decline-manager";
  } else if (getState().auth.user.role === "accountant") {
    url = "decline-accountant";
  }

  api
    .post(`/inadvance/${url}`, JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: DECLINE_INADCANCE_REQUESTS,
          payload: res.data.data
        });
        dispatch(removeLoading());
        dispatch(loadingInAdvanceRequests());
        callback && callback();
        cogoToast.success(i18n.t("Request declined successfully"));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "APPROVE_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const approveReconcileRequests = request => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  // const requestID = requests.map((req) => req._id);
  const data = request;

  let url = "";
  if (getState().auth.user.role === "team-manager") {
    url = "approve-reconcile-manager";
  } else if (getState().auth.user.role === "accountant") {
    url = "approve-reconcile-accountant";
  }

  api
    .post(`/inadvance/${url}`, JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: APPROVE_RECONCILE_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
        dispatch(loadingApprovedInAdvanceRequests());
        cogoToast.success(i18n.t("Request Approved successfully"));
      } else if (res.data.status === 0) {
        dispatch(removeLoading());
        dispatch(loadingApprovedInAdvanceRequests());
        cogoToast.error(i18n.t(res.data.message));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "APPROVE_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const declineReconcileRequests = request => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  // const requestID = requests.map((req) => req._id);
  const data = request;

  let url = "";
  if (getState().auth.user.role === "team-manager") {
    url = "decline-reconcile-manager";
  } else if (getState().auth.user.role === "accountant") {
    url = "decline-reconcile-accountant";
  }

  api
    .post(`/inadvance/${url}`, JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: DECLINE_RECONCILE_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
        dispatch(loadingApprovedInAdvanceRequests());
        cogoToast.success(i18n.t("Request Approved successfully"));
      } else if (res.data.status === 0) {
        dispatch(removeLoading());
        dispatch(loadingApprovedInAdvanceRequests());
        cogoToast.error(i18n.t(res.data.message));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "APPROVE_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};
