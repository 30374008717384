import React, { useState } from "react";
import { Router, Route, Redirect, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Request from "./request/request";
import NewSupplier from "./newSupplier/newSupplier";
import Success from "./success/success";
import SuccessNew from "./success/successNew";
import history from "../../../../../history";
import ExpensePage from "./supplierExpences/index";
import PendingExpense from "./supplierExpences/pendingExpense/pendingExpense";
import ApprovedExpense from "./supplierExpences/approvedExpense/approvedExpense";
import DeclinedExpense from "./supplierExpences/declinedExpense/declindedExpense";
import SateledExpense from "./supplierExpences/sateledExpense/approvedExpense";
import SuppliersList from "./suppliersList/suppliersList";

import Request_ from "./supplierExpences/request/index";
import Supplier from "./supplierView/index";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiChevronDoubleLeft } from "react-icons/hi";

import "./suppliers.css";

const Suppliers = (props) => {
  const [toggleState, setToggleState] = useState(1);

  const { t } = useTranslation();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  let routes = (
    <div className="col-md-12">
      <Router history={history}>
        {(props.auth.user && props.auth.user.role === "user") ||
        props.history.location.pathname ===
          "/dashboard/suppliers/expenses/request" ||
        props.history.location.pathname ===
          "/dashboard/suppliers/view-supplier" ||
        props.history.location.pathname ===
          "/dashboard/suppliers/new-request" ? (
          ""
        ) : props.auth.user.role === "accountant" ? (
          <div className="filter-nav-backgroung">
            <div className="filter-nav expenseFilter">
              {localStorage.getItem("i18nextLng") == "en" ? (
                <span>
                  <h6>{t("teamReq")}</h6> &nbsp;{" "}
                  <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
                  <h6>{t("Supplier")}</h6>
                </span>
              ) : (
                <span>
                  <h6>{t("teamReq")}</h6> &nbsp;{" "}
                  <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
                  <h6>{t("Supplier")}</h6>
                </span>
              )}
              <Link
                to="/dashboard/suppliers/expenses/pending"
                className={
                  // toggleState === 1
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/pending"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(1);
                }}
              >
                {t("pending")}
              </Link>
              <Link
                to="/dashboard/suppliers/expenses/approved"
                className={
                  // toggleState === 2
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/approved"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(2);
                }}
              >
                {t("approved")}
              </Link>
              <Link
                to="/dashboard/suppliers/expenses/declined"
                className={
                  // toggleState === 3
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/declined"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(3);
                }}
              >
                {t("declined")}
              </Link>
              <Link
                to="/dashboard/suppliers/expenses/sateled"
                className={
                  // toggleState === 4
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/sateled"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(4);
                }}
              >
                {t("Settled")}
              </Link>
              <Link
                to="/dashboard/suppliers/expenses/supplierList"
                className={
                  // toggleState === 5
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/supplierList"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(5);
                }}
              >
                {t("Suppliers List")}
              </Link>
            </div>
          </div>
        ) : (
          <div className="filter-nav-backgroung">
            <div className="filter-nav expenseFilter">
              {localStorage.getItem("i18nextLng") == "en" ? (
                <span>
                  <h6>{t("teamReq")}</h6> &nbsp;{" "}
                  <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
                  <h6>{t("Supplier")}</h6>
                </span>
              ) : (
                <span>
                  <h6>{t("teamReq")}</h6> &nbsp;{" "}
                  <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
                  <h6>{t("Supplier")}</h6>
                </span>
              )}
              <Link
                to="/dashboard/suppliers/expenses/pending"
                className={
                  // toggleState === 1
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/pending"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(1);
                }}
              >
                {t("pending")}
              </Link>
              <Link
                to="/dashboard/suppliers/expenses/approved"
                className={
                  // toggleState === 2
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/approved"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(2);
                }}
              >
                {t("approved")}
              </Link>
              <Link
                to="/dashboard/suppliers/expenses/declined"
                className={
                  // toggleState === 3
                  window.location.pathname ===
                  "/dashboard/suppliers/expenses/declined"
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  toggleTab(3);
                }}
              >
                {t("declined")}
              </Link>
            </div>
          </div>
        )}

        <div className="col-md-12 supplier-container">
          <Switch>
            <Route
              path="/dashboard/suppliers/new-request"
              exact
              component={Request}
            />
            <Route
              path="/dashboard/suppliers/reimbursment/success"
              exact
              component={Success}
            />
            <Route
              path="/dashboard/suppliers/reimbursment/newRequestSuccess"
              exact
              component={SuccessNew}
            />
            <Route
              path="/dashboard/suppliers/add-supplier"
              exact
              component={NewSupplier}
            />
            <Route
              path="/dashboard/suppliers/expenses"
              exact
              component={ExpensePage}
            />{" "}
            <Route
              path="/dashboard/suppliers/expenses/pending"
              exact
              component={PendingExpense}
            />
            <Route
              path="/dashboard/suppliers/expenses/approved"
              exact
              component={ApprovedExpense}
            />
            <Route
              path="/dashboard/suppliers/expenses/declined"
              exact
              component={DeclinedExpense}
            />
            <Route
              path="/dashboard/suppliers/expenses/sateled"
              exact
              component={SateledExpense}
            />
            <Route
              path="/dashboard/suppliers/expenses/request"
              exact
              component={Request_}
            />
            <Route
              path="/dashboard/suppliers/view-supplier"
              exact
              component={Supplier}
            />
            <Route
              path="/dashboard/suppliers/expenses/supplierList"
              exact
              component={SuppliersList}
            />
            {props.auth.user && props.auth.user.role === "user" ? (
              <Redirect to="/dashboard/suppliers/my-requests" />
            ) : (
              <Redirect to="/dashboard/suppliers/expenses/pending" />
            )}
          </Switch>
        </div>
      </Router>
    </div>
  );
  return <div>{routes}</div>;
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.loading,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(Suppliers);
