import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@material-ui/data-grid";
import { Link, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiChevronDoubleLeft } from "react-icons/hi";

import "./my-requests.css";
import { listSupplierRequests } from "../../../../../../actions/requestsActions";
import addNewReq from "../../../../../../assets/images/addNewReq.png";

const MyRequests = (props) => {
  const [renderRequests, setRenderRequests] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState(undefined);
  const [showModal, setShowModal] = React.useState(false);
  const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");

  const count = useSelector((state) => state.request.countSupplier);
  const totalCount = useSelector((state) => state.request.totalCountSupplier);

  const history = useHistory();

  useEffect(() => {
    props.listSupplierRequests(filter, page);
  }, []);

  useEffect(() => {
    props.listSupplierRequests(filter, page);
  }, [filter, page]);

  useEffect(() => {
    setRenderRequests(props.request.requestsSupplier);
    setSelectedRequest(props.request.requestsSupplier[0]);
  }, [props.request.requestsSupplier]);

  const { t } = useTranslation();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const columns = [
    {
      field: "number",
      headerName: t("ID"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "from.nameEnglish",
      headerName: t("Supplier"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
      valueGetter: (params) =>
        params.row?.from?.nameDisplay && params.row?.from?.nameDisplay !== ""
          ? params.row?.from?.nameDisplay
          : params.row?.from?.nameEnglish,
    },
    {
      field: "paidFor",
      headerName: t("paidFor"),
      width: 230,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "amount",
      headerName: t("amount"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "paidOn",
      headerName: t("paidOnS_"),
      sortable: true,
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter MuiDataGrid-colCellCenter",
      valueGetter: (params) => params.row.paidOn.split("T")[0],
    },
    {
      field: "short_status",
      headerName: t("status"),
      width: 150,
      cellClassName: `MuiDataGrid-cellCenter MuiDataGrid-colCellCenter`,
      renderCell: (params) => {
        if (params.row.status.includes("approve")) {
          return (
            <p
              style={{
                margin: "auto",
                color: "#268c55",
                backgroundColor: "#e3fbee",
                height: "30px",
                lineHeight: "2",
              }}
            >
              {t("approved")}
            </p>
          );
        } else if (params.row.status.includes("pend")) {
          return (
            <p
              style={{
                margin: "auto",
                color: "#f4a800",
                backgroundColor: "#fff4dd",
                height: "30px",
                lineHeight: "2",
              }}
            >
              {t("pending")}
            </p>
          );
        } else if (params.row.status.includes("Declined")) {
          return (
            <p
              style={{
                margin: "auto",
                color: "#e32424",
                backgroundColor: "#ffebeb",
                height: "30px",
                lineHeight: "2",
              }}
            >
              {t("declined")}
            </p>
          );
        }
      },
    },
    {
      field: "action",
      headerName: t("action"),
      sortable: true,
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter MuiDataGrid-colCellCenter",
      renderCell: (params) => (
        <button
          className="btn btnPrimary"
          style={{ margin: "auto" }}
          onClick={() => {
            history.push(
              `/dashboard/suppliers/expenses/request?num=${params.row._id}`
            );
          }}
        >
          {t("view")}
        </button>
      ),
    },
  ];

  const first = () => 1 + (page * 10 - 10);

  const last = () => {
    const number = 10 * page;
    return number > count ? count : number;
  };

  const nextPage = () => {
    const lastPage = Math.ceil(count / 10);
    page === lastPage ? setPage(lastPage) : setPage(page + 1);
  };

  const previousPage = () => {
    page === 1 ? setPage(1) : setPage(page - 1);
  };

  return (
    <div className="col-md-12">
      <div className="filter-nav-backgroung">
        <div className="filter-nav expenseFilter">
          {localStorage.getItem("i18nextLng") == "en" ? (
            <span>
              <h6>{t("requests")}</h6> &nbsp;{" "}
              <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
              <h6>{t("Supplier")}</h6>
            </span>
          ) : (
            <span>
              <h6>{t("requests")}</h6> &nbsp;{" "}
              <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
              <h6>{t("Supplier")}</h6>
            </span>
          )}
          <p
            className={
              toggleState === 1
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("all");
              setPage(1);
              toggleTab(1);
            }}
          >
            {t("all")}
          </p>
          <p
            className={
              toggleState === 2
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("approved");
              setPage(1);
              toggleTab(2);
            }}
          >
            {t("approved")}
          </p>
          <p
            className={
              toggleState === 3
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("pending");
              setPage(1);
              toggleTab(3);
            }}
          >
            {t("pending")}
          </p>
          <p
            className={
              toggleState === 4
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("declined");
              setPage(1);
              toggleTab(4);
            }}
          >
            {t("declined")}
          </p>
        </div>
      </div>
      <div className="mainPage">
        {props.request.requestsSupplier ? (
          totalCount > 0 ? (
            <div className="container-fluid page-content">
              <div className="requests-head">
                <h1
                  className="title-h1"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                >
                  {t("Supplier Requests") + ` (${totalCount})`}
                </h1>
                <div>
                  <Link
                    className="btn btnPrimary btn-new-req"
                    to="/dashboard/suppliers/new-request"
                  >
                    <AddRoundedIcon className="add-sign" /> {t("newReq")}
                  </Link>{" "}
                  <br />
                  {/*<p className='make-new' onClick={() => setModalTwoIsOpen(true)}>
                            {t('newRequest')}
                        </p>*/}
                </div>
              </div>
              <div style={{ height: 350, width: "100%", marginTop: "20px" }}>
                <DataGrid
                  rows={renderRequests}
                  columns={columns}
                  pageSize={10}
                  disableColumnMenu
                  hideFooter
                  onRowClick={(params) => {
                    const clickedRequest = props.request.requests.find(
                      (req) => {
                        return req.id == params.row.id;
                      }
                    );
                    setSelectedRequest(clickedRequest);
                    setShowModal(true);
                    console.log(params);
                  }}
                />
              </div>
              <div className="team-pagination">
                <p>
                  {first()} - {last()} of {count}
                </p>
                <MdKeyboardArrowLeft
                  className="pagination-icon"
                  onClick={() => previousPage()}
                />
                <p>{page}</p>
                <MdKeyboardArrowRight
                  className="pagination-icon"
                  onClick={() => nextPage()}
                />
              </div>
            </div>
          ) : (
            <div style={{ backgroundColor: "#f1f3f5", height: "80vh" }}>
              <div
                style={{
                  width: "30%",
                  height: "50%",
                  margin: "auto",
                  textAlign: "center",
                  paddingTop: "100px",
                }}
              >
                <img
                  src={addNewReq}
                  alt="add new request"
                  style={{ marginBottom: "30px" }}
                />
                <p className="text-muted">{t("noList")}</p>
                <Link
                  to="/dashboard/suppliers/new-request"
                  className="btn btnPrimary"
                >
                  {t("newReq")}
                </Link>
                <p className="make-new" onClick={() => setModalTwoIsOpen(true)}>
                  {t("newRequest")}
                </p>
              </div>
            </div>
          )
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.request,
    errors: state.error,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, { listSupplierRequests })(MyRequests);
