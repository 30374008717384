import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import { Modal, Container, Row, Col } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import noReceipts from "../../../../../../assets/images/noReceipts.png";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiChevronDoubleLeft } from "react-icons/hi";
import Avatar from "antd/lib/avatar/avatar";

import "./my-requests.css";
import { listRequests } from "../../../../../../actions/requestsActions";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Reimbursement from "../reimbursement/reimbursement";
import addNewReq from "../../../../../../assets/images/addNewReq.png";

const MyRequests = (props) => {
  const [renderRequests, setRenderRequests] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState(undefined);
  const [showModal, setShowModal] = React.useState(false);
  const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");

  const count = useSelector((state) => state.request.count);
  const totalCount = useSelector((state) => state.request.totalCount);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    props.listRequests(filter, page);
  }, []);

  useEffect(() => {
    props.listRequests(filter, page);
  }, [filter, page]);

  useEffect(() => {
    setRenderRequests(props.request.requests);
    setSelectedRequest(props.request.requests[0]);
  }, [props.request.requests]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { t } = useTranslation();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const columns = [
    {
      field: "number",
      headerName: t("ID"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "paidTo",
      headerName: t("companyPaidTo"),
      width: 200,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "paidFor",
      headerName: t("paidFor"),
      width: 230,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "amount",
      headerName: t("amount"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "short_status",
      headerName: t("status"),
      width: 150,
      cellClassName: `MuiDataGrid-cellCenter MuiDataGrid-colCellCenter`,
      renderCell: (params) => {
        if (params.row.short_status === "approved") {
          return (
            <p
              style={{
                margin: "auto",
                color: "#268c55",
                backgroundColor: "#e3fbee",
                height: "30px",
                lineHeight: "2",
              }}
            >
              {t("approved")}
            </p>
          );
        } else if (params.row.short_status === "pending") {
          return (
            <p
              style={{
                margin: "auto",
                color: "#f4a800",
                backgroundColor: "#fff4dd",
                height: "30px",
                lineHeight: "2",
              }}
            >
              {t("pending")}
            </p>
          );
        } else if (params.row.short_status === "declined") {
          return (
            <p
              style={{
                margin: "auto",
                color: "#e32424",
                backgroundColor: "#ffebeb",
                height: "30px",
                lineHeight: "2",
              }}
            >
              {t("declined")}
            </p>
          );
        }
      },
    },
    {
      field: "createdAt",
      headerName: t("time"),
      sortable: true,
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter MuiDataGrid-colCellCenter",
      valueGetter: (params) => params.row.createdAt.split("T")[0],
    },
  ];

  const reqStatusClass = () => {
    if (selectedRequest.short_status === "approved") {
      return {
        color: "#268c55",
        backgroundColor: "#e3fbee",
        marginTop: "15px",
      };
    } else if (selectedRequest.short_status === "declined") {
      return {
        color: "#e32424",
        backgroundColor: "#ffebeb",
        marginTop: "15px",
      };
    } else if (selectedRequest.short_status === "pending") {
      return {
        color: "#f4a800",
        backgroundColor: "#fff4dd",
        marginTop: "15px",
      };
    }
  };

  const first = () => 1 + (page * 10 - 10);

  const last = () => {
    const number = 10 * page;
    return number > count ? count : number;
  };

  const nextPage = () => {
    const lastPage = Math.ceil(count / 10);
    page === lastPage ? setPage(lastPage) : setPage(page + 1);
  };

  const previousPage = () => {
    page === 1 ? setPage(1) : setPage(page - 1);
  };

  return (
    <div>
      <div className="filter-nav-backgroung">
        <div className="filter-nav expenseFilter">
          {localStorage.getItem("i18nextLng") == "en" ? (
            <span>
              <h6>{t("requests")}</h6> &nbsp;{" "}
              <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
              <h6>{t("Reimbursement")}</h6>
            </span>
          ) : (
            <span>
              <h6>{t("requests")}</h6> &nbsp;{" "}
              <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
              <h6>{t("Reimbursement")}</h6>
            </span>
          )}
          <p
            className={
              toggleState === 1
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("all");
              setPage(1);
              toggleTab(1);
            }}
          >
            {t("all")}
          </p>
          <p
            className={
              toggleState === 2
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("approved");
              setPage(1);
              toggleTab(2);
            }}
          >
            {t("approved")}
          </p>
          <p
            className={
              toggleState === 3
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("pending");
              setPage(1);
              toggleTab(3);
            }}
          >
            {t("pending")}
          </p>
          <p
            className={
              toggleState === 4
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setFilter("declined");
              setPage(1);
              toggleTab(4);
            }}
          >
            {t("declined")}
          </p>
        </div>
      </div>
      <div className="mainPage">
        {props.request.requests ? (
          totalCount > 0 ? (
            <div className="container-fluid page-content">
              <div className="requests-head">
                <h1
                  className="title-h1"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                >
                  {t("myRequest") + ` (${totalCount})`}
                </h1>
                <div>
                  <Link
                    className="btn btnPrimary btn-new-req"
                    to="/dashboard/requests/reimbursment/request"
                  >
                    <AddRoundedIcon className="add-sign" /> {t("newReq")}
                  </Link>{" "}
                  <br />
                  <p
                    className="make-new"
                    onClick={() => setModalTwoIsOpen(true)}
                  >
                    {t("newRequest")}
                  </p>
                </div>
              </div>
              {/*<div className="filter">
                    <p 
                    className={toggleState === 1 ? "tab active-tab" : "tab"}
                    onClick={() => {
                        setFilter('all');
                        setPage(1);
                        toggleTab(1)
                    }}>
                        {t('all')}
                    </p>
                    <p 
                    className={toggleState === 2 ? "tab active-tab" : "tab"}
                    onClick={() => {
                        setFilter('approved');
                        setPage(1);
                        toggleTab(2)
                    }}>
                        {t('approved')}
                    </p>
                    <p 
                    className={toggleState === 3 ? "tab active-tab" : "tab"}
                    onClick={() => {
                        setFilter('pending');
                        setPage(1);
                        toggleTab(3)
                    }}>
                        {t('pending')}
                    </p>
                    <p 
                    className={toggleState === 4 ? "tab active-tab" : "tab"}
                    onClick={() => {
                        setFilter('declined');
                        setPage(1);
                        toggleTab(4)
                    }}>
                        {t('declined')}
                    </p>
                  </div>*/}
              <div style={{ height: 350, width: "100%", marginTop: "20px" }}>
                <DataGrid
                  rows={renderRequests}
                  columns={columns}
                  pageSize={10}
                  disableColumnMenu
                  hideFooter
                  onRowClick={(params) => {
                    const clickedRequest = props.request.requests.find(
                      (req) => {
                        return req.id == params.row.id;
                      }
                    );
                    setSelectedRequest(clickedRequest);
                    setShowModal(true);
                    console.log(params);
                  }}
                />
              </div>
              <div className="team-pagination">
                <p>
                  {first()} - {last()} of {count}
                </p>
                <MdKeyboardArrowLeft
                  className="pagination-icon"
                  onClick={() => previousPage()}
                />
                <p>{page}</p>
                <MdKeyboardArrowRight
                  className="pagination-icon"
                  onClick={() => nextPage()}
                />
              </div>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
              >
                <Modal.Body
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  <Container>
                    {selectedRequest ? (
                      <Row>
                        <Col xs={12} md={6} lg={6}>
                          {selectedRequest ? (
                            selectedRequest.images.length > 0 ? (
                              <div
                                id="carouselExampleIndicators"
                                class="carousel slide"
                                data-ride="carousel"
                              >
                                <ol
                                  class="carousel-indicators"
                                  style={{ display: "none" }}
                                >
                                  {selectedRequest.images.map((obj, index) => {
                                    return (
                                      <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to={index}
                                        className="active"
                                      ></li>
                                    );
                                  })}
                                </ol>
                                <div class="carousel-inner">
                                  {selectedRequest.images.map((obj, index) => {
                                    return (
                                      <div
                                        className={
                                          index == 0
                                            ? "active carousel-item"
                                            : "carousel-item"
                                        }
                                      >
                                        {obj.path.split(".").pop() == "pdf" ? (
                                          <div style={{ overflow: "auto" }}>
                                            <Document
                                              style={{ width: "100%" }}
                                              file={obj.path}
                                              onLoadSuccess={
                                                onDocumentLoadSuccess
                                              }
                                            >
                                              <Page
                                                width={400}
                                                style={{ width: "100%" }}
                                                pageNumber={pageNumber}
                                              />
                                            </Document>
                                          </div>
                                        ) : (
                                          <img
                                            class="d-block w-100"
                                            src={obj.path}
                                            alt="First slide"
                                          />
                                        )}
                                        <a
                                          href={obj.path}
                                          download
                                          style={{
                                            position: "absolute",
                                            bottom: "10px",
                                            left: "120px",
                                            zIndex: "150",
                                          }}
                                        >
                                          <button className="btn btnPrimary">
                                            <GetAppRoundedIcon /> {t("Down")}
                                          </button>
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                                <a
                                  class="carousel-control-prev"
                                  href="#carouselExampleIndicators"
                                  role="button"
                                  data-slide="prev"
                                >
                                  <span
                                    class="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="sr-only">Previous</span>
                                </a>
                                <a
                                  class="carousel-control-next"
                                  href="#carouselExampleIndicators"
                                  role="button"
                                  data-slide="next"
                                >
                                  <span
                                    class="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </div>
                            ) : (
                              <div className="no-receipt">
                                <img src={noReceipts} alt="no receipt" />
                                <div
                                  className="red-warning"
                                  style={{ width: "95%" }}
                                >
                                  <div className="warning-head">
                                    <WarningRoundedIcon /> <p>{t("noReq1")}</p>
                                  </div>
                                  <p>{t("noReq2")}</p>
                                </div>
                              </div>
                            )
                          ) : (
                            <div></div>
                          )}
                        </Col>

                        <Col xs={12} md={6} lg={6}>
                          <div className="req-info">
                            <h2>
                              {t("request")} #{selectedRequest.number}
                            </h2>
                            <div
                              className="req-owner-info"
                              style={{
                                width: "100%",
                                justifyContent: "flex-start",
                              }}
                            >
                              {user.image !== "" ? (
                                <Avatar
                                  src={user.image}
                                  shape="square"
                                  size={40}
                                />
                              ) : (
                                <Avatar
                                  style={{
                                    backgroundColor: "#d6edff",
                                    verticalAlign: "middle",
                                    color: "#405261",
                                  }}
                                  size="large"
                                  shape="square"
                                >
                                  {user.firstName}
                                </Avatar>
                              )}
                              <span
                                className="d-inline-block"
                                style={
                                  localStorage.getItem("i18nextLng") == "ar"
                                    ? {
                                        width: "fit-content",
                                        textAlign: "right",
                                        margin: "0px 10px",
                                      }
                                    : {
                                        width: "fit-content",
                                        textAlign: "left",
                                        margin: "0px 10px",
                                      }
                                }
                              >
                                <b>{`${user.firstName} ${user.lastName}`}</b>{" "}
                                <br />
                                <b style={{ color: "#a8acaf" }}>
                                  {selectedRequest.createdAt.split("T")[0]}
                                </b>
                              </span>
                            </div>
                            <p className="req-status" style={reqStatusClass()}>
                              {t(selectedRequest.status)}
                            </p>
                            <div className="data-row">
                              <div className="data-element">
                                <h5 className="label">{t("amount")}</h5>
                                <p className="value popup-data">
                                  {selectedRequest.currency}{" "}
                                  {selectedRequest.amount}
                                </p>
                              </div>
                              <div className="data-element">
                                <h5 className="label">{t("paidOn")}</h5>
                                <p className="value popup-data">
                                  {selectedRequest.paidOn.split("T")[0]}
                                </p>
                              </div>
                            </div>
                            <div className="data-row">
                              <div className="data-element">
                                <h5 className="label">{t("companyPaidTo")}</h5>
                                <p className="value popup-data">
                                  {selectedRequest.paidTo}
                                </p>
                              </div>
                            </div>
                            <div className="paid-for">
                              <h5 className="label">{t("paidFor")}</h5>
                              <p className="value popup-data">
                                {selectedRequest.paidFor}
                              </p>
                            </div>
                            <div className="data-row">
                              <div className="data-element">
                                <h5 className="label">{t("Category")}</h5>
                                <p className="value popup-data">
                                  {!selectedRequest?.category?.name ||
                                  selectedRequest?.category?.name === ""
                                    ? t("No category")
                                    : t(selectedRequest.category.name)}
                                </p>
                              </div>
                              <div className="data-element">
                                <h5 className="label">{t("Case")}</h5>
                                <p className="value popup-data">
                                  {!selectedRequest?.case?.name ||
                                  selectedRequest?.case?.name === ""
                                    ? t("No case")
                                    : t(selectedRequest.case.name)}
                                </p>
                              </div>
                            </div>
                            {selectedRequest.short_status === "pending" ? (
                              <Link
                                className="btn btnPrimary"
                                to={`/dashboard/requests/reimbursment/editRequest/${selectedRequest.id}`}
                                onClick={() => setShowModal(false)}
                              >
                                {t("Edit request")}
                              </Link>
                            ) : (
                              <button
                                className="btn btnPrimary"
                                onClick={() => setShowModal(false)}
                              >
                                {t("Close Request Review")}
                              </button>
                            )}
                          </div>
                        </Col>
                        <CloseOutlinedIcon
                          className="close-icon"
                          onClick={() => setShowModal(false)}
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { right: "auto", left: "5px" }
                              : { right: "5px", left: "auto" }
                          }
                        />
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div style={{ backgroundColor: "#f1f3f5", height: "80vh" }}>
              <div
                style={{
                  width: "30%",
                  height: "50%",
                  margin: "auto",
                  textAlign: "center",
                  paddingTop: "100px",
                }}
              >
                <img
                  src={addNewReq}
                  alt="add new request"
                  style={{ marginBottom: "30px" }}
                />
                <p className="text-muted">{t("noList")}</p>
                <Link
                  to="/dashboard/requests/reimbursment/request"
                  className="btn btnPrimary"
                >
                  {t("newReq")}
                </Link>
                <p className="make-new" onClick={() => setModalTwoIsOpen(true)}>
                  {t("newRequest")}
                </p>
              </div>
            </div>
          )
        ) : (
          <div></div>
        )}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalTwoIsOpen}
        >
          <Modal.Body
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { textAlign: "right" }
                : { textAlign: "left" }
            }
          >
            <Container>
              <Row>
                <Reimbursement />
                <CloseOutlinedIcon
                  className="close-icon"
                  onClick={() => setModalTwoIsOpen(false)}
                />
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.request,
    errors: state.error,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, { listRequests })(MyRequests);
