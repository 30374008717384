import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import WalletBanner from '../CompanyWallet/walletBanner/walletBanner';
import api from '../../../../../apis/api';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';
import { REMOVE_LOADING } from '../../../../../actions/types';
import './team.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RiFileExcel2Fill } from 'react-icons/ri';
import moment from 'moment';

import ReactExport from 'react-export-excel';
import cogoToast from 'cogo-toast';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Home = (props) => {
  const [dataReimBar, setDataReimBar] = useState([]);
  const [dataReimPai, setDataReimPai] = useState([]);
  const [dataInAdBar, setDataInAdBar] = useState([]);
  const [dataInAdPai, setDataInAdPai] = useState([]);
  const [dataCatPai, setDataCatPai] = useState([]);
  const [dataAllPai, setDataAllPai] = useState([]);
  const [dataSupBar, setDataSupBar] = useState([]);
  const [dataSupPai, setDataSupPai] = useState([]);
  const [teams, setTeams] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const today = new Date();
  const [dateRange1, setDateRange1] = useState([
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15),
    new Date(today.getFullYear(), today.getMonth(), today.getDate() + 15),
  ]);
  const [startDate1, endDate1] = dateRange1;
  const selectionRange1 = {
    startDate: dateRange1[0],
    endDate: dateRange1[1],
    key: 'selection',
  };

  const [dateRange2, setDateRange2] = useState([
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
    new Date(),
  ]);
  const [startDate2, endDate2] = dateRange2;
  const selectionRange2 = {
    startDate: dateRange2[0],
    endDate: dateRange2[1],
    key: 'selection',
  };

  const [dateRange3, setDateRange3] = useState([
    new Date(),
    new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()),
  ]);
  const [startDate3, endDate3] = dateRange3;
  const selectionRange3 = {
    startDate: dateRange3[0],
    endDate: dateRange3[1],
    key: 'selection',
  };

  const [dateRange4, setDateRange4] = useState([
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
    new Date(),
  ]);
  const [startDate4, endDate4] = dateRange4;
  const selectionRange4 = {
    startDate: dateRange4[0],
    endDate: dateRange4[1],
    key: 'selection',
  };

  const [dateRange5, setDateRange5] = useState([
    new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
    new Date(),
  ]);
  const [startDate5, endDate5] = dateRange4;
  const selectionRange5 = {
    startDate: dateRange5[0],
    endDate: dateRange5[1],
    key: 'selection',
  };

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getDataReimBar = () => {
    api({
      url: '/dashboard/requestBarChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange2,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataReimBar(res.data.data.result);
          setTeams(res.data.data.teams);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataInAdBar = () => {
    api({
      url: '/dashboard/inadvanceBarChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange3,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataInAdBar(res.data.data.result);
          setTeams(res.data.data.teams);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataReimPai = () => {
    api({
      url: '/dashboard/requestPaiChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange2,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataReimPai(res.data.data.result);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataInAdPai = () => {
    api({
      url: '/dashboard/inadvancePaiChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange3,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataInAdPai(res.data.data.result);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataCatPai = () => {
    api({
      url: '/dashboard/categoryPaiChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange1,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataCatPai(res.data.data.result);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataAllPai = () => {
    api({
      url: '/dashboard/allPaiChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange1,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataAllPai(res.data.data.result);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataSupBar = () => {
    api({
      url: '/dashboard/supplierRequestsBarChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange5,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataSupBar(res.data.data.result);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getDataSupPai = () => {
    api({
      url: '/dashboard/supplierRequestsPaiChart',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: selectionRange5,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setDataSupPai(res.data.data.result);
          dispatch({ type: REMOVE_LOADING });
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const getSuppliers = () => {
    api({
      url: '/supplier/get-supplier',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        let suppliers_ = [];
        for (const iterator of res.data.Suppliers) {
          suppliers_.push(iterator.nameEnglish);
        }
        setSuppliers(suppliers_);
      })
      .catch((err) => {});
  };

  const getTransactions = () => {
    api({
      url: '/dashboard/transactions',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      // data: selectionRange4,
    })
      .then((res) => {
        if (res.data.status === 1) {
          console.log(res);
          setTransactions(res.data.data.result);
        } else {
          // cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDataCatPai();
    getDataAllPai();
    getDataReimBar();
    getDataReimPai();
    getDataInAdBar();
    getDataInAdPai();
    getTransactions();
    getDataSupBar();
    getDataSupPai();
    getSuppliers();
  }, []);

  const columns = [
    {
      field: 'createdAt',
      headerName: t('TIMESTAMP'),
      width: 170,
      cellClassName: 'MuiDataGrid-cellCenter',
      renderCell: (params) => {
        return (
          <p style={{ marginBottom: '0px' }}>
            {moment(params.row.createdAt).format('ll')}-
            {moment(params.row.createdAt).format('LT')}
          </p>
        );
      },
    },
    {
      field: 'id',
      headerName: t('REFERENCE NO'),
      sortable: true,
      width: 150,
      cellClassName: 'MuiDataGrid-cellCenter MuiDataGrid-colCellCenter',
      // valueGetter: (params) => params.row.createdAt.split('T')[0],
    },
    {
      field: 'transaction',
      headerName: t('TRANSACTION'),
      width: 130,
      cellClassName: 'MuiDataGrid-cellCenter',
    },
    {
      field: 'to',
      headerName: t('TO'),
      width: 150,
      cellClassName: 'MuiDataGrid-cellCenter',
    },
    {
      field: 'amount',
      headerName: t('amount').toUpperCase(),
      width: 100,
      cellClassName: `MuiDataGrid-cellCenter MuiDataGrid-colCellCenter`,
    },
    {
      field: 'currancy',
      headerName: t('currency').toUpperCase(),
      width: 150,
      cellClassName: 'MuiDataGrid-cellCenter',
      valueGetter: (params) => t(params.row.currancy),
    },
    {
      field: 'method',
      headerName: t('METHOD'),
      width: 200,
      cellClassName: 'MuiDataGrid-cellCenter',
    },
    {
      field: 'status',
      headerName: t('status').toUpperCase(),
      width: 120,
      cellClassName: 'MuiDataGrid-cellCenter',
      renderCell: (params) => {
        return params.row.status ? (
          params.row.status
        ) : (
          <p style={{ marginBottom: '0px' }}>{t('Approved')}</p>
        );
      },
    },
  ];

  return (
    <div>
      <WalletBanner />

      <div className="mainPage wallet">
        <div className="container-fluid">
          <div
            className="col-12 expense-chart-head"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { textAlign: 'right' }
                : { textAlign: 'left' }
            }
          >
            <p className="h3" style={{ display: 'inline-block' }}>
              {t('Latest Wallet Transactions')}
            </p>
            <div className="date-export">
              {/*<DatePicker
                dateFormat='dd/MM/yyy'
                selectsRange={true}
                startDate={startDate4}
                endDate={endDate4}
                onChange={(update) => {
                  setDateRange4(update);
                }}
                onCalendarClose={() => {
                  getTransactions();
                }}
                isClearable={false}
              />*/}
              <ExcelFile
                element={
                  <button className="btn btnPrimary">
                    {' '}
                    <RiFileExcel2Fill /> {t('Export CSV')}{' '}
                  </button>
                }
                filename={t('Latest Wallet Transactions')}
              >
                <ExcelSheet data={transactions} name="Transactions">
                  <ExcelColumn label="TIMESTAMP" value="createdAt" />
                  <ExcelColumn label="REFERENCE No" value="id" />
                  <ExcelColumn label="TRANSACTION" value="transaction" />
                  <ExcelColumn label="AMOUNT" value="amount" />
                  <ExcelColumn label="CURRENCY" value="currancy" />
                  <ExcelColumn label="METHOD" value="method" />
                  <ExcelColumn label="STATUS" value="status" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
          <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
            <DataGrid
              rows={transactions}
              columns={columns}
              pageSize={10}
              disableColumnMenu
              hideFooterSelectedRowCount
              sortingOrder="asc"
            />
          </div>
        </div>
      </div>

      <div className="mainPage wallet">
        <div className="container-fluid">
          <div
            className="col-12 expense-chart-head"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { textAlign: 'right' }
                : { textAlign: 'left' }
            }
          >
            <p className="h3" style={{ display: 'inline-block' }}>
              {t('companyExpenses')}
            </p>
            <div className="date-export">
              <DatePicker
                dateFormat="dd/MM/yyy"
                selectsRange={true}
                startDate={startDate1}
                endDate={endDate1}
                onChange={(update) => {
                  setDateRange1(update);
                }}
                onCalendarClose={() => {
                  getDataCatPai();
                  getDataAllPai();
                }}
                isClearable={false}
              />
              <ExcelFile
                element={
                  <button className="btn btnPrimary">
                    {' '}
                    <RiFileExcel2Fill /> {t('Export CSV')}{' '}
                  </button>
                }
                filename={t('companyExpenses')}
              >
                <ExcelSheet data={dataCatPai} name="Categories">
                  <ExcelColumn label="Category" value="label" />
                  <ExcelColumn label="Expenses" value="value" />
                </ExcelSheet>
                <ExcelSheet data={dataAllPai} name="Teams">
                  <ExcelColumn label="Team" value="label" />
                  <ExcelColumn label="Expenses" value="value" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6"
              style={{ height: '500px', direction: 'ltr' }}
            >
              <ResponsivePie
                data={dataCatPai}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'blue_green' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
              />
            </div>
            <div
              className="col-md-6"
              style={{ height: '500px', direction: 'ltr' }}
            >
              <ResponsivePie
                data={dataAllPai}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'blue_green' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mainPage wallet">
        <div className="container-fluid">
          <div
            className="col-12 expense-chart-head"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { textAlign: 'right' }
                : { textAlign: 'left' }
            }
          >
            <p className="h3" style={{ display: 'inline-block' }}>
              {t('rr')}
            </p>
            <div className="date-export">
              <DatePicker
                dateFormat="dd/MM/yyy"
                selectsRange={true}
                startDate={startDate2}
                endDate={endDate2}
                onChange={(update) => {
                  setDateRange2(update);
                }}
                onCalendarClose={() => {
                  getDataReimBar();
                  getDataReimPai();
                }}
                isClearable={false}
              />
              <ExcelFile
                element={
                  <button className="btn btnPrimary">
                    {' '}
                    <RiFileExcel2Fill /> {t('Export CSV')}{' '}
                  </button>
                }
                filename={t('rr')}
              >
                <ExcelSheet data={dataReimPai} name="By Teams">
                  <ExcelColumn label="Team" value="label" />
                  <ExcelColumn label="Expenses" value="value" />
                </ExcelSheet>
                <ExcelSheet data={dataReimBar} name="By Day">
                  <ExcelColumn label="Day" value="date" />
                  {teams.map((team) => {
                    return <ExcelColumn label={team} value={team} />;
                  })}
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-8"
              style={{ height: '500px', direction: 'ltr' }}
            >
              {' '}
              <ResponsiveBar
                data={dataReimBar}
                keys={teams}
                indexBy="date"
                margin={{ top: 50, right: 130, bottom: 70, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                colors={{ scheme: 'blue_green' }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Expences',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                groupMode="stacked"
              />
            </div>
            <div
              className="col-md-4"
              style={{ height: '500px', direction: 'ltr' }}
            >
              <ResponsivePie
                data={dataReimPai}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'blue_green' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mainPage wallet">
        <div className="container-fluid">
          <div
            className="col-12 expense-chart-head"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { textAlign: 'right' }
                : { textAlign: 'left' }
            }
          >
            <p className="h3" style={{ display: 'inline-block' }}>
              {t('myReqInAdv')}
            </p>
            <div className="date-export">
              <DatePicker
                dateFormat="dd/MM/yyy"
                selectsRange={true}
                startDate={startDate3}
                endDate={endDate3}
                onChange={(update) => {
                  setDateRange3(update);
                }}
                onCalendarClose={() => {
                  getDataInAdBar();
                  getDataInAdPai();
                }}
                isClearable={false}
              />
              <ExcelFile
                element={
                  <button className="btn btnPrimary">
                    {' '}
                    <RiFileExcel2Fill /> {t('Export CSV')}{' '}
                  </button>
                }
                filename={t('myReqInAdv')}
              >
                <ExcelSheet data={dataReimPai} name="By Teams">
                  <ExcelColumn label="Team" value="label" />
                  <ExcelColumn label="Expenses" value="value" />
                </ExcelSheet>
                <ExcelSheet data={dataReimBar} name="By Day">
                  <ExcelColumn label="Day" value="date" />
                  {teams.map((team) => {
                    return <ExcelColumn label={team} value={team} />;
                  })}
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-8"
              style={{ height: '500px', direction: 'ltr' }}
            >
              {' '}
              <ResponsiveBar
                data={dataInAdBar}
                keys={teams}
                indexBy="date"
                margin={{ top: 50, right: 130, bottom: 70, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                colors={{ scheme: 'blue_green' }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Expences',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                groupMode="stacked"
              />
            </div>
            <div
              className="col-md-4"
              style={{ height: '500px', direction: 'ltr' }}
            >
              <ResponsivePie
                data={dataInAdPai}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'blue_green' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mainPage wallet">
        <div className="container-fluid">
          <div
            className="col-12 expense-chart-head"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { textAlign: 'right' }
                : { textAlign: 'left' }
            }
          >
            <p className="h3" style={{ display: 'inline-block' }}>
              {t('Supplier Requests')}
            </p>
            <div className="date-export">
              <DatePicker
                dateFormat="dd/MM/yyy"
                selectsRange={true}
                startDate={startDate5}
                endDate={endDate5}
                onChange={(update) => {
                  setDateRange5(update);
                }}
                onCalendarClose={() => {
                  getDataSupBar();
                  getDataSupPai();
                }}
                isClearable={false}
              />
              <ExcelFile
                element={
                  <button className="btn btnPrimary">
                    {' '}
                    <RiFileExcel2Fill /> {t('Export CSV')}{' '}
                  </button>
                }
                filename={t('myReqInAdv')}
              >
                <ExcelSheet data={dataReimPai} name="By Teams">
                  <ExcelColumn label="Supplier" value="label" />
                  <ExcelColumn label="Expenses" value="value" />
                </ExcelSheet>
                <ExcelSheet data={dataReimBar} name="By Day">
                  <ExcelColumn label="Day" value="date" />
                  {suppliers.map((team) => {
                    return <ExcelColumn label={team} value={team} />;
                  })}
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-8"
              style={{ height: '500px', direction: 'ltr' }}
            >
              {' '}
              <ResponsiveBar
                data={dataSupBar}
                keys={suppliers}
                indexBy="date"
                margin={{ top: 50, right: 130, bottom: 70, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                colors={{ scheme: 'blue_green' }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 45,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Expences',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                groupMode="stacked"
              />
            </div>
            <div
              className="col-md-4"
              style={{ height: '500px', direction: 'ltr' }}
            >
              <ResponsivePie
                data={dataSupPai}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'blue_green' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.request,
    errors: state.error,
    auth: state.auth,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps)(Home);
