import React from "react";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  approveRequests,
  declineRequests,
} from "../../../../../../../../actions/pendingRequestsActions";

import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import history from "../../../../../../../../history";
import { HiBadgeCheck } from "react-icons/hi";

import "./item-request.css";
import Avatar from "antd/lib/avatar/avatar";

const ItemRequest = (props) => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { t } = useTranslation();

  const [request, setRequest] = useState({});
  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = useState(null);
  const currency = useSelector((state) => state.auth.user.currency[0]);

  useEffect(() => {
    setRequest(props.request);
  }, [request]);

  const onImageClick = (path, e) => {
    e.stopPropagation();
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    event.stopPropagation();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const statusColor = () => {
    if (props.request.status.toLowerCase().includes("approve")) {
      return {
        color: "#268c55",
        backgroundColor: "#e3fbee",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
      };
    } else if (props.request.status.toLowerCase().includes("declin")) {
      return {
        color: "#e32424",
        backgroundColor: "#ffebeb",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
      };
    } else if (props.request.status.toLowerCase().includes("pend")) {
      return {
        color: "#f4a800",
        backgroundColor: "#fff4dd",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
      };
    }
  };

  const renderImages = (request) => {
    const images = request.images;
    if (images && images.length > 0) {
      return images.map((image, index) => {
        return image.path.split(".").pop() === "pdf" ? (
          <div
            className="request-image d-inline-block"
            style={{ overflow: "auto" }}
          >
            <Document
              style={{ width: "100%" }}
              file={image.path}
              onLoadSuccess={onDocumentLoadSuccess}
              key={index}
            >
              <Page width={160} pageNumber={pageNumber} />
            </Document>
          </div>
        ) : (
          <div className="request-image d-inline-block">
            <img
              key={index}
              src={
                image.path.split(".").pop() == "pdf"
                  ? "/images/icons8-file-64.png"
                  : image.path
              }
              alt={image.filename}
              onClick={(e) => onImageClick(image.path, e)}
              className="img-fluid d-inline-block"
              style={{ cursor: "auto" }}
            />
          </div>
        );
      });
    } else {
      return (
        <div className="badge badge-secondary">
          This request doesn't have images
        </div>
      );
    }
  };

  return (
    <React.Fragment key={"right"}>
      <div className="request">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="row align-items-center">
              <div className="col-md-2 text-center">
                <div
                  className="request-user-image"
                  style={{ width: "65px", height: "50px" }}
                >
                  <Avatar
                    src={
                      props.request.supplier.image === ""
                        ? "/images/supplier.png"
                        : props.request.supplier.image
                    }
                    shape="square"
                    className="supplier-logo"
                    size={40}
                  />
                  <Avatar
                    src={
                      props.request.from.image === ""
                        ? props.request.from.firstName
                        : props.request.from.image
                    }
                    size={25}
                    className="floating-avatar"
                  />
                </div>
              </div>
              <div className="col-md-10 req-card" style={{ cursor: "auto" }}>
                <span
                  className="mb-1 d-inline-block"
                  style={
                    localStorage.getItem("i18nextLng") === "ar"
                      ? { width: "50%", textAlign: "right" }
                      : { width: "50%", textAlign: "left" }
                  }
                >
                  <b>{props.request.supplier.name}</b> &nbsp;
                  <HiBadgeCheck
                    style={
                      request.supplier && request.supplier.verified === true
                        ? { fontSize: "1rem", color: "#268c55" }
                        : { fontSize: "1rem", color: "#93a1b0" }
                    }
                  />
                  <br />
                  <b style={{ color: "#a8acaf" }}>
                    {props.request.from.firstName}&nbsp;
                    {props.request.from.lastName}
                  </b>
                </span>
                <div className="d-inline-block request-images">
                  {renderImages(props.request)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row align-items-center">
              <div className="col-md-3 text-center">
                <span>
                  {t(currency)} {request.amount}
                </span>
              </div>
              <div className="col-md-3 text-center">
                <div className="badge badge-info p-1" style={statusColor()}>
                  {t(request.status)}
                </div>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-outline-success float-center btnSecondary"
                  onClick={() => {
                    history.push(
                      `/dashboard/suppliers/expenses/request?num=${request._id}`
                    );
                  }}
                >
                  {t("view")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { approveRequests, declineRequests })(ItemRequest);
