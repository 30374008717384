import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import { setLoading, removeLoading } from "./loadingActions";
import { tokenConfig } from "./tokenActions";
import {
  LIST_TEAMS,
  LIST_USERS,
  ADD_TEAM,
  USER_ACTIVATE,
  USER_DEACTIVATE
} from "./types";
import cogoToast from "cogo-toast";
import { loadRequestCount, loadInadvanceRequestCount } from "./authActions";
import i18n from "../translations/i18n";
//cogoToast.success('Team Added successfully');

export const loadTeams = () => {
  loadRequestCount();
  loadInadvanceRequestCount();

  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());

    api
      .get("/teams/filterData", tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_TEAMS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(res.data.message, res.data.status, "LIST_TEAMS_FAIL")
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const loadUsers = data => {
  loadRequestCount();
  loadInadvanceRequestCount();

  return async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());

    api
      .post("/teams/getMembers", data, tokenConfig(getState))
      .then(res => {
        if (res.data.status === 1) {
          console.log(res);
          dispatch({ type: LIST_USERS, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(res.data.message, res.data.status, "LIST_USERS_FAIL")
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const addTeam = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/teams", JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: ADD_TEAM, payload: res.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Team Added successfully"));
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_TEAM_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const deleteMember = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/user/deleteMember", JSON.stringify({ data }), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch(loadTeams());
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Deleted successfully"));
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_TEAM_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const changeUserRole = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/user/changeRole", JSON.stringify(data), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch(loadTeams());
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_TEAM_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const activateUser = id => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .get(`/user/activate/${id}`, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: USER_ACTIVATE, payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("User activated successfully"));
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "USER_ACTIVATE_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const deactivateUser = id => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .get(`/user/deactivate/${id}`, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: USER_DEACTIVATE, payload: res.data.data });
        dispatch(removeLoading());
        cogoToast.success(i18n.t("User deactivated successfully"));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "USER_DEACTIVATE_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const setEmployee = emp => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  dispatch({ type: "SET_EMPLOYEE", payload: emp });
};

export const editEmp = (values, filterData) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post(`/user/edit`, JSON.stringify(values), tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch(removeLoading());
        dispatch(loadTeams());
        dispatch(loadUsers(filterData));
        cogoToast.success(i18n.t("User Edited successfully"));
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "USER_DEACTIVATE_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};
