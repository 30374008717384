import {
    LIST_INDEBTEDNESS,
    LIST_FILTER_INDEBTEDNESS,
    LIST_INDEBTEDNESS_USER,
  } from '../actions/types';
  
  const INITIAL_STATE = {
    indebtedness: [],
    oreginalIndebtedness: [],

    exel: [],
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LIST_INDEBTEDNESS:
        return {
          ...state,
          indebtedness: action.payload,
          oreginalIndebtedness:action.payload
        };

      case LIST_FILTER_INDEBTEDNESS:
        return {
          ...state,
          indebtedness: action.payload,
        };

      case LIST_INDEBTEDNESS_USER:
        return {
          ...state,
          indebtedness: action.payload,
        };

      default:
        return state;
    }
  };
  