import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, reset } from "redux-form";
import {
  registerCompany,
  startAddCompany
} from "../../../../actions/companyActions";
import { clearErrors } from "../../../../actions/errorActions";
import history from "../../../../history";
import { useTranslation } from "react-i18next";
import { BiHide } from "react-icons/bi";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import "../pages.css";
import "./gettingStarted.css";
import Rocket from "../../../../assets/images/rocket.png";
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";

const GettingStarted = props => {
  const [msg, setmsg] = useState(undefined);
  const [showPass, setShowPass] = useState(false);
  const [showPassC, setShowPassC] = useState(false);
  const [mobile, setMobile] = useState({
    short: "EG",
    phone: ""
  });
  const [phoneErr, setPhoneErr] = useState(false);
  const [countryCode, setCountryCode] = useState("eg");

  const { register, errors, handleSubmit } = useForm();

  const { t } = useTranslation();

  const content = (
    <ul>
      <li>8 Letters min length</li>
      <li>Include capital Letter</li>
      <li>Include small Letter</li>
      <li>Include Number</li>
      <li>Include special character</li>
    </ul>
  );

  const onSubmit = values => {
    if (values.password !== values.confirmPassword)
      return cogoToast.warn(t("Passwords don't match"));

    let correctedMobile = mobile.phone;
    let sentNumber = mobile.code + correctedMobile;
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length !== 10 &&
      mobile.phone.length !== 11
    )
      return cogoToast.error(t("Please, Enter valid mobile number"));
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = mobile.code + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 11
    ) {
      correctedMobile = mobile.phone.substring(1);
      sentNumber = mobile.code + correctedMobile;
    }
    values.phone = sentNumber;

    props.registerCompany(values);
  };

  useEffect(() => {
    const { status, emailVerified } = props.company;
    if (status === 20 || emailVerified === true)
      return history.push(`/create/company`);
    if (status === 60) return history.push(`/create/bank`);
    props.reset("registerCompany");
    props.clearErrors();
  }, []);

  useEffect(() => {
    let { id, msg } = props.errors;
    if (id) {
      if (id === "REGISTER_FAIL") {
        setmsg(msg);
      }
    }
  }, [props.errors]);

  return (
    <div className="page gettingStarted">
      <div className="row">
        {localStorage.getItem("i18nextLng") == "ar" ? (
          <div className="col-md-6" style={{ textAlign: "right" }}>
            <h1>
              {t("getStart")}{" "}
              <img src={Rocket} alt="rocket" style={{ height: "35px" }} />
            </h1>
            <p className="lead">{t("getStartP")}</p>
          </div>
        ) : (
          <div className="col-md-6" style={{ textAlign: "left" }}>
            <h1>
              {t("getStart")}{" "}
              <img src={Rocket} alt="rocket" style={{ height: "35px" }} />
            </h1>
            <p className="lead">{t("getStartP")}</p>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} key="565674869">
        <div className="row">
          <div className="col-md-8">
            {msg ? <div className="alert alert-danger">{msg}</div> : ""}
          </div>
        </div>
        <div
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <div className="row forms">
            <div className="col-md-10 left-forms">
              <div className="form-row">
                <div className="row-element">
                  <div className="form-row" style={{ margin: "0px" }}>
                    <div className="row-element">
                      <label>{t("fName")}</label>
                      <input
                        className="form-control"
                        autoComplete="off"
                        name="firstName"
                        placeholder={t("fNamePH")}
                        ref={register({ required: true })}
                      />
                      {errors.firstName && <p>First name is required</p>}
                    </div>
                    <div className="row-element">
                      <label>{t("lName")}</label>
                      <input
                        className="form-control"
                        autoComplete="off"
                        name="lastName"
                        placeholder={t("lNamePH")}
                        ref={register({ required: true })}
                      />
                      {errors.lastName && <p>Last name is required</p>}
                    </div>
                  </div>
                </div>
                <div className="row-element">
                  <label>{t("bmail")}</label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    title="Enter in the format: example@email.com"
                    name="email"
                    placeholder={t("bmailPH")}
                    ref={register({ required: true })}
                  />
                  {errors.email && <p>Bussiness email is required</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="row-element" style={{ position: "relative" }}>
                  <label>{t("pass")}</label>
                  <input
                    className="form-control"
                    type={showPass ? "text" : "password"}
                    autoComplete="off"
                    pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
                    name="password"
                    placeholder={t("passPH")}
                    ref={register({ required: true })}
                  />
                  {errors.password && <p>Password is required</p>}
                  <BiHide
                    className="passShowHide"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { left: "10px" }
                        : { right: "10px" }
                    }
                    onClick={() => setShowPass(!showPass)}
                  />
                  <span
                    className="pass-requirements"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { left: "40px", top: "41px" }
                        : { right: "40px", top: "41px" }
                    }
                  >
                    <Popover
                      content={content}
                      title="Password must be:"
                      trigger="click"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                </div>
                <div className="row-element" style={{ position: "relative" }}>
                  <label>{t("cPassword")}</label>
                  <input
                    className="form-control"
                    type={showPassC ? "text" : "password"}
                    autoComplete="off"
                    name="confirmPassword"
                    pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
                    placeholder={t("cPasswordPH")}
                    ref={register({ required: true })}
                  />
                  {errors.confirmPassword && <p>Please repeat your password</p>}
                  <BiHide
                    className="passShowHide"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { left: "10px" }
                        : { right: "10px" }
                    }
                    onClick={() => setShowPassC(!showPassC)}
                  />
                  <span
                    className="pass-requirements"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { left: "40px", top: "41px" }
                        : { right: "40px", top: "41px" }
                    }
                  >
                    <Popover
                      content={content}
                      title="Password must be:"
                      trigger="click"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                </div>
              </div>

              <div className="form-row">
                <div className="row-element">
                  <label>{t("team_")}</label>
                  <input
                    className="form-control"
                    type="number"
                    step="1"
                    min="1"
                    pattern="[0-9]"
                    autoComplete="off"
                    name="team"
                    placeholder={t("teamPH")}
                    ref={register({ required: true })}
                  />
                  {errors.team && <p>Team size is required</p>}
                </div>
                <div className="row-element">
                  <label>{t("role")}</label>
                  <input
                    className="form-control"
                    autoComplete="off"
                    name="role"
                    placeholder={t("rolePH")}
                    ref={register({ required: true })}
                  />
                  {errors.role && <p>Role is required</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="row-element">
                  <label>{t("Contact Number")}</label>

                  {/* <PhoneInput
                    country={countryCode}
                    value={mobile}
                    inputProps={{
                      maxlength:
                        countryCode === "eg"
                          ? "16"
                          : countryCode === "sa"
                          ? "15"
                          : "20",
                      name: "phone",
                      autoComplete: "off",
                      // ref: register({ required: true }),
                    }}
                    onChange={(number, country) => {
                      setPhoneErr(false);
                      setCountryCode(country.countryCode);
                      setMobile(number);
                    }}
                  />
                  {phoneErr && <p>Phone is required</p>} */}
                  <ConfigProvider
                    locale={en}
                    areaMapper={area => {
                      return {
                        ...area,
                        emoji: (
                          <span className={`fp ${area.short.toLowerCase()}`} />
                        )
                      };
                    }}
                  >
                    <CountryPhoneInput
                      defaultValue={{ short: "EG" }}
                      id="mobile-input"
                      type="number"
                      placeholder="1234567890"
                      value={mobile}
                      className="form-control"
                      onChange={v => {
                        setPhoneErr(false);
                        setMobile(v);
                      }}
                    />
                  </ConfigProvider>
                </div>
                {localStorage.getItem("i18nextLng") == "ar" ? (
                  <div className="row-element">
                    <label>{t("budget")}</label>
                    <select
                      className="form-control"
                      name="budget"
                      ref={register({ required: true })}
                    >
                      <option selected="selected">1000 - 5000 USD</option>
                      <option>5000 - 20,000 دولار أمريكي</option>
                      <option>20,000 - 30,000 دولار أمريكي</option>
                      <option>30,000 - 100,000 دولار أمريكي</option>
                      <option>أكثر من 100,000 دولار أمريكي</option>
                    </select>
                    {errors.currency && <p>Currency is required</p>}
                  </div>
                ) : (
                  <div className="row-element">
                    <label>{t("budget")}</label>
                    <select
                      className="form-control"
                      name="budget"
                      ref={register({ required: true })}
                    >
                      <option selected="selected">1000 - 5000 USD</option>
                      <option>5000 - 20,000 USD</option>
                      <option>20,000 - 30,000 USD</option>
                      <option>30,000 - 100,000 USD</option>
                      <option>More than 100,000 USD</option>
                    </select>
                    {errors.currency && <p>Currency is required</p>}
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="row-element"></div>
                <div className="row-element">
                  <button type="submit" className="btn btnPrimary">
                    {t("startUrCompany")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
const renderInputAR = ({ input, label, meta, type, placeholder, key }) => {
  return (
    <div className="inputComp">
      <label className="arLabel">{label}</label>
      <input
        key={key}
        className="form-control"
        placeholder={placeholder}
        {...input}
        type={type}
        autoComplete="off"
      />
      {renderError(meta)}
    </div>
  );
};
const renderInput = ({ input, label, meta, type, placeholder, key }) => {
  return (
    <div className="inputComp">
      <label className="enLabel">{label}</label>
      <input
        className="form-control"
        placeholder={placeholder}
        key={key}
        {...input}
        type={type}
        autoComplete="off"
      />
      {renderError(meta)}
    </div>
  );
};

const renderSelectField = ({ meta, label, input, children, key }) => {
  const className = `inputComp ${
    meta.touched && meta.error ? "has-error" : ""
  }`;
  return localStorage.getItem("i18nextLng") == "ar" ? (
    <div className={className}>
      <label className="arLabel">{label}</label>
      <select
        className="form-control"
        key={key}
        {...input}
        defaultValue="5,000 - 10,000 EGP"
      >
        {children}
      </select>
      {renderError(meta)}
    </div>
  ) : (
    <div className={className}>
      <label>{label}</label>
      <select
        className="form-control"
        {...input}
        defaultValue="5,000 - 10,000 EGP"
      >
        {children}
      </select>
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return (
      <div
        className="text-danger"
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { textAlign: "right" }
            : {}
        }
      >
        {error}
      </div>
    );
  }
};
const validateForm = formValues => {
  const errors = {};

  if (!formValues.firstName) {
    errors.firstName = "Please add firstName";
  }

  if (!formValues.lastName) {
    errors.lastName = "Please add lastName";
  }

  if (!/^[0-9a-zA-Z]{8,}$/.test(formValues.password)) {
    errors.password = "8 Letters at least";
  }

  if (!formValues.password) {
    errors.password = "Please add password";
  }
  if (formValues.password !== formValues.confirmPassword) {
    errors.confirmPassword = "password must match";
  }

  if (!formValues.team) {
    errors.team = "Please select team";
  }

  if (!formValues.email) {
    errors.email = "Please add email";
  }
  if (
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      formValues.email
    )
  ) {
    errors.email = "Please add a valid email";
  }

  if (!formValues.confirmPassword) {
    errors.confirmPassword = "Please add confirm Password";
  }

  if (!formValues.role) {
    errors.role = "Please add role";
  }

  if (!formValues.budget) {
    errors.budget = "Please select budget";
  }

  if (!formValues.phone) {
    errors.phone = "Please add phone number";
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    errors: state.error,
    company: state.company,
    lang: state.lang.lang
  };
};

const reduxMiddleware = reduxForm({
  form: "registerCompany",
  validate: validateForm
})(GettingStarted);

export default connect(mapStateToProps, {
  registerCompany,
  reset,
  clearErrors,
  startAddCompany
})(reduxMiddleware);
