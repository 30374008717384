import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';
import api from '../../../../../apis/api';
import { Divider } from 'antd';

import './notificationsSettings.css'
import 'antd/dist/antd.css';

const NotificationsSettings = () => {

  const [settings, setSettings] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    api({
      url: '/notifications/notificationConfig',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        console.log(res);
        setSettings(res.data.data)
      } else {
        cogoToast.error(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])
  
  const saveHandler = () => {
    api({
      url: '/notifications/notificationConfig',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: settings,
    })
    .then((res) => {
      if (res.data.status === 1) {
        console.log(res);
        cogoToast.success('Notifications changes saved');
      } else {
        cogoToast.error('Can\'t save changes');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <div className='mainPage'>
      <div className='notification-settings'>
        <h1>{t('Notifications Settings')}</h1>
        <div className='notification-option'>
          <h5><b>{t('Email Me When')}:</b></h5>
          <h4></h4>
        </div>
        <div className='notification-option'>
          <label>{t('My reimburse request gets approved')}</label>
          <input type='checkbox' checked={settings.approveRembersmentRequest}
            onChange={(e) => setSettings(prevState => ({...prevState, approveRembersmentRequest: e.target.checked}))}/>
        </div>
        <Divider />
        <div className='notification-option'>
          <label>{t('My in advance request gets approved')}</label>
          <input type='checkbox' checked={settings.approveInadvanceRequest}
            onChange={(e) => setSettings(prevState => ({...prevState, approveInadvanceRequest: e.target.checked}))}/>
        </div>
        <Divider />
        <div className='notification-option'>
          <label>{t('My supplier request gets approved')}</label>
          <input type='checkbox' checked={settings.approveSupplierRequest}
            onChange={(e) => setSettings(prevState => ({...prevState, approveSupplierRequest: e.target.checked}))}/>
        </div>
        <Divider />
        <div className='notification-option'>
          <label>{t('Any of my requests gets declined')}</label>
          <input type='checkbox' checked={settings.declineRequest}
            onChange={(e) => setSettings(prevState => ({...prevState, declineRequest: e.target.checked}))}/>
        </div>
        <Divider />
        <div className='notification-option'>
          <label>{t('My in advance request reconciliation is due')}</label>
          <input type='checkbox' checked={settings.inadvanceRequestDue}
            onChange={(e) => setSettings(prevState => ({...prevState, inadvanceRequestDue: e.target.checked}))}/>
        </div>
        <Divider />
        <div className='notification-option'>
          <label>{t('My manager writes a comment to my request')}</label>
          <input type='checkbox' checked={settings.commentToRequest}
            onChange={(e) => setSettings(prevState => ({...prevState, commentToRequest: e.target.checked}))}/>
        </div>

        <div className='notification-option'>
          <h5><b>{t('Newsletter')}:</b></h5>
          <h4></h4>
        </div>
        <div className='notification-option'>
          <label>{t('Subscribe to Hollydesk newsletter')}</label>
          <input type='checkbox' checked={settings.Newsletter}
            onChange={(e) => setSettings(prevState => ({...prevState, Newsletter: e.target.checked}))}/>
        </div>
        <Divider />
        <div className='notification-option'>
          <label>{t('Subscribe to Hollydesk feature announcements')}</label>
          <input type='checkbox' checked={settings.featureAnnouncment}
            onChange={(e) => setSettings(prevState => ({...prevState, featureAnnouncment: e.target.checked}))}/>
        </div>

        <button className='btn btnPrimary save-changes' onClick={saveHandler}>{t('Save Changes')}</button>
      </div>
    </div>
  );
};

export default NotificationsSettings;