import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import { setLoading, removeLoading } from "./loadingActions";
import { tokenConfig } from "./tokenActions";
import history from "../history";
import {
  ADD_REQUEST,
  LIST_REQUESTS,
  WALLET,
  LIST_REQUESTS_SUPPLIER,
  LIST_REQUESTS_INADVANCE,
  REQ_TO_RECONCILE
} from "./types";
import cogoToast from "cogo-toast";
import { loadRequestCount, loadInadvanceRequestCount } from "./authActions";
import i18n from "../translations/i18n";
export const addRequest = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/requests/add-request", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      if (res.data.status === 1) {
        dispatch({ type: ADD_REQUEST, payload: res.data.data });
        dispatch({ type: WALLET, payload: res.data.data.totalAmount });
        dispatch(removeLoading());
        history.push(
          `/dashboard/requests/reimbursment/success?num=${res.data.data.number}`
        );
        cogoToast.success(i18n.t("Request sent successfully"));
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      } else if (res.data.message === "Insufficient Funds") {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
        cogoToast.error(i18n.t("Insufficient Funds In Company Wallet"));
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
        cogoToast.error(i18n.t(res.data.message));
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
    });
};

export const editRequest = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/requests/editRequest", data, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: ADD_REQUEST, payload: res.data.data });
        dispatch({ type: WALLET, payload: res.data.data.totalAmount });
        dispatch(removeLoading());
        history.push(
          `/dashboard/requests/reimbursment/success?num=${res.data.data.number}`
        );
        cogoToast.success(i18n.t("Request sent successfully"));
      } else if (res.data.message === "Insufficient Funds") {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
        cogoToast.error(i18n.t("Insufficient Funds In Company Wallet"));
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
        cogoToast.error(i18n.t(res.data.message));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const listRequests = (filter, page) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();
  setLoading(true);

  api
    .get(
      `/requests/my-requests?filter=${filter}&page=${page}`,
      tokenConfig(getState)
    )
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: LIST_REQUESTS, payload: res.data.data });
        dispatch(removeLoading());
        setLoading(false);
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "LIST_REQUESTS_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const cashout = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/user/cashout", data, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: WALLET, payload: res.data.data });

        dispatch(removeLoading());
        history.push(`/dashboard/requests/myWallet`);
        cogoToast.success(i18n.t("cashout done successfully"));
      } else {
        cogoToast.error(i18n.t("insufficient funds"));

        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

//---------------------------

export const addSupplier = (data, callback) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/supplier/add-supplier", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      if (res.data.status === 1) {
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Supplier added successfully"));
        // history.push(`/dashboard/suppliers/new-request`);
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
        callback && callback();
      } else {
        cogoToast.error(i18n.t("Failed to add the supplier"));
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
    });
};

export const editSupplier = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/supplier/edit-supplier", data, tokenConfig(getState))
    .then(res => {
      if (res.data.status == 1) {
        dispatch(removeLoading());
        cogoToast.success(i18n.t("Edited successfully"));
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const addRequestSupplier = data => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .post("/supplier/request", data, tokenConfig(getState))
    .then(res => {
      if (res.status == 200) {
        dispatch(removeLoading());
        history.push(
          `/dashboard/suppliers/reimbursment/newRequestSuccess/?num=${res.data.data.number}`
        );
        cogoToast.success(i18n.t("Request sent successfully"));
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_REQUEST_FAIL")
        );
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
    });
};

export const listSupplierRequests = (filter, page) => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  api
    .get(
      `/supplier/my-requests?filter=${filter}&page=${page}`,
      tokenConfig(getState)
    )
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: LIST_REQUESTS_SUPPLIER, payload: res.data.data });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "LIST_REQUESTS_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const listInadvanceRequests = (filter, page) => {
  return (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
    loadRequestCount();
    loadInadvanceRequestCount();

    api
      .get(
        `/inadvance/my-requests?filter=${filter}&page=${page}`,
        tokenConfig(getState)
      )
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: LIST_REQUESTS_INADVANCE, payload: res.data.data });
          dispatch(removeLoading());
        } else {
          dispatch(
            returnErrors(
              res.data.messageCE,
              res.data.status,
              "LIST_REQUESTS_FAIL"
            )
          );
        }
      })
      .catch(err => {
        dispatch(returnErrors(err, 0));
      });
  };
};

export const saveInAdvanceReq = reqToReconcile => {
  return {
    type: REQ_TO_RECONCILE,
    reqToReconcile
  };
};

export const toggleBtnSpin = spin => {
  return {
    type: "TOGGLE_BTN_SPIN",
    spin
  };
};
