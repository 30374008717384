import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactExport from 'react-export-excel';
import { listIndebtedness, filterIndebtedness } from '../../../../../../actions/indebtednessActions';

import './indebtedness.css'

const CompanyIndebtedness = (props) => {
    const [renderRequests, setRenderRequests] = useState([]);
    const [toggleState, setToggleState] = useState(1);

    const [all, setAll] = useState([...props.indebtedness]);
    const [debit, setDebit] = useState([]);
    const [credit, setCredit] = useState([]);
    const [field_, setField_] = useState('Employee');
    const [value_, setValue_] = useState('all');

    useEffect(() => {
        props.listIndebtedness();
    }, []);

    useEffect(() => {
        setAll(props.indebtedness.filter((req) => {
            return req.debit > 0 || req.credit > 0
        }))

        setDebit(
            props.indebtedness.filter((req) => {
                return req.debit > 0
            })
        )
    
        setCredit(props.indebtedness.filter(req => {
            return req.credit > 0
        }))

        setRenderRequests(all);
       
    }, [props.oreginalIndebtedness]);

    useEffect(() => {
        setAll(props.indebtedness.filter((req) => {
            return req.debit > 0 || req.credit > 0
        }))

        setRenderRequests(props.indebtedness.filter((req) => {
            return req.debit > 0 || req.credit > 0 || req.money > 0 || req.money < 0
        }));
       
    }, [props.indebtedness]);

    const { t } = useTranslation();

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const columns = ()=> [
        {
            field: 'number' || 'id',
            headerName: t('ID'),
            width: 150,
            cellClassName: 'MuiDataGrid-cellCenter',
        },
        {
            field: `${field_}`,
            headerName: `${field_}`,
            width: 200,
            cellClassName: 'MuiDataGrid-cellCenter MuiDataGrid-colCellCenter',
            renderCell: (params) => (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 'auto', justifyContent: 'space-around'}}>
                    <p style={{marginBottom: '0px'}}>{
                      value_ === 'user' ? (params.row.from.firstName+" "+params.row.from.lastName)
                      :
                      value_ === 'all' ? (params.row.from.firstName+" "+params.row.from.lastName)
                      :
                      value_ === 'month' ? (params.row.month) 
                      :
                      (params.row.from.firstName+" "+params.row.from.lastName)}
                    </p>
                </div>
            )
            
        },
        {
            field: 'debit',
            headerName: t('debit'),
            width: 150,
            cellClassName: 'MuiDataGrid-cellCenter',
            valueGetter: (params) => params.row.money ? (params.row.money > 0 ? params.row.money : 0) : params.row.debit,
        },
        {
            field: 'credit',
            headerName: t('credit_'),
            width: 150,
            cellClassName: 'MuiDataGrid-cellCenter',
            valueGetter: (params) => params.row.money ? (params.row.money < 0 ? params.row.money : 0) : params.row.credit,
        },
        {
            field: 'nuber',
            headerName: t('reqno'),
            width: 150,
            cellClassName: 'MuiDataGrid-cellCenter',
            valueGetter: (params) => '#' + params.row.number,
        },
    ];

  return (
    <div className='mainPage indebtedness'>
        <div className="container-fluid page-content">
          <div className="requests-head" >
            <h1
              className="title-h1"
              style={
                localStorage.getItem('i18nextLng') == 'ar'
                  ? { width: '100%', textAlign: 'right' }
                  : { width: '100%', textAlign: 'left' }
              }
            >
              {t('compInd')}
            </h1>
            <div className='exportBtn'>
            <ExcelFile
            className="export col-1"
            element={
              localStorage.getItem('i18nextLng') == 'en' ? (
                <button className="btn btn-success btnPrimary">
                  Export Data
                </button>
              ) : (
                <button className="btn btn-success btnPrimary">
                  تحميل البيانات
                </button>
              )
            }
            filename="Company Indebtedness"
          >
            <ExcelSheet
              data={props.exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Paid On" value="paidOn" />
              <ExcelColumn label="Paid For" value="paidFor" />
              <ExcelColumn label="Paid To" value="paidTo" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Images"
                value={(col) => {
                  let str = '';
                  col.images.forEach((element) => {
                    str = str + ' , \n ' + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
            </div>
          </div>
          <div className="filter">
            <p
              className={toggleState === 1 ? 'tab active-tab' : 'tab'}
              onClick={() => {
                setRenderRequests(all);
                toggleTab(1);
              }}
            >
              {t('all')}
            </p>
            <p
              className={toggleState === 2 ? 'tab active-tab' : 'tab'}
              onClick={() => {
                setRenderRequests(debit);
                toggleTab(2);
              }}
            >
              {t('debit') + ` (${debit.length})`}
            </p>
            <p
              className={toggleState === 3 ? 'tab active-tab' : 'tab'}
              onClick={() => {
                setRenderRequests(credit);
                toggleTab(3);
              }}
            >
              {t('credit_') + ` (${credit.length})`}
            </p>
          </div>
            <div className="filter">
             <div className="row-element mb-4">
              <select className="form-control col-sm-6 col-md-4 col-lg-4" onChange = {e=>{
                props.filterIndebtedness(props.oreginalIndebtedness ,e.target.value)
                setValue_(e.target.value)
              }}>
                <option selected disabled >Filter</option>
                <option value='all' onSelect={()=>setField_('Employee')} >All</option>
                <option value='user' onSelect={()=>setField_('Employee')}>By User</option>
                <option value='month' onSelect={()=>setField_('Month')}>By Month</option>
              </select>
            </div>
          </div>
        
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={renderRequests}
              columns={columns()}
              pageSize={10}
              disableColumnMenu
              hideFooterSelectedRowCount
              sortingOrder="asc"
            />
          </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    indebtedness: state.indebtedness.indebtedness,
    oreginalIndebtedness:state.indebtedness.oreginalIndebtedness,
    errors: state.error,
    lang: state.lang.lang,
    exel: state.indebtedness.exel,
  };
};

export default connect(mapStateToProps, { listIndebtedness,filterIndebtedness })(CompanyIndebtedness);
