import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  loadTeams,
  loadUsers,
  deleteMember,
  setEmployee,
} from "../../../../../../actions/teamActions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { reduxForm } from "redux-form";
import "./team.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import EditMember from "./editMember";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Avatar } from "antd";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    width: "1000px",
    height: "23px",
    margin: "0 4px",
    padding: "6.5px 20px 6.5px 4px",
    backgroundColor: "#556575",
  },
  body: {
    fontSize: 13.5,
    width: "350px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    width: "100%",
    height: "32px",
    margin: "8px 0",
    backgroundColor: "#ffffff",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const Team = (props) => {
  const classes = useStyles();

  const [state, setState] = useState(false);

  const teams = useSelector((state) =>
    state.team.teams.filter((team) => team.active)
  );
  const users = useSelector((state) => state.team.users.members) || [];
  const company = useSelector((state) => state.auth.user.company.name);
  const logedUserRole = useSelector((state) => state.auth.user.role);

  const [selected, setSelected] = useState(undefined);
  const [index, setIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [employee, setEmployee] = useState({});
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("default");
  const [level, setLevel] = useState("default");
  const [active, setActive] = useState("default");
  const [status, setStatus] = useState("default");
  const [team, setTeam] = useState("all");
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTeams());
    const data = { team, page, level, active, sort, status };
    dispatch(loadUsers(data));
  }, []);

  useEffect(() => setSelected(teams[0]), [teams]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderTeams = () => {
    return teams && teams.length > 0 ? (
      teams.map((team) => {
        return <option value={team._id}>{team.name}</option>;
      })
    ) : (
      <option>No teams</option>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUserRole = (data) => {
    api({
      url: "/user/changeRole",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    })
      .then((res) => {
        if (res.data.status === 1) {
          const data = { team, page, level, active, sort, status };
          dispatch(loadUsers(data));
          cogoToast.success("User role changed successfully");
        } else {
          cogoToast.error("Failed changing user role");
        }
      })
      .catch((err) => {
        cogoToast.error("Failed changing user role");
      });
  };

  const activateUser = (id) => {
    api({
      url: `/user/activate/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          const data = { team, page, level, active, sort, status };
          dispatch(loadUsers(data));
          cogoToast.success("User activated successfully");
        } else {
          cogoToast.error("Failed activating the user");
        }
      })
      .catch((err) => {
        cogoToast.error("Failed activating the user");
      });
  };

  const deactivateUser = (id) => {
    api({
      url: `/user/deactivate/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          const data = { team, page, level, active, sort, status };
          dispatch(loadUsers(data));
          cogoToast.success("User deactivated successfully");
        } else {
          cogoToast.error("Failed deactivating the user");
        }
      })
      .catch((err) => {
        cogoToast.error("Failed deactivating the user");
      });
  };

  const searchHandler = () => {
    setPage(1);
    const data = { team, page, level, active, sort, status, search };
    dispatch(loadUsers(data));
  };

  const clearSearch = () => {
    setSearch("");
    const data = { team, page, level, active, sort, status, search: "" };
    dispatch(loadUsers(data));
  };

  useEffect(() => filterHandler(), [level, active, sort, page, team, status]);

  const filterHandler = () => {
    const data = { team, page, level, active, sort, status };
    dispatch(loadUsers(data));
  };

  const nextPage = () => {
    console.log(selected);
    const lastPage = Math.ceil(selected.usersCount / 10);
    page === lastPage ? setPage(lastPage) : setPage(page + 1);
  };

  const previousPage = () => {
    page === 1 ? setPage(1) : setPage(page - 1);
  };

  const first = () => 1 + (page * 10 - 10);

  const last = () => {
    if (users.length > 0) {
      const lastElement = users.length;
      const number = 10 + (page * 10 - 10);
      return number > lastElement ? lastElement : number;
    } else {
      return 10;
    }
  };

  return (
    <div className="mainPage">
      <EditMember
        state={state}
        setState={setState}
        employee={employee}
        filterData={{ team, page, level, active, sort, status, search }}
      />

      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-md-12">
            <h1
              className="mb-4"
              style={
                localStorage.getItem("i18nextLng") == "en"
                  ? { textAlign: "left" }
                  : { textAlign: "right" }
              }
            >
              {company} ({teams.length > 0 ? teams[0].usersCount : 0})
            </h1>
          </div>
          <div className="col-md-12 team-controls">
            <div className="team-filters-group">
              <div className="form-control team-filter">
                <h6>{t("team__")}:</h6>
                <select
                  onChange={(e) => {
                    setTeam(e.target.value);
                  }}
                >
                  {renderTeams()}
                </select>
              </div>
              <div className="form-control team-filter">
                <h6>{t("Sort")}:</h6>
                <select
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                >
                  <option
                    value="default"
                    selected={sort === "default" ? true : false}
                  >
                    {t("Default")}
                  </option>
                  <option value="newest">{t("Newest")}</option>
                  <option value="oldest">{t("Oldest")}</option>
                </select>
              </div>
              <div className="form-control team-filter">
                <h6>{t("level_")}:</h6>
                <select
                  onChange={(e) => {
                    setLevel(e.target.value);
                  }}
                >
                  <option
                    value="default"
                    selected={level === "default" ? true : false}
                  >
                    {t("Default")}
                  </option>
                  <option value="user">{t("Employee")}</option>
                  <option value="team-manager">{t("Manager")}</option>
                </select>
              </div>
              <div className="form-control team-filter">
                <h6>{t("Activity")}:</h6>
                <select
                  onChange={(e) => {
                    setActive(e.target.value);
                  }}
                >
                  <option
                    value="default"
                    selected={active === "default" ? true : false}
                  >
                    {t("Default")}
                  </option>
                  <option value={true}>{t("Active")}</option>
                  <option value={false}>{t("Inactive")}</option>
                </select>
              </div>
              <div className="form-control team-filter">
                <h6>{t("status")}:</h6>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option
                    value="default"
                    selected={active === "default" ? true : false}
                  >
                    {t("Default")}
                  </option>
                  <option value="Verified">{t("Verified")}</option>
                  <option value="Pending">{t("Pending")}</option>
                </select>
              </div>
            </div>
            <div className="search-group">
              <div className="search-clear ">
                <input
                  className=""
                  type="type"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={t("Search")}
                />
                <CloseOutlinedIcon
                  className="clear-icon"
                  onClick={() => clearSearch()}
                  style={
                    search === ""
                      ? { visibility: "hidden", cursor: "pointer" }
                      : { visibility: "visible", cursor: "pointer" }
                  }
                />
              </div>
              <button
                className="btn btnPrimary"
                onClick={() => searchHandler()}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={{ minHeight: 400, width: "100%" }}>
              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        {t("name")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("bmail")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("level")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("title")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("joined")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("status")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t("activation")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {t("action")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users && users.length > 0
                      ? users.map((user) => (
                          <StyledTableRow key={user._id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={
                                localStorage.getItem("i18nextLng") == "en"
                                  ? { textAlign: "left" }
                                  : { textAlign: "right" }
                              }
                            >
                              <div className="team-user">
                                {user.image && user.image.path !== "" ? (
                                  <Avatar src={user.image.path} />
                                ) : (
                                  <Avatar
                                    style={{
                                      backgroundColor: "#d6edff",
                                      verticalAlign: "middle",
                                      color: "#405261",
                                    }}
                                  >
                                    {user.firstName}
                                  </Avatar>
                                )}{" "}
                                &nbsp;{" "}
                                {`${user.firstName + " " + user.lastName}`}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={
                                localStorage.getItem("i18nextLng") == "en"
                                  ? { textAlign: "left" }
                                  : { textAlign: "right" }
                              }
                            >
                              {user.email}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {user.role === "manager" ? (
                                "Owner"
                              ) : (
                                <div className="inputComp">
                                  <select
                                    name="role"
                                    defaultValue={user.role}
                                    type="text"
                                    className="form-control"
                                    style={{
                                      fontSize: "13.5px",
                                      padding: "0px",
                                    }}
                                    onChange={(event) =>
                                      changeUserRole({
                                        _id: user._id,
                                        role: event.target.value,
                                      })
                                    }
                                  >
                                    <option value="user">{t("emp")}</option>
                                    <option value="team-manager">
                                      {t("manager")}
                                    </option>
                                  </select>
                                </div>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={
                                localStorage.getItem("i18nextLng") == "en"
                                  ? { textAlign: "left" }
                                  : { textAlign: "right" }
                              }
                            >
                              {user.jobTitle}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {moment(user.createdAt).format("YYYY-MM-DD")}
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              {user.verified ? t("ver") : t("pend")}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {user.role === "manager" &&
                              logedUserRole !== "manager" ? (
                                ""
                              ) : user.active ? (
                                <button
                                  className="btn btn-link text-danger tableBtn"
                                  style={{ fontSize: "13.5px" }}
                                  onClick={() => deactivateUser(user._id)}
                                >
                                  {t("Deactivate")}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-link tableBtn"
                                  style={{ fontSize: "13.5px" }}
                                  onClick={() => activateUser(user._id)}
                                >
                                  {t("Activate")}
                                </button>
                              )}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div style={{ width: "fit-content" }}>
                                <Menu
                                  menuButton={
                                    <MenuButton
                                      className="btn"
                                      onClick={(e) => {
                                        handleClick(e);
                                      }}
                                    >
                                      <i
                                        className="fas fa-ellipsis-h"
                                        style={{ color: "black" }}
                                      >
                                        {" "}
                                      </i>
                                    </MenuButton>
                                  }
                                >
                                  <MenuItem
                                    disabled={
                                      user.role === "manager" &&
                                      logedUserRole !== "manager"
                                    }
                                    onClick={() => {
                                      setEmployee(user);
                                      setState(true);
                                      handleClose();
                                    }}
                                  >
                                    {t("Edit")}
                                  </MenuItem>
                                  <MenuItem
                                    disabled={user.role === "manager"}
                                    onClick={() => {
                                      props.deleteMember(user._id);
                                      handleClose();
                                    }}
                                    style={{ color: "red" }}
                                  >
                                    {t("Delete")}
                                  </MenuItem>
                                </Menu>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="team-pagination">
                <p>
                  {first()} - {last()} of {selected ? selected.usersCount : ""}
                </p>
                <MdKeyboardArrowLeft
                  className="pagination-icon"
                  onClick={() => previousPage()}
                />
                <p>{page}</p>
                <MdKeyboardArrowRight
                  className="pagination-icon"
                  onClick={() => nextPage()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    team: state.team,
    employee: state.team.employee,
  };
};

const reduxMiddleware = reduxForm({
  form: "changeRole",
})(Team);

export default connect(mapStateToProps, {
  loadTeams,
  deleteMember,
  setEmployee,
})(reduxMiddleware);
