import React from 'react';
import { connect } from 'react-redux';
import { startAddCompany } from '../../../../actions/companyActions';
import { setLang } from '../../../../actions/langActions';

import { useTranslation } from 'react-i18next';

import history from '../../../../history';
import { Link } from 'react-router-dom';
import './demoRequest.css';
import MainNav from '../mainHeader/mainNav';
import gameball from '../../../../assets/images/gameball-logo.png'
import zest from '../../../../assets/images/zest-logo.jpg'
import gsef from '../../../../assets/images/gsef-logo.png'
import dayra from '../../../../assets/images/Dayra.png'

const DemoRequest = (props) => {
  const { t } = useTranslation();
  const requestDemo = () => {
    props.startAddCompany();
    history.push('/create');
  };

  return (
    <div className="container-fluid welcome">
    <MainNav />
      <div className="container">
        <div className="row align-items-center header justify-content-center">
          <div className="col-md-6">
            <h1
              style={
                localStorage.getItem('i18nextLng') == 'ar'
                  ? { width: '90%', textAlign: 'right' }
                  : { width: '90%', textAlign: 'left' }
              }
            >
              {t('welcome')}
            </h1>
            <p
              style={
                localStorage.getItem('i18nextLng') == 'ar'
                  ? { width: '80%', textAlign: 'right' }
                  : { width: '80%', textAlign: 'left' }
              }
            >
              {t('welcomeP')}
            </p>
            <button className="btn register-btn" onClick={() => requestDemo()}>
              {t('signUp')}
            </button>
            <Link className="btn login-btn" to="/login">
              {t('logIn')}
            </Link>
            <p
            style={
                localStorage.getItem('i18nextLng') == 'ar'
                  ? { textAlign: 'right' }
                  : { textAlign: 'left' }
              }
            >{t('trusted')}</p>
                <div className='logos-area'>
                    <img className="img-fluid trusted-logos" src={gameball} alt="logo-one" />
                    <img className="img-fluid trusted-logos" src={zest} alt="logo-two" />
                    <img className="img-fluid trusted-logos" src={gsef} alt="logo-three" />
                    <img className="img-fluid trusted-logos" src={dayra} alt="logo-four" />
                </div>
                        
          </div>
          <div className="col-5">
            <div className="col-12">
              <img
                className="img-fluid home-img"
                src="/images/mobile.png"
                alt="mobile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.lang.lang,
});

export default connect(mapStateToProps, { startAddCompany, setLang })(
  DemoRequest
);
