import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import UnderConstructionPage from "../../../underConstructionPage/underConstructionPage";

const Insights = () => {
  return (
    <div>
      <NavBar />
      <UnderConstructionPage />
    </div>
  );
};

export default Insights;
