import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Team from './pages/newStr/Teams/team/index';
import Invite from './pages/newStr/Teams/invite/invite';
import CreateTeam from './pages/newStr/Teams/createTeam/createTeam';
import history from '../../history';
import Home from './pages/newStr/Insights'; //insights and wallet
import { useTranslation } from 'react-i18next';

const DashboardPage = (props) => {

  let routes = (
    <Router history={history}>
      <div className="col-md-12">
        <Switch>
          <Route path="/dashboard/team/" exact component={Home} />

          <Route path="/dashboard/team/home" exact component={Team} />
          <Route path="/dashboard/team/invite" exact component={Invite} />
          <Route path="/dashboard/team/create" exact component={CreateTeam} />
        
          <Redirect to="/dashboard/" />
        </Switch>
      </div>
    </Router>
  );
  return <div>{routes}</div>;
};

export default connect(null, {})(DashboardPage);
