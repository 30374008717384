import React, { useEffect, useState } from "react";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";

const CashoutForm = ({
  method,
  banksNames,
  amount,
  selectedData,
  setShowModal,
  setShowModalTwo,
  mobile,
  setMobile,
  name,
  setName,
  selectData
}) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [bankAddress, setBankAddress] = useState("");

  const [phoneErr, setPhoneErr] = useState(false);

  const currency = useSelector(state => state.auth.user.currency[0]);

  useEffect(() => {
    setName(selectedData.bankAccountName);
    setAccountNumber(selectedData.bankAccountNumber);
    setBank(selectedData.bankName);
    setBankAddress(selectedData.bankBranchAddress);
    setMobile({ short: "EG", phone: selectedData.phoneNumber });
  }, [selectedData]);

  useEffect(() => {
    setName("");
    setAccountNumber("");
    setBank("");
    setBankAddress("");
    setMobile({ short: "EG", phone: "" });
  }, [method]);

  const submitCashout = e => {
    e.preventDefault();
    if (method === "") {
      cogoToast.warn("Please select transfare method");
    } else {
      if (method === "BANK") {
        // name === "" ||
        // accountNumber === "" ||
        amount === "" || amount === "0" || amount <= 0 || amount.length === 0
          ? // bankAddress === "" ||
            // bank === ""
            cogoToast.warn("Please complete all information")
          : setShowModal(true);
      } else {
        // name === "" ||
        // phone === "" ||
        amount === "" || amount === "0" || amount <= 0 || amount.length === 0
          ? cogoToast.warn("Please complete all information")
          : setShowModal(true);
      }
    }
  };
  const { t } = useTranslation();

  return (
    <form
      className="form"
      onSubmit={submitCashout}
      style={
        localStorage.getItem("i18nextLng") == "ar"
          ? { marginTop: "0px", textAlign: "right" }
          : { marginTop: "0px", textAlign: "left" }
      }
    >
      {method !== "BANK" ? (
        <>
          <h4
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? {
                    width: "100%",
                    textAlign: "right",
                    marginBottom: "25px"
                  }
                : {
                    width: "100%",
                    textAlign: "left",
                    marginBottom: "25px"
                  }
            }
          >
            {t("cashoutinfo")}
          </h4>
          <div className="form-row wide">
            <label>{t("Phone")}</label>
            <ConfigProvider
              locale={en}
              areaMapper={area => {
                return {
                  ...area,
                  emoji: <span className={`fp ${area.short.toLowerCase()}`} />
                };
              }}
            >
              <CountryPhoneInput
                defaultValue={{ short: "EG" }}
                id="mobile-input"
                type="number"
                placeholder="1234567890"
                required
                value={selectedData.phone}
                className="form-control"
                onChange={v => {
                  setPhoneErr(false);
                  selectData({ ...selectedData, phone: v });
                  setMobile(v);
                }}
              />
            </ConfigProvider>
          </div>
          <div className="form-row wide">
            <label>{t("fullName")}</label>
            <input
              className="form-control"
              placeholder="ex. mohamed kamel"
              required
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </>
      ) : (
        <>
          <h4
            className="mb-3"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { width: "100%", textAlign: "right" }
                : { width: "100%", textAlign: "left" }
            }
          >
            {t("cashoutinfo")}
          </h4>
          <div className="form-row wide">
            <label>{t("fullName")}</label>
            <input
              className="form-control"
              placeholder="ex. mohamed kamel"
              value={selectedData.bankAccountName}
              required
              onChange={e => {
                selectData({
                  ...selectedData,
                  bankAccountName: e.target.value
                });
              }}
            />
          </div>

          <div className="form-row wide">
            <label>{t("accNum")}</label>
            <input
              className="form-control"
              placeholder="123123123123123123"
              required
              type="number"
              value={selectedData.bankAccountNumber}
              onChange={e => {
                selectData({
                  ...selectedData,
                  bankAccountNumber: e.target.value
                });
              }}
            />
          </div>

          <div className="form-row wide">
            <label>{t("bankName")}</label>
            {currency === "EGP" ? (
              <select
                className="form-control"
                required
                placeholder="Please, Select your bank name"
                defaultValue={banksNames[0]}
                onChange={e => {
                  selectData({
                    ...selectedData,
                    bankName: e.target.value
                  });
                }}
                value={selectedData.bankName}
              >
                {banksNames.map(name => {
                  return (
                    <option
                      value={name}
                      // selected={name === selectedData.bankName}
                    >
                      {name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                className="form-control"
                placeholder="ex. Alinma"
                value={bank}
                onChange={e => setBank(e.target.value)}
              />
            )}
          </div>

          <div className="form-row wide">
            <label>{t("bankAddress")}</label>
            <input
              className="form-control"
              placeholder="ex. san stefano, alexandriaBE"
              value={selectedData.bankBranchAddress}
              required
              onChange={e => {
                selectData({
                  ...selectedData,
                  bankBranchAddress: e.target.value
                });
              }}
            />
          </div>
        </>
      )}
      {currency && currency === "EGP" ? (
        <button
          className="btn btnPrimary ml-4 col-12"
          style={{ width: "90% " }}
          type="submit"
        >
          {t("cashout")}
        </button>
      ) : (
        <button
          className="btn btnPrimary ml-4 col-12"
          style={{ width: "90% " }}
          type="submit"
          onClick={() => {
            method === ""
              ? cogoToast.warn("Please select transfare method")
              : setShowModalTwo(true);
          }}
        >
          {t("cashout")}
        </button>
      )}
    </form>
  );
};

export default CashoutForm;
