import React, { useEffect } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import DemoRequest from './components/demo-request/demoRequest';
import MainNav from './components/mainHeader/mainNav';
import CheckMail from './components/checkMail/checkMail';
import VerifyFail from './components/verifyFail/verifyFail';
import SetupFinished from './components/setupFinished/setupFinished';
import Login from './components/login/login';
import ForgetPassword from './components/forgetPassword/index';
import CreatePassword from './components/createPassword/index';
import ChangePassword from './components/changePassword/index';

import VerificationCode from './components/verificationCode/verificationCode';

import history from '../../history';

const Website = () => {
  useEffect(() => {
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: 't9p3f0de',
    });
    // history.replace("/login");
  }, []);

  let routes = (
    <Router history={history}>
      <div>
        <Switch>
          {/*<Route path="/" exact component={DemoRequest} />*/}
          <Route path="/login" exact component={Login} />
          <Route path="/forget-password" exact component={ForgetPassword} />
          <Route path="/verificate-code" exact component={VerificationCode} />
          <Route path="/changePass" exact component={ChangePassword} />
          <Route path="/createPass" exact component={CreatePassword} />

          <Route path="/register-success" exact component={CheckMail} />
          <Route path="/verify-fail" exact component={VerifyFail} />
          <Route path="/finish-setup" exact component={SetupFinished} />
          <Redirect to="/login" />
        </Switch>
      </div>
    </Router>
  );

  return <div>{routes}</div>;
};

export default connect(null, {})(Website);
