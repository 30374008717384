import {
  ADD_TEAM,
  LIST_USERS,
  LIST_TEAMS,
  SEND_INVITE,
  USER_ACTIVATE,
  USER_DEACTIVATE,
} from '../actions/types';

const INITIAL_STATE = {
  teams: [],
  count: 0,
  message: undefined,
  employee: {},
  users: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_TEAMS:
      return {
        ...state,
        teams: action.payload,
        index: 0,
        message: undefined,
      };
      case LIST_USERS:
        return {
          ...state,
          users: action.payload,
        };
    case SEND_INVITE:
      return {
        ...state,
        message: action.payload,
      };
    case ADD_TEAM:
      return {
        ...state,
        message: action.payload.message,
        teams: [...state.teams, action.payload.data],
      };
    case USER_ACTIVATE:
      return {
        ...state,
        teams: [
          ...state.teams,
          state.teams.map((team) => {
            const userIndex = team.users.findIndex(
              (obj) => obj._id === action.payload
            );
            team.users[userIndex].active = true;
          }),
        ],
      };
    case USER_DEACTIVATE:
      return {
        ...state,
        teams: state.teams.map((team) => {
          const userIndex = team.users.findIndex(
            (obj) => obj._id === action.payload
          );
          team.users[userIndex].active = false;
        }),
      };
    case 'SET_EMPLOYEE':
      return {
        ...state,
        employee: action.payload,
      };

    default:
      return state;
  }
};
