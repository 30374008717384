import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import api from "../../../../../../apis/api";
import { BiEditAlt } from "react-icons/bi";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import "antd/dist/antd.css";
import { Avatar } from "antd";

import "./createTeam.css";
import cogoToast from "cogo-toast";

const CreateTeam = () => {
  const [teams, setTeams] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [team, setTeam] = useState({});
  const [newName, setNewName] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = () => {
    api({
      url: "/teams",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTeams(res.data.data.filter((team) => team.active));
        setLoading(false);
        console.log(res.data.data);
      })
      .catch((err) => {});
  };

  const deleteTeam = () => {
    api({
      url: `/teams/delete/${team._id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.status == 1) {
          getTeams();
          setModalTwoIsOpen(false);
          cogoToast.info("Team deleted successfuly");
        }
      })
      .catch((err) => {});
  };

  const editTeam = () => {
    api({
      url: `/teams/edit/${team._id}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { name: newName },
    })
      .then((res) => {
        if (res.data.status == 1) {
          getTeams();
          setModalIsOpen(false);
          cogoToast.info("Team name edited successfuly");
        }
      })
      .catch((err) => {});
  };

  const onSubmit = (data) => {
    api({
      url: "/teams/",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    })
      .then((res) => {
        if (res.data.status === 1) {
          getTeams();
          setNewName("");
          cogoToast.success("team added Successfuly");
        } else {
          cogoToast.error("Adding new team failed");
        }
      })
      .catch((err) => {});
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const customStyle = {
    content: {
      borderRadius: "20px",
      width: "50%",
      height: "fit-content",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)",
    },
  };

  return (
    <div>
      <div
        className="mainPage create-team"
        style={
          localStorage.getItem("i18nextLng") == "en"
            ? { textAlign: "left" }
            : { textAlign: "right" }
        }
      >
        <h1>{t("Create New Team")}</h1>
        <h6>{t("Team Information")}</h6>
        <form
          className="form-row create-team-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="text"
            placeholder={t("Team name")}
            autoComplete="off"
            className="form-control element60"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            name="name"
            ref={register({ required: true })}
          />
          {errors.team && <p>Team name is required</p>}
          <button type="submit" className="btn element30 btnURL">
            {t("Create New Team")}
          </button>
        </form>
      </div>
      <div className="teams-cards">
        {isLoading ? (
          <div className="loading">
            <div class="shapes-7"></div>
          </div>
        ) : (
          teams.map((team) => {
            return (
              <div
                className="team-card"
                key={team._id}
                style={
                  localStorage.getItem("i18nextLng") == "en"
                    ? { textAlign: "left" }
                    : { textAlign: "right" }
                }
              >
                <h3>
                  <b>
                    {team.name} {t("team___")}
                  </b>
                </h3>
                <p>
                  {t("This team is created by")} <span>@{team.createdBy}</span>{" "}
                  {t("and the team manager is")}{" "}
                  <span>@{team.manager + " "}</span>.
                </p>
                <h6>
                  <b>
                    {t("Team Members")} ({team.usersCount})
                  </b>
                </h6>
                <Avatar.Group>
                  {/*<Avatar src="/images/human.png" />
                <Avatar style={{backgroundColor: '#ffd6d6'}}> MA </Avatar>
                <Avatar src="/images/member.png" />
                <Avatar style={{backgroundColor: '#d6edff'}}> HK </Avatar>*/}
                  {team.users.map((user) => {
                    return user.image === "" ? (
                      <Avatar style={{ backgroundColor: "#d6edff" }}>
                        {user.firstName}
                      </Avatar>
                    ) : (
                      <Avatar src={user.image} />
                    );
                  })}
                </Avatar.Group>
                {team.name === "Finance" ||
                team.name === "Management" ||
                team.name === "HR" ? (
                  ""
                ) : (
                  <div
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? {
                            position: "absolute",
                            right: "auto",
                            left: "15px",
                            top: "15px",
                          }
                        : {
                            position: "absolute",
                            right: "15px",
                            left: "auto",
                            top: "15px",
                          }
                    }
                  >
                    <Menu
                      menuButton={
                        <MenuButton
                          className="pen-icon"
                          onClick={(e) => {
                            handleClick(e);
                          }}
                        >
                          <BiEditAlt className="" />
                        </MenuButton>
                      }
                    >
                      <MenuItem
                        onClick={() => {
                          setTeam(team);
                          handleClose();
                          setModalIsOpen(true);
                        }}
                      >
                        {t("Edit")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setTeam(team);
                          handleClose();
                          setModalTwoIsOpen(true);
                        }}
                        style={{ color: "red" }}
                      >
                        {t("Delete")}
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyle}
      >
        <h4>{t("Enter the new team name")}</h4>
        <input
          className="form-control"
          value={newName}
          placeholder={team.name}
          onChange={(e) => setNewName(e.target.value)}
        />
        <button
          className="btn btnPrimary btn-new-req"
          onClick={() => editTeam()}
        >
          {t("Edit")}
        </button>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setModalIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>

      <Modal
        isOpen={modalTwoIsOpen}
        onRequestClose={() => setModalTwoIsOpen(false)}
        style={customStyle}
      >
        <h4>
          {t("Please confirm to delete")} {team.name}
        </h4>
        <button className="btn btnSec btn-new-req" onClick={() => deleteTeam()}>
          {t("Delete")}
        </button>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setModalTwoIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>
    </div>
  );
};

export default CreateTeam;
