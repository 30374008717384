import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import api from "../../../../apis/api";
import { BiHide } from "react-icons/bi";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import "./invitation.css";
import cogoToast from "cogo-toast";
import history from "../../../../history";

const Invitation = props => {
  const [teams, setTeams] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [showNewPass, setShowNewPass] = useState(false);
  const companyId = props.location.search.split("=")[1].split("&")[0];

  const { register, errors, handleSubmit } = useForm();
  const { t } = useTranslation();

  const content = (
    <ul>
      <li>8 Letters min length</li>
      <li>Include capital Letter</li>
      <li>Include small Letter</li>
      <li>Include Number</li>
      <li>Include special character</li>
    </ul>
  );

  const getCompanyAndTeams = () => {
    api
      .post("teams/getCompanyAndTeams", { id: companyId })
      .then(res => {
        if (res.data.status === 1) {
          setTeams(res.data.data.teams);
          setCompanyName(res.data.data.company.name);
        } else {
          cogoToast.error(t("Can not get company data"));
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getCompanyAndTeams();
  }, []);

  const renderTeams = () => {
    if (teams && teams.length > 0) {
      let options = (
        <option value="noteam" disabled>
          {t("Pleasechooseateam")}
        </option>
      );
      const filteredTeams = teams.filter(team => team.name !== "All");
      return (options = [
        options,
        ...filteredTeams.map(team => {
          console.log(team);
          return (
            <option
              value={team.id}
              key={team.id}
              disabled={team.name === "All"}
            >
              {team.name}
            </option>
          );
        })
      ]);
    } else {
      return <option value="noteam">No teams found</option>;
    }
  };

  const submitMember = data => {
    api
      .post("teams/registerByLink", data)
      .then(res => {
        if (res.data.message === "User regesterd") {
          cogoToast.success(t("User regestered"));
          history.replace("/login");
        } else cogoToast.error(t(res.data.message));
      })
      .catch(err => console.log(err));
  };

  const onSubmit = data => {
    data.company = companyId;
    submitMember(data);
  };

  return (
    <div className="invitation">
      <div className="invitation-side">
        <img src="/images/l.png" alt="logo" />
        <h3>{t("Manage Your Work Expenses!")}</h3>
        <img src="/images/mobile.png" alt="logo" />
      </div>
      <div className="page gettingStarted invitation-content">
        <h1>{t("invite1")}</h1>
        <h5>
          {t("invite2")} 👋, {companyName} {t("invite3")}
        </h5>
        <h5>{t("invite4")}</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="row-element">
              <div className="form-row" style={{ margin: "0px" }}>
                <div className="row-element">
                  <label>{t("fName")}</label>
                  <input
                    type="text"
                    placeholder={t("fNamePH")}
                    autoComplete="off"
                    className="form-control"
                    name="firstName"
                    ref={register({ required: true })}
                  />
                  {errors.firstName && <p>First name is required</p>}
                </div>
                <div className="row-element">
                  <label>{t("lName")}</label>
                  <input
                    type="text"
                    placeholder={t("lNamePH")}
                    autoComplete="off"
                    className="form-control"
                    name="lastName"
                    ref={register({ required: true })}
                  />
                  {errors.lastName && <p>Last name is required</p>}
                </div>
              </div>
            </div>
            <div className="row-element">
              <label>{t("bmail")}</label>
              <input
                type="text"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                title="Enter in the format: example@email.com"
                placeholder={t("bmailPH")}
                autoComplete="off"
                className="form-control"
                name="email"
                ref={register({ required: true })}
              />
              {errors.email && <p>Email is required</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t("team")}</label>
              <select
                className="form-control"
                name="team"
                ref={register({ required: true })}
              >
                {renderTeams()}
              </select>
              {errors.team && <p>Team is required</p>}
            </div>
            <div className="row-element">
              <label>{t("Work Title (Optional)")}</label>
              <input
                type="text"
                placeholder={t("Recruitment Specialist")}
                autoComplete="off"
                className="form-control"
                name="jobTitle"
                ref={register({ required: false })}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="row-element" style={{ position: "relative" }}>
              <label>{t("pass")}</label>
              <input
                type={showNewPass ? "text" : "password"}
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
                placeholder={t("passPH")}
                autoComplete="off"
                className="form-control"
                name="password"
                minLength="8"
                ref={register({ required: true })}
              />
              {errors.password && <p>Password is required</p>}
              <BiHide
                className="passShowHide"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "10px", top: "42px" }
                    : { right: "10px", top: "42px" }
                }
                onClick={() => setShowNewPass(!showNewPass)}
              />
              <span
                className="pass-requirements"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "40px", top: "35px" }
                    : { right: "40px", top: "35px" }
                }
              >
                <Popover
                  content={content}
                  title="Password must be:"
                  trigger="click"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </div>
            <div className="row-element">
              <label style={{ visibility: "hidden" }}>{t("pass")}</label>
              <button
                className="btn btnPrimary"
                type="submit"
                style={{ marginTop: "0px" }}
              >
                {t("Join Company Account")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Invitation;
