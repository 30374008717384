import React, { useEffect, useState } from "react";
import { HiOutlineFilter } from "react-icons/hi";
import { GoChevronDown } from "react-icons/go";
import { Menu, Dropdown } from "antd";
import ReactExport from "react-export-excel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getCases,
  getCategories
} from "../../../../../../actions/categoryActions";

import "./expenseFilter.css";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExpenseFilter = ({ exel, action }) => {
  const categories = useSelector(state => state.categories.categories) || [];
  const cases = useSelector(state => state.categories.cases) || [];

  const [users, setUsers] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [userId, setUserId] = useState("");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const getAllEmployees = () => {
    api({
      url: `/user`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.status === 1) {
          const filteredUsers = res.data.data.filter(
            user => !user.lastName.includes("Deleted")
          );
          setUsers(filteredUsers);
          console.log(res.data.data);
        } else cogoToast.error("Couldn't get employees data");
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCases());
    getAllEmployees();
  }, []);

  useEffect(() => {
    action && action(categoryId, caseId, userId);
  }, [userId, categoryId, caseId]);

  const categoryOptions = categories.map(cat => {
    return <option value={cat._id}>{cat.name}</option>;
  });

  const casesOptions = cases.map(cat => {
    return <option value={cat._id}>{cat.name}</option>;
  });

  const usersOptions = users.map(user => {
    return (
      <option value={user._id}>
        {user.firstName} {user.lastName}
      </option>
    );
  });

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key="0">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/csv.png" alt="csv" /> &nbsp; CSV
              </p>
            }
            filename="Pending Expenses"
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Paid On" value="paidOn" />
              <ExcelColumn label="Paid For" value="paidFor" />
              <ExcelColumn label="Paid To" value="paidTo" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Images"
                value={col => {
                  let str = "";
                  col.images.forEach(element => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="1">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/QuickBooks.png" alt="csv" /> &nbsp; QuickBooks
              </p>
            }
            filename="Pending Expenses"
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Product/Category" value="paidTo" />
              <ExcelColumn
                label="Product/Service Description"
                value="paidFor"
              />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="Rate" value="rate" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Tax Code" value="taxCode" />
              <ExcelColumn label="Tax Amount" value="taxAmount" />
              <ExcelColumn label="Class" value="category" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Memo"
                value={col => {
                  let str = "";
                  col.images.forEach(element => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="2">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/Xero.png" alt="csv" /> &nbsp; Xero
              </p>
            }
            filename="Pending Expenses"
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Number" value="number" />
              <ExcelColumn label="Reference" value="paidFor" />
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Account" value="paidTo" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn
                label="Memo"
                value={col => {
                  let str = "";
                  col.images.forEach(element => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="3">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/FreshBooks.png" alt="csv" /> &nbsp; FreshBooks
              </p>
            }
            filename="Pending Expenses"
          >
            <ExcelSheet
              data={exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Merchant" value="paidTo" />
              <ExcelColumn label="Description" value="paidFor" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Memo"
                value={col => {
                  let str = "";
                  col.images.forEach(element => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="expense-filter-wraper">
      <div className="expense-filter">
        <div className="expense-filter-actions">
          <HiOutlineFilter className="expense-filter-icon" />
          <h4>{t("Filter by")}:</h4>
          <div className="expense-filter-item">
            <label>{t("Category")}</label>
            <select
              className="form-control"
              name="categoryId"
              onChange={e => setCategoryId(e.target.value)}
            >
              <option value="">{t("All")}</option>
              {categoryOptions}
            </select>
          </div>
          <div className="expense-filter-item">
            <label>{t("Case")}</label>
            <select
              className="form-control"
              name="categoryId"
              onChange={e => setCaseId(e.target.value)}
            >
              <option value="">{t("All")}</option>
              {casesOptions}
            </select>
          </div>
          <div className="expense-filter-item">
            <label>{t("Employee")}</label>
            <select
              className="form-control"
              name="employeeId"
              onChange={e => setUserId(e.target.value)}
            >
              <option value="">{t("All")}</option>
              {usersOptions}
            </select>
          </div>
        </div>
        <div className="expense-filter-export">
          <Dropdown overlay={menu()} trigger={["click"]}>
            {localStorage.getItem("i18nextLng") == "en" ? (
              <button className="btn btn-success btnGray">
                Export To <GoChevronDown style={{ fontSize: "16px" }} />
              </button>
            ) : (
              <button className="btn btn-success btnGray">
                إستخراج إلى <GoChevronDown style={{ fontSize: "16px" }} />
              </button>
            )}
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ExpenseFilter;
