import React from "react";

import ForgetPasswordForm from "./forgetPasswordForm/forgetPasswordForm";
import LoginBanner from "../login/loginBanner/loginBanner";

const ForgetPassword = () => {
  return (
    <section className="login-page-wrapper">
      <div className="login-page">
        <ForgetPasswordForm />
        <LoginBanner />
      </div>
    </section>
  );
};

export default ForgetPassword;
