import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Col } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { setLang } from '../../../../actions/langActions';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../translations/i18n';

import './sidebar.css';

const Sidebar = (props) => {
  const { t } = useTranslation();
  const [visibilty, setvisibilty] = useState(false);

  useEffect(() => {
    localStorage.setItem('i18nextLng', localStorage.getItem('lang'));
    const element = document.getElementById('body');
    document.body.dir = i18n.dir();
  }, []);
  return (
    <div className="col-md-2 sidebar ">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        show={visibilty}
      >
        <Modal.Header closeButton onClick={() => setvisibilty(false)}>
          {localStorage.getItem('i18nextLng') == 'en' ? (
            <Modal.Title md="12">send email</Modal.Title>
          ) : (
            <Modal.Title md="12">ارسل الى البريد</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Col} md="12" controlId="validationFormik101">
              {localStorage.getItem('i18nextLng') == 'en' ? (
                <Modal.Title md="12"> E-mail</Modal.Title>
              ) : (
                <Modal.Title
                  md="12"
                  style={
                    localStorage.getItem('i18nextLng') == 'ar'
                      ? { width: '100%', textAlign: 'right' }
                      : { width: '100%', textAlign: 'left' }
                  }
                >
                  بريدك الالكترونى
                </Modal.Title>
              )}
              <Form.Control type="text" name="email" />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik101">
              {localStorage.getItem('i18nextLng') == 'en' ? (
                <Modal.Title md="12"> Subject</Modal.Title>
              ) : (
                <Modal.Title
                  md="12"
                  style={
                    localStorage.getItem('i18nextLng') == 'ar'
                      ? { width: '100%', textAlign: 'right' }
                      : { width: '100%', textAlign: 'left' }
                  }
                >
                  الموضوع
                </Modal.Title>
              )}{' '}
              <Form.Control type="text" name="subject" />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik101">
              {localStorage.getItem('i18nextLng') == 'en' ? (
                <Modal.Title md="12"> Body</Modal.Title>
              ) : (
                <Modal.Title
                  md="12"
                  style={
                    localStorage.getItem('i18nextLng') == 'ar'
                      ? { width: '100%', textAlign: 'right' }
                      : { width: '100%', textAlign: 'left' }
                  }
                >
                  الرسالة
                </Modal.Title>
              )}
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                name="mail"
                rows="3"
              ></textarea>
            </Form.Group>

            <button
              className="btn btnPrimary col-3"
              onClick={() => {
                setvisibilty(false);
              }}
            >
              Send
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="row">
        <div className="col-md-12 side-nav text-center">
          <Link to="#" className="btn col-md-12">
            <section class="col-md-12 black ">
              <div class="d-flex align-items-center justify-content-center black h-100 w-100">
                <img
                  src="/images/l.png"
                  className="col-12 h-100 d-flex flex-column m-3 black"
                ></img>
              </div>
            </section>
          </Link>
          <p className="h1" style={{ color: 'white' }}>
            {t('setup')}
          </p>

          <img className="img-fluid" src="/images/logo.png" alt="hollydesk" />
          <ul class=" align-items-center justify-content-center  w-100 col-md-12">
            <li>
              <span
                className={`${
                  props.location.pathname === '/create' ||
                  props.location.pathname === '/create/'
                    ? ' active'
                    : ''
                }`}
              >
                {t('getStart')}
              </span>
            </li>
            <li>
              <span
                className={`${
                  props.location.pathname === '/create/company' ||
                  props.location.pathname === '/create/company/'
                    ? ' active'
                    : ''
                }`}
              >
                {t('companyDetails')}
              </span>
            </li>
            <li>
              <span
                className={`${
                  props.location.pathname === '/create/admin-roles' ||
                  props.location.pathname === '/create/admin-roles/'
                    ? ' active'
                    : ''
                }`}
              >
                {t('adminRoles')}
              </span>
            </li>
            <Link
              onClick={() => {
                props.setLang(localStorage.getItem('i18nextLng'));
                localStorage.setItem(
                  'lang',
                  localStorage.getItem('i18nextLng')
                );
              }}
            >
              {localStorage.getItem('i18nextLng') == 'en' ? '🇪🇬 ' : '🇺🇸'}
            </Link>
          </ul>
          <p className="question">
            {t('anyTroubles')}
            <a
              className="btn contact-us"
              onClick={() => {
                // setvisibilty(true);
                window.open('mailto:cx@hollydesk.com?subject=Subject&body=Body%20goes%20here')
              }}
            >
              {t('contactUs')}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.lang.lang,
});
export default connect(mapStateToProps, { setLang })(withRouter(Sidebar));
