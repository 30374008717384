import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resendMail } from '../../../../actions/companyActions';
import { useTranslation } from 'react-i18next';

import './checkMail.css';

const CheckMail = (props) => {
  const { t } = useTranslation();

  return (
    <div className="checkMail">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-4 text-center">
            <img className="img-fluid" src="images/check.png" alt="hollydesk" />
            <div>
              {' '}
              <h2>{t('mailV')}</h2>
            </div>{' '}
            <p className="mb-2">
              {t('mailV2')}
              <br />
              <div className="my-3">
                {props.company.id &&
                props.company.token 
                // &&
                // !props.companyemailResent
                 ? (
                  <button
                    onClick={() =>
                      props.resendMail(props.company.id, props.company.token)
                    }
                    className="btn btn-dark ml-2"
                    style={{ fontSize: '15px' }}
                  >
                    {t('resend')}
                  </button>
                ) : (
                  ''
                )}
              </div>
            </p>
            {props.company.emailResent ? (
              <span className="text-white">Email resent!</span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

export default connect(mapStateToProps, { resendMail })(CheckMail);
