import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import history from "../history";
import { tokenConfig } from "./tokenActions";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  WALLET,
  REQUEST_COUNT,
  MY_REQUEST_COUNT,
  INADVANC_REQUEST_COUNT,
  MY_INADVANCE_REQUEST_COUNT
} from "./types";
import { loadCompanyData } from "./companyActions";
import { EWalletsOptions } from "./userActions";
import { getNotifications } from "./notificationsActions";
import cogoToast from "cogo-toast";
import firebase from "firebase";

import i18n from "../translations/i18n";

export const login = data => async dispatch => {
  dispatch(clearErrors());
  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // const firebaseConfig = {
  //   apiKey: "AIzaSyCXHHWW0w-ZecBP4wO_JhyswEblc-LDUK4",
  //   authDomain: "hollydesk-49a5f.firebaseapp.com",
  //   projectId: "hollydesk-49a5f",
  //   storageBucket: "hollydesk-49a5f.appspot.com",
  //   messagingSenderId: "447493188410",
  //   appId: "1:447493188410:web:fae10b0b7eed309b56be03"
  // };

  // data.deviceToken="stringNull"

  // console.log(Notification.permission);

  // if (firebase.messaging.isSupported() && Notification.permission=='granted' ) {
  //   if (firebase.apps.length === 0) {
  //     firebase.initializeApp(firebaseConfig);
  //   }
  //   const messaging = firebase.messaging();
  //   messaging.onMessage((payload) => console.log(payload))
  //   let currentToken= await messaging.getToken({vapidKey: "BB2aVWvnuBEw4qT0GJM-dW0TVp0yHX19A0fPA7tKHg3YZSZpSPzxc2mtGAH80WD7CKyrN2qs_ewekFDI1xekJwE"})
  //   data.deviceToken = currentToken;
  // }

  data.deviceToken = localStorage.getItem("notificationToken") || "_";

  data = JSON.stringify(data);

  api
    .post("/auth/login", data, config)
    .then(res => {
      if (res.data.status === 1) {
        localStorage.setItem("hollydeskEmail", res.data.data.user.email);
        localStorage.setItem("hollydeskId", res.data.data.user.email);
        localStorage.setItem("hollydeskLogedIn", true);

        window.Intercom("shutdown");

        window.Intercom("boot", {
          app_id: "t9p3f0de",
          email: localStorage.getItem("hollydeskEmail"),
          user_id: localStorage.getItem("hollydeskId"),
          created_at: 1234567890
        });

        dispatch({ type: LOGIN_SUCCESS, payload: res.data.data });
        dispatch({ type: WALLET, payload: res.data.data.user.credit });
        dispatch(loadCompanyData());
        dispatch(EWalletsOptions());
        dispatch(getNotifications());
        history.push("/dashboard");
      } else {
        cogoToast.error(i18n.t(res.data.message));
        dispatch(returnErrors(res.data.message, res.data.status, "LOGIN_FAIL"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
      dispatch({ type: LOGIN_FAIL });
    });
};

export const forgetPassword = data => dispatch => {
  dispatch(clearErrors());
  dispatch(verificationEmail(data));
  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  data = JSON.stringify(data);

  api
    .post("/auth/forgetPassword", data, config)
    .then(res => {
      if (res.data.status === 1) {
        cogoToast.info(i18n.t("Verication code sent to your E-mail"));
        history.push("/verificate-code");
        dispatch();
      } else {
        dispatch(returnErrors(res.data.message, res.data.status, "LOGIN_FAIL"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
      dispatch({ type: LOGIN_FAIL });
    });
};

export const verificationEmail = email => {
  return {
    type: "VERIFICATION_EMAIL",
    email
  };
};

export const changeUserEmail = email => {
  return {
    type: "CHANGE_USER_EMAIL",
    email
  };
};

export const addPhoneNumber = phone => {
  return {
    type: "ADD_PHONE_NUMBER",
    phone
  };
};

export const removePhoneNumber = phone => {
  return {
    type: "REMOVE_PHONE_NUMBER",
    phone
  };
};

export const updateProfile = user => {
  return {
    type: "UPDATE_PROFILE",
    user
  };
};

export const getUserInfo = data => dispatch => {
  api({
    url: "/user/profile",
    method: "get",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": `Bearer ${localStorage.getItem("token")}`
    }
  })
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: "GET_USER_INFO",
          userInfo: res.data.data.userDetails
        });
        dispatch(EWalletsOptions());
        dispatch({ type: WALLET, payload: res.data.data.userDetails.credit });
      } else {
        console.log("Failed update user information");
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const verifyCode = data => dispatch => {
  dispatch(clearErrors());
  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  api
    .post("/auth/verifyCode", data, config)
    .then(res => {
      if (res.data.status === 1) {
        // cogoToast.info('Verication code sent to your E-mail');
        history.push("/changePass");
        dispatch();
      } else {
        cogoToast.error(i18n.t(res.data.message));
        dispatch(returnErrors(res.data.message, res.data.status, "LOGIN_FAIL"));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
      dispatch({ type: LOGIN_FAIL });
    });
};

export const loadRequestCount = data => async (dispatch, getState) => {
  api

    .get("/requests/count", tokenConfig(getState))

    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: REQUEST_COUNT,
          payload: res.data.data[0].pending.count
        });
      } else {
        dispatch({ type: REQUEST_COUNT, payload: 0 });
      }
    })
    .catch(err => {
      dispatch({ type: REQUEST_COUNT, payload: 0 });
    });
};

export const loadInadvanceRequestCount = data => async (dispatch, getState) => {
  api

    .get("/inadvance/count", tokenConfig(getState))

    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: INADVANC_REQUEST_COUNT,
          payload: res.data.data[0].pending.count
        });
      } else {
        dispatch({ type: INADVANC_REQUEST_COUNT, payload: 0 });
      }
    })
    .catch(err => {
      dispatch({ type: INADVANC_REQUEST_COUNT, payload: 0 });
    });
};

export const loadMyRequestCount = data => async (dispatch, getState) => {
  api
    .get("/requests/myCountRequests", tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: MY_REQUEST_COUNT,
          payload: res.data.data
        });
      } else {
        dispatch({ type: MY_REQUEST_COUNT, payload: 0 });
      }
    })
    .catch(err => {
      dispatch({ type: MY_REQUEST_COUNT, payload: 0 });
    });
};

export const loadMyInadvanceRequestCount = data => async (
  dispatch,
  getState
) => {
  api
    .get("/inadvance/myCountRequests", tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: MY_INADVANCE_REQUEST_COUNT,
          payload: res.data.data
        });
      } else {
        dispatch({ type: MY_INADVANCE_REQUEST_COUNT, payload: 0 });
      }
    })
    .catch(err => {
      dispatch({ type: MY_INADVANCE_REQUEST_COUNT, payload: 0 });
    });
};

export const logout = () => {
  history.push("/");
  localStorage.setItem("hollydeskLogedIn", false);
  localStorage.removeItem("hollydeskEmail");
  localStorage.removeItem("hollydeskId");

  window.Intercom("shutdown");

  window.Intercom("boot", {
    app_id: "t9p3f0de"
  });

  return {
    type: LOGOUT_SUCCESS
  };
};
