import React, { useState, useEffect } from "react";
import api from "../../../../../../apis/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cogoToast from "cogo-toast";

const WalletBanner = () => {
  const [companyName, setCompanyName] = useState("");
  const [balance_, setBalance_] = useState(0);
  const [deposit, setDeposit] = useState({});
  const [credit, setCredit] = useState({});
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.user);
  const currency = useSelector((state) => state.auth.user.currency[0]);

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = () => {
    api({
      url: "/company/balance",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          setCompanyName(res.data.data.result.name);
          setBalance_(Number(res.data.data.result.credits["$numberDecimal"]));
          setCredit(res.data.data.result.lastTransaction);
          setDeposit(res.data.data.result.lastCashout);
        } else {
          cogoToast.error(res.data.data.message);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="wallet-banner">
      <div className="wallet-info">
        <h1>
          {t("hello")} {user.firstName}
        </h1>
        <h5>{t("LATEST TRANSACTION")}</h5>
        <div className="transaction">
          <div className="sub-transaction">
            <img src="/images/deposit.png" alt="last-deposit" />
            {deposit.date === "" ? (
              <h4>No past transactions</h4>
            ) : (
              <div>
                <h5>
                  {deposit.amount} {t(currency)} - {t("Deposited")}
                </h5>
                <h6>
                  {moment(deposit.date).format("LL")} -{" "}
                  {moment(deposit.date).format("LT")}
                </h6>
              </div>
            )}
          </div>
          <div className="sub-transaction">
            <img src="/images/credit.png" alt="last-deposit" />
            {credit.date === "" ? (
              <h4>No past transactions</h4>
            ) : (
              <div>
                <h5>
                  {credit.amount} {t(currency)} - {t("Credited")}
                </h5>
                <h6>
                  {moment(credit.date).format("LL")} -{" "}
                  {moment(credit.date).format("LT")}
                </h6>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="wallet-card">
        <img src="/images/world-map.png" alt="wallet-card" />
        <div className="wallet-card-info">
          <div className="companies">
            <h3 style={{ width: "fit-content", fontWeight: "bold" }}>
              {companyName}
            </h3>
            <h3 style={{ width: "fit-content", fontWeight: "bold" }}>
              Hollydesk
            </h3>
          </div>
          <div className="credits">
            <p>{t(currency === "EGP" ? "EGPBalance" : "SAR Balance")}</p>
            <h1>
              {Number.isInteger(balance_)
                ? balance_
                : parseFloat(balance_).toFixed(2)}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletBanner;
