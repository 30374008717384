import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { listRequests } from "../../../../../actions/requestsActions";
import { Link } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CashoutTransactions from "./cashoutTransactions/cashoutTransactions";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";

import "./my-wallet.css";

let MyWallet = (props) => {
  const currency = useSelector((state) => state.auth.user.currency[0]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [all, setAll] = useState(true);
  const [amount, setAmount] = useState(0);
  const [value_, setValue_] = useState("CashOutAll");

  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => setAmount(props.wallet), []);

  const handleChange = (event) => {
    setValue_(event.target.value);
    if (event.target.value === "CashOutAll") {
      setAmount(props.wallet);
    } else {
      setAmount("");
    }
    setAll(!all);
  };

  const proceedHnadler = () => {
    return amount <= 0 && value_ === "CashOutPortion"
      ? cogoToast.warn("Pleas set a valid cashout amount!")
      : history.push(
          `/dashboard/requests/myWallet/cashoout/${value_}/${amount}`
        );
  };

  const renderInput = ({ input, label, meta, type, placeholder }) => {
    return (
      <div className="inputComp">
        <label>{label}</label>
        <input
          className="form-control"
          placeholder={placeholder}
          {...input}
          type={type}
          autoComplete="off"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  const customStyle = {
    content: {
      borderRadius: "20px",
      width: "90%",
      height: "fit-content",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)",
    },
  };

  return (
    <div className="mainPage wallet">
      <div className="container-fluid">
        <div className="my-wallet-container">
          <div className="my-wallet-desc">
            <div className="col-md-12 mb-5">
              <div className="wallet-image">
                <img
                  // style={{ height: "320px" }}
                  src="/images/my-wallet.png"
                  alt="reimbursementImage"
                  className="img-fluid"
                />
              </div>
            </div>
            <h3
              className="mb-5"
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { width: "100%", textAlign: "right" }
                  : { width: "100%", textAlign: "left" }
              }
            >
              {t("cashout0")}
            </h3>
            <ul>
              <li
                className="mb-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left" }
                }
              >
                <img
                  src="/images/greenCheck.png"
                  className="col-1"
                  alt="screenshot"
                />
                <span> {t("cashout1")}</span>
              </li>
              <li
                className="mb-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? {
                        width: "100%",
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }
                    : {
                        width: "100%",
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }
                }
              >
                <img
                  src="/images/greenCheck.png"
                  className="col-1"
                  alt="rules"
                />
                <span>{t("cashout2")}</span>
              </li>
              <li
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left" }
                }
              >
                <img
                  src="/images/greenCheck.png"
                  className="col-1"
                  alt="complaints"
                />
                <span>{t("cashout3")}</span>
              </li>
            </ul>
          </div>
          <div className="my-wallet-options">
            <div className="col-12 wallet-trans">
              {" "}
              <div className="wallet-image">
                <h3
                  className="mb-2 ml-1"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                >
                  {t("myWallet")}
                </h3>
              </div>
              <div className="card ">
                <div className="card-bottom pt-3 px-3 mb-2">
                  <div className="d-flex flex-row ">
                    <div className="d-flex flex-column">
                      <h4
                        className="mb-5"
                        style={
                          localStorage.getItem("i18nextLng") == "ar"
                            ? { width: "100%", textAlign: "right" }
                            : { width: "100%", textAlign: "left" }
                        }
                      >
                        {t("hello")}, {props.auth.user.firstName}
                      </h4>
                      <p
                        className="h5 mb-0"
                        style={
                          localStorage.getItem("i18nextLng") == "ar"
                            ? { width: "100%", textAlign: "right" }
                            : { width: "100%", textAlign: "left" }
                        }
                      >
                        {t(currency === "EGP" ? "EGPBalance" : "SAR Balance")}
                      </p>
                      <h1>
                        {props.wallet} {t(currency === "EGP" ? "EGP" : "SAR")}
                      </h1>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <button
                className="btn btnPrimary col-12"
                onClick={() => setModalIsOpen(true)}
              >
                {t("My Cashout Transactions")}
              </button>
              <h5
                className="mb-2 mt-5 ml-1"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left" }
                }
              >
                {t("cashout")}
              </h5>
              <FormControl component="fieldset">
                <RadioGroup
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                  aria-label="gender"
                  name="gender1"
                  value={value_}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    style={
                      localStorage.getItem("i18nextLng") == "en"
                        ? { width: "100%", textAlign: "left" }
                        : { width: "100%", textAlign: "right" }
                    }
                    value="CashOutAll"
                    control={
                      <Radio
                        color="primary"
                        style={
                          localStorage.getItem("i18nextLng") == "en"
                            ? {
                                textAlign: "left",
                              }
                            : {
                                textAlign: "right",
                              }
                        }
                      />
                    }
                    label={t("cashoutAll")}
                  />
                  <FormControlLabel
                    style={
                      localStorage.getItem("i18nextLng") == "en"
                        ? { width: "100%", textAlign: "left" }
                        : { width: "100%", textAlign: "right" }
                    }
                    value="CashOutPortion"
                    control={
                      <Radio
                        color="primary"
                        style={
                          localStorage.getItem("i18nextLng") == "en"
                            ? {
                                textAlign: "left",
                                display: "inline-block",
                              }
                            : {
                                textAlign: "right",
                                display: "inline-block",
                              }
                        }
                      />
                    }
                    label={t("cashoutP")}
                  />
                </RadioGroup>
              </FormControl>
              <div className="col-12 row">
                <div className="col-5">
                  <input
                    style={
                      localStorage.getItem("i18nextLng") == "en"
                        ? {
                            width: "100%",
                            textAlign: "right",
                            display: "inline-block",
                          }
                        : {
                            width: "100%",
                            textAlign: "left",
                            display: "inline-block",
                          }
                    }
                    className="col-12"
                    name="amount"
                    disabled={all}
                    placeholder="00"
                    type="number"
                    min="1"
                    value={amount}
                    component={renderInput}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="col-7"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? {
                          width: "100%",
                          textAlign: "right",
                          display: "inline-block",
                        }
                      : {
                          width: "100%",
                          textAlign: "left",
                          display: "inline-block",
                        }
                  }
                >
                  <p className="max ">
                    {t("max")} {props.wallet}
                  </p>
                </div>
              </div>
              <Link
                className="btn btnPrimary mt-5"
                style={{ width: "100%" }}
                onClick={() => {
                  proceedHnadler();
                }}
              >
                {t("ProccedToCashOut")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyle}
      >
        <CashoutTransactions />
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setModalIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.request,
    errors: state.error,
    auth: state.auth,
    wallet: state.wallet.wallet,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, { listRequests })(MyWallet);
