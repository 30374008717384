import React from "react";
import PropTypes from "prop-types";
import { getOrdinalNumForDate } from "../../utils/getOridnalNumForDate";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { Link } from "react-router-dom";
import styles from "./payrollList.module.css";
import moment from "moment";
import { links } from "../../utils/links";

const getDetailsLink = (PayrollType, payrollID) => {
  if (PayrollType === "fixed") return links.fixedLinks.details(payrollID);

  return links.payAsYouGoLinks.details(payrollID);
};

const PayrollList = ({ payrolls, type }) => {
  return (
    <ul className={styles.list}>
      {payrolls.map(payroll => (
        <li className={styles.item} key={payroll.id}>
          {type === "fixed" &&
            (payroll.active && payroll.enabled ? (
              <span className={styles.item_active}>Active</span>
            ) : !payroll.active ? (
              <span className={styles.item_deactive}>
                Deactivated for a month
              </span>
            ) : (
              <span className={styles.item_deactive}>Deactivated forever</span>
            ))}
          <div className={styles.wrapper}>
            <h3 className={styles.title}>{payroll.name}</h3>
            <Link to={getDetailsLink(type, payroll.id)}>
              <Edit />
            </Link>
          </div>
          {type === "fixed" ? (
            <p className={styles.description}>
              Monthly paid on {getOrdinalNumForDate(payroll.dueDate)} of the
              month
            </p>
          ) : (
            <p className={styles.description}>
              Paid on {moment(payroll.createdAt).format("DD/MM/YYYY")}
            </p>
          )}
        </li>
      ))}
    </ul>
  );
};

PayrollList.propTypes = {
  payrolls: PropTypes.array,
  type: PropTypes.string.isRequired
};

export default PayrollList;
