import React from "react";
import { NavLink } from "react-router-dom";
import { links } from "../../utils/links";
import styles from "./nav.module.css";

const NavBar = () => {
  return (
    <div className={styles.nav}>
      <NavLink
        to={links.fixedLinks.home}
        exact
        className={styles.link}
        activeClassName={styles.link_active}
      >
        Fixed rate
      </NavLink>
      <NavLink
        to={links.payAsYouGoLinks.home}
        className={styles.link}
        activeClassName={styles.link_active}
      >
        Pay as you go
      </NavLink>
      <NavLink
        to={links.insights}
        className={styles.link}
        activeClassName={styles.link_active}
      >
        Insights
      </NavLink>
    </div>
  );
};

export default NavBar;
