import { Space } from "antd";
import { Alert } from "antd";
import EmptyPayrolls from "../components/EmptyPayrolls/EmptyPayrolls";
import PropTypes from "prop-types";
import Spinner from "../components/Spinner";

const withAsyncChecks = WrappedComponent => {
  const wrapped = ({ isLoading, error, data, ...others }) => {
    if (isLoading) {
      return <Spinner />;
    }
    if (error) {
      return (
        <Space
          style={{ height: "80vh", display: "grid", placeItems: "center" }}
        >
          <Alert message="Sorry,Something went wrong :(" type="error" />{" "}
        </Space>
      );
    }

    return !data ? null : data.length || !(data instanceof Array) ? (
      <WrappedComponent data={data} {...others} />
    ) : (
      <EmptyPayrolls
        isPayroll={others.isPayroll}
        payrollType={others.payrollType}
      />
    );
  };

  wrapped.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.any,
    error: PropTypes.any.isRequired,
    isPayroll: PropTypes.bool,
    payrollType: PropTypes.string
  };

  return wrapped;
};

withAsyncChecks.propTypes = {
  Component: PropTypes.element
};

export default withAsyncChecks;
