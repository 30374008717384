import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../components/Spinner";
import { links } from "../utils/links";
import FixedRate from "../containers/Fixed/Home";

const CreateFixedRate = lazy(() => import("../containers/Fixed/Create"));
const PayrollDetails = lazy(() => import("../containers/Fixed/Details"));
const PayrollEdit = lazy(() => import("../containers/Fixed/Edit"));

const FixedPayroll = () => {
  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path={links.fixedLinks.home} exact component={FixedRate} />
          <Route
            path={links.fixedLinks.create}
            exact
            component={CreateFixedRate}
          />
          <Route
            path={links.fixedLinks.editRoute}
            exact
            component={PayrollEdit}
          />
          <Route
            path={links.fixedLinks.detailsRoute}
            exact
            component={PayrollDetails}
          />
          <Redirect to={links.fixedLinks.home} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default FixedPayroll;
