import React from "react";
import { Link } from "react-router-dom";
import PayrollList from "../../../components/PayrollList/PayrollList";
import withAsyncChecks from "../../../hoc/withAsyncChecks";
import styles from "./styels/PAYG.module.css";
import { ReactComponent as WhatsApp } from "../../../assets/icons/whatsapp.svg";
import { links } from "../../../utils/links";

const PayAsYOuGoPayrolls = ({ data: payrolls }) => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>PayAsYouGo payrolls</h2>
        <div className={styles.links_wrapper}>
          <Link to={links.teams} className={styles.link_outline}>
            Edit payroll teams
          </Link>

          <Link
            to={links.payAsYouGoLinks.create}
            className={styles.link_contained}
          >
            Add new payroll
          </Link>
          <Link
            to={links.payAsYouGoLinks.contacts}
            className={styles.link_contacts}
          >
            <WhatsApp style={{ width: "20px" }} />
            WhatsApp
          </Link>
        </div>
      </div>
      <PayrollList payrolls={payrolls} type="payAsYouGo" />
    </section>
  );
};

export default withAsyncChecks(PayAsYOuGoPayrolls);
