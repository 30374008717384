import React from "react";
import NavBar from "../../../components/NavBar/NavBar";
import useAxios from "../../../hooks/useAxios";
import axios from "../../../lib/axios";
import { endpoints } from "../../../utils/links";
import PayAsYOuGoPayrolls from "./PayAsYOuGoPayrolls";

const Index = () => {
  const [response, error, loading] = useAxios({
    axiosInstance: axios,
    method: "get",
    url: endpoints.payroll.lists()
  });
  return (
    <div>
      <NavBar />

      <PayAsYOuGoPayrolls
        data={response?.data}
        error={error}
        isLoading={loading}
        isPayroll
        payrollType="payAsYouGo"
      />
    </div>
  );
};

export default Index;
