import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import { VscBellDot } from "react-icons/vsc";
import { VscBell } from "react-icons/vsc";
import { getNotifications } from "../../../../../actions/notificationsActions";
import { Divider } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import api from "../../../../../apis/api";
import cogoToast from "cogo-toast";

import "./notificationsMenu.css";

const NotificationsMenu = () => {
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [unseen, setUnseen] = useState([]);

  const moreNotifications = useSelector(
    state => state.notifications.notifications
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setNotifications([]);
    setPage(1);
    dispatch(getNotifications(page));
  }, []);

  useEffect(() => {
    dispatch(getNotifications(page));
  }, [page]);

  useEffect(() => {
    const updatedNotifications = [...notifications, ...moreNotifications];
    const uniqueNotifications = Array.from(
      new Set(updatedNotifications.map(a => a._id))
    ).map(id => {
      return updatedNotifications.find(a => a._id === id);
    });
    const unseenNotifications = uniqueNotifications.filter(notf => !notf.seen);
    setNotifications(uniqueNotifications);
    setUnseen(unseenNotifications);
  }, [moreNotifications]);

  const readNotification = id => {
    api({
      url: "/notifications/seen",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*"
      },
      data: { id }
    })
      .then(res => {
        if (res.data.status === 1) {
          const updatedNotifications = notifications.map(el =>
            el._id === id ? { ...el, seen: true } : el
          );
          setNotifications(updatedNotifications);
          const unseenNotifications = updatedNotifications.filter(
            notf => !notf.seen
          );
          setUnseen(unseenNotifications);
        } else {
          cogoToast.error(t("Failed reading the notification"));
        }
      })
      .catch(err => console.log(err));
  };

  const markAllRead = () => {
    api({
      url: "/notifications/seenAll",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*"
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          const updatedNotifications = notifications.map(el => ({
            ...el,
            seen: true
          }));
          setNotifications(updatedNotifications);
          setUnseen([]);
        } else {
          cogoToast.error(t("Failed reading the notification"));
        }
      })
      .catch(err => console.log(err));
  };

  const notificationRedirection = type => {
    switch (type) {
      case "rembersment":
        history.push("/dashboard/requests/my-requests");
        break;

      case "inadvance":
        history.push("/dashboard/requests/inAdvance");
        break;

      case "Reconciliation":
        history.push("/dashboard/requests/inAdvance");
        break;

      case "Supplier":
        history.push("/dashboard/suppliers/my-requests");
        break;

      case "team-reimbursement":
        history.push("/dashboard/expense/reimbursement");
        break;

      case "team-inadvance":
        history.push("/dashboard/expense/inAdvance");
        break;

      case "team-supplier":
        history.push("/dashboard/suppliers/expenses/pending");
        break;

      default:
        break;
    }
  };

  const menu = (
    <div className="notifications-menu">
      {notifications.length > 0 ? (
        <div>
          <div className="notifications-head">
            <h5>{t("Notifications")}</h5>
            <p className="mark-read" onClick={() => markAllRead()}>
              {t("mark all as read")}
            </p>
          </div>
          {notifications.map((notification, i) => {
            return (
              <div key={notification._id}>
                <div className="single-notification">
                  <div className="notification-avatar">
                    {notification.from.userImage !== "" ? (
                      <Avatar src={notification.from.userImage} alt="avatar" />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#d6edff",
                          verticalAlign: "middle",
                          color: "#405261",
                          cursor: "pointer"
                        }}
                      >
                        {notification.from.firstName}
                      </Avatar>
                    )}
                  </div>
                  <div
                    className="notification"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                    onClick={() => {
                      notificationRedirection(notification.type);
                      setVisible(false);
                      readNotification(notification._id);
                    }}
                  >
                    <p
                      className="notifi-descrp"
                      style={{
                        fontWeight: notification.seen ? "normal" : "bold"
                      }}
                    >
                      {localStorage.getItem("i18nextLng") === "en"
                        ? notification.msgEn
                        : notification.msgAr}
                    </p>
                    <p className="notification-submition">
                      {t("Has been submited by")} @{notification.from.firstName}
                      &nbsp;{notification.from.lastName}
                    </p>
                    <p className="notification-submition">
                      {moment(notification.createdAt).format("LL")} -{" "}
                      {moment(notification.createdAt).format("LT")}
                    </p>
                  </div>
                </div>
                <Divider />
              </div>
            );
          })}
          <p onClick={() => setPage(page + 1)}>{t("Load more")}</p>
        </div>
      ) : (
        <p>{t("No recent notifications")}</p>
      )}
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onBlur={() => setVisible(false)}
    >
      <a
        className="ant-dropdown-link "
        onClick={e => {
          e.preventDefault();
          setVisible(visible ? false : true);
        }}
      >
        {unseen.length > 0 ? (
          <VscBellDot className="nav-action" />
        ) : (
          <VscBell className="nav-action" />
        )}
      </a>
    </Dropdown>
  );
};

export default NotificationsMenu;
