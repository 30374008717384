import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  verifyEmail,
  addCompanyDetails
} from "../../../../actions/companyActions";
import { Field, reduxForm, reset } from "redux-form";
import { clearErrors } from "../../../../actions/errorActions";

import history from "../../../../history";
import { withRouter } from "react-router-dom";
import "../pages.css";
import "./companyDetails.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import { loadCompanyCountries } from "../../../../actions/companyActions";
import cogoToast from "cogo-toast";


const CompanyDetails = props => {
  const { t } = useTranslation();

  const legalName = useSelector(state => state.company.legalName);

  const [show, setshow] = useState(true);
  const [visible, setvisible] = useState(false);
  const [msg, setmsg] = useState(undefined);
  const [mobile, setMobile] = useState({
    short: "EG",
    phone: ""

  });
  const [phoneErr, setPhoneErr] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  useEffect(() => {
    console.log('hi');
    props.loadCompanyCountries();
  }, []);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let token = params.get('token');

    props.verifyEmail(id, token);

    props.reset("companyDetails");

    props.clearErrors();
    localStorage.setItem('lang', props.lang);
    localStorage.setItem('i18nextLng', props.lang);
  }, []);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get("id");
    let token = params.get("token");

    let { status } = props.company;
    if (status === 100) history.push(`/finish-setup?id=${id}&token=${token}`);
    if (status === 80)
      history.push(`/create/admin-roles?id=${id}&token=${token}`);
    let { emailVerified } = props.company;

    if (emailVerified === false) {
      return history.push('/verify-fail');
    }
    if (emailVerified === true) {
      setvisible(true);
      setTimeout(() => {
        setshow(false);
      }, 5000);
    }
  }, [props.company]);

  useEffect(() => {
    let { id, msg } = props.errors;

    if (id === 'ADD_COMPANY_FAIL') {
      setmsg(msg);
    }
  }, [props.errors]);

  const onSubmit = values => {
    let correctedMobile = mobile.phone;
    let sentNumber = mobile.code + correctedMobile;
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === 'EG' &&
      mobile.phone.length !== 10 &&
      mobile.phone.length !== 11
    )
      return cogoToast.error(t("Please, Enter valid mobile number"));

    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === 'EG' &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = mobile.code + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === 'EG' &&
      mobile.phone.length === 11
    ) {
      correctedMobile = mobile.phone.substring(1);
      sentNumber = mobile.code + correctedMobile;
    }
    values.phone = sentNumber;

    props.addCompanyDetails(values);
  };

  return (
    <div
      className="page company"
      style={
        localStorage.getItem('i18nextLng') == 'ar'
          ? { width: '100%', textAlign: 'right', overflow: 'hidden' }
          : { width: '100%', textAlign: 'left', overflow: 'hidden' }
      }
    >
      <h1
        style={
          localStorage.getItem('i18nextLng') == 'ar'
            ? { width: '100%', textAlign: 'right' }
            : { width: '100%', textAlign: 'left' }
        }
      >
        {t('companyDetails')}
      </h1>
      <p
        style={
          localStorage.getItem('i18nextLng') == 'ar'
            ? { width: '100%', textAlign: 'right' }
            : { width: '100%', textAlign: 'left' }
        }
      >
        {t('companyDP')}
      </p>
      <div className="row">
        <div className="col-md-6">
          <div className="alert alertPage">
            <h6
              style={
                localStorage.getItem('i18nextLng') == 'ar'
                  ? { width: '100%', textAlign: 'right' }
                  : { width: '100%', textAlign: 'left' }
              }
            >
              {t('companyLegal')}
            </h6>
            <p
              style={
                localStorage.getItem('i18nextLng') == 'ar'
                  ? { width: '100%', textAlign: 'right' }
                  : { width: '100%', textAlign: 'left' }
              }
            >
              {t('companyLegalP')}
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-8">
            {msg ? <div className="alert alert-danger">{msg}</div> : ''}
          </div>
        </div>

        <div
          style={
            localStorage.getItem('i18nextLng') == 'ar'
              ? { textAlign: 'right' }
              : { textAlign: 'left' }
          }
        >
          <div className="row forms">
            <div className="col-md-10 left-forms">
              <div className="form-row">
                <div className="row-element">
                  <label>{t('Country')}</label>
                  <select
                    className="form-control"
                    name="country"
                    ref={register({ required: true })}
                  >
                    {props.company.contries.map(c => (
                      <option value={c._id}>{c.country} </option>
                    ))}
                  </select>
                  {errors.country && <p>Country is required</p>}
                </div>
                <div className="row-element">
                  <div className="form-row" style={{ margin: '0px' }}>
                    <div className="row-element">
                      <label>{t('city')}</label>
                      <input
                        className="form-control"
                        autoComplete="off"
                        name="city"
                        placeholder={t('citPH')}
                        ref={register({ required: true })}
                      />
                      {errors.city && <p>City is required</p>}
                    </div>
                    <div className="row-element">
                      <label>{t('pcode')}</label>
                      <input
                        className="form-control"
                        autoComplete="off"
                        name="postCode"
                        placeholder={t('42511')}
                        ref={register({ required: true })}
                      />
                      {errors.postCode && <p>Post code is required</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="row-element" style={{ position: 'relative' }}>
                  <label>{t('companyLegal')}</label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    name="legalName"
                    placeholder={t('companyLegalPH')}
                    ref={register({ required: true })}
                  />
                  {errors.legalName && <p>Company legal name is required</p>}
                </div>
                <div className="row-element" style={{ position: 'relative' }}>
                  <label>{t('website')}</label>
                  <input
                    className="form-control"
                    type="url"
                    autoComplete="off"
                    name="website"
                    placeholder={t('https//www.company.com')}
                    ref={register({ required: true })}
                  />
                  {errors.website && <p>Company website is required</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="row-element">
                  <label>{t('officeAddress')}</label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    name="address"
                    placeholder={t('officeAddressPH')}
                    ref={register({ required: true })}
                  />
                  {errors.address && <p>Address is required</p>}
                </div>
                <div className="row-element">
                  <label>{t('phone')}</label>
                  {/* <input
                    className="form-control"
                    type="number"
                    autoComplete="off"
                    name="phone"
                    placeholder={t("01234567890")}
                    ref={register({ required: true })}
                  />
                  {errors.phone && <p>Phone is required</p>} */}
                  <ConfigProvider
                    locale={en}
                    areaMapper={area => {
                      return {
                        ...area,
                        emoji: (
                          <span className={`fp ${area.short.toLowerCase()}`} />
                        )
                      };
                    }}
                  >
                    <CountryPhoneInput
                      defaultValue={{ short: 'EG' }}
                      id="mobile-input"
                      type="number"
                      placeholder="1234567890"
                      value={mobile}
                      className="form-control"
                      onChange={v => {
                        setPhoneErr(false);
                        setMobile(v);
                      }}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div className="form-row">
                <div className="row-element"></div>
                <div className="row-element">
                  <button className="btn btnPrimary" type="submit">
                    {t('countToAdmin')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    errors: state.error,
    company: state.company,
    lang: state.lang.lang
  };
};

const renderSelectField = ({ meta, label, input, children }) => {
  const className = `inputComp ${
    meta.touched && meta.error ? 'has-error' : ''
  }`;
  return (
    <div className={className}>
      <label>{label}</label>
      <select className="form-control" {...input}>
        {children}
      </select>
      {renderError(meta)}
    </div>
  );
};

const renderSelectFieldAR = ({ meta, label, input, children }) => {
  const className = `inputComp ${
    meta.touched && meta.error ? 'has-error' : ''
  }`;
  return (
    <div className={className}>
      <label className="arLabel">{label}</label>
      <select className="form-control" {...input}>
        {children}
      </select>
      {renderError(meta)}
    </div>
  );
};

const renderInput = ({ input, label, meta, type, placeholder }) => {
  return (
    <div className="inputComp">
      <label>{label}</label>
      <input
        className="form-control"
        placeholder={placeholder}
        {...input}
        type={type}
        autoComplete="off"
      />
      {renderError(meta)}
    </div>
  );
};

const renderInputAR = ({ input, label, meta, type, placeholder }) => {
  //console.log(localStorage.getItem('i18nextLng'));
  return (
    <div className="inputComp">
      <label className="arLabel">{label}</label>
      <input
        className="form-control"
        placeholder={placeholder}
        {...input}
        type={type}
        autoComplete="off"
      />
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="text-danger">{error}</div>;
  }
};

const validateForm = formValues => {
  const errors = {};

  if (!formValues.country) {
    errors.country = 'Please add country';
  }

  if (!formValues.legalName) {
    errors.legalName = 'Please add legalName';
  }

  if (!formValues.address) {
    errors.address = 'Please add address';
  }

  if (!formValues.city) {
    errors.city = 'Please add city';
  }

  if (!formValues.postCode) {
    errors.postCode = 'Please select postCode';
  }

  //   if (!formValues.website) {
  //     errors.website = 'Please add website';
  //   }

  //   if (!formValues.phone) {
  //     errors.phone = 'Please add phone';
  //   }

  return errors;
};

const reduxMiddleware = reduxForm({
  form: 'companyDetails',
  validate: validateForm,
  enableReinitialize: true
})(CompanyDetails);

export default connect(mapStateToProps, {
  verifyEmail,
  addCompanyDetails,
  reset,
  clearErrors,
  loadCompanyCountries
})(withRouter(reduxMiddleware));
