import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { GoChevronDown } from 'react-icons/go';
import { RiWallet3Fill } from 'react-icons/ri';
import { FcSafe } from 'react-icons/fc';

import 'antd/dist/antd.css';

const WalletsMenu = () => {

  const role = useSelector(state => state.auth.user.role) || 'user'

  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link className='nav-menu-item' to='/dashboard/requests/myWallet'>
          <div className='icon-wrapper' style={{backgroundColor: '#e7f8ef'}}>
            <RiWallet3Fill className='nav-menu-icon'/>
          </div>
          &nbsp;&nbsp;
          {t('myWallet')}
        </Link>
      </Menu.Item>
      {role === 'accountant' || role === 'manager' ? (
        <Menu.Item key="1">
          <Link className='nav-menu-item' to='/dashboard/wallet'>
            <div className='icon-wrapper' style={{backgroundColor: '#e7f8ef'}}>
              <FcSafe className='nav-menu-icon'/>
            </div>
            &nbsp;&nbsp;
            {t('companyWallet')}
          </Link>
        </Menu.Item>
      ) : ('')}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a 
      className={
      window.location.pathname.includes('requests/myWallet') ||
      window.location.pathname.includes('dashboard/wallet') ? 'active-nav-tab ant-dropdown-link' : 'nav-tab ant-dropdown-link'} 
      onClick={e => e.preventDefault()}>
        {t('Wallets')} &nbsp; <GoChevronDown style={{fontSize: '16px'}}/>
      </a>
    </Dropdown>
  );
};

export default WalletsMenu;