import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ExpenseItem from "./Item/item";
import { loadingRequests } from "../../../../../../actions/pendingRequestsActions";
import { loadRequestCount } from "../../../../../../actions/authActions";
import { getCategories } from "../../../../../../actions/categoryActions";

import ExpenseFilter from "./expenseFilter";

import "./pendingExpense.css";

const PendingExpense = props => {
  const [exel, setExel] = useState([]);

  useEffect(() => {
    props.loadingRequests();
    props.loadRequestCount();
    props.getCategories();

    const { pendings } = props;
    let exel = [];
    if (pendings && pendings.length > 0) {
      for (const iterator of pendings) {
        exel = [...exel, ...iterator.pending.requests];
      }
      for (const iterator of exel) {
        iterator.name = iterator.from.firstName + " " + iterator.from.lastName;
      }
      setExel(props.exel);
    }
  }, []);

  const applyFilter = (cat, cas, user) => {
    const catQuery = cat === "" ? cat : `category=${cat}`;
    const caseQuery = cas === "" ? cas : `case=${cas}`;
    const userQuery = user === "" ? user : `employee=${user}`;

    const query = `${catQuery}&${caseQuery}&${userQuery}`;
    props.loadingRequests(query);
  };

  const renderExpenseItems = () => {
    const { pendings } = props;

    if (pendings && pendings.length > 0) {
      return pendings.map((pending, i) => {
        let exel = [...pending.pending.requests];
        if (exel.requests && exel.length > 0) {
          for (const iterator of exel) {
            iterator.name =
              iterator.from.firstName + " " + iterator.from.lastName;
          }
        }
        return (
          <div
            className="container-fluid pending-expense"
            style={{ marginTop: i === 0 && "150px" }}
          >
            <ExpenseItem pending={pending.pending} exel={exel} />{" "}
          </div>
        );
      });
    } else if (pendings && pendings.length === 0) {
      return localStorage.getItem("i18nextLng") == "en" ? (
        <section class="h-100 w-100">
          <header class="container h-100 w-100">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div
                class="d-flex flex-column"
                style={{ fontSize: "50px", marginTop: "100px" }}
              >
                You don't have any requests
              </div>
            </div>
          </header>
        </section>
      ) : (
        <section class="h-100 w-100">
          <header class="container h-100 w-100">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div
                class="d-flex flex-column"
                style={{ fontSize: "50px", marginTop: "100px" }}
              >
                ليس لديك طلبات{" "}
              </div>
            </div>
          </header>
        </section>
      );
    }
  };

  return (
    <div className="row">
      <ExpenseFilter exel={exel} action={applyFilter} />
      {renderExpenseItems()}
    </div>
  );
};

const mapToStateProps = state => {
  return {
    pendings: state.pending.pendings,
    exel: state.pending.exel,
    lang: state.lang.lang
  };
};

export default connect(mapToStateProps, {
  loadRequestCount,
  loadingRequests,
  getCategories
})(PendingExpense);
