const INITIAL_STATE = {
  notifications: [],
  totalCount: 0,
  totalUnseen: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      return {
        notifications: action.payload.notification,
        totalCount: action.payload.totalCount,
        totalUnseen: action.payload.totalUnseen
      };

    default:
      return state;
  }
};