import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BillingPlanCard from "./billingPlanCard/billingPlanCard";
import cogoToast from "cogo-toast";
import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "../../../../../actions/billingPlansActions";
import api from "../../../../../apis/api";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { HiCreditCard } from "react-icons/hi";
import { getSavedCards } from "../../../../../actions/companyActions";

import "./billingPlan.css";

const BillingPlan = () => {
  const plans = useSelector((state) => state.billing.plans);
  const currentPlan = useSelector((state) => state.billing.currentPlan);
  const paymentCards = useSelector((state) => state.company.savedCards);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [method, setMethod] = useState("card");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getSavedCards());
    if (paymentCards.length > 0) setMethod(paymentCards[0]._id);
  }, []);

  const higherPlans = plans.filter((plan) => {
    return parseInt(plan.price) <= parseInt(currentPlan.price);
  });

  const addCreditCard = () => {
    api({
      url: "/company/getTokenBillingForm",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      // data: { amount: creditCardAmount },
    })
      .then((res) => {
        if (res.data.status === 1) {
          window.COWPAYOTPDIALOG.init();
          window.COWPAYOTPDIALOG.load(res.data.data.confirmation_form_id);
        } else {
          cogoToast.error("Transaction Faild");
        }
      })
      .catch((err) => {});
  };

  window.addEventListener(
    "message",
    function(e) {
      if (e.data && e.data.callback_type === "card_tokenized") {
        dispatch(getSavedCards());
      }
    },
    false
  );

  const cancelSubscription = () => {
    api({
      url: "/plans/cancel",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(getPlans());
          cogoToast.success("Plan unsubscribed successfully");
        } else {
          cogoToast.error("Unsubscription Faild");
        }
      })
      .catch((err) => {});
  };

  const deleteCard = (id) => {
    api({
      url: "company/deleteCard",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { id: id },
    })
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(getSavedCards());
          cogoToast.success("Card deleted successfully");
        } else {
          cogoToast.error("Deleting card Faild");
        }
      })
      .catch((err) => {});
  };

  const setDefaultCard = (id) => {
    api({
      url: "company/setDefaultCard",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { id: id },
    })
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(getSavedCards());
          cogoToast.success("Card set as default");
        } else {
          cogoToast.error("Set default card faild");
        }
      })
      .catch((err) => {});
  };

  const customStyle = {
    content: {
      borderRadius: "10px",
      width: "25%",
      height: "fit-content",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)",
    },
  };

  return Object.keys(currentPlan).length !== 0 ? (
    <div className="mainPage billing-details">
      <h1 id="BillingPlan-Title">{t("Billing Details")}</h1>
      <p id="BillingPlan-SubTitle" style={{ color: "#BABBC2" }}>
        {t("Your Membership")}
      </p>
      <div className="current-membership">
        <div className="membership-dateils">
          <p id="BillingPlan-DetailsTitle" style={{ color: "#BABBC2" }}>
            {t("Current Subscription Details")}
          </p>
          <h3 id="BillingPlan-planName" style={{ fontWeight: "bold" }}>
            {currentPlan.name}
          </h3>
          <p id="BillingPlan-renewDate" style={{ fontWeight: "bold" }}>
            {t("Renewal date")}: {currentPlan.renewDate}
          </p>
          <button
            id="BillingPlan-changePlanButton"
            className="btn btnPrimary"
            style={{ width: "fit-content" }}
          >
            {t("Change Plan")}
          </button>
        </div>
        <h3
          id="BillingPlan-planPrice"
          className="membership-price"
          style={{ fontWeight: "bold" }}
        >
          {t("EGP")} {currentPlan.price}{" "}
          <span className="month">/ {t("Month")}</span>
        </h3>
      </div>
      <div className="plan-control">
        <div className="plan-control-element">
          <div className="row-element">
            <p id="BillingPlan-paymentMethod" className="control-element-title">
              Payment method
            </p>
            <p id="BillingPlan-visaNumber" className="control-element-content">
              VISA **** 9082
            </p>
          </div>
          <p
            id="BillingPlan-addCardButton"
            className="control-element-action"
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            Change card
          </p>
        </div>
        <div className="plan-control-element">
          <div className="row-element">
            <p id="BillingPlan-InvoiceTitle" className="control-element-title">
              Invoices
            </p>
            <p id="BillingPlan-lastInvoice" className="control-element-content">
              Last invoice paid
            </p>
          </div>
          <p
            id="BillingPlan-viewInvoiceButton"
            className="control-element-action"
          >
            View invoices
          </p>
        </div>
        <div className="plan-control-element">
          <div className="row-element">
            <p id="BillingPlan-StatusTitle" className="control-element-title">
              Status
            </p>
            <p id="BillingPlan-status" className="control-element-content">
              Active
            </p>
          </div>
          <p
            id="BillingPlan-cancelSubscription"
            className="control-element-action"
            onClick={() => cancelSubscription()}
          >
            Cancel subscription
          </p>
        </div>
      </div>
      {higherPlans.length === 1 ? (
        ""
      ) : (
        <div style={{ width: "100%" }}>
          <h3
            id="BillingPlan-UpgardeTitle"
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "30px",
            }}
          >
            {t("Upgrade Your Bussiness Plan Today")}
          </h3>
          <div className="plan-cards-container">
            {higherPlans.map((plan, i) => {
              return (
                <BillingPlanCard
                  id={`BillingPlan-Title-${i}`}
                  plan={plan}
                  key={plan.id}
                  subscribed={
                    Object.keys(currentPlan).length > 0 ? true : false
                  }
                  currentPlan={currentPlan}
                />
              );
            })}
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyle}
      >
        <div
          className="addCard-modal"
          style={{ width: "100%", alignItems: "flex-start" }}
        >
          <h4>{t("Change Card")}</h4>
          {paymentCards.length > 0 ? (
            paymentCards.map((card, i) => {
              return (
                <div
                  className="cash-method"
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span
                    id={`PlanConfiguration-method-${i + 330}`}
                    style={{ fontWeight: "bold" }}
                  >
                    <HiCreditCard
                      className="updgrade-icon"
                      style={{
                        color: card.default ? "#56C288" : "#BDBDBD",
                      }}
                    />
                    &nbsp; &nbsp;
                    {`**** ${card.last_four}`}
                  </span>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {card.default ? (
                      <p
                        style={{ marginInlineStart: "35px", color: "#56C288" }}
                      >
                        {t("Default")}
                      </p>
                    ) : (
                      <p
                        style={{
                          marginInlineStart: "35px",
                          color: "#9CA3AF",
                          cursor: "pointer",
                        }}
                        onClick={() => setDefaultCard(card._id)}
                      >
                        {t("Make as default")}
                      </p>
                    )}
                    <p
                      style={{ color: "#EB5757", cursor: "pointer" }}
                      onClick={() => deleteCard(card._id)}
                    >
                      {t("Delete")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>You do not have saved cards</p>
          )}
          <button className="btn btnPrimary" onClick={() => addCreditCard()}>
            {t("Add New Card")}
          </button>
          <button
            style={{ marginTop: "10px", width: "100%" }}
            className="btn btn-outline-success"
            onClick={() => setModalIsOpen(false)}
          >
            {t("Cancel")}
          </button>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setModalIsOpen(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "15px", top: "15px" }
                : { right: "15px", left: "auto", top: "15px" }
            }
          />
        </div>
      </Modal>
    </div>
  ) : (
    <div className="mainPage billing-plan">
      <h5 id="BillingPlan-Title2" style={{ color: "#74767A" }}>
        {t("Enjoy the ease of your business spending")}
      </h5>
      <h1 id="BillingPlan-SubTitle2">
        {t(
          " Select the most appropriate solution that best empowers your team "
        )}
      </h1>
      <div className="plan-cards-container">
        {plans.map((plan, i) => {
          return (
            <BillingPlanCard
              plan={plan}
              key={plan.id}
              id={`BillingPlan-Title-${i + 10}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BillingPlan;
