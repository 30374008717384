export const TRANSLATIONS_EN = {
  why: "Why hollyDesk?",
  demo: "Request demo",
  welcome: "Welcome to Holly ! Your Employees' Payment Friend ",
  welcomeP:
    "Give your employees the privilege of submitting work-related \n invoiced and getting paid in no time, while keeping your financial records clean!",
  logIn: "Log In To My Account",
  signUp: "Register Your Company",
  signIn: "Login To Your Account 🚀 ",
  trusted: "Trusted By",
  email: "Your Email Address",
  password: "Your Password",
  forget1: "I can’t remember password, ",
  forget2: "Forgot Password",
  contact1: "Have a problem logging in, ",
  contact2: "Contact Us",
  dontHaveAccount: "I don’t have an account, ",
  verification1: "Forgot My Password",
  verification2:
    "We have sent you a verification email to your email address to verify it’s you. Please enter the code or reset your password through the url at the email.",
  loginText1: "Company's expenses management, ",
  loginText2: "anywhere in real time",
  dontReceive: "Didn’t receive email? ",
  verify: "Verify It’s Me",
  session: "Session expires in ",
  teamReq: "Team Requests",
  ExpenseClaims: "Expense claims",
  dashboard: "Dashboard",
  requests: "My Requests",
  companyWallet: "Company Wallet",
  payroll: "Payroll",
  logOut: "Log Out",
  team: "Team",
  team__: "Team",
  team___: "Team",
  home: "Home",
  invite: "Invite Team Member",
  all: "All",
  pending: "Pending",
  approved: "Approved",
  declined: "Declined",
  myRequest: "Reimbursement Requests",
  myReqInAdv: "In Advance Requests",
  rr: "Reimbursement Requests",
  myWallet: "My Wallet",
  EGP: "EGP",
  EGPBalance: "EGP Balance",
  hello: "Hello",
  filter: "Filter",
  to: "to",
  companyExpenses: "Company Expenses",
  type: "type",
  amount: "Amount",
  ID: "ID",
  name: "Name",
  level: "Level",
  joined: "Joined At",
  status: "Status",
  activation: "Activation",
  action: "Actions",
  title: "Title",
  setup: "Setup Your Company Information",
  getStart: "Getting Started!",
  companyDetails: "Company Details",
  adminRoles: "Admin Roles",
  anyTroubles: "Any troubles?",
  contactUs: "Contact Us",
  getStartP:
    "Hi there 👋, before setting up your company to start using Hollydesk, we’d like to know more about you!",
  fName: "First Name",
  fNamePH: "ex. John",
  lName: "Last Name",
  lNamePH: "ex. Don",
  pass: "Password",
  passPH: "Enter Your Password",
  team_: "How Large Is Your Team?",
  teamPH: "500",
  bmail: "Business Email",
  bmailPH: "example@gmail.com",
  cPassword: "Confirm Password",
  cPasswordPH: "Repeat Password",
  role: "Your role at the company",
  rolePH: "ex. CEO",
  budget: "How much do you think you’ll spend monthly at HollyDesk?",
  startUrCompany: "Start Company Setup",
  mailV: "Check your inbox, we've sent you an email",
  mailV2:
    "We have sent you an email verification link, give it a click to make sure it's you !",
  resend: "Resend Email",
  emailVerified: "Email Verified! Please add company details",
  companyDP:
    "Write your company information and fill in the reguired data fields below.",
  howToGet: "How To Get <u>Reimbursed</u>?",
  howToGetInAd: "How To get <u>In Advance</u>?",
  step1: "<b> 1. Take </b> a clear photo of the receipt and upload it.",
  step2: "<b>2. Fill-in</b> reimbursement information.",
  step3: " <b> 3. Submit </b> the reimbursement request.",
  step4: "<b> 4. Get Paid </b> when your managers verifies the request!",
  step1InAd: "<b> 1. Submit </b> the In Advance request.",
  step2InAd: "<b> 2. Get Paid </b> when your managers verifies the request!",
  step3InAd: "<b> 3. Take </b> a clear photo of the receipt and upload it.",
  step4InAd: "<b> 4. Reconcile </b> your receipts",
  donthaver: "Don't have a receipt?",
  haver: "Have a receipt?",
  downr: "Download receipt template",
  newReq: "Add new Request",

  fillRI: "2. Fill-in Reimbursement Information",
  fillRI2: "1. Upload Receipt Image/File",
  fillRI3: "2. Upload Receipt Image/File",
  fillRI4: "2. Fill-in Invoice Information",
  imgUploaded: "Files uploaded successfully!",
  imgUploaded2: "You can upload another file as well",
  imgUploaded3: "Upload your receipt image/file here, or browse",
  imgUploaded4: "Supports jpg, png, svg, pdf. File size up to 10 MB",

  totalAmountDue: "Total Amount Due",
  currency: "Currency",
  companyPaidTo: "Paid To",
  companyPaidToPH: "i.e Samir & Ali Stationary",
  paidOn: "Paid On",
  paidOnPH: "DD / MM / YYYY",
  paidFor: "Paid For",
  paidForPH: "i.e Buying company paper supplies",
  submit: "Submit",
  //------
  inviteUsers: "Invite Users",
  jobTitle: "Job Title",
  jobTitlePH: " Job Title",

  emp: "Employee",
  manager: "Manager",
  role_: "Role",
  inviteBTN: "Invite",
  addTeam: " Add Team",
  Pleasechooseateam: "Please choose a team",

  companyLegal: "Company Legal Name",
  companyLegalP:
    "Make sure you write your company legal name as the one written in the official registration document.",
  Country: "Country",
  CountryPH: "Egypt ",
  companyLegalPH: "  Etisalat",
  officeAddressPH: "6 Mohamed Sobhy st - Giza ",
  city: "City",
  citPH: "Cairo",
  pcode: " Postal Code",
  website: " Website",
  officeAddress: " Address",
  phone: "  Phone",
  countToAdmin: "Next  ",
  //----------
  cashout0: "Cashing Out - Never been so easy!",
  cashout1: "Safe and secure cashing out proccess.",
  cashout2:
    "Choose your favourit method to recieve your cash out from one of the electronic wallets or by bank transfer",
  cashout3: "Keep track of your records.",
  //-------------
  cashout: "Cash Out",
  cashoutP: "Cash Out Portion Of Available Balance",
  cashoutAll: "Cash Out All Available Balance",
  ProccedToCashOut: "Procced To Cash Out",

  ///
  selectcashout: "Select cash out Method",
  cashoutinfo: "cash out info",
  fullName: "Full Name",
  accNum: "Account Number",
  bankName: "Bank Name",
  bankAddress: "Bank Address",
  ///
  donthavereq: "You don't have any requests",
  time: "Date",
  suc1: " Your Request Has Been Submitted Successfully!",
  suc2:
    "Your team managers are reviewing it now, once approved, you will get reimbursed right away!",
  sucInAd:
    "If your in advance request is approved, your request will be open until you reconcile it by uploading your receipts once received.",
  reqno: "Request no.",
  backtohome_: "Back to home",
  sucRec1: "Your Reconciliation Request Has Been Submitted Successfully!",
  sucRec2:
    "The accountant will review your receipts and close your request or reject reconciliation",

  ///

  ///

  ver: "Verified",
  pend: "Pending",
  Deactivate: "Deactivate",
  Activate: "Activate",
  latestUpdate: "Latest Update",
  recStatus: "Reconcilation Status",
  //

  credit: "Credit Card",
  payinadv: "Pay In Advance",
  bankt: "Bank Transfer",
  fees: "This payment Method will charge you with 3% extra fees",
  cardNumber: "Card Number",
  exp: "Expiration",
  con: "Confirm",

  BND: "Bank account details",
  fees2:
    "Company Will charge you wallet with in 3-5 days after transaction success",
  recipt: "Receipt",
  uploadRecipt: "Upload Receipt",
  sendRecipt: " Send Receipt",
  feeCalc: "Fees Calculator",
  fee3: "Price after proccess fees (0.5% from amount)",
  fee4: " Price after VAT (14% from process fees)",
  reset: "Reset",
  bank: "Bank",
  Decline: "Decline",
  Approve: "Approve",
  sAt: "Submitted At",
  max: "Max Avalible=",
  oldPass: "Old Password",
  newPass: "New Password",
  rNewPass: "Repeat New Password",
  changePassword: "Change Password",
  "request approved and money sent to user":
    "request approved and money sent to user",
  "request declind from accountant": "request declind from accountant",
  "approved for board member": "approved for board member",
  "pending manager approval": "pending manager approval",
  "awaiting accountant approve": "awaiting accountant approve",
  "Declined from team manager": "Declined from team manager",
  inAdvance: "In Advance Requests",
  newRequest: "How to make new request?",
  Indebtedness: "Indebtedness",
  debit: "Debit",
  credit_: "Credit",
  compInd: "Company Indebtedness Record",
  noReq1: "In Advance Requests Receipt Upload Policy",
  noReq2:
    "In Advance Requests requires the employee to upload the receipts once the request has been approved and the receipt has been received to reconcile the request with the company. In Advance Policy",
  noList: "You don't have any past requests",
  send: "1. Send In Advance Request",
  payToInAd: "Will be Paid To",
  payToInAdPH: "i.e. Go Bus",
  payDate: "Pay Date",
  payForInAd: "Will be Paid For",
  payForInAdPH: "i.e. Travling to Alexandria",
  submitReq: "Submit Request",
  warn3: "I Have a Receipt or Invoice",
  warn4:
    "If you already have a receipt or invoice that you want to attach to this request, you’re in the wrong request type and you should",
  warn5: "Switch to Reimbursement Request",
  request: "Request",
  closeReq: "Close Request Review",
  createdAt: "Created At",
  recRej: "Reconciliation Rejection",
  recRej1: "Select the reason for the reconciliation rejection of request",
  recRej2: "Photo of uploaded receipt is not clear",
  recRej3: "Uploaded receipt is not related to the request details",
  recRej4:
    "There’s a variance between the requested amount and amount paid (Reconcile)",
  recRej5: "Amount Stated in Receipt",
  recRej6: "In Advance Request Variance Reconciliation",
  recRej7:
    "There’s variance between requested and approved amount and actual amount paid reported by manager. The amount will be reconciled based on your choice of reconciliation, it can be either In-Cash Reconciliation or Automatic Reconciliation",
  recRej8: "In-Cash Reconciliation",
  recRej9: "Automatic Reconciliation",
  recRej10: "Submit Reconciliation Response",
  "Request approved": "Request approved",
  "Reconciliation approved": "Reconciliation approved",
  "Reconciliation declind": "Reconciliation declind",
  "Approved for board member": "Approved for board member",
  "Pending manager approval": "Pending manager approval",
  "Approved for accountant": "Approved for accountant",
  "Pending accountant approval": "Pending accountant approval",
  "Request declind from accountant": "Request declind from accountant",
  "This request doesn't have images": "This request doesn't have images",
  view: "View",
  "Close Request Review": "Close Request Review",
  "Reconcile Request": "Reconcile Request",
  "RECONCILIATION REVIEW": "RECONCILIATION REVIEW",
  RECONCILED: "RECONCILED",
  "N/A": "N/A",
  "Supplier Requests": "Supplier Requests",
  "Pending Requests": "Pending Requests",
  "Approved Requests": "Approved Requests",
  "Declined Requests": "Declined Requests",
  "Settled Requests": "Settled Requests",
  Settled: "Settled",
  paidOnS_: "Issued On",
  Supplier: "Supplier",
  "Add New Supplier Company": "Add New Supplier Company",
  newSupWarning:
    "Adding a new supplier doesn't mean it will be added permently to the company's list of suppliers, a finanical advisor should review this application and choose to added to the system",
  supLegDoc: "Supplier Legal Document",
  CompanyRegestration: "Supplier Legal Document",
  "Supplier Request": "Supplier Request",
  UnregSuppWar:
    "This supplier is not registerd and verified on company list of supplier, the employee has provided all legal documents and support files to register this supplier.",
  regSuppWar:
    "This supplier is registerd and verified on company list of supplier, ",
  Down: "Download",
  AutoRec1: "This user has L.E",
  AutoRec2: " to reconcile his inadvance request NO.",
  AutoRec3: ",do you want to withdraw this amount to reconcile now?",
  AutoRec4: "This user has to pay L.E",
  policy1:
    "You will be charged extra 1.5% on your transaction. Your amount will be automatically debited to your wallet after proceeding.",
  policy2:
    "You will be charged extra 0.5% on your transaction. Your amount will be debited to your wallet after submitting the bank deposit receipt and within 2-3 days of transaction success.",
  policyOffer:
    "Your amount will be debited to your wallet after submitting the bank deposit receipt and within 2-3 days of transaction success.",
  policy3:
    "Your amount will be debited to your wallet after submitting the bank deposit receipt and within 2-3 business days days of transaction success.",
  level_: "Level",
  invite1: "You Have Been Invited!",
  invite2: "Hi there",
  invite3: "has invited you to join their company account on Hollydesk",
  invite4:
    "Accept the invitation now and start managing your work related expenses",
  invite000: "You Have Been Invited!"
};
