import { ADD_PAYROLL_REQYEST } from '../actions/types';

const INITIAL_STATE = {
  success: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PAYROLL_REQYEST:
      return {
        success: true,
      };
    case 'ADD_PAYROLL_REQYEST_FAIL':
      return state;

    default:
      return state;
  }
};
