import React from 'react';
import { connect } from 'react-redux';

import './secNav.css';
import { Link, withRouter } from 'react-router-dom';

class SecNav extends React.Component {
  renderNavs() {
    let path = this.props.location.pathname;
    let { navs } = this.props;
    return navs?.map((nav) => {
      if (nav.name == 'My Requests') {
        return (
          <li
            key={nav.name}
            className={`nav-item align-items-center ${
              path === nav.link ? 'active' : ''
            }`}
            style={path === nav.link ? { color: '#39ac6e' } : {}}
          >
            <Link className="nav-link align-items-center" to={`${nav.link}`}>
              <span> {nav.name}</span>{' '}
              <span
                className={` round ${
                  this.props.myRequestCount == 0 ? '' : 'green'
                } .round-sm `}
              >
                <span>{this.props.myRequestCount}</span>
              </span>
            </Link>
          </li>
        );
      }
      return (
        <li
          key={nav.name}
          className={`nav-item ${path === nav.link ? 'active' : ''}`}
          style={path === nav.link ? { color: '#39ac6e' } : {} }
        >
          <Link className="nav-link" to={`${nav.link}`}>
            {nav.name}{' '}
            {nav.name === 'My Wallet' || nav.name === 'محفظتى'
              ? '( ' + this.props.wallet + ' EGP )'
              : (
               nav.link=='/dashboard/expense/reimbursement'?this.props.requestCount:(
               nav.link=='/dashboard/expense/inAdvance'?this.props.requestInadvanceCount:
               nav.link=='/dashboard/requests/my-requests'?this.props.myRequestCount:(
               nav.link=='/dashboard/requests/inAdvance'?this.props.myInAdvanceRequestCount:''
                   )
                   )
              )}

              
          </Link>
        </li>
      );
    });
  }



  render() {
    return (
      <nav className="navbar secNavbarDash navbar-expand-lg navbar-light align-items-center">
        <ul className="navbar-nav align-items-center second-nav-bar">{this.renderNavs()}</ul>
        <ul className="navbar-nav  align-items-center">
        
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    wallet: state.wallet.wallet,
     requestCount: state.auth.count,
    requestInadvanceCount: state.auth.inadvanceCount,
    myRequestCount: state.auth.myCount,
      myInAdvanceRequestCount: state.auth.myInAdvanceRequestCount,

  };
};

export default connect(mapStateToProps, {})(withRouter(SecNav));
