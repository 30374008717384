import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import PendingExpense from './pendingExpense/pendingExpense';
import ApprovedExpense from './approvedExpense/approvedExpense';
import DeclinedExpense from './declinedExpense/declindedExpense';

import SecNav from '../../../../components/sec-navbar/secNav';
import history from '../../../../../../history';
import { useTranslation } from 'react-i18next';

const ExpensePage = (props) => {
  const { t } = useTranslation();

  let routes = (
    <Router history={history}>
      {props.auth.user.role === 'manager' ? (
        <SecNav
          navs={[
            {
              name: t('approved'),
              link: '/dashboard/suppliers/expenses/approved',
            },
            {
              name: t('declined'),
              link: '/dashboard/suppliers/expenses/declined',
            },
          ]}
        />
      ) : (
        <SecNav
          navs={[
            {
              name: t('pending'),
              link: '/dashboard/suppliers/expenses/pending',
            },
            {
              name: t('approved'),
              link: '/dashboard/suppliers/expenses/approved',
            },
            {
              name: t('declined'),
              link: '/dashboard/suppliers/expenses/declined',
            },
          ]}
        />
      )}
      <div className="col-md-12">
        <Switch>
          {' '}
          <Route
            path="/dashboard/suppliers/expenses/pending"
            exact
            component={PendingExpense}
          />
          <Route
            path="/dashboard/suppliers/expenses/approved"
            exact
            component={ApprovedExpense}
          />
          <Route
            path="/dashboard/suppliers/expenses/declined"
            exact
            component={DeclinedExpense}
          />
          <Redirect to="/dashboard/suppliers/expense/approved" />
        </Switch>
      </div>
    </Router>
  );
  return <div>{routes}</div>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    requestCount: state.auth.count,
    myRequestCount: state.auth.myCount,
  };
};

export default connect(mapStateToProps, {})(ExpensePage);
