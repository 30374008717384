import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import './';

const SuccessReconcile = (props) => {
  const { t } = useTranslation();

  return (
    <div className="mainPage success">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <i className="fas fa-3x fa-check"></i>
            <h2>
              <b>{t('sucRec1')}</b>
            </h2>
            <p>{t('sucRec2')}</p>
            <span>
              <b>
                {t('reqno')}
                <span className="green">
                  #{props.location.search.split('?num=')[1]}
                </span>
              </b>
            </span>
            <Link
              to={'/dashboard/requests/inAdvance'}
              className="btn btnPrimary btnSmall"
            >
              {t('backtohome_')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(withRouter(SuccessReconcile));