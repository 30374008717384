import api from '../apis/api';
import { returnErrors } from './errorActions';
import { tokenConfig } from './tokenActions';

export const getNotifications = (page) => async (dispatch, getState) => {
  api
    .get(`/notifications?page=${page}`, tokenConfig(getState))
    .then((res) => {
      if (res.data.status === 1) {
        dispatch({ type: 'GET_NOTIFICATIONS', payload: res.data.data });
      } else {
        dispatch(returnErrors(res.data.message, res.data.status, 'Can\'t get notifications'));
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err, 0));
    });
};
