import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import { setLoading, removeLoading } from "./loadingActions";
import { tokenConfig } from "./tokenActions";
import cogoToast from "cogo-toast";

export const getPlans = () => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .get("/plans", tokenConfig(getState))
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: "GET_PLANS", payload: res.data });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "LIST_PENDING_REQUESTS_FAIL"
          )
        );
      }
    })
    .catch((err) => {
      dispatch(returnErrors(err, 0));
    });
};
