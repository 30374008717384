import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";
import history from "../../../../../../history";
import { Switch } from "antd";

import "./success.css";
import SettlementRequest from "./SettlementRequest";

const Team = (props) => {
  const { t } = useTranslation();
  const [hard, setHard] = useState(false);
  const [mony, setMony] = useState(false);
  const [oldMonyState, setOldMonyState] = useState(null);

  const [request, setRequest] = useState({});

  const [forword, setForword] = useState(false);

  const [Num, setNum] = useState(window.location.search.split("?num=")[1]);

  const [method, setMethod] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    api({
      url: `/supplier/single-request/${Num}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.data.request);
        if (res.data.status == 1) {
          console.log(res.data);
          setHard(res.data.data.request.hard);
          setMony(res.data.data.request.mony);
          setOldMonyState(res.data.data.request.mony);

          setAmount(res.data.data.request.amount);
          setRequest(res.data.data.request);
          if (Num == "") setNum(res.data.num);
          console.log(res.data.data.forward);
          res.data.data.request.forword.forEach((element) => {
            element.approved == null ? setForword(false) : setForword(true);
          });
        }
      })
      .catch((err) => {});
  }, [Num]);

  const submitForm = (bankDetails) => {
    api({
      url: "/supplier/setaled",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        mony,
        hard,
        num: props.location.search.split("?num=")[1],
        bankDetails,
      },
    })
      .then((res) => {
        if (res.data.res == "suc") {
          if (hard && mony) cogoToast.success("Sent Successfuly To Settled");
          else cogoToast.success("Edited Successfuly ");
          history.push(`/dashboard/suppliers/my-requests`);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="mainPage success">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <i className="fas fa-3x fa-check"></i>
              <h2>
                <b>{t("suc1")}</b>
              </h2>

              <span>
                <b>
                  {t("reqno")}
                  <span className="green">#{Num}</span>
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
      {props.auth.user.role === "accountant" && forword ? (
        <div className="mainPage success" style={{ width: "60%" }}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-12  ">
                {/* // <form className="setteled-checks">
                //   <div
                //     className="form-check"
                //     style={
                //       localStorage.getItem('i18nextLng') == 'ar'
                //         ? { width: '100%', textAlign: 'right' }
                //         : { width: '100%', textAlign: 'left' }
                //     }
                //   >
                //     <input
                //       class="form-check-input"
                //       type="checkbox"
                //       value=""
                //       id="flexCheckDefault"
                //       checked={hard}
                //       onChange={() => setHard(!hard)}
                //     />
                //     <label className="form-check-label" for="flexCheckDefault">
                //       {t('Hard Copy Document Received')}
                //     </label>
                //   </div>
                //   <div
                //     class="form-check"
                //     style={
                //       localStorage.getItem('i18nextLng') == 'ar'
                //         ? { width: '100%', textAlign: 'right' }
                //         : { width: '100%', textAlign: 'left' }
                //     }
                //   >
                //     <input
                //       class="form-check-input"
                //       type="checkbox"
                //       checked={mony}
                //       onChange={() => setMony(!mony)}
                //       id="flexCheckDefault"
                //     />
                //     <label class="form-check-label" for="flexCheckDefault">
                //       {t('Amount Transferred To Supplier Account')}
                //     </label>
                //   </div>
                // </form> */}
                <div className="mx-4">
                  <div className="row mb-3 align-content-center">
                    <Switch
                      defaultChecked={hard}
                      onChange={(e) => setHard(e)}
                    />
                    <p className="mx-1 ">
                      I received a hard copy document for invoice
                    </p>
                  </div>
                  <div className="row ">
                    <Switch
                      defaultChecked={mony}
                      disabled={oldMonyState}
                      onChange={(e) => {
                        console.log(e);
                        setMony(e);
                      }}
                    />
                    <p className="mx-1">
                      I want to transfer money to the vendor now{" "}
                      {oldMonyState == true ? " (already paid)" : ""}
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {mony && oldMonyState != true ? (
        <div>
          <div className="mainPage success " style={{ width: "60%" }}>
            <div className="container-fluid ">
              <div className="row justify-content-center">
                <div className="col-md-12 ">
                  <SettlementRequest
                    price={request.amount}
                    supplier={request.from}
                    amount={amount}
                    setAmount={setAmount}
                    method={method}
                    setMethod={setMethod}
                    submitForm={submitForm}
                    mony={mony}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {!mony || method != 3 ? (
        <div className="justify-content-center row">
          <Link
            to={`/dashboard/suppliers/my-requests`}
            onClick={() => {
              forword ? submitForm() : console.log("");
            }}
            className="btn btnPrimary btnSmall col-6 justify-content-center"
          >
            {t("Save")}
          </Link>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {})(withRouter(Team));
