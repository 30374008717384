import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import UploadImages from "../../../../components/requests/UploadImage/uploadImages";
import {
  addRequest,
  toggleBtnSpin
} from "../../../../../../actions/requestsActions";
import { useHistory, withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import api from "../../../../../../apis/api";

import "./request.css";

const ManualRequest = React.memo(props => {
  const currency = useSelector(state => state.auth.user.currency[0]);
  const categories = useSelector(state => state.categories.categories) || [];
  const cases = useSelector(state => state.categories.cases) || [];
  const spin = useSelector(state => state.request.spin) || false;
  const [users, setUsers] = useState([]);

  const { t } = useTranslation();
  const history = useHistory();
  const [msg, setMsg] = useState(undefined);

  const dispatch = useDispatch();

  const onSubmit = values => {
    let { files, name } = props.upload;
    if (name === "requests" && files.length > 0) {
      dispatch(toggleBtnSpin(true));
      let images = [];
      files.forEach(file => {
        images.push(file._id);
      });
      values["images"] = images;
      values.received ? (values.received = true) : (values.received = false);
      values.categoryId === "" && (values.categoryId = null);
      values.caseId === "" && (values.caseId = null);
      api({
        url: `/requests/add-user-request/`,
        method: "post",
        data: values,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`
        }
      })
        .then(res => {
          if (res.data.status === 1) {
            dispatch({ type: "WALLET", payload: res.data.data.totalAmount });
            history.push(
              `/dashboard/requests/reimbursment/success?num=${res.data.data.number}`
            );
            cogoToast.success(res.data.message);
            dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
          } else {
            cogoToast.error(res.data.message);
            dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
          }
        })
        .catch(err => {
          dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
        });
    } else {
      cogoToast.error(t("please upload image"));
    }
  };

  const getAllEmployees = () => {
    api({
      url: `/user`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        console.log(res);
        if (res.data.status === 1) {
          setUsers(res.data.data);
        } else cogoToast.error("Couldn't get employees data");
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (props.errors.id === "ADD_BANK_FAIL") {
      setMsg(props.errors.msg);
    }
    getAllEmployees();
  }, []);

  return (
    <div className="mainPage request request-inadvance">
      <div className="reconcile-upload">
        <UploadImages name="requests" />
      </div>
      <div className="spliter"></div>
      <div className="inadvance-form">
        <form onSubmit={props.handleSubmit(onSubmit)} className="md-form">
          <h2
            className="mb-4"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { width: "100%", textAlign: "right" }
                : { width: "100%", textAlign: "left" }
            }
          >
            {t("fillRI")}
          </h2>

          {localStorage.getItem("i18nextLng") == "ar" ? (
            <div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    label={t("totalAmountDue")}
                    name="amount"
                    placeholder="00"
                    type="number"
                    min="0"
                    component={renderInputAR}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    label={t("currency")}
                    name="currency"
                    component={renderSelectFieldAR}
                  >
                    <option value="select" selected>
                      اختر العملة
                    </option>
                    <option value={currency} selected>
                      {t(currency)}
                    </option>
                  </Field>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    label={t("companyPaidTo")}
                    name="paidTo"
                    placeholder={t("companyPaidToPH")}
                    type="text"
                    component={renderInputAR}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    label={t("paidOn")}
                    name="date"
                    placeholder={t("paidOnPH")}
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    component={renderInputAR}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Field
                    label={t("paidFor")}
                    name="paidFor"
                    placeholder={t("paidForPH")}
                    type="text"
                    component={renderInputAR}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    label={t("Category")}
                    name="categoryId"
                    component={renderSelectField}
                  >
                    <option value="">{t("Select Category")}</option>
                    {categories.map(cat => {
                      return <option value={cat._id}>{cat.name}</option>;
                    })}
                  </Field>
                </div>
                <div className="col-md-6">
                  <Field
                    label={t("Case")}
                    name="caseId"
                    component={renderSelectField}
                  >
                    <option value="">Select Case</option>
                    {cases.map(cat => {
                      return <option value={cat._id}>{cat.name}</option>;
                    })}
                  </Field>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Field
                    label={t("Assigned To")}
                    name="userID"
                    component={renderSelectField}
                  >
                    <option value="select" selected>
                      Select Employee
                    </option>
                    {users?.map((user, index) => {
                      return (
                        <option value={user._id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>
              <div hidden>
                <Field
                  label="لقد استلمت المبلغ نقدا لحالة طارئة"
                  type="checkbox"
                  name="received"
                  component={renderCheckField}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    label={t("totalAmountDue")}
                    name="amount"
                    placeholder="00"
                    type="number"
                    min="0"
                    component={renderInput}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    label={t("currency")}
                    name="currency"
                    component={renderSelectField}
                  >
                    <option value="select" selected>
                      Select Currency
                    </option>
                    <option value={currency} selected>
                      {t(currency)}
                    </option>
                  </Field>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    label={t("companyPaidTo")}
                    name="paidTo"
                    placeholder={t("companyPaidToPH")}
                    type="text"
                    component={renderInput}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    label={t("paidOn")}
                    name="date"
                    placeholder={t("paidOnPH")}
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    component={renderInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Field
                    label={t("paidFor")}
                    name="paidFor"
                    placeholder={t("paidForPH")}
                    type="text"
                    component={renderInput}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Field
                    label={t("Category")}
                    name="categoryId"
                    component={renderSelectField}
                  >
                    <option value="">{t("Select Category")}</option>
                    {categories.map(cat => {
                      return <option value={cat._id}>{cat.name}</option>;
                    })}
                  </Field>
                </div>
                <div className="col-md-6">
                  <Field
                    label={t("Case")}
                    name="caseId"
                    component={renderSelectField}
                  >
                    <option value="">Select Case</option>
                    {cases.map(cat => {
                      return <option value={cat._id}>{cat.name}</option>;
                    })}
                  </Field>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Field
                    label={t("Assigned To")}
                    name="userID"
                    component={renderSelectField}
                  >
                    <option value="select" selected>
                      Select Employee
                    </option>
                    {users?.map((user, index) => {
                      return (
                        <option value={user._id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>
              {/* <div>
                <Field
                  label={t(
                    "Don't deduct any money, this request just for documenting"
                  )}
                  type="checkbox"
                  name="received"
                  component={renderCheckField}
                />
              </div> */}
              <div hidden>
                <Field
                  label={t(
                    "I already recieved the money in cash for an urgent case"
                  )}
                  type="checkbox"
                  name="received"
                  component={renderCheckField}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              {spin ? (
                <button type="button" disabled className="btn btnPrimary">
                  <LoadingOutlined />
                </button>
              ) : (
                <button className="btn btnPrimary">{t("submit")}</button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});

const renderInput = function({ input, label, meta, type, placeholder, max }) {
  return (
    <div className="inputComp">
      <label className="enLabel">{label}</label>
      <input
        max={type == "date" ? max : max}
        className="form-control"
        placeholder={placeholder}
        {...input}
        type={type}
        min="0"
        step="0.01"
        autoComplete="off"
      />
      {renderError(meta)}
    </div>
  );
};

const renderInputAR = function({
  input,
  label,
  meta,
  type,
  placeholder,
  className,
  id,
  max
}) {
  return (
    <div className="inputComp">
      <label className="arLabel">{label}</label>
      <input
        className={className || "form-control"}
        max={type == "date" ? max : max}
        placeholder={placeholder}
        id={id || ""}
        {...input}
        type={type}
        step="0.01"
        min="0"
      />
      {renderError(meta)}
    </div>
  );
};

const renderSelectField = function({ meta, label, input, children }) {
  const className = `inputComp ${
    meta.touched && meta.error ? "has-error" : ""
  }`;
  return (
    <div className={className}>
      <label className="enLabel">{label}</label>
      <select className="form-control" {...input}>
        {children}
      </select>
      {renderError(meta)}
    </div>
  );
};

const renderSelectFieldAR = function({ meta, label, input, children }) {
  const className = `inputComp ${
    meta.touched && meta.error ? "has-error" : ""
  }`;
  return (
    <div className={className}>
      <label className="arLabel">{label}</label>
      <select className="form-control" {...input}>
        {children}
      </select>
      {renderError(meta)}
    </div>
  );
};

const renderCheckField = function({ meta, label, input, children }) {
  const className = `inputComp ${
    meta.touched && meta.error ? "has-error" : ""
  }`;
  return (
    <label
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >
      <input type="checkbox" {...input} />
      <span>
        <div
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          &nbsp;&nbsp;&nbsp;&nbsp;{label}
          {/* <br />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <b style={{ fontSize: "12px", color: "rgb(18, 146, 238)" }}>
            لا تقم بتحديد المربع الا إذا كنت استلمت المبلغ مسبقا من الشركة
          </b> */}
        </div>
      </span>
    </label>
  );
};

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="text-danger">{error}</div>;
  }
};

const validateForm = formValues => {
  const errors = {};

  if (!formValues.amount) {
    errors.amount = "Please add amount";
  }

  if (!formValues.currency) {
    errors.currency = "Please add currency";
  }

  if (!formValues.paidTo) {
    errors.paidTo = "Please add paidTo";
  }

  if (!formValues.date) {
    errors.date = "Please add date";
  }

  if (!formValues.paidFor) {
    errors.paidFor = "Please add paidFor";
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    upload: state.upload,
    errors: state.error,
    lang: state.lang.lang
  };
};

const reduxMiddleware = reduxForm({
  form: "addRequest",
  validate: validateForm,
  enableReinitialize: true
})(ManualRequest);

export default connect(mapStateToProps, { addRequest })(
  withRouter(reduxMiddleware)
);
