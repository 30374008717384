import axios from "axios";

const JWTToken = localStorage.getItem("token");

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "x-auth-token": `Bearer ${JWTToken}`,
    "Content-Type": "application/json"
  }
});
