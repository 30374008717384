import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import UploadImagesWithEdit from "../../../../components/requests/UploadImage/uploadImagesWithEdit";
import { editRequest } from "../../../../../../actions/requestsActions";
import { withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  getCases,
  getCategories
} from "../../../../../../actions/categoryActions";

const EditRequest = React.memo(props => {
  const { t } = useTranslation();
  const [msg, setMsg] = useState(undefined);

  const { register, errors, handleSubmit } = useForm();

  const inAdvanceRequests = useSelector(state => state.request.requests);
  const categories = useSelector(state => state.categories.categories) || [];
  const cases = useSelector(state => state.categories.cases) || [];

  const dispatch = useDispatch();

  const editedRequest = inAdvanceRequests.find(
    req => req.id === props.match.params.id
  );

  const onSubmit = values => {
    let { files, name } = props.upload;
    if (name === "requests" && files.length > 0) {
      let images = [];
      files.forEach(file => {
        images.push(file._id);
      });
      values["images"] = images;
      values.id = props.match.params.id;
      values.received ? (values.received = true) : (values.received = false);
      values.categoryId === "" && (values.categoryId = null);
      values.caseId === "" && (values.caseId = null);
      props.editRequest(values, files);
    } else {
      cogoToast.error(t("please upload image"));
    }
  };

  useEffect(() => {
    if (props.errors.id === "ADD_BANK_FAIL") {
      setMsg(props.errors.msg);
    }
    dispatch(getCategories());
    dispatch(getCases());
  }, []);

  return (
    <div className="mainPage request request-inadvance">
      <div className="reconcile-upload">
        <UploadImagesWithEdit
          name="requests"
          draftImages={editedRequest.images}
        />
      </div>
      <div className="spliter"></div>
      <div
        className="inadvance-form"
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { textAlign: "right" }
            : { textAlign: "left" }
        }
      >
        <h2>{t("fillRI")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="row-element">
              <label>{t("totalAmountDue")}</label>
              <input
                type="number"
                placeholder="00"
                step="0.01"
                defaultValue={editedRequest.amount}
                autoComplete="off"
                className="form-control"
                name="amount"
                ref={register({ required: true })}
              />
              {errors.amount && <p>Amount is required</p>}
            </div>
            <div className="row-element">
              <label>{t("currency")}</label>
              <select
                className="form-control"
                name="currency"
                ref={register({ required: true })}
              >
                <option selected>EGP</option>
              </select>
              {errors.currency && <p>Currency is required</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t("companyPaidTo")}</label>
              <input
                className="form-control"
                autoComplete="off"
                name="paidTo"
                defaultValue={editedRequest.paidTo}
                placeholder={t("companyPaidToPH")}
                ref={register({ required: true })}
              />
              {errors.paidTo && <p>{t("Paid to is required")}</p>}
            </div>
            <div className="row-element">
              <label>{t("paidOn")}</label>
              <input
                className="form-control"
                type="date"
                name="date"
                defaultValue={moment(editedRequest.paidOn).format("YYYY-MM-DD")}
                max={new Date().toISOString().split("T")[0]}
                ref={register({ required: true })}
              />
              {errors.date && <p>{t("Pay date is required")}</p>}
            </div>
          </div>
          <div className="form-row wide">
            <label>{t("paidFor")}</label>
            <input
              className="form-control"
              autoComplete="off"
              placeholder={t("paidForPH")}
              name="paidFor"
              defaultValue={editedRequest.paidFor}
              ref={register({ required: true })}
            />
            {errors.paidFor && <p>{t("Paid for is required")}</p>}
          </div>

          <div className="form-row">
            <div className="row-element">
              <label>{t("Category")}</label>
              <select
                className="form-control"
                name="categoryId"
                ref={register({ required: false })}
                // defaultValue={editRequest.categoryId}
              >
                <option value="">{t("Select Category")}</option>
                {categories.map(cat => {
                  return (
                    <option
                      value={cat._id}
                      selected={cat.id === editedRequest.categoryId}
                    >
                      {cat.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="row-element">
              <label>{t("Case")}</label>
              <select
                className="form-control"
                name="caseId"
                ref={register({ required: false })}
              >
                <option value="">Select Case</option>
                {cases.map(cat => {
                  return <option value={cat._id}>{cat.name}</option>;
                })}
              </select>
            </div>
          </div>

          <label
            hidden
            style={{
              display: "flex",
              msFlexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            <input type="checkbox" />
            <span>
              <div
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { textAlign: "right" }
                    : { textAlign: "left" }
                }
              >
                &nbsp;&nbsp;&nbsp;&nbsp;
                {t("I already recieved the money in cash for an urgent case")}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b style={{ fontSize: "12px", color: "rgb(18, 146, 238)" }}>
                  لا تقم بتحديد المربع الا إذا كنت استلمت المبلغ مسبقا من الشركة
                </b>
              </div>
            </span>
          </label>
          <button className="btn btnPrimary">{t("submitReq")}</button>
        </form>
      </div>
    </div>
  );
});

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="text-danger">{error}</div>;
  }
};

const validateForm = formValues => {
  const errors = {};

  if (!formValues.amount) {
    errors.amount = "Please add amount";
  }

  if (!formValues.currency) {
    errors.currency = "Please add currency";
  }

  if (!formValues.paidTo) {
    errors.paidTo = "Please add paidTo";
  }

  if (!formValues.date) {
    errors.date = "Please add date";
  }

  if (!formValues.paidFor) {
    errors.paidFor = "Please add paidFor";
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    upload: state.upload,
    errors: state.error,
    lang: state.lang.lang
  };
};

const reduxMiddleware = reduxForm({
  form: "addRequest",
  validate: validateForm,
  enableReinitialize: true
})(EditRequest);

export default connect(mapStateToProps, { editRequest })(
  withRouter(reduxMiddleware)
);
