import {
  ADD_REQUEST,
  LIST_REQUESTS,
  REQUEST_COUNT,
  LIST_REQUESTS_SUPPLIER,
  LIST_REQUESTS_INADVANCE,
  REQ_TO_RECONCILE,
} from "../actions/types";

const INITIAL_STATE = {
  number: undefined,
  numberSupplier: undefined,
  numberInadvance: undefined,

  requests: [],
  count: 0,
  totalCount: 0,
  requestsSupplier: [],
  requestsInadvance: [],
  reqToReconcile: {},
  countInAdv: 0,
  totalCountInAdv: 0,
  countSupplier: 0,
  totalCountSupplier: 0,
  spin: false,

  exel: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_REQUEST:
      return {
        ...state,
        number: action.payload,
      };
    case LIST_REQUESTS:
      return {
        ...state,
        number: undefined,
        requests: action.payload.requests,
        count: action.payload.count,
        totalCount: action.payload.totalCount,
      };

    case LIST_REQUESTS_SUPPLIER:
      return {
        ...state,
        numberSupplier: undefined,
        requestsSupplier: action.payload.requests,
        countSupplier: action.payload.count,
        totalCountSupplier: action.payload.totalCount,
      };

    case LIST_REQUESTS_INADVANCE:
      return {
        ...state,
        numberInadvance: undefined,
        requestsInadvance: action.payload.requests,
        countInAdv: action.payload.count,
        totalCountInAdv: action.payload.totalCount,
      };
    case REQ_TO_RECONCILE:
      return {
        ...state,
        reqToReconcile: action.reqToReconcile,
      };
    case "TOGGLE_BTN_SPIN":
      return {
        ...state,
        spin: action.spin,
      };

    default:
      return state;
  }
};
