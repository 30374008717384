import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cogoToast from "cogo-toast";
import api from "../../../../../../apis/api";
import { BiHide } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [Password, setPassword] = useState("");
  const [rPassword, setRPassword] = useState("");
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRPass, setShowRPass] = useState(false);
  const { handleSubmit } = useForm();

  const { t } = useTranslation();

  const content = (
    <ul>
      <li>8 Letters min length</li>
      <li>Include capital Letter</li>
      <li>Include small Letter</li>
      <li>Include Number</li>
      <li>Include special character</li>
    </ul>
  );

  const changePass = () => {
    if (Password === rPassword && Password !== "" && rPassword !== "") {
      let config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      let token = localStorage.getItem("token");

      if (token) {
        config.headers["x-auth-token"] = token;
      }

      let data = {
        oldPassword: oldPassword,
        password: Password,
        rPassword: rPassword
      };

      api
        .post(`/user/changePassword`, JSON.stringify(data), config)
        .then(res => {
          if (res.data.status === "wrong password") {
            cogoToast.warn("Old password is wrong ");
          } else if (res.data.status === "correct password") {
            cogoToast.success("Password changed");
            setOldPassword("");
            setPassword("");
            setRPassword("");
          } else {
            cogoToast.warn(res.data.message);
          }
        })
        .catch(err => {});
    } else {
      cogoToast.warn(t("Repeated password does not match the new password"));
    }
  };

  return (
    <div className="account-settings-form">
      <form onSubmit={handleSubmit(changePass)}>
        <div className="form-row wide" style={{ position: "relative" }}>
          <label>{t("oldPass")}</label>
          <input
            className="form-control"
            type={showOldPass ? "text" : "password"}
            // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            onChange={e => setOldPassword(e.target.value)}
            value={oldPassword}
          />
          <BiHide
            className="passShowHide"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { left: "10px", top: "42px" }
                : { right: "10px", top: "42px" }
            }
            onClick={() => setShowOldPass(!showOldPass)}
          />
        </div>
        <div className="form-row wide" style={{ position: "relative" }}>
          <label>{t("newPass")}</label>
          <input
            className="form-control"
            type={showNewPass ? "text" : "password"}
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
            onChange={e => setPassword(e.target.value)}
            minLength="8"
            value={Password}
          />
          <BiHide
            className="passShowHide"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { left: "10px", top: "42px" }
                : { right: "10px", top: "42px" }
            }
            onClick={() => setShowNewPass(!showNewPass)}
          />
          <span
            className="pass-requirements"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { left: "40px", top: "35px" }
                : { right: "40px", top: "35px" }
            }
          >
            <Popover
              content={content}
              title="Password must be:"
              trigger="click"
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
        </div>
        <div className="form-row wide" style={{ position: "relative" }}>
          <label>{t("rNewPass")}</label>
          <input
            className="form-control"
            type={showRPass ? "text" : "password"}
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
            title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
            onChange={e => setRPassword(e.target.value)}
            minLength="8"
            value={rPassword}
          />
          <BiHide
            className="passShowHide"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { left: "10px", top: "42px" }
                : { right: "10px", top: "42px" }
            }
            onClick={() => setShowRPass(!showRPass)}
          />
          <span
            className="pass-requirements"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { left: "40px", top: "35px" }
                : { right: "40px", top: "35px" }
            }
          >
            <Popover
              content={content}
              title="Password must be:"
              trigger="click"
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
        </div>
        <button
          type="submit"
          className="btn btnPrimary"
          // onClick={(e) => changePass(e)}
        >
          {t("changePassword")}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
