import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';

import firebase from 'firebase';

import PersistedStore from './PersistedStore';

import App from './components/App';

Modal.setAppElement('#root');

if (firebase.messaging.isSupported()) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then(function(registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function(err) {
        console.log('Service worker registration failed, error:', err);
      });
  }

  navigator.serviceWorker.addEventListener('message', (message) =>
    console.log('hellooooooooooo')
  );
}

const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyCXHHWW0w-ZecBP4wO_JhyswEblc-LDUK4',
    authDomain: 'hollydesk-49a5f.firebaseapp.com',
    projectId: 'hollydesk-49a5f',
    storageBucket: 'hollydesk-49a5f.appspot.com',
    messagingSenderId: '447493188410',
    appId: '1:447493188410:web:fae10b0b7eed309b56be03',
  });
};

ReactDOM.render(
  <Provider store={PersistedStore.getDefaultStore().store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);

firebase.app.length == 0 ? initializeFirebase() : console.log('init');
