import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { MdVerifiedUser } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AuthCode from "react-auth-code-input";
import { Statistic } from "antd";
import cogoToast from "cogo-toast";
import api from "../../../../../../apis/api";
import {
  addPhoneNumber,
  removePhoneNumber,
} from "../../../../../../actions/authActions";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import "react-phone-input-2/lib/style.css";
import "antd-country-phone-input/dist/index.css";
import "flagpack/dist/flagpack.css";

const MobileNumber = () => {
  const userMobileNumber = useSelector((state) => state.auth.user.phone);
  const currency = useSelector((state) => state.auth.user.currency[0]);

  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [style, setStyle] = useState({
    color: "#268c55",
    pointerEvents: "none",
    cursor: "wait",
  });
  const [mobile, setMobile] = useState({
    short: "EG",
    phone: "",
  });
  const [deletedMobile, setDeletedMobile] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);

  const { Countdown } = Statistic;
  const deadline = Date.now() + 1000 * 60 * 10;
  const onFinish = () =>
    setStyle({
      color: "#268c55",
      pointerEvents: "auto",
      cursor: "pointer",
    });

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const customStyle = {
    content: {
      borderRadius: "20px",
      width: "50%",
      height: "fit-content",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)",
    },
  };

  const verifyMobileHandler = (num, type) => {
    let correctedMobile;
    let sentNumber;
    if (
      type === "ADD" &&
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length !== 10 &&
      mobile.phone.length !== 11
    )
      return cogoToast.error("Please, Enter valid mobile number");
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = mobile.code + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 11
    ) {
      correctedMobile = mobile.phone.substring(1);
      sentNumber = mobile.code + correctedMobile;
    }
    api({
      url: "user/verifyPhone",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { type, phone: type === "ADD" ? sentNumber : num },
    })
      .then((res) => {
        if (res.data.status === 1) {
          type === "ADD" ? setIsOpen(true) : setShowModal(true);
        } else {
          cogoToast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChangePhoneHandler = () => {
    let correctedMobile;
    let sentNumber;
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = mobile.code + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 11
    ) {
      correctedMobile = mobile.phone.substring(1);
      sentNumber = mobile.code + correctedMobile;
    }
    api({
      url: "user/changePhone",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { phone: sentNumber, code: code },
    })
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(addPhoneNumber(sentNumber));
          setIsOpen(false);
          cogoToast.success("Mobile Added successfully");
          setMobile({ short: "EG", phone: "" });
        } else {
          cogoToast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePhoneHandler = () => {
    api({
      url: "user/delete-phone-number",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { phone: deletedMobile, code: code },
    })
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(removePhoneNumber(deletedMobile));
          setShowModal(false);
          cogoToast.success("Mobile Deleted successfully");
        } else {
          cogoToast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="phone-section">
      {userMobileNumber && userMobileNumber.length > 0
        ? userMobileNumber.map((num, i) => {
            return (
              <div className="form-row wide" key={100 + i}>
                <label>{t("Your verified numbers")}</label>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <PhoneInput country={"eg"} value={num} disabled />
                  <MdVerifiedUser
                    className="phone-verified"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? {
                            right: "auto",
                            left: "15px",
                            top: "10px",
                          }
                        : {
                            right: "15px",
                            left: "auto",
                            top: "10px",
                          }
                    }
                  />
                  <TiDelete
                    className="phone-delete"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? {
                            right: "auto",
                            left: "40px",
                            top: "7px",
                          }
                        : {
                            right: "40px",
                            left: "auto",
                            top: "7px",
                          }
                    }
                    onClick={() => {
                      setDeletedMobile(num);
                      verifyMobileHandler(num, "Delete");
                    }}
                  />
                </div>
              </div>
            );
          })
        : t("You don't have verified mobile numbers")}
      <div className="account-settings-form">
        <div className="form-row wide">
          <label>{t("Add Mobile Number")}</label>
          {/* <PhoneInput
            country={currency === "EGP" ? "eg" : "sa"}
            value={mobile}
            inputProps={{
              maxlength: currency === "EGP" ? "16" : "15",
            }}
            onChange={(number) => setMobile(number)}
          /> */}
          <ConfigProvider
            locale={en}
            areaMapper={(area) => {
              return {
                ...area,
                emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
              };
            }}
          >
            <CountryPhoneInput
              defaultValue={{ short: "EG" }}
              id="mobile-input"
              type="number"
              placeholder="1234567890"
              value={mobile}
              className="form-control"
              onChange={(v) => {
                setPhoneErr(false);
                setMobile(v);
              }}
            />
          </ConfigProvider>
        </div>
        <button
          className="btn btnPrimary"
          onClick={() => {
            verifyMobileHandler(mobile, "ADD");
          }}
        >
          {t("Verify Mobile Number")}
        </button>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyle}
      >
        <h4>{t("Verify Mobile Number")}</h4>
        <h6>
          {t("We have sent you a verification code to your mobile number")}{" "}
          <b>{mobile.phone}</b> {t("to verify it’s you")}.{" "}
          {t("Please enter the code")}
        </h6>
        <AuthCode
          characters={4}
          onChange={(auth) => setCode(auth)}
          containerClassName="otp-container"
          inputClassName="input otp-cell"
        />
        <p className="counter-line">
          {" "}
          {t("session")} &nbsp;{" "}
          {<Countdown value={deadline} onFinish={onFinish} format="mm:ss" />}{" "}
          &nbsp; {t("minutes")}{" "}
        </p>
        <p>
          {t("Didn't Recieve SMS?")} &nbsp;
          <span
            style={style}
            onClick={() => {
              setStyle({
                color: "#268c55",
                pointerEvents: "none",
                cursor: "not-allowed",
              });
            }}
          >
            {t("Resend")}
          </span>
        </p>
        <button
          className="btn btnPrimary verify-button"
          onClick={ChangePhoneHandler}
        >
          {t("Verify My Mobile Number")}
        </button>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? {
                  right: "auto",
                  left: "15px",
                  top: "15px",
                }
              : {
                  right: "15px",
                  left: "auto",
                  top: "15px",
                }
          }
        />
      </Modal>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={customStyle}
      >
        <h4>{t("Verify Mobile Number")}</h4>
        <h6>
          {t("We have sent you a verification code to your mobile number")}{" "}
          <b>{deletedMobile}</b> {t("to verify it’s you")}.{" "}
          {t("Please enter the code")}
        </h6>
        <AuthCode
          characters={4}
          onChange={(auth) => setCode(auth)}
          containerClassName="otp-container"
          inputClassName="input otp-cell"
        />
        <p className="counter-line">
          {" "}
          {t("session")} &nbsp;{" "}
          {<Countdown value={deadline} onFinish={onFinish} format="mm:ss" />}{" "}
          &nbsp; {t("minutes")}{" "}
        </p>
        <p>
          {t("Didn't Recieve SMS?")} &nbsp;
          <span
            style={style}
            onClick={() => {
              setStyle({
                color: "#268c55",
                pointerEvents: "none",
                cursor: "not-allowed",
              });
            }}
          >
            {t("Resend")}
          </span>
        </p>
        <button
          className="btn btnPrimary verify-button"
          onClick={deletePhoneHandler}
        >
          {t("Delete My Mobile Number")}
        </button>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setShowModal(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? {
                  right: "auto",
                  left: "15px",
                  top: "15px",
                }
              : {
                  right: "15px",
                  left: "auto",
                  top: "15px",
                }
          }
        />
      </Modal>
    </div>
  );
};

export default MobileNumber;
