import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { useDispatch, useSelector } from "react-redux";
import { Upload, Avatar } from "antd";
import cogoToast from "cogo-toast";
import api from "../../../../../apis/api";
import { getUserInfo, updateProfile } from "../../../../../actions/authActions";

import "./profileSettings.css";

const ProfileSettings = () => {
  const user = useSelector((state) => state.auth.user);
  const userAvatar = useSelector((state) => state.auth.user.image);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [jobTitle, setJobTitle] = useState(user.jobTitle || "");
  const [fileList, setFileList] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const props = {
    onRemove: (file) => {
      this.setState(() => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const updateProfileHandler = () => {
    if (firstName === "") {
      cogoToast.error("Enter your first name");
    } else if (lastName === "") {
      cogoToast.error("Enter your last name");
    } else if (jobTitle === "") {
      cogoToast.error("Enter your current job title");
    } else {
      let formData = new FormData();
      if (fileList.length > 0) {
        formData.append("file", Array.from(fileList)[0]);
        formData.append("type", "profile");
      } else {
        formData.append("file", "");
        formData.append("type", "notSet");
      }
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("jobTitle", jobTitle);

      api({
        url: "/user/profile?type=profile",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        },
        data: formData,
      })
        .then((res) => {
          if (res.data.status === 1) {
            dispatch(updateProfile(res.data.data));
            setFileList([]);
            cogoToast.success("Profile updated successfully");
          } else {
            cogoToast.error("Failed to update profile");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteProfilePic = () => {
    userAvatar !== "" &&
      api({
        url: "/user/delete-photo",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          if (res.data.status === 1) {
            dispatch(getUserInfo());
            cogoToast.success("Profile picture deleted successfully");
          } else {
            cogoToast.error("Failed deleting profile picture");
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  return (
    <div className="mainPage">
      <h1 className="profile-setting-header">{t("Profile Settings")}</h1>
      <div className="profile-settings">
        <div className="profilePicture-side">
          {fileList && fileList.length > 0 ? (
            <Avatar src={URL.createObjectURL(fileList[0])} size={300} />
          ) : userAvatar && userAvatar !== "" ? (
            <Avatar src={userAvatar} size={300} />
          ) : (
            <Avatar
              style={{
                backgroundColor: "#d6edff",
                verticalAlign: "middle",
                color: "#405261",
              }}
              size={300}
            >
              {firstName}
            </Avatar>
          )}

          <ImgCrop>
            <Upload {...props} showUploadList={false}>
              <button className="btn update-avatar">
                {t("Upload New Profile Picture")}
              </button>
            </Upload>
          </ImgCrop>
          <p
            style={{ color: "#1292ee", cursor: "pointer" }}
            onClick={deleteProfilePic}
          >
            <b>{t("Delete Your Profile Picture")}</b>
          </p>
        </div>
        <div className="profile-info">
          <div className="form-row">
            <div
              className="row-element"
              style={
                localStorage.getItem("i18nextLng") == "en"
                  ? { textAlign: "left" }
                  : { textAlign: "right" }
              }
            >
              <label>{t("fName")}</label>
              <input
                type="text"
                placeholder={t("fNamePH")}
                autoComplete="off"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div
              className="row-element"
              style={
                localStorage.getItem("i18nextLng") == "en"
                  ? { textAlign: "left" }
                  : { textAlign: "right" }
              }
            >
              <label>{t("lName")}</label>
              <input
                type="text"
                placeholder={t("lNamePH")}
                autoComplete="off"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div
            className="form-row wide"
            style={
              localStorage.getItem("i18nextLng") == "en"
                ? { textAlign: "left" }
                : { textAlign: "right" }
            }
          >
            <label>{t("team")}</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              value={user.team.name}
              disabled
            />
            <p className="change-hint">
              {t(
                "Want to change your teamwork? Contact your HR Manager to change it for you"
              )}
            </p>
          </div>
          <div
            className="form-row wide"
            style={
              localStorage.getItem("i18nextLng") == "en"
                ? { textAlign: "left" }
                : { textAlign: "right" }
            }
          >
            <label>{t("Work Title")}</label>
            <input
              type="text"
              placeholder={t("Your current job title")}
              autoComplete="off"
              className="form-control"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
          <div
            className="form-row wide"
            style={
              localStorage.getItem("i18nextLng") == "en"
                ? { textAlign: "left" }
                : { textAlign: "right" }
            }
          >
            <label>{t("role_")}</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              disabled
              value={
                user.role === "user"
                  ? t("Employee")
                  : user.role === "team-manager"
                  ? t("Manager")
                  : user.role === "accountant"
                  ? t("Accountant")
                  : user.role === "HR"
                  ? t("HR")
                  : t("Account Owner")
              }
            />
            <p className="change-hint">
              {t(
                "Want to change your role level? Contact your HR Manager to change it for you"
              )}
            </p>
          </div>
          <button className="btn btnPrimary" onClick={updateProfileHandler}>
            {t("Update Profile Changes")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
