import React from "react";
import Carousel from "antd/lib/carousel";

import pic1 from "../../../../../assets/images/800X900 1.png";
import pic2 from "../../../../../assets/images/800X900 2.png";
import pic3 from "../../../../../assets/images/800X900 3.png";
import pic4 from "../../../../../assets/images/800X900 4.png";

import "./loginBanner.css";

const LoginBanner = () => {
  return (
    <div className="loggin-banner-container">
      <Carousel autoplay>
        <img
          className="login-banner-pic"
          id="login-banner-pic-1"
          alt="login-banner-pic"
          src={pic1}
        />
        <img
          className="login-banner-pic"
          id="login-banner-pic-2"
          alt="login-banner-pic"
          src={pic2}
        />
        <img
          className="login-banner-pic"
          id="login-banner-pic-3"
          alt="login-banner-pic"
          src={pic3}
        />
        <img
          className="login-banner-pic"
          id="login-banner-pic-3"
          alt="login-banner-pic"
          src={pic4}
        />
      </Carousel>
    </div>
  );
};

export default LoginBanner;
