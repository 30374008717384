import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiChevronDoubleLeft } from "react-icons/hi";

import "./TeamRequestsReimbursement.css";
import PendingExpense from "../pendingExpense/pendingExpense";
import ApprovedExpense from "../approvedExpense/approvedExpense";
import DeclinedExpense from "../declinedExpense/declindedExpense";
import ManualRequest from "../manualRequest/manualRequest";
import { useSelector } from "react-redux";

const TeamRequestsReimbursement = () => {
  const [component, setComponent] = useState("");
  const [toggleState, setToggleState] = useState(1);

  const role = useSelector((state) => state.auth.user.role);

  const { t } = useTranslation();

  useEffect(() => {
    setComponent(pendingComponent);
  }, []);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const pendingComponent = () => {
    return (
      <div>
        <PendingExpense />
      </div>
    );
  };

  const approvedComponent = () => {
    return (
      <div>
        <ApprovedExpense />
      </div>
    );
  };

  const declinedComponent = () => {
    return (
      <div>
        <DeclinedExpense />
      </div>
    );
  };

  const manualRequestComponent = () => {
    return (
      <div>
        <ManualRequest />
      </div>
    );
  };

  return (
    <div>
      <div className="filter-nav-backgroung">
        <div className="filter-nav expenseFilter">
          {localStorage.getItem("i18nextLng") == "en" ? (
            <span>
              <h6>{t("teamReq")}</h6> &nbsp;{" "}
              <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
              <h6>{t("Reimbursement")}</h6>
            </span>
          ) : (
            <span>
              <h6>{t("teamReq")}</h6> &nbsp;{" "}
              <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
              <h6>{t("Reimbursement")}</h6>
            </span>
          )}
          <p
            className={
              toggleState === 1
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setComponent(pendingComponent);
              toggleTab(1);
            }}
          >
            {t("pending")}
          </p>
          <p
            className={
              toggleState === 2
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setComponent(approvedComponent);
              toggleTab(2);
            }}
          >
            {t("approved")}
          </p>
          <p
            className={
              toggleState === 3
                ? "filter-element active-filter-element"
                : "filter-element"
            }
            onClick={() => {
              setComponent(declinedComponent);
              toggleTab(3);
            }}
          >
            {t("declined")}
          </p>

          {role === "accountant" && (
            <p
              className={
                toggleState === 4
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(manualRequestComponent);
                toggleTab(4);
              }}
            >
              {t("Manual Request")}
            </p>
          )}
        </div>
      </div>
      <div className="teamReqContainer">{component}</div>
    </div>
  );
};

export default TeamRequestsReimbursement;
