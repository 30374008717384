import React from "react";
import { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  approveReconcileRequests,
  declineReconcileRequests
} from "../../../../../../../actions/pendingRequestsActions";
import { editRequestCategory } from "../../../../../../../actions/categoryActions";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { useForm } from "react-hook-form";

import "./item-request.css";
import noReceipts from "../../../../../../../assets/images/noReceipts.png";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { Avatar } from "antd";

const ItemRequest = props => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const categories = useSelector(state => state.categories.categories);
  const cases = useSelector(state => state.categories.cases) || [];
  const user = useSelector(state => state.auth.user.role);
  const currency = useSelector(state => state.auth.user.currency[0]);

  const [showModal, setShowModal] = React.useState(false);
  const [showModalOne, setShowModalOne] = React.useState(false);
  const [image, setImage] = React.useState(undefined);
  const [images, setImages] = React.useState([]);

  const [request, setRequest] = React.useState(undefined);
  const [categoryId, setCategoryId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [numPages, setNumPages] = useState(null);

  const [pageNumber, setPageNumber] = React.useState(1);

  const [selected, setSelected] = useState(0);

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const { t } = useTranslation();

  useEffect(() => {
    setRequest(props.request);
    setCategoryId(props.request?.category?._id);
    setCaseId(props.request?.case?._id);
    let im = props.request.images;
    for (const iterator of im) {
      setImages([...images, { url: iterator.path }]);
    }
  }, [props.request]);

  const onImageClick = () => {
    setShowModal(true);
  };

  const statusColor = () => {
    if (props.request.status.toLowerCase().includes("approve")) {
      return {
        color: "#268c55",
        backgroundColor: "#e3fbee",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    } else if (props.request.status.toLowerCase().includes("declin")) {
      return {
        color: "#e32424",
        backgroundColor: "#ffebeb",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    } else if (props.request.status.toLowerCase().includes("pend")) {
      return {
        color: "#f4a800",
        backgroundColor: "#fff4dd",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    }
  };

  const categoryOptions = categories.map(cat => {
    return (
      <option
        value={cat._id}
        selected={request && request.category?._id === cat.id}
      >
        {cat.name}
      </option>
    );
  });

  const caseOptions = cases.map(cat => {
    return (
      <option
        value={cat._id}
        selected={request && request.case?._id === cat.id}
      >
        {cat.name}
      </option>
    );
  });

  const onSubmit = data => {
    const reqBody = data;
    reqBody.requestID = request._id;
    if (!data.realAmount) {
      reqBody.realAmount = -1;
    }
    console.log(reqBody);
    props.declineReconcileRequests(reqBody);
    setShowModalOne(false);
    setSelected(0);
  };

  const toggleDrawer = (anchor, open) => event => {
    event.stopPropagation();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const renderImages = request => {
    const images = request.images;
    if (images && images.length > 0) {
      return images.map((image, index) => {
        return image.path.split(".").pop() === "pdf" ? (
          <div
            className="request-image d-inline-block"
            style={{ overflow: "auto" }}
          >
            <Document
              style={{ width: "100%" }}
              file={image.path}
              onLoadSuccess={onDocumentLoadSuccess}
              key={index}
            >
              <Page width={160} pageNumber={pageNumber} />
            </Document>
          </div>
        ) : (
          <div className="request-image d-inline-block">
            <img
              key={index}
              src={
                image.path.split(".").pop() == "pdf"
                  ? "/images/icons8-file-64.png"
                  : image.path
              }
              alt={image.filename}
              onClick={e => onImageClick(image.path, e)}
              className="img-fluid d-inline-block"
            />
          </div>
        );
      });
    } else {
      return (
        <div className="badge badge-secondary">
          {t("This request doesn't have images")}
        </div>
      );
    }
  };

  return (
    <React.Fragment key={"right"}>
      <div className="request">
        <div className="row align-items-center">
          <div
            className="col-md-6"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <div className="row align-items-center">
              <div className="col-md-12 req-card">
                <div className="req-owner-info">
                  {props.request ? (
                    props.request.from.userImage !== "" ? (
                      <Avatar
                        src={props.request.from.userImage}
                        shape="square"
                        size={40}
                      />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#d6edff",
                          verticalAlign: "middle",
                          color: "#405261"
                        }}
                        size="large"
                        shape="square"
                      >
                        {props.request ? props.request.from.firstName : ""}
                      </Avatar>
                    )
                  ) : (
                    ""
                  )}
                  <span
                    className="d-inline-block"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? {
                            width: "fit-content",
                            textAlign: "right",
                            margin: "0px 10px"
                          }
                        : {
                            width: "fit-content",
                            textAlign: "left",
                            margin: "0px 10px"
                          }
                    }
                  >
                    <b>{props.request.name}</b> <br />
                    <b style={{ color: "#a8acaf" }}>{props.request.number}</b>
                  </span>
                </div>
                <div className="d-inline-block request-images">
                  {renderImages(props.request)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row align-items-center">
              <div className="col-md-2 text-center">
                <span>
                  {t(currency)} {request ? request.amount : ""}
                </span>
              </div>
              <div className="col-md-4 text-center">
                <div className="badge badge-info p-1" style={statusColor()}>
                  {request ? t(request.status) : ""}
                </div>
              </div>
              <div className="col-md-6 text-center">
                <button
                  className={
                    props.request.reconsileShortStatus === 1
                      ? "btn float-center btnThrd col-5 mr-5"
                      : "btn float-center btnPrimary col-5 mr-5"
                  }
                  onClick={() => setShowModal(true)}
                >
                  {t("view")}
                </button>
              </div>
              <div className="col-1"></div>
              <div
                className="col-1"
                onClick={toggleDrawer("right", true)}
              ></div>
            </div>
          </div>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
          >
            <Modal.Body
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    {request ? (
                      request.images.length > 0 ? (
                        <div
                          id="carouselExampleIndicators"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <ol
                            class="carousel-indicators"
                            style={{ display: "none" }}
                          >
                            {props.request.images.map((obj, index) => {
                              return (
                                <li
                                  data-target="#carouselExampleIndicators"
                                  data-slide-to={index}
                                  className="active"
                                ></li>
                              );
                            })}
                          </ol>
                          <div class="carousel-inner">
                            {props.request.images.map((obj, index) => {
                              return (
                                <div
                                  className={
                                    index == 0
                                      ? "active carousel-item"
                                      : "carousel-item"
                                  }
                                >
                                  {obj.path.split(".").pop() == "pdf" ? (
                                    <div style={{ overflow: "auto" }}>
                                      <Document
                                        style={{ width: "100%" }}
                                        file={obj.path}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                      >
                                        <Page
                                          width={400}
                                          style={{ width: "100%" }}
                                          pageNumber={pageNumber}
                                        />
                                      </Document>
                                    </div>
                                  ) : (
                                    <img
                                      class="d-block w-100"
                                      src={obj.path}
                                      alt="First slide"
                                    />
                                  )}
                                  <a
                                    href={obj.path}
                                    download
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "120px",
                                      zIndex: "150"
                                    }}
                                  >
                                    <button className="btn btnPrimary">
                                      <GetAppRoundedIcon /> {t("Down")}
                                    </button>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                          <a
                            class="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      ) : (
                        <div className="no-receipt">
                          <img src={noReceipts} alt="no receipt" />
                          <div className="red-warning" style={{ width: "95%" }}>
                            <div className="warning-head">
                              <WarningRoundedIcon /> <p>{t("noReq1")}</p>
                            </div>
                            <p>{t("noReq2")}</p>
                          </div>
                        </div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="">
                      <h2>
                        {t("request")} #{request ? request.number : ""}
                      </h2>
                      <div
                        className="req-owner-info"
                        style={{ width: "100%", justifyContent: "flex-start" }}
                      >
                        {props.request ? (
                          props.request.from.userImage !== "" ? (
                            <Avatar
                              src={props.request.from.userImage}
                              shape="square"
                              size={40}
                            />
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: "#d6edff",
                                verticalAlign: "middle",
                                color: "#405261"
                              }}
                              size="large"
                              shape="square"
                            >
                              {props.request
                                ? props.request.from.firstName
                                : ""}
                            </Avatar>
                          )
                        ) : (
                          ""
                        )}
                        <span
                          className="d-inline-block"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? {
                                  width: "fit-content",
                                  textAlign: "right",
                                  margin: "0px 10px"
                                }
                              : {
                                  width: "fit-content",
                                  textAlign: "left",
                                  margin: "0px 10px"
                                }
                          }
                        >
                          <b>{props.request.name}</b> <br />
                          <b style={{ color: "#a8acaf" }}>
                            {props.request.createdAt.split("T")[0]}
                          </b>
                        </span>
                      </div>
                      <p className="req-status" style={statusColor()}>
                        {request ? t(request.status) : ""}
                      </p>
                      <div className="data-row">
                        <div className="data-element">
                          <h5 className="label">{t("amount")}</h5>
                          <p className="popup-data">
                            {request ? request.currency : ""}{" "}
                            {request ? request.amount : ""}
                          </p>
                        </div>
                        <div className="data-element">
                          <h5 className="label">{t("payToInAd")}</h5>
                          <p className="value popup-data">
                            {request ? request.paidTo : ""}
                          </p>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="data-element">
                          <h5 className="label">{t("createdAt")}</h5>
                          <p className="value popup-data">
                            {request ? request.createdAt.split("T")[0] : ""}
                          </p>
                        </div>
                        <div className="data-element">
                          <h5 className="label">{t("payDate")}</h5>
                          <p className="value popup-data">
                            {request ? request.paidOn.split("T")[0] : ""}
                          </p>
                        </div>
                      </div>
                      <div className="paid-for">
                        <h5 className="label">{t("payForInAd")}</h5>
                        <p className="value popup-data">
                          {request ? request.paidFor : ""}
                        </p>
                      </div>
                      {user !== "accountant" && (
                        <div className="data-row">
                          <div className="data-element">
                            <h5 className="label">{t("Category")}</h5>
                            <p className="value popup-data">
                              {props.request?.category?.name === "" ||
                              !props.request?.category
                                ? t("Not selected")
                                : props.request?.category?.name}
                            </p>
                          </div>
                          <div className="data-element">
                            <h5 className="label">{t("Case")}</h5>
                            <p className="value popup-data">
                              {props.request?.case?.name === "" ||
                              !props.request?.case
                                ? t("Not selected")
                                : props.request?.case?.name}
                            </p>
                          </div>
                        </div>
                      )}
                      {request ? (
                        !request.note || request.note === "" ? (
                          ""
                        ) : (
                          <div className="paid-for">
                            <h5 className="label">{t("Note")}</h5>
                            <p className="value popup-data">
                              {request ? request.note : ""}
                            </p>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                      {user === "accountant" && (
                        <>
                          <div
                            className="form-row"
                            style={{ marginTop: "0px", marginBottom: "10px" }}
                          >
                            <div className="row-element">
                              <h5 className="label">{t("Category")}</h5>
                              <select
                                className="form-control"
                                onChange={e => setCategoryId(e.target.value)}
                              >
                                <option value="">{t("Select Category")}</option>
                                {categoryOptions}
                              </select>
                            </div>
                            <div className="row-element">
                              <h5 className="label">{t("Case")}</h5>
                              <select
                                className="form-control"
                                onChange={e => setCaseId(e.target.value)}
                              >
                                <option value="">{t("Select Case")}</option>
                                {caseOptions}
                              </select>
                            </div>
                          </div>
                          <button
                            className="btn btnPrimary"
                            onClick={() =>
                              dispatch(
                                editRequestCategory({
                                  requestID: props.request._id,
                                  categoryId,
                                  caseId
                                })
                              )
                            }
                          >
                            {t("Change category & case")}
                          </button>
                        </>
                      )}
                      {request ? (
                        request.reconsileShortStatus === 1 &&
                        props.auth.user.role === "accountant" ? (
                          <div className="mt-1  p-1 row">
                            <button
                              className="btn float-center btnSec col-6 mr-3"
                              onClick={() => {
                                setShowModal(false);
                                setImage(undefined);
                                setShowModalOne(true);
                              }}
                            >
                              {t("Reject or Reconcile")}
                            </button>
                            <button
                              className="btn btn-outline-success float-center btnSecondary col-5"
                              onClick={() =>
                                props.approveReconcileRequests({
                                  requestID: request._id
                                })
                              }
                            >
                              {t("Approve & Close")}
                            </button>
                          </div>
                        ) : (
                          <div></div>
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </Col>
                  <CloseOutlinedIcon
                    className="close-icon"
                    onClick={() => {
                      setShowModal(false);
                    }}
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { right: "auto", left: "5px" }
                        : { right: "5px", left: "auto" }
                    }
                  />
                </Row>
              </Container>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModalOne}
          >
            <Modal.Body>
              <Modal.Title
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "bold"
                }}
              >
                {t("recRej")}
              </Modal.Title>
              <p className="head main-head" style={{ color: "#405261" }}>
                {t("recRej1")} #{request ? request.number : ""}
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="note-option">
                  <input
                    type="radio"
                    ref={register({ required: true })}
                    defaultChecked
                    value="Photo of uploaded receipt is not clear"
                    name="note"
                    onClick={() => setSelected(0)}
                  />
                  <p>{t("recRej2")}</p>
                </div>
                <div className="note-option">
                  <input
                    type="radio"
                    ref={register({ required: true })}
                    value="Uploaded receipt is not related to the request details"
                    name="note"
                    onClick={() => setSelected(0)}
                  />
                  <p>{t("recRej3")}</p>
                </div>
                <div className="note-option">
                  <input
                    type="radio"
                    ref={register({ required: true })}
                    value="There’s a variance between the requested amount and amount paid (Reconcile)"
                    name="note"
                    onClick={() => setSelected(1)}
                  />
                  <p>{t("recRej4")}</p>
                </div>
                {selected === 1 ? (
                  <div className="note-3">
                    <p className="head">{t("recRej5")}</p>
                    <div
                      className="form-row"
                      style={{ justifyContent: "spaceBetween" }}
                    >
                      <input
                        type="number"
                        ref={register({ required: true })}
                        name="realAmount"
                        className="form-control"
                        style={{ width: "49%" }}
                      />
                      <select
                        name="currency"
                        ref={register({ required: true })}
                        className="form-control"
                        style={{ width: "49%" }}
                      >
                        <option selected>EGP</option>
                      </select>
                    </div>
                    <div className="blue-warning">
                      <div className="warning-head">
                        <WarningRoundedIcon />
                        <p>{t("recRej6")}</p>
                      </div>
                      <p style={{ marginBottom: "20px" }}>{t("recRej7")}</p>
                      <div className="note-option">
                        <input
                          type="radio"
                          name="discount"
                          ref={register({ required: true })}
                          value="false"
                        />
                        {t("recRej8")}
                      </div>
                      <div className="note-option">
                        <input
                          type="radio"
                          name="discount"
                          ref={register({ required: true })}
                          value="true"
                        />
                        {t("recRej9")}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <button
                  className="btn btnPrimary"
                  style={{ width: "60%", marginLeft: "20%" }}
                >
                  {t("recRej10")}
                </button>
              </form>
              <CloseOutlinedIcon
                className="close-icon"
                onClick={() => {
                  setShowModalOne(false);
                  setSelected(0);
                }}
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { right: "auto", left: "15px", top: "15px" }
                    : { right: "15px", left: "auto", top: "15px" }
                }
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapToStateProps = state => {
  return {
    pendings: state.pending.pendings,
    exel: state.pending.exel,
    lang: state.lang.lang,
    auth: state.auth
  };
};
export default connect(mapToStateProps, {
  approveReconcileRequests,
  declineReconcileRequests
})(ItemRequest);
