import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiChevronDoubleLeft } from "react-icons/hi";

import PendingExpenseInAdvance from "../pendingExpenseInAdvance/pendingExpenseInAdvance";
import ApprovedExpenseInAdvance from "../approvedExpense/approvedExpenseInAdvance";
import ReconciledExpenseInAdvance from "../approvedExpense/ReconciledExpenseInAdvance";
import DeclinedExpenseInAdvance from "../declinedExpense/declindedExpenseInAdvance";
import CompanyIndebtedness from "../indebtedness/indebtedness";
import ManualInadvanceRequest from "../manualRequest/manualInadvanceRequest";

const TeamRequestsInAdvance = () => {
  const [component, setComponent] = useState("");
  const [toggleState, setToggleState] = useState(1);

  const user = useSelector((state) => state.auth.user.role);

  useEffect(() => {
    setComponent(pendingComponent);
  }, []);

  const { t } = useTranslation();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const pendingComponent = () => {
    return (
      <div>
        <PendingExpenseInAdvance />
      </div>
    );
  };

  const approvedComponent = () => {
    return (
      <div>
        <ApprovedExpenseInAdvance />
      </div>
    );
  };

  const reconciledComponent = () => {
    return (
      <div>
        <ReconciledExpenseInAdvance />
      </div>
    );
  };

  const declinedComponent = () => {
    return (
      <div>
        <DeclinedExpenseInAdvance />
      </div>
    );
  };

  const indebtednessComponent = () => {
    return (
      <div>
        <CompanyIndebtedness />
      </div>
    );
  };

  const manualRequestComponent = () => {
    return (
      <div>
        <ManualInadvanceRequest />
      </div>
    );
  };

  return (
    <div>
      {user === ("accountant" || "manager") ? (
        <div className="filter-nav-backgroung">
          <div className="filter-nav expenseFilter">
            {localStorage.getItem("i18nextLng") == "en" ? (
              <span>
                <h6>{t("teamReq")}</h6> &nbsp;{" "}
                <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
                <h6>{t("In Advance")}</h6>
              </span>
            ) : (
              <span>
                <h6>{t("teamReq")}</h6> &nbsp;{" "}
                <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
                <h6>{t("In Advance")}</h6>
              </span>
            )}
            <p
              className={
                toggleState === 1
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(pendingComponent);
                toggleTab(1);
              }}
            >
              {t("pending")}
            </p>
            <p
              className={
                toggleState === 2
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(approvedComponent);
                toggleTab(2);
              }}
            >
              {t("approved")}
            </p>
            <p
              className={
                toggleState === 3
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(reconciledComponent);
                toggleTab(3);
              }}
            >
              {t("Reconciled")}
            </p>
            <p
              className={
                toggleState === 4
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(declinedComponent);
                toggleTab(4);
              }}
            >
              {t("declined")}
            </p>
            <p
              className={
                toggleState === 5
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(indebtednessComponent);
                toggleTab(5);
              }}
            >
              {t("Indebtedness")}
            </p>
            {user === "accountant" && (
              <p
                className={
                  toggleState === 6
                    ? "filter-element active-filter-element"
                    : "filter-element"
                }
                onClick={() => {
                  setComponent(manualRequestComponent);
                  toggleTab(6);
                }}
              >
                {t("Manual Request")}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="filter-nav-backgroung">
          <div className="filter-nav expenseFilter">
            {localStorage.getItem("i18nextLng") == "en" ? (
              <span>
                <h6>{t("teamReq")}</h6> &nbsp;{" "}
                <HiChevronDoubleRight className="bradCrumbIcon" /> &nbsp;{" "}
                <h6>{t("In Advance")}</h6>
              </span>
            ) : (
              <span>
                <h6>{t("teamReq")}</h6> &nbsp;{" "}
                <HiChevronDoubleLeft className="bradCrumbIcon" /> &nbsp;{" "}
                <h6>{t("In Advance")}</h6>
              </span>
            )}
            <p
              className={
                toggleState === 1
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(pendingComponent);
                toggleTab(1);
              }}
            >
              {t("pending")}
            </p>
            <p
              className={
                toggleState === 2
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(approvedComponent);
                toggleTab(2);
              }}
            >
              {t("approved")}
            </p>
            <p
              className={
                toggleState === 3
                  ? "filter-element active-filter-element"
                  : "filter-element"
              }
              onClick={() => {
                setComponent(declinedComponent);
                toggleTab(3);
              }}
            >
              {t("declined")}
            </p>
          </div>
        </div>
      )}

      <div className="teamReqContainer">{component}</div>
    </div>
  );
};

export default TeamRequestsInAdvance;
