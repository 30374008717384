import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { forgetPassword } from "../../../../actions/authActions";
import { verifyEmail } from "../../../../actions/userActions";
import { useTranslation } from "react-i18next";
import api from "../../../../apis/api";
import cogoToast from "cogo-toast";
import { BiHide } from "react-icons/bi";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { withRouter, useHistory } from "react-router-dom";
import LoginBanner from "../login/loginBanner/loginBanner";

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const [errorMsg, seterrorMsg] = useState(undefined);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRPass, setShowRPass] = useState(false);
  const [id, setId] = useState(undefined);
  let history = useHistory();

  const content = (
    <ul>
      <li>8 Letters min length</li>
      <li>Include capital Letter</li>
      <li>Include small Letter</li>
      <li>Include Number</li>
      <li>Include special character</li>
    </ul>
  );

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get("id");
    setId(id);
  }, []);

  const email = useSelector((state) => state.auth.verificationEmail);

  const onSubmit = (values) => {
    let resetData;
    if (values.confirmPassword === values.password) {
      resetData = { password: values.password, id };
    } else cogoToast.error("حدث خطأ ما");

    api({
      url: "user/setData",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: resetData,
    })
      .then((res) => {
        if (res.data.status === 1) {
          localStorage.getItem("i18nextLng") == "ar"
            ? cogoToast.success("تم تغيير كلمة السر")
            : cogoToast.success("Password Changed");
          history.push("/");
        } else {
          cogoToast.error("Inivtation link is expired");
        }
      })
      .catch((err) => {
        localStorage.getItem("i18nextLng") == "ar"
          ? cogoToast.error("حدث خطأ ما")
          : cogoToast.success("somthing went wrong");
      });
  };

  return (
    <section className="login-page-wrapper">
      <div className="login-page">
        <div className="loginForm-wrapper">
          <form className="loginForm" onSubmit={props.handleSubmit(onSubmit)}>
            {localStorage.getItem("i18nextLng") == "ar" ? (
              <h3 className="section-title loginform-title">
                ضبط بيانات الحساب
              </h3>
            ) : (
              <h3 className="section-title loginform-title">
                Set Account Info
              </h3>
            )}
            <div className="form-element-full">
              <h6>{t("password")}</h6>
              <Field
                name="password"
                type={showNewPass ? "text" : "password"}
                key="3"
                placeholder={t("passPH")}
                component={renderInputAR}
              />
              <BiHide
                className="passShowHide"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "30px", top: "65px" }
                    : { right: "30px", top: "65px" }
                }
                onClick={() => setShowNewPass(!showNewPass)}
              />
              <span
                className="pass-requirements"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "60px", top: "58px" }
                    : { right: "60px", top: "58px" }
                }
              >
                <Popover
                  content={content}
                  title="Password must be:"
                  trigger="click"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </div>
            <div className="form-element-full">
              <h6>{t("cPassword")}</h6>
              <Field
                name="confirmPassword"
                placeholder={t("cPasswordPH")}
                type={showRPass ? "text" : "password"}
                key="6"
                component={renderInputAR}
              />
              <BiHide
                className="passShowHide"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "30px", top: "65px" }
                    : { right: "30px", top: "65px" }
                }
                onClick={() => setShowRPass(!showRPass)}
              />
              <span
                className="pass-requirements"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "60px", top: "58px" }
                    : { right: "60px", top: "58px" }
                }
              >
                <Popover
                  content={content}
                  title="Password must be:"
                  trigger="click"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </div>

            <div className="form-element-full">
              <button className="btn btnPrimary login-button">
                {t("Save")}
              </button>
            </div>
          </form>
        </div>
        <LoginBanner />
      </div>
    </section>
  );
};

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.fName) {
    errors.fName = "Please add your first name";
  }
  if (!formValues.lName) {
    errors.lName = "Please add your last name";
  }

  if (
    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
      formValues.password
    )
  ) {
    errors.password = "8 Letters at least";
  }

  if (!formValues.password) {
    errors.password = "Please add password";
  }
  if (formValues.password !== formValues.confirmPassword) {
    errors.confirmPassword = "password must match";
  }

  return errors;
};

const reduxMiddleware = reduxForm({
  form: "login",
  validate: validateForm,
})(ChangePassword);

const mapStateToProps = (state) => {
  return {
    errors: state.error,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, { forgetPassword, verifyEmail })(
  withRouter(reduxMiddleware)
);

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="text-danger">{error}</div>;
  }
};

const renderInput = ({ input, label, meta, type }) => {
  return (
    <div className="form-group" style={{ width: "100%" }}>
      <label>{label}</label>
      <input
        type={type}
        className="form-control input-lg"
        autoComplete="off"
        pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
        {...input}
      />
      {renderError(meta)}
    </div>
  );
};

const renderInputAR = ({ input, label, meta, type }) => {
  return (
    <div className="form-group" style={{ width: "100%" }}>
      <label className="ar">{label}</label>
      <input
        type={type}
        className="form-control input-lg"
        autoComplete="off"
        pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
        {...input}
      />
      {renderError(meta)}
    </div>
  );
};
