import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { GoChevronDown } from 'react-icons/go';
import { RiTeamFill } from 'react-icons/ri';
import { RiUserAddFill } from 'react-icons/ri';
import { RiBuildingFill } from 'react-icons/ri';

import 'antd/dist/antd.css';

const TeamWorkMenu = () => {

  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link className='nav-menu-item' to='/dashboard/team/home'>
          <div className='icon-wrapper' style={{backgroundColor: '#dedff5'}}>
            <RiTeamFill className='nav-menu-icon'/>
          </div>
          &nbsp;&nbsp;
          {t('Team Members')}
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link className='nav-menu-item' to='/dashboard/team/invite'>
          <div className='icon-wrapper' style={{backgroundColor: '#e7f8ef'}}>
            <RiUserAddFill className='nav-menu-icon'/>
          </div>
          &nbsp;&nbsp;
          {t('Invite Team Member')}
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link className='nav-menu-item' to='/dashboard/team/create'>
          <div className='icon-wrapper' style={{backgroundColor: '#fff4dd'}}>
            <RiBuildingFill className='nav-menu-icon'/>
          </div>
          &nbsp;&nbsp;
          {t('Create New Team')}
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a 
      className={
      window.location.pathname.includes('team/home') ||
      window.location.pathname.includes('team/invite') ||
      window.location.pathname.includes('team/create') ? 'active-nav-tab ant-dropdown-link' : 'nav-tab ant-dropdown-link'} 
      onClick={e => e.preventDefault()}>
        {t('Teamwork')} &nbsp; <GoChevronDown style={{fontSize: '16px'}}/>
      </a>
    </Dropdown>
  );
};

export default TeamWorkMenu;