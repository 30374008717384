import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { editEmp } from "../../../../../../actions/teamActions";

const EditEmployee = (props) => {
  const [selectedUser, setSelectedUSer] = useState({});

  const teams = useSelector((state) => state.team.teams);

  const { register, errors, handleSubmit } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedUSer(props.selectedUser);
  }, [props.selectedUser]);

  const renderTeams = () => {
    if (teams && teams.length > 0) {
      let options = (
        <option value="noteam" disabled>
          {t("Pleasechooseateam")}
        </option>
      );
      const filteredTeams = teams.filter((team) => team.name !== "All");
      return (options = [
        options,
        ...filteredTeams.map((team) => {
          if (team.active)
            return (
              <option
                value={team._id}
                disabled={team.name === "All"}
                selected={
                  selectedUser.team && selectedUser.team.name === team.name
                }
              >
                {team.name}
              </option>
            );
        }),
      ]);
    } else {
      return <option value="noteam">No teams found</option>;
    }
  };

  const onEditUser = (data) => {
    data._id = selectedUser._id;
    if (data.jobTitle === "") {
      data.jobTitle = " ";
    }
    dispatch(editEmp(data));
    setTimeout(() => {
      props.refreshUsers();
      props.closeDeawer();
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit(onEditUser)}>
      <div className="form-row wide">
        <label>{t("bmail")}</label>
        <input
          type="text"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
          title="Enter in the format: example@email.com"
          className="form-control"
          disabled
          autoComplete="off"
          name="email"
          defaultValue={selectedUser.email}
          placeholder={t("bmailPH")}
        />
      </div>
      <div className="form-row">
        <div
          className="row-element"
          style={
            localStorage.getItem("i18nextLng") == "en"
              ? { textAlign: "left" }
              : { textAlign: "right" }
          }
        >
          <label>{t("fName")}</label>
          <input
            type="text"
            placeholder={t("fNamePH")}
            autoComplete="off"
            className="form-control"
            defaultValue={selectedUser.firstName}
            name="firstName"
            ref={register({ required: true })}
          />
          {errors.firstName && <p>First name is required</p>}
        </div>
        <div
          className="row-element"
          style={
            localStorage.getItem("i18nextLng") == "en"
              ? { textAlign: "left" }
              : { textAlign: "right" }
          }
        >
          <label>{t("lName")}</label>
          <input
            type="text"
            placeholder={t("lNamePH")}
            autoComplete="off"
            className="form-control"
            defaultValue={selectedUser.lastName}
            name="lastName"
            ref={register({ required: true })}
          />
          {errors.lastName && <p>Last name is required</p>}
        </div>
      </div>
      <div className="form-row wide">
        <label>{t("Work Title (Optional)")}</label>
        <input
          type="text"
          placeholder={t("manager")}
          autoComplete="off"
          className="form-control"
          name="jobTitle"
          ref={register({ required: false })}
        />
      </div>
      <div className="form-row wide">
        <label>{t("team")}</label>
        <select
          className="form-control"
          name="team"
          ref={register({ required: true })}
        >
          {renderTeams()}
        </select>
        {errors.team && <p>Team is required</p>}
      </div>
      <div className="form-row wide">
        <label>{t("role_")}</label>
        <select
          className="form-control"
          name="role"
          ref={register({ required: true })}
        >
          <option value="user" selected={selectedUser.role === "user"}>
            {t("emp")}
          </option>
          <option
            value="team-manager"
            selected={selectedUser.role === "team-manager"}
          >
            {t("manager")}
          </option>
        </select>
        {errors.team && <p>Team is required</p>}
      </div>
      <button type="submit" className="btn btnPrimary">
        {t("Save Edits")}
      </button>
    </form>
  );
};

export default EditEmployee;
