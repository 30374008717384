import React from 'react';
import { useTranslation } from 'react-i18next';

import './accountSettings.css';
import ChangeEmail from './accountForms/changeEmail';
import ChangePassword from './accountForms/changePassword';
import MobileNumber from './accountForms/mobileNumber';

const AccountSettings = () => {

  const { t } = useTranslation();

  return (
    <div className='mainPage'>
      <div className='account-settings'>
        <h1>{t('Account Settings')}</h1>
        <ChangeEmail/>
        <ChangePassword/>
        <MobileNumber />
      </div>
    </div>
  );
};

export default AccountSettings;