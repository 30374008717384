import api from "../apis/api";
import { returnErrors, clearErrors } from "./errorActions";
import { setLoading, removeLoading } from "./loadingActions";
import { tokenConfig } from "./tokenActions";
import { SEND_INVITE, VERIFY_EMAIL_LOGIN } from "./types";
import { loadTeams } from "./teamActions";
import cogoToast from "cogo-toast";
import { loadRequestCount, loadInadvanceRequestCount } from "./authActions";
import i18n from "../translations/i18n";
//cogoToast.success('Team Added successfully');

export const sendInvite = data => (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  return api
    .post(`/user/invite`, data, tokenConfig(state))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: SEND_INVITE, payload: res.data.data });
        dispatch(removeLoading());
        dispatch(loadTeams);
        cogoToast.success(i18n.t("User invited successfully"));
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "SEND_INVITE_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const changePassword = data => (dispatch, state) => {
  dispatch(setLoading());

  //console.log('data');
  loadRequestCount();
  loadInadvanceRequestCount();

  return api
    .post(`/user/changePassword`, JSON.stringify(data), tokenConfig(state))
    .then(res => {
      if (res.data.status === "wrong password") {
        cogoToast.warn(i18n.t("Old password is wrong "));
        dispatch({ type: "CHANGE_PASSWORD", payload: res.data.data });

        dispatch(removeLoading());
      } else if (res.data.status === "correct password") {
        cogoToast.success(i18n.t("Password changed"));
        dispatch({ type: "CHANGE_PASSWORD", payload: res.data.data });

        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, "SEND_INVITE_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const verifyEmail = data => dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  loadRequestCount();
  loadInadvanceRequestCount();

  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  api
    .post(`/user/verify-email`, JSON.stringify(data), config)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: VERIFY_EMAIL_LOGIN, payload: res.data.data });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "VERIFY_EMAIL_LOGIN_FAIL"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const EWalletsOptions = data => (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  return api
    .get(`/user/walletTypes`, tokenConfig(state))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: "EWALLETS_OPTIONS", payload: res.data.data.wallets });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "Can not get E-wallets options"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const getCashoutMethods = () => async (dispatch, getState) => {
  dispatch(clearErrors());

  api
    .get(`/user/cashout-data`, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: "GET_CASHOUT_METHODS",
          payload: res.data.data.cashoutData
        });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "Can not get cashout methods"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const addCashoutMethods = (
  method,
  setShowModal,
  setMobile,
  selectData
) => (dispatch, getState) => {
  dispatch(clearErrors());

  api
    .post(`/user/cashout-data`, method, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch(getCashoutMethods());
        dispatch(removeLoading());
        setShowModal(false);
        selectData({
          phone: { code: "20", short: "EG", phone: "" },
          bankName: "Arab African International Bank"
        });
        setMobile({
          short: "EG",
          phone: "",
          code: "20"
        });
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "Can not add cashout methods"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const editCashoutMethods = (
  method,
  setShowModal,
  setMobile,
  selectData
) => async (dispatch, getState) => {
  dispatch(clearErrors());

  api
    .put(`/user/cashout-data`, method, tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch(getCashoutMethods());
        dispatch(removeLoading());
        setShowModal(false);
        selectData({
          phone: { code: "20", short: "EG", phone: "" },
          bankName: "Arab African International Bank"
        });
        setMobile({
          short: "EG",
          phone: "",
          code: "20"
        });
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "Can not edit cashout methods"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const deleteCashoutMethods = (id, selectData) => async (
  dispatch,
  getState
) => {
  dispatch(clearErrors());

  const headers = {
    "Content-Type": "application/json"
  };

  let token = getState().auth.token;

  if (token) {
    headers["x-auth-token"] = token;
  }

  api
    .delete("/user/cashout-data", { data: id, headers })
    .then(res => {
      if (res.data.status === 1) {
        dispatch(getCashoutMethods());
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(
            res.data.message,
            res.data.status,
            "Can not delete cashout methods"
          )
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};
