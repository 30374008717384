export const USER_LOADED = 'USER_LOADED';
export const USER_LOADING = 'USER_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SET_LOADING = 'SET_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const START_UPLOAD = 'START_UPLOAD';
export const ADD_FILE = 'ADD_FILE';
export const ADD_FILES = 'ADD_FILES';
export const REMOVE_FILE = 'REMOVE_FILE';
export const UPLOAD_FINISHED = 'UPLOAD_FINISHED';
export const UPLOAD_SUPMITTED = 'UPLOAD_SUPMITTED';
export const INITIAL_PICS = 'INITIAL_PICS';

export const START_ADD_COMPANY = 'START_ADD_COMPANY';
export const REGISTER_COMPANY = 'REGISTER_COMPANY';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const RESEND_MAIL = 'RESEND_MAIL';
export const RESEND_FAIL = 'RESEND_FAIL';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_FAIL = 'VERIFY_FAIL';
export const ADD_COMPANY_DETAILS = 'ADD_COMPANY_DETAILS';
export const ADD_BANK = 'ADD_BANK';
export const SKIP_BANK = 'SKIP_BANK';
export const WALLET = 'WALLET';

export const ADD_SIGNATURE = 'ADD_SIGNATURE';
export const SEND_INVITE = 'SEND_INVITE';
export const FINISH_SETUP = 'FINISH_SETUP';

export const ADD_REQUEST = 'ADD_REQUEST';
export const LIST_REQUESTS = 'LIST_REQUESTS';
export const LIST_REQUESTS_SUPPLIER = 'LIST_REQUESTS_SUPPLIER';
export const LIST_REQUESTS_INADVANCE = 'LIST_REQUESTS_INADVANCE';
export const REQ_TO_RECONCILE = 'REQ_TO_RECONCILE';

export const VERIFY_EMAIL_LOGIN = 'VERIFY_EMAIL_LOGIN';

export const LIST_PENDING_REQUESTS = 'LIST_PENDING_REQUESTS';
export const LIST_APPROVED_REQUESTS = 'LIST_APPROVED_REQUESTS';
export const LIST_DECLINED_REQUESTS = 'LIST_DECLINED_REQUESTS';
export const LIST_PENDING_INADVANCE_REQUESTS = 'LIST_PENDING_INADVANCE_REQUESTS';
export const LIST_APPROVED_INADVANCE_REQUESTS = 'LIST_APPROVED_INADVANCE_REQUESTS';
export const LIST_DECLINED_INADVANCE_REQUESTS = 'LIST_DECLINED_INADVANCE_REQUESTS';

export const APPROVE_REQUESTS = 'APPROVE_REQUESTS';
export const DECLINE_REQUESTS = 'DECLINE_REQUESTS';
export const APPROVE_INADCANCE_REQUESTS = 'APPROVE_INADCANCE_REQUESTS';
export const DECLINE_INADCANCE_REQUESTS = 'DECLINE_INADCANCE_REQUESTS';
export const APPROVE_RECONCILE_REQUESTS = 'APPROVE_RECONCILE_REQUESTS';
export const DECLINE_RECONCILE_REQUESTS = 'DECLINE_RECONCILE_REQUESTS';
export const LIST_INDEBTEDNESS = 'LIST_INDEBTEDNESS';
export const LIST_FILTER_INDEBTEDNESS = 'LIST_FILTER_INDEBTEDNESS';
export const LIST_INDEBTEDNESS_USER = 'LIST_INDEBTEDNESS_USER';

export const LIST_TEAMS = 'LIST_TEAMS';
export const LIST_USERS = 'LIST_USERS';
export const ADD_TEAM = 'ADD_TEAM';
export const USER_ACTIVATE = 'USER_ACTIVATE';
export const USER_DEACTIVATE = 'USER_DEACTIVATE';
export const REQUEST_COUNT = 'REQUEST_COUNT';

export const MY_REQUEST_COUNT = 'MY_REQUEST_COUNT';
export const LIST_TRANSACTIONS = 'LIST_TRANSACTIONS';

export const ADD_PAYROLL_REQYEST = 'ADD_PAYROLL_REQYEST';
export const SET_LANG = 'SET_LANG';
export const INADVANC_REQUEST_COUNT = 'INADVANC_REQUEST_COUNT';

export const LIST_RECONCILED_INADVANCE_REQUESTS='LIST_RECONCILED_INADVANCE_REQUESTS'
export const MY_INADVANCE_REQUEST_COUNT='MY_INADVANCE_REQUEST_COUNT'



export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const EDIT_REQUEST_CATEGORY = 'EDIT_REQUEST_CATEGORY';
export const EDIT_REQUEST_CATEGORY_INADVANCE = 'EDIT_REQUEST_CATEGORY_INADVANCE';