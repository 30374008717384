import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { listRequests, cashout } from "../../../../../actions/requestsActions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import cogoToast from "cogo-toast";
import api from "../../../../../apis/api";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import CashoutData from "./cashoutData/cashoutData";
import CashoutForm from "./cashoutForm/cashoutForm";

import "./my-wallet.css";
import "react-phone-input-2/lib/style.css";

const CashOut = props => {
  const [wallet, setWallet] = useState(0);
  const [phone, setPhone] = useState("");
  const [method, setMethod] = useState("EWALLET");
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [all, setAll] = useState(false);
  const [amount, setAmount] = useState(0);
  const [value_, setValue_] = useState("CashOutAll");
  const [selectedData, setSelectedData] = useState({
    phone: { code: "20", short: "EG", phone: "" },
    bankName: "Arab African International Bank"
  });

  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);

  const [mobile, setMobile] = useState({
    short: "EG",
    code: "20",
    phone: ""
  });
  const [phoneErr, setPhoneErr] = useState(false);
  const currency = useSelector(state => state.auth.user.currency[0]);

  const banksNames = [
    "Arab African International Bank",
    "Arab Banking Corporation",
    "Al Ahli Bank of Kuwait - Egypt",
    "Al Baraka Bank Egypt",
    "Abu Dhabi Islamic Bank Egypt",
    "Arab Investment Bank",
    "Arab Bank",
    "Arab International Bank",
    "Ahly United Bank",
    "Audi Bank",
    "Attijariwafa Bank Egypt S.A.E",
    "Banque Du Caire",
    "Blom Bank Egypt",
    "Bank of Alexandria",
    "Credit Agricole Egypt",
    "Commercial International Bank",
    "Citibank",
    "Egyptian Arab Land Bank",
    "Export Development Bank of Egypt",
    "Egyptian Gulf Bank",
    "Emirates National Bank of Dubai",
    "First Abu Dhabi Bank",
    "Faisal Islamic Bank of Egypt",
    "Housing And Development Bank",
    "HSBC",
    "Industrial Development Bank",
    "Mashreq Bank",
    "Misr Iran Development Bank",
    "Banque Misr",
    "National Bank of Egypt",
    "National Bank of Greece",
    "National Bank Of Kuwait â€“ Egypt",
    "Nasser Social Bank",
    "The Principal Bank for Development and Agri",
    "Qatar National Bank Alahli",
    "Societe Arabe Internationale De Banque",
    "Suez Canal Bank",
    "The United Bank",
    "Union National Bank"
  ];

  useEffect(() => {
    setAmount(props.match.params.amount);
    setValue_(props.match.params.method);
  }, []);

  useEffect(() => {
    setAll(value_ === "CashOutPortion");
    value_ === "CashOutAll"
      ? setAmount(props.wallet)
      : setAmount(props.match.params.amount);
  }, [value_]);

  useEffect(() => {
    setMobile({ short: "EG", phone: selectedData.phoneNumber, code: "20" });
  }, [selectedData]);

  const handleChange = event => {
    setValue_(event.target.value);
    if (event.target.value === "CashOutAll") {
      setAmount(props.wallet);
    }
    setAll(!all);
  };

  const renderInput = ({ input, label, meta, type, placeholder }) => {
    return (
      <div className="inputComp">
        <label>{label}</label>
        <input
          className="form-control"
          placeholder={placeholder}
          {...input}
          type={type}
          autoComplete="off"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  const cashout = () => {
    if (method === "BANK") {
      props.cashout({
        name: selectedData.bankAccountName,
        accountNumber: selectedData.bankAccountNumber,
        bank: selectedData.bankName,
        amount: amount,
        method: method,
        bankAddress: selectedData.bankBranchAddress
      });
    } else {
      let correctedMobile;
      let sentNumber;
      const mobile = selectedData.phone;
      if (
        Object.keys(mobile).length !== 0 &&
        mobile.short === "EG" &&
        mobile.phone.length !== 10 &&
        mobile.phone.length !== 11
      ) {
        console.log(
          Object.keys(mobile).length,
          mobile.short,
          mobile.phone.length
        );

        return cogoToast.error("Please, Enter valid mobile number");
      }
      if (
        Object.keys(mobile).length !== 0 &&
        mobile.short === "EG" &&
        mobile.phone.length === 10
      ) {
        correctedMobile = mobile.phone;
        sentNumber = mobile.code + correctedMobile;
      } else if (
        Object.keys(mobile).length !== 0 &&
        mobile.short === "EG" &&
        mobile.phone.length === 11
      ) {
        correctedMobile = mobile.phone.substring(1);
        sentNumber = mobile.code + correctedMobile;
      }
      props.cashout({
        phone: sentNumber,
        amount: amount,
        method: method,
        name: name
      });
    }
    setName("");
    setAccountNumber("");
    setBank("");
    setBankAddress("");
    setPhone("");
    setMobile({ short: "EG", phone: "", code: 20 });
    setShowModal(false);
  };

  return (
    <div className="mainPage wallet">
      <div className="container-fluid">
        <div className="cashout-container">
          <div className="cashout-column">
            <div
              className=" wallet-trans"
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { width: "100%", textAlign: "right" }
                  : { width: "100%", textAlign: "left" }
              }
            >
              {" "}
              <div className="wallet-image" style={{ width: "100%" }}>
                <h3
                  className="mb-3 ml-1"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : { width: "100%", textAlign: "left" }
                  }
                >
                  {t("myWallet")}
                </h3>
                <div className="card ">
                  <div className="card-bottom pt-3 px-3 mb-2">
                    <div className="d-flex flex-row ">
                      <div className="d-flex flex-column">
                        <h4
                          className="mb-5"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          {t("hello")}, {props.auth.user.firstName}
                        </h4>
                        <p
                          className="h5 mb-0"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          {t(currency === "EGP" ? "EGPBalance" : "SAR Balance")}
                        </p>
                        <h1>
                          {props.wallet} {t(currency === "EGP" ? "EGP" : "SAR")}
                        </h1>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <h5
                className="mb-2 mt-5 ml-1"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left" }
                }
              >
                {t("cashout")}
              </h5>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={value_}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="CashOutAll"
                      control={<Radio color="primary" />}
                      label={t("cashoutAll")}
                    />
                    <FormControlLabel
                      value="CashOutPortion"
                      control={<Radio color="primary" />}
                      label={t("cashoutP")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12 row">
                <div className="col-5">
                  <input
                    className="col-12"
                    name="amount"
                    disabled={value_ === "CashOutAll"}
                    placeholder="00"
                    type="number"
                    min="1"
                    value={amount}
                    component={renderInput}
                    onChange={e => {
                      setAmount(e.target.value);
                    }}
                    style={{
                      display: "inline-block"
                    }}
                  />
                </div>
                <div className="col-7">
                  <p className="max ">
                    {t("max")} {props.wallet}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="cashout-column">
            <CashoutData
              handleChange={setMethod}
              method={!method ? "EWALLET" : method}
              selectData={setSelectedData}
              banksNames={banksNames}
              selectedData={selectedData}
              setName={setName}
            />
          </div>

          <div className="cashout-column">
            <CashoutForm
              method={method}
              banksNames={banksNames}
              amount={amount}
              selectedData={selectedData}
              selectData={setSelectedData}
              setShowModal={setShowModal}
              setShowModalTwo={setShowModalTwo}
              setName={setName}
              name={name}
              setMobile={setMobile}
              mobile={mobile}
            />
          </div>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            className="wallet-confirm"
          >
            <Modal.Body>
              {method !== "BANK" ? (
                <div
                  className="confirm-modal"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  <h3>
                    {t("Sending")} {amount} {t("EGP")} {t("to")}{" "}
                    {selectedData.phone.phone} ?!
                  </h3>
                  <div className="confirm-buttons">
                    <button
                      className="btn btnSec"
                      onClick={() => setShowModal(false)}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btnPrimary"
                      onClick={() => cashout()}
                    >
                      {t("Confirm")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="confirm-modal">
                  <h3>
                    {t("Transfer")} {amount} {t("EGP")} {t("to")}{" "}
                    {selectedData.bankAccountNumber} ?!
                  </h3>
                  <div className="confirm-buttons">
                    <button
                      className="btn btnSec"
                      onClick={() => setShowModal(false)}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btnPrimary"
                      onClick={() => cashout()}
                    >
                      {t("Confirm")}
                    </button>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModalTwo}
            className="wallet-confirm"
          >
            <Modal.Body>
              <div className="soon-modal">
                <h3>{t("Coming soon, stay tuned")}</h3>
                <button
                  className="btn btnPrimary"
                  onClick={() => setShowModalTwo(false)}
                >
                  {t("Confirm")}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    request: state.request,
    errors: state.error,
    auth: state.auth,
    wallet: state.wallet.wallet,
    lang: state.lang.lang
  };
};

export default connect(mapStateToProps, { listRequests, cashout })(CashOut);
