import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form"
import PhoneInput from 'react-phone-input-2'
import api from '../../../../../apis/api';
import cogoToast from 'cogo-toast';
import { loadCompanyData } from '../../../../../actions/companyActions';

import './companyProfile.css'
import 'react-phone-input-2/lib/style.css'

const CompanyProfile = () => {

  const role = useSelector(state => state.auth.user.role)
  const company = useSelector(state => state.company.company)

  const [phone, setPhone] = useState(company.phone)
  const [accOwner, setAccOwner] = useState(company.accountOwner)

  const { register, errors, handleSubmit } = useForm()

  const { t } = useTranslation();

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCompanyData())
  }, [])

  const onSubmit = (data) => {
    data.phone = phone
    console.log(data);

    api({
      url: '/company/edit',
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
          'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
      },
      data: data,
    })
    .then((res) => {
      if (res.data.status === 1) {
        console.log(res);
        dispatch(loadCompanyData())
        cogoToast.success('Company profile updated successfully')
      } else {
        cogoToast.error('Company profile update failed')
      }
    })
    .catch((err) => {});
  }

  return (
    <div className='mainPage'>
      <div className='company-profile'>
        <h2>{t('Edit Company Profile')}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row wide">
            <label>{t('Country')}</label>
            <input className="form-control" autoComplete="off" defaultValue={company.country} name="country" disabled />
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t('city')}</label>
              <input type="text" autoComplete="off" defaultValue={company.city} className="form-control" name="city" ref={register({ required: true })} />
              {errors.city && <p>city is required</p>}
            </div>
            <div className="row-element">
              <label>{t('pcode')}</label>
              <input type="text" autoComplete="off" defaultValue={company.postCode} className="form-control" name="postCode" ref={register({ required: true })} />
              {errors.postCode && <p>Postal code is required</p>}
            </div>
          </div>
          <div className="form-row wide">
            <label>{t('companyLegal')}</label>
            <input className="form-control" autoComplete="off" defaultValue={company.legalName} name="legalName" ref={register({ required: true })} />
            {errors.legalName && <p>Company legal name is required</p>}
          </div>
          <div className="form-row wide">
            <label>{t('website')}</label>
            <input className="form-control" autoComplete="off" defaultValue={company.website} name="website" ref={register({ required: true })} />
            {errors.website && <p>Company website is required</p>}
          </div>
          <div className="form-row wide">
            <label>{t('officeAddress')}</label>
            <input className="form-control" autoComplete="off" defaultValue={company.address} name="address" ref={register({ required: true })} />
            {errors.address && <p>Company address is required</p>}
          </div>
          <div className="form-row wide">
            <label>{t('Office Phone Number (Optional)')}</label>
            <PhoneInput country={'eg'} value={phone} onChange={(val) => setPhone(val)} className='wide'/>
          </div>
          <button type='submit' className='btn btnPrimary'>{t('Update Company Details')}</button>
        </form>
        {role && role === 'manager' ? (
          <div className='ownership'>
            <h2>{t('Company Account Ownership')}</h2>
            <div className="form-row wide">
              <label>{t('Account Owner')}</label>
              <input className="form-control" autoComplete="off" value={accOwner} disabled/>
            </div>
            <p style={{color: '#748494', marginTop: '-20px'}}>{t('You can transfer the account ownership to another user')}</p>
            <h5 style={{color: '#1292ee', marginTop: '30px', cursor: 'pointer'}}>{t('Transfer Account Ownership')}</h5>
          </div>
        ):('')}
      </div>
    </div>
  );
};

export default CompanyProfile;