const INITIAL_STATE = {
  plans: [],
  currentPlan: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_PLANS":
      return {
        ...state,
        plans: action.payload.plans,
        currentPlan: action.payload.currentPlan,
      };
    default:
      return state;
  }
};
