import { LIST_APPROVED_REQUESTS, LIST_APPROVED_INADVANCE_REQUESTS ,LIST_RECONCILED_INADVANCE_REQUESTS} from '../actions/types';

const INITIAL_STATE = {
  approveds: [],
  exel: [],
  approvedsInAdvance: [],
  reconciledInAdvance: [],
};

let exel;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_APPROVED_REQUESTS:
      exel = [];
      if (action.payload) {
        for (const iterator of action.payload) {
          exel = [...exel, ...iterator.pending.requests];
        }
        for (let iterator of exel) {
          if (iterator.from) {
            iterator.name =
              iterator.from.firstName + ' ' + iterator.from.lastName;
          } else {
            iterator.name =
              iterator.fromManager.firstName +
              ' ' +
              iterator.fromManager.lastName;
          }
        }
      }
      return {
        ...state,
        approveds: action.payload,
        exel: exel,
      };

    case LIST_APPROVED_INADVANCE_REQUESTS:
      exel = [];
      if (action.payload) {
        for (const iterator of action.payload) {
          exel = [...exel, ...iterator.pending.requests];
        }
        for (let iterator of exel) {
          if (iterator.from) {
            iterator.name =
              iterator.from.firstName + ' ' + iterator.from.lastName;
          } else {
            iterator.name =
              iterator.fromManager.firstName +
              ' ' +
              iterator.fromManager.lastName;
          }
        }
      }
      return {
        ...state,
        approvedsInAdvance: action.payload,
        exel: exel,
      };

    case LIST_RECONCILED_INADVANCE_REQUESTS:
      exel = [];
      if (action.payload) {
        for (const iterator of action.payload) {
          exel = [...exel, ...iterator.pending.requests];
        }
        for (let iterator of exel) {
          if (iterator.from) {
            iterator.name =
              iterator.from.firstName + ' ' + iterator.from.lastName;
          } else {
            iterator.name =
              iterator.fromManager.firstName +
              ' ' +
              iterator.fromManager.lastName;
          }
        }
      }
      return {
        ...state,
        reconciledInAdvance: action.payload,
        exel: exel,
      };

    default:
      return state;
  }
};
