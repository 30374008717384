import { SET_LANG } from "../actions/types";
import i18n from "../translations/i18n";

let INITIAL_STATE = {
  lang: "en"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANG:
      i18n.changeLanguage(state.lang == "ar" ? "en" : "ar");
      document.body.dir = i18n.dir();
      localStorage.setItem("lang", localStorage.getItem("i18nextLng"));

      const element = document.getElementById("body");
      // i18n.language == 'ar'
      //   ? element.setAttribute('style', "font-family: 'Almarai';")
      //   : element.setAttribute('style', "font-family: 'Almarai';");
      return {
        lang: state.lang == "ar" ? "en" : "ar"
      };

    default:
      return state;
  }
};
