import React from "react";
import { Link } from "react-router-dom";
import withAsyncChecks from "../../../hoc/withAsyncChecks";

import styles from "./styles/fixed.module.css";
import PayrollList from "../../../components/PayrollList/PayrollList";
import { links } from "../../../utils/links";

const FixedPayrolls = ({ data: payrolls }) => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Fixed rate payrolls</h2>
        <div className={styles.links_wrapper}>
          <Link to={links.teams} className={styles.link_outline}>
            Edit payroll teams
          </Link>

          <Link to={links.fixedLinks.create} className={styles.link_contained}>
            Add new payroll
          </Link>
        </div>
      </div>
      <PayrollList payrolls={payrolls} type="fixed" />
    </section>
  );
};

export default withAsyncChecks(FixedPayrolls);
