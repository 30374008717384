import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resendMail } from "../../../../actions/companyActions";
import { useTranslation } from "react-i18next";

import "./setupFinished.css";

const SetupFinished = (props) => {
  const { t } = useTranslation();

  return (
    <div className="setupFinished">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 text-center">
            {/* <Link to="/" className="btn">
                <h2>
                  Holly<span className="green-color">desk.</span>
                </h2>
              </Link> */}
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="row col-4 justify-content-center text-center">
            <div className="col-10">
              <img
                className="img-fluid mb-5 mt-4"
                src="images/mirage-welcome@3x.png"
                alt="hollydesk"
              />
            </div>
          </div>

          <div className="col-9 text-center">
            <h1>
              <b>
                {t("hello")}{" "}
                <span className="green-color">{props.company.managerName}</span>{" "}
                👋! {t("Your Company Account Is All Set Up!")}
              </b>
            </h1>
            <p className="lead">
              {t("Congrats! Now it’s time to complete your work profile info!")}
            </p>
            <a className="btn btnPrimary col-md-4" href="/login">
              {t("Complete My profile")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

export default connect(mapStateToProps, { resendMail })(SetupFinished);
