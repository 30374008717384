import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { changePassword } from '../../../../../actions/userActions';
import cogoToast from 'cogo-toast';
import api from '../../../../../apis/api';
import { useTranslation } from 'react-i18next';

const Profile = (props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [Password, setPassword] = useState('');

  const [rPassword, setRPassword] = useState('');
  const { t } = useTranslation();

  const changePass = () => {
    if (Password === rPassword) {
      let config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let token = localStorage.getItem('token');

      if (token) {
        config.headers['x-auth-token'] = token;
      }

      let data = {
        oldPassword: oldPassword,
        password: Password,
        rPassword: rPassword,
      };

      api
        .post(`/user/changePassword`, JSON.stringify(data), config)
        .then((res) => {
          if (res.data.status === 'wrong password') {
            cogoToast.warn('Old password is wrong ');
          } else if (res.data.status === 'correct password') {
            cogoToast.success('Password changed');
          } else {
          }
        })
        .catch((err) => {});
    } else {
      cogoToast.warn('Password does not match');
    }
  };

  return (
    <div className="mainPage wallet">
      <div className="container-fluid ">
        <div className="inputComp col-4">
          <label
            htmlFor="old"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {t('oldPass')}
          </label>
          <input
            className="form-control"
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            value={oldPassword}
            id="old"
          />
        </div>
        <div className="inputComp col-4">
          <label
            htmlFor="new"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {t('newPass')}
          </label>
          <input
            className="form-control"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={Password}
            id="new"
          />
        </div>
        <div className="inputComp col-4">
          <label
            htmlFor="r"
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {t('rNewPass')}
          </label>
          <input
            className="form-control"
            onChange={(e) => {
              setRPassword(e.target.value);
            }}
            value={rPassword}
            id="r"
          />
        </div>
        <div
          className="col-md-12 mt-3"
          style={
            localStorage.getItem('i18nextLng') == 'ar'
              ? { width: '100%', textAlign: 'right' }
              : { width: '100%', textAlign: 'left' }
          }
        >
          <button className="btn btn-outline-success" onClick={changePass}>
            {t('changePassword')}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error,
  };
};

export default connect(mapStateToProps, { changePassword })(Profile);
