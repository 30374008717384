import React from 'react';
import { useTranslation } from 'react-i18next';

import './underConstructionPage.css'

const UnderConstructionPage = () => {

  const { t } = useTranslation();

  return (
    <div className='mainPage'>
      <div  className='underConstruction'>
        <img src='/images/under_construction.png' alt='underConstruction'/>
        <h1 style={{color: '#1a242e'}}>{t('This page is under construction!')}</h1>
        <h4>{t('We’re working hard on it, come back later 👋')}</h4>
      </div>
    </div>
  );
};

export default UnderConstructionPage;