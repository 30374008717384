import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "antd/lib/input";
import { login } from "../../../../../actions/authActions";
import cogoToast from "cogo-toast";
import { useDispatch } from "react-redux";
import logo from "../../../../../assets/images/logo.png";

import "./loginForm.css";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onSubmitHandler = e => {
    e.preventDefault();
    if (email === "") return cogoToast.warn("Please, Enter your email");
    if (password === "") return cogoToast.warn("Please, Enter your password");
    dispatch(login({ email, password }));
  };

  return (
    <div className="loginForm-wrapper">
      <form className="loginForm">
        <a href="https://www.hollydesk.com/">
          <img className="login-logo" src={logo} alt="logo" id="logo" />
        </a>
        <h1 className="section-title loginform-title" id="loginform-title">
          {t("Welcome again,")}
          <br />
          {t("Log in to your account")}.
        </h1>
        <div className="form-element-full">
          <label id="login-form-email-label">{t("Your Email Address")}</label>
          <Input
            id="login-form-email-input"
            placeholder={t("Ex: someone@hollydesk.com")}
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="form-element-full">
          <label id="login-form-password-label">{t("Your Password")}</label>
          <Input.Password
            id="login-form-password-input"
            placeholder={t("Ex: ***************")}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <Link
          to="/forget-password"
          className="forget-pass-link"
          id="forget-pass-link"
        >
          {t("Forgot Password!")}
        </Link>
        <button
          className="btn btnPrimary login-btn"
          id="login-btn"
          onClick={e => onSubmitHandler(e)}
        >
          {t("Log in to my account")}
        </button>
        <span className="login-register" id="login-register">
          {t("I don’t have an account,")}{" "}
          <Link
            to="/create"
            className="login-register-link"
            id="login-register-link"
          >
            {t("Register Your Company")}
          </Link>
        </span>
      </form>
    </div>
  );
};

export default LoginForm;
