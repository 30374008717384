import {
  REGISTER_COMPANY,
  VERIFY_EMAIL,
  VERIFY_FAIL,
  ADD_COMPANY_DETAILS,
  RESEND_MAIL,
  SEND_INVITE,
  ADD_BANK,
  SKIP_BANK,
  ADD_SIGNATURE,
  LIST_TRANSACTIONS,
} from "../actions/types";

const INITIAL_STATE = {
  registered: false,
  emailVerified: undefined,
  token: undefined,
  id: null,
  status: 0,
  legalName: "",
  emailResent: false,
  details: undefined,
  invites: [],
  managerName: undefined,
  transactions: [],
  company: {},
  contries: [],
  savedCards: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_COMPANY:
      return {
        ...state,
        registered: true,
        token: action.payload.token,
        id: action.payload.id,
        managerName: action.payload.name,
        emailResent: false,
      };
    case RESEND_MAIL:
      return {
        ...state,
        emailResent: true,
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        emailVerified: true,
        emailResent: false,
        status: Number(action.payload.status),
        legalName: action.payload.legalName,
      };
    case VERIFY_FAIL:
      return {
        ...state,
        emailVerified: false,
        emailResent: false,
        status: 0,
      };
    case ADD_COMPANY_DETAILS:
      return {
        ...state,
        details: action.payload,
        emailResent: false,
        status: Number(action.payload.status),
      };
    case ADD_BANK:
      return {
        ...state,
        emailResent: false,
        status: Number(action.payload.status),
      };
    case SKIP_BANK:
      return {
        ...state,
        emailResent: false,
        status: Number(80),
      };
    case ADD_SIGNATURE:
      return {
        ...state,
        emailResent: false,
        status: Number(action.payload.status),
      };
    case SEND_INVITE:
      return {
        ...state,
        invites: [action.payload, ...state.invites],
      };
    case LIST_TRANSACTIONS:
      return {
        ...state,
        transactions: [...action.payload],
      };
    case "COMPANY_DATA":
      return {
        ...state,
        company: action.payload,
      };
    case "COUNTRIES":
      return {
        ...state,
        contries: action.payload,
      };
    case "GET_SAVED_CARDS":
      return {
        ...state,
        savedCards: action.payload,
      };
    default:
      return state;
  }
};
