import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { Link, useHistory, withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { addSupplier } from "../../../../../../../actions/requestsActions";
import api from "../../../../../../../apis/api";
import { HiBadgeCheck } from "react-icons/hi";
import { Modal } from "react-bootstrap";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import "./index.css";

const MyRequests = props => {
  const history = useHistory();

  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [request, setRequest] = useState({});
  const [ID, setID] = useState("");
  const [forword, setForword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [spin1, setSpin1] = useState(false);
  const [spin2, setSpin2] = useState(false);
  const [spin3, setSpin3] = useState(false);
  const currency = useSelector(state => state.auth.user.currency[0]);

  const getData = () => {
    api({
      url: `/supplier/teams/?id=${props.location.search.split("?num=")[1]}`,
      method: "get",

      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        console.log(res.data);
        setTeams(res.data.data);
      })
      .catch(err => {});
  };

  const getRequstData = () => {
    api({
      url: `/supplier/single-request/${
        props.location.search.split("?num=")[1]
      }`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        res.data.data.request.paidOn = res.data.data.request.paidOn.slice(
          0,
          10
        );
        res.data.data.request.due = res.data.data.request.due.slice(0, 10);
        setRequest(res.data.data.request);
        setID(res.data.data.request.from.id);
        setForword(res.data.data.forword);
      })
      // .then(() => {
      //   setRequest({ paidOn: request.paidOn.slice(1, 10) });
      //   setRequest({ due: request.due.slice(1, 10) });
      // })
      .catch(err => {});
  };

  useEffect(() => {
    getData();
    getRequstData();
    console.log(props);
  }, []);

  const onSubmitHandler = () => {
    let sTeam = [];

    for (const iterator of teams) {
      if (iterator.selected == true) {
        sTeam.push(iterator);
      }
    }

    api({
      url: "/supplier/approve",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      },
      data: { teams: sTeam, request: request._id }
    })
      .then(res => {
        if (res.data.res == "suc") {
          setSpin1(false);
          cogoToast.success(
            sTeam.length === 0
              ? "Request Approved Successfuly"
              : "Request Forwarded Successfuly"
          );
          history.push(
            `/dashboard/suppliers/reimbursment/success?num=${
              props.location.search.split("?num=")[1]
            }`
          );
        } else setSpin1(false);
      })
      .catch(err => {
        setSpin1(false);
      });
  };

  const onDecline = () => {
    let sTeam = [];

    for (const iterator of teams) {
      if (iterator.selected == true) {
        sTeam.push(iterator);
      }
    }

    api({
      url: "/supplier/decline",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      },
      data: { teams: sTeam, request: request._id }
    })
      .then(res => {
        if (res.data.res == "suc") {
          setSpin3(false);
          cogoToast.success("Request Declined");
          history.push(`/dashboard/suppliers/expenses/pending`);
        } else setSpin3(false);
      })
      .catch(err => {
        setSpin3(false);
      });
  };

  const onForword = () => {
    api({
      url: "/supplier/forword-to-cto",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      },
      data: { request: request._id }
    })
      .then(res => {
        if (res.data.res == "suc") {
          setSpin2(false);
          cogoToast.success("Request Forworded");
          history.push(`/dashboard/suppliers/reimbursment`);
        } else setSpin2(false);
      })
      .catch(err => {
        setSpin2(false);
      });
  };

  const userActions = () => {
    if (
      props.auth.user.role === "accountant" &&
      forword &&
      request.from.verified
    ) {
      return (
        <div className="supplier-user-actions">
          {spin1 ? (
            <button type="button" disabled className="btn btnPrimary">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnPrimary"
              onClick={e => {
                e.preventDefault();
                setSpin1(true);
                onSubmitHandler();
              }}
            >
              {t("Approve")} {request.amount} {t(currency)}
            </button>
          )}
          {spin2 ? (
            <button type="button" disabled className="btn btnPrimary">
              <LoadingOutlined />
            </button>
          ) : (
            <a
              className="btn btnPrimary"
              onClick={() => {
                setSpin2(true);
                onForword();
              }}
            >
              {t("Forward To Account Owner")}
            </a>
          )}
          {spin3 ? (
            <button type="button" disabled className="btn btnSec ml-0">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnSec ml-0"
              onClick={e => {
                e.preventDefault();
                setSpin3(true);
                onDecline();
              }}
            >
              {t("Decline")}
            </button>
          )}
          <p className="btn btnPrimary" onClick={() => history.goBack()}>
            {t("Back")}
          </p>
        </div>
      );
    } else if (
      props.auth.user.role === "accountant" &&
      forword &&
      !request.from.verified
    ) {
      return (
        <div className="supplier-user-actions">
          {spin1 ? (
            <button type="button" disabled className="btn btnPrimary">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnPrimary"
              onClick={e => {
                e.preventDefault();
                setSpin1(true);
                setShowModal(true);
              }}
            >
              {t("Approve")} {request.amount} {t(currency)}
            </button>
          )}
          {spin2 ? (
            <button type="button" disabled className="btn btnPrimary">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnPrimary"
              onClick={() => {
                setSpin2(true);
                onForword();
              }}
            >
              {t("Forward To Account Owner")}
            </button>
          )}
          {spin3 ? (
            <button type="button" disabled className="btn btnSec ml-0">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnSec ml-0"
              onClick={e => {
                e.preventDefault();
                setSpin3(true);
                onDecline();
              }}
            >
              {t("Decline")}
            </button>
          )}
          <p className="btn btnPrimary" onClick={() => history.goBack()}>
            {t("Back")}
          </p>
        </div>
      );
    }

    if (props.auth.user.role === "manager") {
      return (
        <div className="supplier-user-actions">
          {spin1 ? (
            <button type="button" disabled className="btn btnPrimary">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnPrimary"
              onClick={e => {
                e.preventDefault();
                setSpin1(true);
                onSubmitHandler();
              }}
            >
              {t("Approve")} {request.amount} {t(currency)}
            </button>
          )}
          {spin3 ? (
            <button type="button" disabled className="btn btnSec ml-0">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnSec ml-0"
              onClick={e => {
                e.preventDefault();
                setSpin3(true);
                onDecline();
              }}
            >
              {t("Decline")}
            </button>
          )}
          <p className="btn btnPrimary" onClick={() => history.goBack()}>
            {t("Back")}
          </p>
        </div>
      );
    }
    if (props.auth.user.role === "team-manager" && forword) {
      return (
        <div className="supplier-user-actions">
          {spin1 ? (
            <button type="button" disabled className="btn btnPrimary">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnPrimary"
              onClick={e => {
                e.preventDefault();
                setSpin1(true);
                onSubmitHandler();
              }}
            >
              {t("Approve")} {request.amount} {t(request.currency)}
            </button>
          )}
          {spin3 ? (
            <button type="button" disabled className="btn btnSec ml-0">
              <LoadingOutlined />
            </button>
          ) : (
            <button
              className="btn btnSec ml-0"
              onClick={e => {
                e.preventDefault();
                setSpin3(true);
                onDecline();
              }}
            >
              {t("Decline")}
            </button>
          )}
          <p className="btn btnPrimary" onClick={() => history.goBack()}>
            {t("Back")}
          </p>
        </div>
      );
    } else {
      return (
        <div className="supplier-user-actions">
          <p className="btn btnPrimary" onClick={() => history.goBack()}>
            {t("Back")}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="mainPage">
      <div className="container-fluid">
        <div className="row justify-content-center align-self-center supplier-req-rev">
          <div className="col-12 row ">
            <br />
            <h5
              style={{
                fontWeight: "900",
                textAlign: "center",
                fontSize: "32px",
                width: "100%"
              }}
              className="my-5"
            >
              {t("Supplier Request")} #{request.number}
            </h5>
            <form className="md-form">
              <br />

              <h4
                className="mb-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left", fontWeight: "900" }
                }
              >
                {t("1- Invoice Information")}
              </h4>

              <div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Total Amount Due")}
                    </label>
                    <input
                      className="form-control"
                      value={request.amount}
                      disabled
                    />
                    <br />
                  </div>
                  <div className="col-md-6">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Issued On")}
                    </label>
                    <input
                      className="form-control"
                      value={request["paidOn"]}
                      disabled
                    />
                    <br />
                  </div>
                  <div className="col-md-6">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Due")}
                    </label>
                    <input
                      className="form-control"
                      value={request["due"]}
                      disabled
                    />
                    <br />
                  </div>

                  <div className="col-md-12">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Service Paied For")}
                    </label>

                    <input
                      className="form-control"
                      value={request.paidFor}
                      disabled
                    />
                  </div>
                  <br />
                </div>
              </div>
              {props.auth.user.role !== "user" && forword ? (
                <div>
                  <label
                    className={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? "arLabel"
                        : "enLabel"
                    }
                  >
                    {t("ForwardTo")}
                  </label>
                  <div
                    className="row"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    {teams.map((t, i) => (
                      <div className="col-6 my-2 ">
                        <div className="round_ my-auto">
                          <input
                            type="checkbox"
                            name="teams"
                            onChange={() => {
                              teams[i].selected = !teams[i].selected;
                            }}
                            id={i}
                            value={t._id}
                            key={i}
                            checked={i.selected}
                          />
                          <label htmlFor={i} key={i} className="p-1">
                            {t.name[0]}
                          </label>
                        </div>
                        <p
                          className=" py-auto mt-1 ml-2 mr-2"
                          style={{
                            display: "inline-block",
                            margin: "auto",
                            position: "absolute"
                          }}
                        >
                          {t.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}

              <hr />
              <h4
                className="mb-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left", fontWeight: "900" }
                }
              >
                {t("2- Supplier Information")}
              </h4>
              <br />
              <div
                className="row p-0"
                style={{
                  borderRadius: "4px",
                  border: "solid 0.5px #c5ced6",
                  backgroundColor: "#f9fafa"
                }}
              >
                <div
                  className="row col-12  m-0"
                  style={{
                    border: "solid 1px #c5ced6",
                    backgroundColor: "#fff",
                    borderRadius: "4px 4px 0px 0px"
                  }}
                >
                  <div className="m-3" style={{ width: "fit-content" }}>
                    <img
                      src={
                        request && request.from && request.from.companyLogo
                          ? request.from.companyLogo.path
                          : ""
                      }
                      className="col-12"
                      style={{
                        height: "50px",
                        width: "auto",
                        padding: "0px",
                        margin: "auto"
                      }}
                    ></img>
                  </div>
                  <div
                    className="my-3"
                    style={{
                      width: "fit-content",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <h4 style={{ marginBottom: "0px" }}>
                      {request.from ? request.from.nameEnglish : ""}
                    </h4>
                    &nbsp;
                    <HiBadgeCheck
                      style={
                        request.from && request.from.verified === true
                          ? { fontSize: "1.3rem", color: "#268c55" }
                          : { fontSize: "1.3rem", color: "#93a1b0" }
                      }
                    />
                  </div>
                </div>
                {request.from && request.from.verified === true ? (
                  <div
                    className="col-12"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    <h5 className="my-3">{t("Registered Supplier")}</h5>
                    <p style={{ fontSize: "12px" }}>
                      {t("regSuppWar")}
                      <Link
                        to={`/dashboard/suppliers/view-supplier?id=${ID}?num=${
                          props.location.search.split("?num=")[1]
                        }`}
                      >
                        {t("View Supplier Application")}
                      </Link>
                    </p>
                  </div>
                ) : (
                  <div
                    className="col-12"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                  >
                    <h5 className="my-3">{t("Unregistered Supplier")}</h5>
                    <p style={{ fontSize: "12px" }}>
                      {t("UnregSuppWar")}
                      <Link
                        to={`/dashboard/suppliers/view-supplier?id=${ID}?num=${
                          props.location.search.split("?num=")[1]
                        }`}
                      >
                        {t("View Supplier Application")}
                      </Link>
                    </p>
                  </div>
                )}
              </div>
              <hr />
              <h4
                className="mb-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left", fontWeight: "900" }
                }
              >
                {t("3- Invoices")}
              </h4>
              {request.images
                ? request.images.map(i => (
                    <div
                      className=" p-3 row"
                      style={{
                        height: "55px",
                        border: "solid 1px #c5ced6",
                        backgroundColor: "#f1f3f5",
                        borderRadius: "4px"
                      }}
                    >
                      <b style={{ color: "green" }} className="col-9">
                        {i.name.substring(0, 10)}...
                      </b>
                      <a
                        style={{ color: "green" }}
                        className="col-3"
                        href={i.path}
                        download
                      >
                        {t("Down")}
                      </a>
                    </div>
                  ))
                : ""}
              <hr />
              {userActions()}
            </form>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        className="wallet-confirm"
      >
        <Modal.Body>
          <div
            className="confirm-modal"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { textAlign: "right" }
                : { textAlign: "left" }
            }
          >
            <p>{t("You can only accept requests from verified suppliers!")}</p>
            <p>
              {t(
                "View and verify all supplier's documents to add him to your suppliers' list"
              )}
            </p>
            <div className="confirm-buttons">
              <button
                className="btn btnSec"
                onClick={() => setShowModal(false)}
              >
                {t("Cancel")}
              </button>
              <button
                className="btn btnPrimary"
                onClick={() => {
                  history.push(`/dashboard/suppliers/view-supplier?id=${ID}`);
                }}
              >
                {t("view")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const validateForm = formValues => {
  const errors = {};

  if (!formValues.amount) {
    errors.amount = "Please add amount";
  }

  if (!formValues.currency) {
    errors.currency = "Please add currency";
  }

  if (!formValues.paidTo) {
    errors.paidTo = "Please add paidTo";
  }

  if (!formValues.date) {
    errors.date = "Please add date";
  }

  if (!formValues.paidFor) {
    errors.paidFor = "Please add paidFor";
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    request: state.request,
    errors: state.error,
    lang: state.lang.lang,
    upload: state.upload
  };
};

const reduxMiddleware = reduxForm({
  form: "addRequest",
  validate: validateForm,
  enableReinitialize: true
})(MyRequests);

export default connect(mapStateToProps, { addSupplier })(
  withRouter(reduxMiddleware)
);
