let countries = {
  Egypt: [
    'Abu Hammad',
    'Al Mahallah al Kubra',
    'Al Mansurah',
    'Al Marj',
    'Alexandria',
    'Almazah',
    'Ar Rawdah',
    'Assiut',
    'Az Zamalik',
    'Badr',
    'Banha',
    'Bani Suwayf',
    'Cairo',
    'Damietta',
    'Faraskur',
    'Flaminj',
    'Giza',
    'Heliopolis',
    'Helwan',
    'Hurghada',
    'Ismailia',
    'Kafr ash Shaykh',
    'Luxor',
    'Madinat an Nasr',
    'Madinat as Sadis min Uktubar',
    'Minya',
    'Nasr',
    'New Cairo',
    'Port Said',
    'Rafah',
    'Ramsis',
    'Sadat',
    'Shirbin',
    'Shubra',
    'Sohag',
    'Suez',
    'Tanta',
    'Toukh',
    'Zagazig',
  ],
  'Saudi Arabia': [
    'Abha',
    'Abqaiq',
    'Al Bahah',
    'Al Faruq',
    'Al Hufuf',
    'Al Qatif',
    'Al Yamamah',
    'At Tuwal',
    'Buraidah',
    'Dammam',
    'Dhahran',
    'Hayil',
    'Jazirah',
    'Jazirah',
    'Jeddah',
    'Jizan',
    'Jubail',
    'Khamis Mushait',
    'Khobar',
    'Khulays',
    'Linah',
    'Madinat Yanbu` as Sina`iyah',
    'Mecca',
    'Medina',
    'Mina',
    'Najran',
    'Rabigh',
    'Rahimah',
    'Rahman',
    'Ramdah',
    'Ras Tanura',
    'Riyadh',
    'Sabya',
    'Safwa',
    'Sakaka',
    'Sambah',
    'Sayhat',
    'Tabuk',
    'Yanbu` al Bahr',
  ],
};
export default countries;
