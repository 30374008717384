import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { useHistory, withRouter } from "react-router-dom";
import { editSupplier } from "../../../../../../actions/requestsActions";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";
import { useForm } from "react-hook-form";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import countries from "../countries";
import Upload from "../../../../components/requests/UploadImage/upload";

const SupplierView = props => {
  const { t } = useTranslation();
  const [country, setCountry] = useState("Egypt");
  const [teams, setTeams] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [disable, setDisable] = useState(true);
  const [allowed, setAllowed] = useState(true);
  const [registration, setRegistration] = useState({});
  const [license, setLicense] = useState({});
  const [VAT, setVAT] = useState({});
  const [logo, setLogo] = useState({});
  const [mobile, setMobile] = useState({
    short: "EG",
    phone: ""
  });
  const [phoneErr, setPhoneErr] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const history = useHistory();

  const registrationHandler = value => setRegistration(value);
  const licenseHandler = value => setLicense(value);
  const VATHandler = value => setVAT(value);
  const logoHandler = value => setLogo(value);

  useEffect(() => {
    getData();
    getSupplierData();
  }, []);

  const getData = () => {
    api({
      url: "/dashboard/teams",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        let teams = [];
        res.data.teams.forEach(value => {
          value.selected = false;
          value.active == true ? teams.push(value) : teams.sort();
        });
        setTeams(teams);
      })
      .catch(err => {});
  };

  const getSupplierData = () => {
    api({
      url: `/supplier/get-supplier/${props.location.search.split("?id=")[1]}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        setSupplier(res.data.supplier);
        let editedNumber = res.data.supplier.phone;
        editedNumber.length === 12 &&
          editedNumber[0] === "2" &&
          editedNumber[1] === "0" &&
          (editedNumber = res.data.supplier.phone.substring(2));
        setMobile({ short: "EG", phone: editedNumber, code: "20" });
        setRegistration(
          res.data.supplier.registration ? res.data.supplier.registration : {}
        );
        setLicense(res.data.supplier.license ? res.data.supplier.license : {});
        setVAT(res.data.supplier.vat ? res.data.supplier.vat : {});
        setLogo(
          res.data.supplier.companyLogo ? res.data.supplier.companyLogo : {}
        );
      })
      .catch(err => {});
  };

  const verifySupplier = () => {
    api({
      url: `supplier/verify-supplier`,
      method: "post",
      data: { supplierID: supplier.id },
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          cogoToast.success("Supplier Added To successfully!");
          history.goBack();

          getSupplierData();
        } else {
          cogoToast.error("Can not get data");
        }
      })
      .catch(err => {});
  };

  const onSubmit = formData => {
    let values = { ...formData };
    let id = props.location.search.split("?id=")[1].split("?num=")[0];
    values["_id"] = id;
    values.registration = registration;
    values.license = license;
    values.vat = VAT;
    values.companyLogo = logo;

    let correctedMobile = mobile.phone;
    let sentNumber = mobile.code + correctedMobile;
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length !== 10 &&
      mobile.phone.length !== 11
    )
      return cogoToast.error("Please, Enter valid mobile number");
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = mobile.code + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 11
    ) {
      correctedMobile = mobile.phone.substring(1);
      sentNumber = mobile.code + correctedMobile;
    }
    values.phone = sentNumber;

    if (disable) {
      setDisable(false);
      setAllowed(false);
    } else props.editSupplier(values);
  };

  return (
    <div className="mainPage">
      <div className="container-fluid">
        <p
          onClick={() => history.goBack()}
          style={{ cursor: "pointer", color: "#1292ee", width: "fit-content" }}
        >
          <i className="fas fa-arrow-left"></i> &nbsp; {t("Back")}
        </p>
        <form className="md-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row justify-content-center align-self-center supplier-req-rev">
            <div className="col-12 row ">
              <h4
                className="mb-4 mt-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : {
                        width: "100%",
                        textAlign: "left",

                        fontWeight: "900",
                        fontSize: "23px"
                      }
                }
              >
                {t("1 - Supplier Company Information")}
              </h4>
              <br />
              <div className="row">
                <div className="inputComp col-12">
                  <label
                    className={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? "arLabel"
                        : "enLabel"
                    }
                  >
                    {t("Company Legal Name (Arabic)")}
                  </label>
                  <input
                    placeholder={t("Arabic Legal Name")}
                    name="nameArabic"
                    defaultValue={supplier.nameArabic}
                    ref={register({
                      required: true,
                      pattern: /\S/
                    })}
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    disabled={disable}
                  />
                  {errors.nameArabic && (
                    <span className="text-danger">
                      {t("This field is required")}
                    </span>
                  )}
                  <br />
                </div>

                <div className="inputComp col-12">
                  <label
                    className={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? "arLabel"
                        : "enLabel"
                    }
                  >
                    {t("Company Legal Name (English)")}
                  </label>
                  <input
                    placeholder={t("English Legal Name")}
                    defaultValue={supplier.nameEnglish}
                    name="nameEnglish"
                    ref={register({
                      required: true,
                      pattern: /\S/
                    })}
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    disabled={disable}
                  />
                  {errors.nameEnglish && (
                    <span className="text-danger">
                      {" "}
                      {t("This field is required")}
                    </span>
                  )}
                  <br />
                </div>

                <div className="col-md-6">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Country")}
                    </label>
                    <select
                      placeholder={t("Enter Country")}
                      name="country"
                      defaultValue={supplier.country}
                      ref={register({
                        required: true
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      onChange={e => setCountry(e.target.value)}
                      disabled={disable}
                    >
                      {Object.keys(countries).map(country => (
                        <option value={country}>{country}</option>
                      ))}
                    </select>
                    {errors.country && (
                      <span className="text-danger">Enter Valid Country</span>
                    )}
                    <br />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("City")}
                    </label>
                    <select
                      placeholder={t("Enter City")}
                      defaultValue={supplier.city}
                      defaultChecked={supplier.city}
                      name="city"
                      ref={register({
                        required: true,
                        pattern: /[A-Za-z]/
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      disabled={disable}
                    >
                      {countries[country].map(country => (
                        <option
                          value={country}
                          selected={country == supplier.city}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                    {errors.city && (
                      <span className="text-danger">Enter Valid City</span>
                    )}
                    <br />
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Industry")}
                    </label>
                    <input
                      placeholder={t("Company Industry")}
                      defaultValue={supplier.industry}
                      name="industry"
                      ref={register({
                        required: true,
                        pattern: /\S/
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      disabled={disable}
                    />
                    {errors.industry && (
                      <span className="text-danger">
                        {t("Enter Valid Industry")}
                      </span>
                    )}
                    <br />
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Website")}
                    </label>
                    <input
                      placeholder="www.example.com"
                      name="website"
                      disabled={disable}
                      defaultValue={supplier.website}
                      ref={register({
                        required: true,

                        pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.website && (
                      <span className="text-danger">
                        {t("Enter Valid Website")}
                      </span>
                    )}
                    <br />
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Phone")}
                    </label>
                    {/* <input
                      placeholder="+20100000000"
                      name="phone"
                      defaultValue={supplier.phone}
                      ref={register({
                        required: true,
                      })}
                      type="number"
                      className="form-control"
                      autoComplete="off"
                      disabled={disable}
                    />
                    {errors.phone && (
                      <span className="text-danger">Enter Valid Phone</span>
                    )} */}
                    <ConfigProvider
                      locale={en}
                      areaMapper={area => {
                        return {
                          ...area,
                          emoji: (
                            <span
                              className={`fp ${area.short.toLowerCase()}`}
                            />
                          )
                        };
                      }}
                    >
                      <CountryPhoneInput
                        id="mobile-input"
                        type="number"
                        placeholder="1234567890"
                        value={mobile}
                        className="form-control"
                        onChange={v => {
                          setPhoneErr(false);
                          setMobile(v);
                        }}
                        disabled={disable}
                      />
                    </ConfigProvider>
                    <br />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="col-12">
                <hr />
              </div>
              <hr />
              <br />
              <h4
                className="mb-4 mt-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : {
                        width: "100%",
                        textAlign: "left",

                        fontWeight: "900",
                        fontSize: "23px"
                      }
                }
              >
                2- {t("CompanyRegestration")}
              </h4>
              <div className="col-12 justify-content-center align-self-center">
                <div className="row">
                  <Upload
                    name="Regestration"
                    extractedFile={registrationHandler}
                    acceptedFormats={["jpg", "jpeg", "png", "svg", "pdf"]}
                    label={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Upload company registration"
                        : "تحميل البطاقة الضريبية للشركة"
                    }
                    name={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Company registration"
                        : "البطاقة الضريبية للشركة"
                    }
                    edit={!allowed}
                    srcFile={supplier?.registration}
                    isViewSupplier={true}
                  />
                  <Upload
                    name="Regestration"
                    extractedFile={licenseHandler}
                    acceptedFormats={["jpg", "jpeg", "png", "svg", "pdf"]}
                    label={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Upload Commercial License"
                        : "تحميل السجل التجاري للشركة"
                    }
                    name={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Commercial License"
                        : "السجل التجاري للشركة"
                    }
                    edit={!allowed}
                    srcFile={supplier?.license}
                    isViewSupplier={true}
                  />
                  <Upload
                    name="Regestration"
                    extractedFile={VATHandler}
                    acceptedFormats={["jpg", "jpeg", "png", "svg", "pdf"]}
                    label={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Upload VAT certificate"
                        : "تحميل القيمة المضافة للشركة"
                    }
                    name={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "VAT certificate"
                        : "القيمة المضافة للشركة"
                    }
                    edit={!allowed}
                    srcFile={supplier?.vat}
                    isViewSupplier={true}
                  />
                </div>
              </div>
              {/* {supplier.companyDoc
                ? supplier.companyDoc.map((i) => (
                    <div
                      className=" p-3 row col-12 m-1"
                      style={{
                        height: "55px",
                        border: "solid 1px #c5ced6",
                        backgroundColor: "#f1f3f5",
                        borderRadius: "4px",
                      }}
                    >
                      <b style={{ color: "green" }} className="col-9">
                        {i.name.substring(0, 10)}...
                      </b>
                      <a
                        style={{ color: "green" }}
                        className="col-3"
                        href={i.path}
                        download
                      >
                        {t("Down")}
                      </a>
                    </div>
                  ))
                : ""} */}
              <br />
              <br />
              <div className="col-12">
                <hr />
              </div>
              <div className="col-md-12">
                <h4
                  className="mb-4 mt-4"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { width: "100%", textAlign: "right" }
                      : {
                          width: "100%",
                          textAlign: "left",
                          fontWeight: "900",
                          fontSize: "23px"
                        }
                  }
                >
                  3- {t("Supplier Display")}
                </h4>
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                >
                  {t("Company Display Name")}
                </label>
                <input
                  ref={register({
                    required: true,
                    pattern: /\S/
                  })}
                  defaultValue={supplier.nameDisplay}
                  name="displayName"
                  placeholder="ex. HollyDesk"
                  type="tesxt"
                  className="form-control"
                  autoComplete="off"
                  disabled={disable}
                />
                {errors.displayName && (
                  <span className="text-danger">
                    {t("This field is required")}
                  </span>
                )}
                {/* <div
                  className=""
                  style={{ margin: "30px auto", width: "fit-content" }}
                >
                  <img
                    src={supplier.companyLogo ? supplier.companyLogo.path : ""}
                    style={{
                      height: "100px",
                      width: "auto",
                      padding: "0px",
                      margin: "auto",
                    }}
                  ></img>
                </div> */}
                <div className="row mt-3">
                  <div className="col-4"></div>
                  <Upload
                    name="Regestration"
                    extractedFile={logoHandler}
                    acceptedFormats={["jpg", "jpeg", "png", "svg"]}
                    label={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Upload Supplier Logo"
                        : "تحميل صورة شعار المورد"
                    }
                    name={
                      localStorage.getItem("i18nextLng") === "en"
                        ? "Supplier Logo"
                        : "صورة شعار المورد"
                    }
                    edit={!allowed}
                    srcFile={supplier?.companyLogo}
                    isViewSupplier={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-12"></div>
            <hr />

            <div className="col-5">
              <hr />
            </div>
            <div className="col-md-12"></div>

            {props.auth.user.role === "accountant" ? (
              <div className="supplier-user-actions">
                <button
                  className="btn btnPrimary col-12 m-1"
                  onClick={() => verifySupplier()}
                  disabled={supplier.verified}
                >
                  {t("Add Supplier to system")}
                </button>
                <a
                  className="btn btnSec col-12 m-1"
                  href="/dashboard/suppliers/"
                >
                  {t("Cancel")}
                </a>

                {disable ? (
                  <a
                    onClick={() => {
                      setDisable(false);
                      setAllowed(false);
                    }}
                    className="btn btnPrimary col-12 m-1  text-light"
                  >
                    {t("Edit")}
                  </a>
                ) : (
                  <button type="submit" className="btn btnPrimary col-12 m-1  ">
                    {t("Save")}
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const validateForm = formValues => {
  const errors = {};

  if (!formValues.amount) {
    errors.amount = "Please add amount";
  }

  if (!formValues.currency) {
    errors.currency = "Please add currency";
  }

  if (!formValues.paidTo) {
    errors.paidTo = "Please add paidTo";
  }

  if (!formValues.date) {
    errors.date = "Please add date";
  }

  if (!formValues.paidFor) {
    errors.paidFor = "Please add paidFor";
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    request: state.request,
    errors: state.error,
    lang: state.lang.lang,
    upload: state.upload
  };
};

const reduxMiddleware = reduxForm({
  form: "addRequest",
  validate: validateForm,
  enableReinitialize: true
})(SupplierView);

export default connect(mapStateToProps, { editSupplier })(
  withRouter(reduxMiddleware)
);
