import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Menu from 'antd/lib/menu';
import Divider from 'antd/lib/divider';

import { RiShoppingBag3Fill } from 'react-icons/ri';
import { RiHandCoinFill } from 'react-icons/ri';
import { BsFileText } from 'react-icons/bs';
import { RiBubbleChartFill } from 'react-icons/ri';
import { RiWallet3Fill } from 'react-icons/ri';
import { FcSafe } from 'react-icons/fc';
import { RiTeamFill } from 'react-icons/ri';
import { RiUserAddFill } from 'react-icons/ri';
import { RiBuildingFill } from 'react-icons/ri';
import { RiBuilding4Fill } from 'react-icons/ri';
import { RiFilePaper2Fill } from 'react-icons/ri';
import { SiCashapp } from 'react-icons/si';
import { FaUserCircle } from 'react-icons/fa';
import { RiLock2Fill } from 'react-icons/ri';
import { RiMailUnreadFill } from 'react-icons/ri';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { logout } from '../../../../../actions/authActions';

const DrawerMenu = () => {
  const role = useSelector((state) => state.auth.user.role);
  const [openKeys, setOpenKeys] = React.useState(['sub1']);
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5'];

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { SubMenu } = Menu;

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
      {role === 'user' || role === 'team-manager' || role === 'HR' ? (
        ''
      ) : (
        <Menu.Item>
          <Link to="/dashboard/team">{t('dashboard')}</Link>
        </Menu.Item>
      )}

      <SubMenu key="sub1" title={t('Requests')}>
        <Menu.ItemGroup title={t('requests')}>
          <Menu.Item key="0">
            <Link
              className="nav-menu-item"
              to="/dashboard/requests/my-requests"
            >
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#deebf5' }}
              >
                <RiShoppingBag3Fill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('rr')}
            </Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link className="nav-menu-item" to="/dashboard/requests/inAdvance">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#e7f8ef' }}
              >
                <RiHandCoinFill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('inAdvance')}
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link
              className="nav-menu-item"
              to="/dashboard/suppliers/my-requests"
            >
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#fcf4e5' }}
              >
                <BsFileText className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Supplier Requests')}
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        {role === 'user' ? (
          ''
        ) : (
          <div>
            <Divider />
            <Menu.ItemGroup title={t('teamReq')}>
              <Menu.Item key="3">
                <Link
                  className="nav-menu-item"
                  to="/dashboard/expense/reimbursement"
                >
                  <div
                    className="icon-wrapper"
                    style={{ backgroundColor: '#deebf5' }}
                  >
                    <RiShoppingBag3Fill className="nav-menu-icon" />
                  </div>
                  &nbsp;&nbsp;
                  {t('rr')}
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link
                  className="nav-menu-item"
                  to="/dashboard/expense/inAdvance"
                >
                  <div
                    className="icon-wrapper"
                    style={{ backgroundColor: '#e7f8ef' }}
                  >
                    <RiHandCoinFill className="nav-menu-icon" />
                  </div>
                  &nbsp;&nbsp;
                  {t('inAdvance')}
                </Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link
                  className="nav-menu-item"
                  to="/dashboard/suppliers/expenses/pending"
                >
                  <div
                    className="icon-wrapper"
                    style={{ backgroundColor: '#fcf4e5' }}
                  >
                    <BsFileText className="nav-menu-icon" />
                  </div>
                  &nbsp;&nbsp;
                  {t('Supplier Requests')}
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            {role === 'accountant' ? (
              <Menu.ItemGroup>
                <Divider />
                <Menu.Item key="6">
                  <Link
                    className="nav-menu-item"
                    to="/dashboard/expense/categories"
                  >
                    <div
                      className="icon-wrapper"
                      style={{ backgroundColor: '#deebf5' }}
                    >
                      <RiBubbleChartFill className="nav-menu-icon" />
                    </div>
                    &nbsp;&nbsp;
                    {t('Categories')}
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            ) : (
              ''
            )}
          </div>
        )}
      </SubMenu>

      <SubMenu key="sub2" title={t('Wallets')}>
        <Menu.Item key="7">
          <Link className="nav-menu-item" to="/dashboard/requests/myWallet">
            <div
              className="icon-wrapper"
              style={{ backgroundColor: '#e7f8ef' }}
            >
              <RiWallet3Fill className="nav-menu-icon" />
            </div>
            &nbsp;&nbsp;
            {t('myWallet')}
          </Link>
        </Menu.Item>
        {role === 'accountant' || role === 'manager' ? (
          <Menu.Item key="8">
            <Link className="nav-menu-item" to="/dashboard/wallet">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#e7f8ef' }}
              >
                <FcSafe className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('companyWallet')}
            </Link>
          </Menu.Item>
        ) : (
          ''
        )}
      </SubMenu>

      {role === 'accountant' || role === 'HR' || role === 'manager' ? (
        <SubMenu key="sub3" title={t('Teamwork')}>
          <Menu.Item key="9">
            <Link className="nav-menu-item" to="/dashboard/team/home">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#dedff5' }}
              >
                <RiTeamFill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Team Members')}
            </Link>
          </Menu.Item>
          <Menu.Item key="10">
            <Link className="nav-menu-item" to="/dashboard/team/invite">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#e7f8ef' }}
              >
                <RiUserAddFill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Invite Team Member')}
            </Link>
          </Menu.Item>
          <Menu.Item key="11">
            <Link className="nav-menu-item" to="/dashboard/team/create">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#fff4dd' }}
              >
                <RiBuildingFill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Create New Team')}
            </Link>
          </Menu.Item>
        </SubMenu>
      ) : (
        ''
      )}

      {role === 'accountant' || role === 'manager' ? (
        <SubMenu key="sub4" title={t('Company Board')}>
          <Menu.Item key="12">
            <Link className="nav-menu-item" to="/dashboard/companyProfile">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#deebf5' }}
              >
                <RiBuilding4Fill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Edit Company Profile')}
            </Link>
          </Menu.Item>
          <Menu.Item key="13">
            <Link
              className="nav-menu-item"
              to="/dashboard/pageUnderConstruction"
            >
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#eef0f3' }}
              >
                <RiFilePaper2Fill className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Set Company Policy')}
            </Link>
          </Menu.Item>
          <Menu.Item key="14">
            <Link className="nav-menu-item" to="/dashboard/billingPlan">
              <div
                className="icon-wrapper"
                style={{ backgroundColor: '#e7f8ef' }}
              >
                <SiCashapp className="nav-menu-icon" />
              </div>
              &nbsp;&nbsp;
              {t('Company Billing Plan')}
            </Link>
          </Menu.Item>
        </SubMenu>
      ) : (
        ''
      )}
      {role === 'manager' || role === 'accountant' ? (
        <Menu.Item>
          <Link to="/dashboard/payroll">{t('payroll')}</Link>
        </Menu.Item>
      ) : (
        ''
      )}
      <SubMenu key="sub5" title={t('User Control')}>
        <Menu.Item key="15">
          <Link className="nav-menu-item" to="/dashboard/profileSettings">
            <div
              className="icon-wrapper"
              style={{ backgroundColor: '#dedff5' }}
            >
              <FaUserCircle className="nav-menu-icon" />
            </div>
            &nbsp;&nbsp;
            {t('Profile Settings')}
          </Link>
        </Menu.Item>
        <Menu.Item key="16">
          <Link className="nav-menu-item" to="/dashboard/accountSettings">
            <div
              className="icon-wrapper"
              style={{ backgroundColor: '#e7f8ef' }}
            >
              <RiLock2Fill className="nav-menu-icon" />
            </div>
            &nbsp;&nbsp;
            {t('Account Settings')}
          </Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link className="nav-menu-item" to="/dashboard/notificationSettings">
            <div
              className="icon-wrapper"
              style={{ backgroundColor: '#fcf4e5' }}
            >
              <RiMailUnreadFill className="nav-menu-icon" />
            </div>
            &nbsp;&nbsp;
            {t('Notifications Settings')}
          </Link>
        </Menu.Item>
        <Divider />
        <Menu.Item key="18">
          <div className="nav-menu-item" onClick={() => dispatch(logout())}>
            <div
              className="icon-wrapper"
              style={{ backgroundColor: '#fcf4e5' }}
            >
              <RiLogoutBoxRLine className="nav-menu-icon" />
            </div>
            &nbsp;&nbsp;
            {t('Logout From My Account')}
          </div>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default DrawerMenu;
