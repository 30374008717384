export const tokenConfig = (getState) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let token = localStorage.getItem('token'); //getState().auth.token

  if (token) {
    config.headers['x-auth-token'] = token;
  }

  return config;
};

export const tokenConfigUpload = (getState) => {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  let token = localStorage.getItem('token'); // getState().auth.token

  if (token) {
    config.headers['x-auth-token'] = token;
  }

  return config;
};
