import React from "react";
import EmptyImg from "../../assets/imgs/empty.png";
import { Link } from "react-router-dom";
import styles from "./Empty.module.css";
import { links } from "../../utils/links";

const EmptyPayrolls = ({ isPayroll, payrollType }) => {
  return (
    <section className={styles.container}>
      <img src={EmptyImg} alt="empty" />
      {isPayroll && (
        <>
          <h2 className={styles.title}>
            {payrollType === "fixed"
              ? "You don't have any fixed rate payrolls yet!"
              : "You don't have any pay as you go payrolls yet!"}
          </h2>
          <Link
            className={styles.link}
            to={
              payrollType === "fixed"
                ? links.fixedLinks.create
                : links.payAsYouGoLinks.create
            }
          >
            {payrollType === "fixed"
              ? "Add fixed rate payroll"
              : "Add pay as you go payroll"}
          </Link>
        </>
      )}
    </section>
  );
};

export default EmptyPayrolls;
