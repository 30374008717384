import {
  START_UPLOAD,
  ADD_FILE,
  REMOVE_FILE,
  UPLOAD_SUPMITTED,
  UPLOAD_FINISHED,
  ADD_FILES,
} from '../actions/types';

let INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_PASSWORD':
      return state;
    default:
      return state;
  }
};
