import React from "react";

import VerificationCodeForm from "./verificationCodeForm/verificationCodeForm";
import LoginBanner from "../login/loginBanner/loginBanner";

const VerificationCode = () => {
  return (
    <section className="login-page-wrapper">
      <div className="login-page">
        <VerificationCodeForm />
        <LoginBanner />
      </div>
    </section>
  );
};

export default VerificationCode;
