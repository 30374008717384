import { GET_ERRORS, CLEAR_ERRORS } from './types'
import { removeLoading } from './loadingActions'

export const returnErrors = (msg, status, id = null) => dispatch => {
    dispatch(removeLoading())
    dispatch({
        type: GET_ERRORS,
        payload: { msg, status, id }
    })
}

export const clearErrors = () => dispatch => {
    dispatch(removeLoading())
    dispatch({
        type: CLEAR_ERRORS
    })
}