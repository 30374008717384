import {
  START_UPLOAD,
  ADD_FILE,
  REMOVE_FILE,
  UPLOAD_SUPMITTED,
  UPLOAD_FINISHED,
  ADD_FILES,
  INITIAL_PICS,
} from '../actions/types';

let INITIAL_STATE = {
  loading: false,
  file: undefined,
  name: undefined,
  files: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_UPLOAD:
      return {
        ...state,
        loading: true,
      };
    case ADD_FILE:
      return {
        name: action.payload.name,
        file: state.files.push(action.payload.file),
        loading: false,
      };
    case REMOVE_FILE:
      let x = [...state.files];
      x.splice(action.payload.index, 1);
      return {
        ...state,
        files: x,
      };
    case ADD_FILES:
      return {
        name: action.payload.name,
        files: [...state.files, ...action.payload.files],
          // state.name === action.payload.name
          //   ? [...state.files, ...action.payload.files]
          //   : action.payload.files,
        loading: false,
      };
    case REMOVE_FILE:
      return {
        ...state,
        file: undefined,
        name: undefined,
      };
    case UPLOAD_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_SUPMITTED:
      return {
        loading: false,
        file: undefined,
        name: undefined,
        files: [],
      };
    case INITIAL_PICS:
      return {
        ...state,
        files: action.payload.pics,
        name: 'requests'
      }
    default:
      return state;
  }
};
