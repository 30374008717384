import { SET_LANG } from './types';
import i18n from '../translations/i18n';
import { loadRequestCount ,loadInadvanceRequestCount} from './authActions';

export const setLang = (lang) => async (dispatch, getState) => {
  loadRequestCount();
  loadInadvanceRequestCount()

  dispatch({
    type: SET_LANG,
  });
};
