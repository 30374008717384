import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import { useForm } from 'react-hook-form';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import cogoToast from 'cogo-toast';

export default function SettlementRequest({
  method,
  setMethod,
  price,
  supplier,
  submitForm,
  spin,
}) {
  const { t } = useTranslation();

  const [bankDetails, setBankDetails] = useState({
    name: '',
    accountNumber: '',
    bankName: '',
    bankAddress: '',
    amount: '',
  });
  const [saveCard, setSaveCard] = useState(true);
  const [getDataFromSaved, setGetDataFromSaved] = useState(false);
  // const [spin, setSpin] = useState(false);

  useEffect(() => {
    if (supplier?.savedCard) setGetDataFromSaved(supplier.savedCard);
  }, [supplier]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onOptionChange = (e) => {
    setMethod(e);
  };

  const onSubmitAccount = (values) => {
    setBankDetails({
      name: values.name,
      accountNumber: values.accountNumber,
      bankName: values.bankName,
      bankAddress: values.bankAddress,
      amount: values.amount,
    });
    if (parseInt(values.amount) <= 0)
      return cogoToast.error(
        `The amount is required and should be positive and not be greater than ${price} EGP`
      );
    if (getDataFromSaved) {
      submitForm({
        name: supplier.bankAccountName,
        accountNumber: supplier.bankAccountNumber,
        bankName: supplier.bankName,
        bankAddress: supplier.bankBranchAddress,
        amount: values.amount,
        saveCard: saveCard,
      });
      console.log(bankDetails);
    } else {
      submitForm({
        name: values.name,
        accountNumber: values.accountNumber,
        bankName: values.bankName,
        bankAddress: values.bankAddress,
        amount: values.amount,
        saveCard: saveCard,
      });
      console.log(bankDetails);
    }
  };

  const onSubmitAmount = (values) => {};

  return (
    <div className="col justify-content-start">
      <h3 className="mb-4">Settlement request</h3>
      <div className="col text-center col-12 justify-content-center">
        <p className="h4">
          Total request amount:{' '}
          <span style={{ color: '#56C288' }}>{price} EGP</span>
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmitAmount)}>
        <div className="inputComp mb-4">
          <label
            className={
              localStorage.getItem('i18nextLng') == 'ar' ? 'arLabel' : 'enLabel'
            }
          >
            You will pay{' '}
          </label>
          <input
            className="form-control"
            placeholder="ex.40000 "
            type="number"
            min="0.01"
            max={price}
            step="0.01"
            title={`The amount is required and should be positive and not be greater than ${price}`}
            autoComplete="off"
            name="amount"
            ref={register({ required: true, min: '0.01', max: price })}
          />
          {errors.amount && (
            <span className="text-danger">{`The amount is required and should be positive and not be greater than ${price} EGP`}</span>
          )}
        </div>
      </form>
      <hr />
      <h3> Choose your payment method</h3>
      <p
        className=" mx-0 w-100"
        style={{
          fontSize: '18px',
          opacity: '50%',
        }}
      >
        Please know if you choose any payment method except your wallet you will
        complete the payment manually
      </p>

      <div
        class="bulgy-radios"
        role="radiogroup"
        aria-labelledby="bulgy-radios-label"
      >
        <label>
          <input
            type="radio"
            name="options"
            value="0"
            defaultChecked
            onChange={(e) => onOptionChange(e.target.value)}
          />
          <span className="radio"></span>
          <span className="label">Bank transfer</span>
        </label>
        <label>
          <input
            type="radio"
            name="options"
            value="1"
            onChange={(e) => onOptionChange(e.target.value)}
          />
          <span className="radio"></span>
          <span className="label">Pay with cash</span>
        </label>
        <label>
          <input
            type="radio"
            name="options"
            value="2"
            onChange={(e) => onOptionChange(e.target.value)}
          />
          <span className="radio"></span>
          <span className="label">Pay with check</span>
        </label>
        <label>
          <input
            type="radio"
            name="options"
            value="3"
            onChange={(e) => onOptionChange(e.target.value)}
          />
          <span className="radio"></span>
          <span className="label">Hollydesk Wallet</span>
        </label>
      </div>

      {method == 3 ? (
        <div>
          <hr />

          <p
            className=" mx-0 w-100"
            style={{
              fontSize: '17px',
              opacity: '50%',
            }}
          >
            Please add your vendor bank account, the transaction will be done in
            24 working hours.
          </p>
          {getDataFromSaved ? (
            <form
              className="supplierBankForm"
              style={{ marginTop: '0px', marginInline: 0 }}
              onSubmit={handleSubmit(onSubmitAccount)}
            >
              <div className="mb-3"></div>
              <div className="row">
                <div className="col-8">
                  <p style={{ marginInlineStart: 0 }}>
                    {' '}
                    Account Name: {supplier.bankAccountName}{' '}
                  </p>
                  <p style={{ marginInlineStart: 0 }}>
                    {' '}
                    Account Number: {supplier.bankAccountNumber}{' '}
                  </p>
                  <p style={{ marginInlineStart: 0 }}>
                    {' '}
                    Account Bank: {supplier.bankName}{' '}
                  </p>
                  <p style={{ marginInlineStart: 0 }}>
                    {' '}
                    Bank Address: {supplier.bankBranchAddress}{' '}
                  </p>
                </div>
                <div className="col-4">
                  <span className="green">
                    <a onClick={() => setGetDataFromSaved(false)}>
                      Use Another Account
                    </a>
                  </span>
                </div>
              </div>
              <div className="justify-content-center row">
                {spin ? (
                  <button
                    type="button"
                    disabled
                    className="btn btnPrimary btnSmall col-12"
                  >
                    <LoadingOutlined />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btnPrimary btnSmall col-12"
                    // onClick={() => setSpin(true)}
                  >
                    Pay and back to requests
                  </button>
                )}
              </div>
            </form>
          ) : (
            <form
              className="supplierBankForm"
              style={{ marginTop: '0px', marginInline: 0 }}
              onSubmit={handleSubmit(onSubmitAccount)}
            >
              <div className="mb-3">
                <label htmlFor="name">Account holder name</label>
                <input
                  className="col-12  form-control"
                  placeholder="ex. mohamed kamel"
                  name="name"
                  country="eg"
                  id="name"
                  ref={register({ required: true, pattern: /[A-Za-z]/ })}
                />
                {errors.name && (
                  <span className="text-danger">Name is required</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="accountNumber">Account number</label>
                <input
                  className="col-12  form-control"
                  placeholder="123123123123123123"
                  country="eg"
                  id="accountNumber"
                  name="accountNumber"
                  type="number"
                  step="1"
                  ref={register({ required: true, pattern: /^[0-9]/ })}
                />
                {errors.accountNumber && (
                  <span className="text-danger">
                    Account number is required
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="bankName">Bank name</label>

                <select
                  name="bankName"
                  className="col-12  form-control"
                  id="bankName"
                  ref={register({ required: true })}
                >
                  {banksNames.map((name) => {
                    return <option value={name}>{name}</option>;
                  })}
                </select>

                {errors.bankName && (
                  <span className="text-danger">Bank name is required</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="bankAddress">Bank branch (Bank address)</label>
                <input
                  className="col-12  form-control"
                  placeholder="ex. san stefano, alexandriaBE"
                  name="bankAddress"
                  id="bankAddress"
                  ref={register({ required: true, pattern: /[A-Za-z]/ })}
                />
                {errors.bankAddress && (
                  <span className="text-danger">Bank branch is required</span>
                )}
              </div>
              <div className="row mb-3 align-content-center">
                <Switch defaultChecked onChange={(e) => setSaveCard(e)} />
                <p className="mx-1 ">
                  Save this account as a default account for this supplier
                </p>
              </div>
              <div className="justify-content-center row">
                {spin ? (
                  <button
                    type="button"
                    disabled
                    className="btn btnPrimary btnSmall col-12"
                  >
                    <LoadingOutlined />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btnPrimary btnSmall col-12"
                    // onClick={() => setSpin(true)}
                  >
                    Pay and back to requests
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

const banksNames = [
  'Arab African International Bank',
  'Arab Banking Corporation',
  'Al Ahli Bank of Kuwait - Egypt',
  'Al Baraka Bank Egypt',
  'Abu Dhabi Islamic Bank Egypt',
  'Arab Investment Bank',
  'Arab Bank',
  'Arab International Bank',
  'Ahly United Bank',
  'Audi Bank',
  'Attijariwafa Bank Egypt S.A.E',
  'Banque Du Caire',
  'Blom Bank Egypt',
  'Bank of Alexandria',
  'Credit Agricole Egypt',
  'Commercial International Bank',
  'Citibank',
  'Egyptian Arab Land Bank',
  'Export Development Bank of Egypt',
  'Egyptian Gulf Bank',
  'Emirates National Bank of Dubai',
  'First Abu Dhabi Bank',
  'Faisal Islamic Bank of Egypt',
  'Housing And Development Bank',
  'HSBC',
  'Industrial Development Bank',
  'Mashreq Bank',
  'Misr Iran Development Bank',
  'Banque Misr',
  'National Bank of Egypt',
  'National Bank of Greece',
  'National Bank Of Kuwait â€“ Egypt',
  'Nasser Social Bank',
  'The Principal Bank for Development and Agri',
  'Qatar National Bank Alahli',
  'Societe Arabe Internationale De Banque',
  'Suez Canal Bank',
  'The United Bank',
  'Union National Bank',
  'E-wallet',
];
