import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { addTeam } from "../../../../../../actions/teamActions";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { loadTeams, editEmp } from "../../../../../../actions/teamActions";

const EditTeam = props => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({
      _id: props.employee._id,
      firstName: props.employee.firstName,
      lastName: props.employee.lastName,
      role: props.employee.role,
      jobTitle: props.employee.jobTitle,
      team: props.employee.team
    });
  }, [props.employee]);

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
      role: e.target.name == "team" ? "user" : formValues.role
    });

    console.log(formValues);
  };

  const renderTeams = () => {
    const { teams } = props.team;
    if (teams && teams.length > 0) {
      let options = <option value="noteam">{t("Pleasechooseateam")}</option>;
      return (options = [
        options,
        ...teams.map(team => {
          return <option value={team._id}>{team.name}</option>;
        })
      ]);
    } else {
      return <option value="noteam">No teams found</option>;
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    props.editEmp(formValues, props.filterData);
    props.loadTeams();
    props.setState(false);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.state}
    >
      <Modal.Header closeButton onClick={() => props.setState(false)}>
        <Modal.Title>{}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div>
          <form onSubmit={e => onSubmit(e)} className="row col-md-12">
            <div className="col-md-6 mb-2">
              <div className="inputComp">
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                >
                  {t("fName")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="firstName"
                  onChange={handleChange}
                  defaultValue={props.employee.firstName}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="inputComp">
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                >
                  {t("lName")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="lastName"
                  onChange={handleChange}
                  defaultValue={props.employee.lastName}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="inputComp">
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                >
                  {t("email")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="email"
                  disabled
                  onChange={handleChange}
                  defaultValue={props.employee.email}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="inputComp">
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                  style={{ width: "100%" }}
                >
                  {t("role")}
                </label>
                <select
                  name="role"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  defaultValue={props.employee.role}
                  disabled={props.employee.role === "manager"}
                >
                  <option value="user">{t("emp")}</option>
                  <option value="team-manager">{t("manager")}</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="inputComp">
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                >
                  {t("jobTitle")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="jobTitle"
                  onChange={handleChange}
                  defaultValue={props.employee.jobTitle}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="inputComp">
                <label
                  className={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? "arLabel"
                      : "enLabel"
                  }
                  style={{ width: "100%" }}
                >
                  {t("team")}
                </label>
                <select
                  name="team"
                  type="text"
                  onChange={handleChange}
                  className="form-control"
                  defaultValue={props.employee.team}
                  disabled={props.employee.role === "manager"}
                >
                  {renderTeams()}
                </select>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <div className="inputComp">
                <button
                  className="btn btnPrimary"
                  type="submit"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  {t("Edit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const validateForm = formValues => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "Please add name";
  }

  return errors;
};

const reduxMiddleware = reduxForm({
  form: "addTeam",
  validate: validateForm,
  enableReinitialize: true
})(EditTeam);

const mapStateToProps = state => {
  return {
    errors: state.error,
    team: state.team,
    lang: state.lang.lang
  };
};

export default connect(mapStateToProps, { addTeam, loadTeams, editEmp })(
  reduxMiddleware
);
