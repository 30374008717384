import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import TeamRequestsReimbursement from "./pages/newStr/ReimbursementRequests/TeamRequestsReimbursement/TeamRequestsReimbursement";
import TeamRequestsInAdvance from "./pages/newStr/InAdvanceRequests/teamRequestsInAdvance/teamRequestsInAdvance";
import Categories from "./pages/newStr/Categories/categories";

import history from "../../history";
import { useTranslation } from "react-i18next";

const ExpensePage = props => {
  const { t } = useTranslation();

  let routes = (
    <Router history={history}>
      <div className="col-md-12">
        <Switch>
          <Route
            path="/dashboard/expense/reimbursement"
            exact
            component={TeamRequestsReimbursement}
          />
          <Route
            path="/dashboard/expense/inAdvance"
            exact
            component={TeamRequestsInAdvance}
          />
          <Route
            path="/dashboard/expense/categories"
            exact
            component={Categories}
          />
          <Redirect to="/dashboard/expense/reimbursement" />
        </Switch>
      </div>
    </Router>
  );

  return <div>{routes}</div>;
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    requestCount: state.auth.count,
    myRequestCount: state.auth.myCount
  };
};

export default connect(mapStateToProps, {})(ExpensePage);
