import React, { useState } from "react";
import { useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import "./reconsile.css";
import UploadImages from "../../../../components/requests/UploadImage/uploadImagesInAd";
import api from "../../../../../../apis/api";

const Reconcile = () => {
  const request = useSelector(state => state.request.reqToReconcile);
  const files = useSelector(state => state.upload.files);
  const [note, setNote] = useState("");

  const { t } = useTranslation();

  const history = useHistory();

  const onSubmitHandler = () => {
    let reconcileData = {
      _id: request._id,
      note: note,
      images: files
    };

    if (reconcileData.images.length > 0) {
      api({
        url: "/inadvance/reconsile-request",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*"
        },
        data: reconcileData
      })
        .then(res => {
          if (res.data.status === 1) {
            history.replace(
              `/dashboard/requests/inAdvanceRequest/reconcile/success?num=${res.data.data.number}`
            );
          } else {
            cogoToast.error("Request Reconcilation failed");
          }
        })
        .catch(err => console.log(err));
    } else {
      cogoToast.error(t("please upload file"));
    }
  };

  const onDraftHandler = () => {
    let reconcileData = {
      _id: request._id,
      // note: note,
      images: files
    };

    if (reconcileData.images.length > 0) {
      api({
        url: "/inadvance/draft-request",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*"
        },
        data: reconcileData
      })
        .then(res => {
          if (res.data.status === 1) {
            history.replace(`/dashboard/requests/inAdvance`);
          } else {
            cogoToast.error("Request drafting failed");
          }
        })
        .catch(err => console.log(err));
    } else {
      cogoToast.error(t("please upload file"));
    }
  };

  return (
    <div
      className="mainPage request-inadvance"
      style={
        localStorage.getItem("i18nextLng") == "ar"
          ? { textAlign: "right" }
          : { textAlign: "left" }
      }
    >
      <div className="inadvance-form">
        <form>
          <h2>{t("send")}</h2>
          <div className="form-row">
            <div className="row-element">
              <label>{t("amount")}</label>
              <span className="form-control static-data">{request.amount}</span>
            </div>
            <div className="row-element">
              <label>{t("currency")}</label>
              <span className="form-control static-data">
                {request.currency}
              </span>
            </div>
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t("payToInAd")}</label>
              <span className="form-control static-data">{request.paidTo}</span>
            </div>
            <div className="row-element">
              <label>{t("payDate")}</label>
              <span className="form-control static-data">
                {request.paidOn.split("T")[0]}
              </span>
            </div>
          </div>
          <div className="form-row wide">
            <label>{t("payForInAd")}</label>
            <span className="form-control static-data">{request.paidFor}</span>
          </div>
          <div className="form-row wide">
            <label>{t("Leave Note")}</label>
            <input
              className="form-control"
              placeholder={t("Leave Note PH")}
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </div>
        </form>
      </div>
      <div className="spliter"></div>
      <div className="reconcile-upload">
        <UploadImages name="requests" draftImages={request.images} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            className="btn btnPrimary reconcile-btn"
            onClick={() => onSubmitHandler()}
          >
            {t("Submit Reconcilation")}
          </button>
          <button
            className="btn btnThrd reconcile-btn"
            onClick={() => onDraftHandler()}
          >
            {t("Draft Reconcilation")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reconcile;
