import React from 'react'
import { connect } from 'react-redux'
import { uploadPicture } from '../../../../actions/uploadActions'
import { reduxForm } from 'redux-form'

import './upload.css'

class Upload extends React.Component {
    state = {
        file: null
    }

    handleChange(event) {
        if (event.target.files[0]) {
            this.setState({
                file: event.target.files[0]
            })
            let formData = new FormData()
            formData.append('file', event.target.files[0])
            this.props.uploadPicture(formData, this.props.name)
        }
    }

    render() {
        let { file } = this.state
        return (
            <div className="row">
                <div className="col-md-6 uploadImage">
                    <input type="file" name="image" onChange={(e) => this.handleChange(e)} />
                </div>
                {file ? <div className="col-md-6 imagePreview">
                    <img src={URL.createObjectURL(file)} alt="imagepreview" />
                </div> :
                    <div className="col-md-6 imagePreview" >Image preview</div>}
            </div>
        );
    }
}

const reduxMiddleware = reduxForm({
    form: 'upload',
    enableReinitialize: true
})(Upload)

const mapStateToProps = state => {
    return {
        upload: state.upload
    }
}

export default connect(mapStateToProps, { uploadPicture })(reduxMiddleware)