import React from "react";

import ChangePasswordForm from "./changePasswordForm/changePasswordForm";
import LoginBanner from "../login/loginBanner/loginBanner";

const ChangePassword = () => {
  return (
    <section className="login-page-wrapper">
      <div className="login-page">
        <ChangePasswordForm />
        <LoginBanner />
      </div>
    </section>
  );
};

export default ChangePassword;
