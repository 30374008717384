import React, { lazy, Suspense } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import history from "../../../../../history";
import { ToastContainer } from "react-toastify";

// Styles
import style from "./style.module.css";
import Spinner from "./components/Spinner";
import "react-toastify/dist/ReactToastify.css";
import "./globalstyle.css";
import { links } from "./utils/links";
import FixedRateRouter from "./Routers/FixedPayroll";
import PayAsYouGoRouter from "./Routers/PayAsYouGo";
import Insights from "./containers/insights";

// Lazy Components
// const Insights = lazy(() => import("./containers/insights"));
const PayrollTeams = lazy(() => import("./containers/PayrollTeams"));
const NotFound = lazy(() => import("./components/Not-Found/NotFound"));

const index = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Router history={history}>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className={style.container}>
          <Switch>
            <Route path={links.fixedLinks.home} component={FixedRateRouter} />
            <Route
              path={links.payAsYouGoLinks.home}
              component={PayAsYouGoRouter}
            />
            <Route path={links.insights} exact component={Insights} />
            <Route path={links.teams} exact component={PayrollTeams} />

            <Route path={links.notFound} exact component={NotFound} />

            <Redirect to={links.fixedLinks.home} />
          </Switch>
        </div>
      </Router>
    </Suspense>
  );
};

export default index;
