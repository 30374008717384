import React from "react";
import { connect } from "react-redux";
import ExpenseItem from "./Item/item";
import ReactExport from "react-export-excel";
import { loadingSuppliersDecilnedRequests } from "../../../../../../../actions/pendingRequestsActions";
import { Menu, Dropdown } from "antd";
import { GoChevronDown } from "react-icons/go";

import "./approvedExpense.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DeclinedExpense extends React.Component {
  state = {
    exel: [],
  };

  componentDidMount() {
    this.props.loadingSuppliersDecilnedRequests();
    //console.log('declineds');

    const { declineds } = this.props;
    //console.log(declineds);
    let exel = [];
    if (declineds && declineds.length > 0) {
      for (const iterator of declineds) {
        exel = [...exel, ...iterator.pending.requests];
      }
      //console.log(exel);

      for (const iterator of exel) {
        if (iterator.from) {
          iterator.name =
            iterator.from.firstName + " " + iterator.from.lastName;
        }

        //console.log(iterator.from);
      }
      this.setState({ exel: exel });
    }
  }

  renderExpenseItems() {
    const { declineds } = this.props;

    if (declineds && declineds.length > 0) {
      return declineds.map((pending) => {
        return (
          <div className="container-fluid  pending-expense">
            <ExpenseItem pending={pending.pending} />
          </div>
        );
      });
    } else if (declineds && declineds.length === 0) {
      return localStorage.getItem("i18nextLng") == "en" ? (
        <section class="h-100 w-100">
          <header class="container h-100 w-100">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div class="d-flex flex-column" style={{ fontSize: "50px" }}>
                You don't have any requests
              </div>
            </div>
          </header>
        </section>
      ) : (
        // <div className="container-fluid  pending-expense"></div>
        <section class="h-100 w-100">
          <header class="container h-100 w-100">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div class="d-flex flex-column" style={{ fontSize: "50px" }}>
                ليس لديك طلبات{" "}
              </div>
            </div>
          </header>
        </section>
      );
    }
  }

  menu() {
    return (
      <Menu>
        <Menu.Item key="0">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/csv.png" alt="csv" /> &nbsp; CSV
              </p>
            }
            filename="Approved Expenses"
          >
            <ExcelSheet
              data={this.props.exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Paid On" value="paidOn" />
              <ExcelColumn label="Paid For" value="paidFor" />
              <ExcelColumn label="Paid To" value="paidTo" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Images"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="1">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/QuickBooks.png" alt="csv" /> &nbsp; QuickBooks
              </p>
            }
            filename="Approved Expenses"
          >
            <ExcelSheet
              data={this.props.exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Product/Category" value="paidTo" />
              <ExcelColumn
                label="Product/Service Description"
                value="paidFor"
              />
              <ExcelColumn label="Quantity" value="quantity" />
              <ExcelColumn label="Rate" value="rate" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Tax Code" value="taxCode" />
              <ExcelColumn label="Tax Amount" value="taxAmount" />
              <ExcelColumn label="Class" value="category" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Memo"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="2">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/Xero.png" alt="csv" /> &nbsp; Xero
              </p>
            }
            filename="Approved Expenses"
          >
            <ExcelSheet
              data={this.props.exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Number" value="number" />
              <ExcelColumn label="Reference" value="paidFor" />
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Account" value="paidTo" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn
                label="Memo"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>

        <Menu.Item key="3">
          <ExcelFile
            className="export col-1"
            element={
              <p className="export-option">
                <img src="/images/FreshBooks.png" alt="csv" /> &nbsp; FreshBooks
              </p>
            }
            filename="Approved Expenses"
          >
            <ExcelSheet
              data={this.props.exel}
              name="Expences"
              className="btn btn-success btnPrimary"
            >
              <ExcelColumn label="Date" value="paidOn" />
              <ExcelColumn label="Merchant" value="paidTo" />
              <ExcelColumn label="Description" value="paidFor" />
              <ExcelColumn label="Amount" value="amount" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Currency" value="currency" />
              <ExcelColumn label="Employee Name" value="name" />
              <ExcelColumn label="Request No." value="number" />
              <ExcelColumn
                label="Memo"
                value={(col) => {
                  let str = "";
                  col.images.forEach((element) => {
                    str = str + " , \n " + element.path;
                  });
                  return str;
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    return (
      <div className="row">
        <div
          className="col-9 row mr-1"
          onClick={() => {
            //console.log(exel);
          }}
        ></div>
        <div className="container-fluid-left mt-3 col-3 row here">
          <Dropdown overlay={this.menu()} trigger={["click"]}>
            {localStorage.getItem("i18nextLng") == "en" ? (
              <button className="btn btn-success btnPrimary">
                Export To <GoChevronDown style={{ fontSize: "16px" }} />
              </button>
            ) : (
              <button className="btn btn-success btnPrimary">
                إستخراج إلى <GoChevronDown style={{ fontSize: "16px" }} />
              </button>
            )}
          </Dropdown>
        </div>

        {this.renderExpenseItems()}
      </div>
    );
  }
}

const mapToStateProps = (state) => {
  return {
    declineds: state.declined.declineds,
    exel: state.declined.exel,
    lang: state.lang.lang,
  };
};

export default connect(mapToStateProps, { loadingSuppliersDecilnedRequests })(
  DeclinedExpense
);
