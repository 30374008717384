import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Divider } from 'antd';
import { GoChevronDown } from 'react-icons/go';
import { RiShoppingBag3Fill } from 'react-icons/ri';
import { RiHandCoinFill } from 'react-icons/ri';
import { BsFileText } from 'react-icons/bs';
import { RiBubbleChartFill } from 'react-icons/ri';

import 'antd/dist/antd.css';

const RequestsMenu = () => {

  const role = useSelector(state => state.auth.user.role) || 'user'

  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.ItemGroup title={t('requests')}>
        <Menu.Item key="0">
          <Link className='nav-menu-item' to='/dashboard/requests/my-requests'>
            <div className='icon-wrapper' style={{backgroundColor: '#deebf5'}}>
              <RiShoppingBag3Fill className='nav-menu-icon'/>
            </div>
            &nbsp;&nbsp;
            {t('rr')}
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link className='nav-menu-item' to='/dashboard/requests/inAdvance'>
            <div className='icon-wrapper' style={{backgroundColor: '#e7f8ef'}}>
              <RiHandCoinFill className='nav-menu-icon'/>
            </div>
            &nbsp;&nbsp;
            {t('inAdvance')}
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link className='nav-menu-item' to='/dashboard/suppliers/my-requests'>
            <div className='icon-wrapper' style={{backgroundColor: '#fcf4e5'}}>
              <BsFileText className='nav-menu-icon'/>
            </div>
            &nbsp;&nbsp;
            {t('Supplier Requests')}
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>

      {role === 'user' ? ('') : (
        <div>
          <Divider />
          <Menu.ItemGroup title={t('teamReq')}>
            <Menu.Item key="4">
              <Link className='nav-menu-item' to='/dashboard/expense/reimbursement'>
                <div className='icon-wrapper' style={{backgroundColor: '#deebf5'}}>
                  <RiShoppingBag3Fill className='nav-menu-icon'/>
                </div>
                &nbsp;&nbsp;
                {t('rr')}
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link className='nav-menu-item' to='/dashboard/expense/inAdvance'>
                <div className='icon-wrapper' style={{backgroundColor: '#e7f8ef'}}>
                  <RiHandCoinFill className='nav-menu-icon'/>
                </div>
                &nbsp;&nbsp;
                {t('inAdvance')}
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link className='nav-menu-item' to='/dashboard/suppliers/expenses/pending'>
                <div className='icon-wrapper' style={{backgroundColor: '#fcf4e5'}}>
                  <BsFileText className='nav-menu-icon'/>
                </div>
                &nbsp;&nbsp;
                {t('Supplier Requests')}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
          {role === 'accountant' ? (
            <Menu.ItemGroup>
              <Divider />
              <Menu.Item key="7">
                <Link className='nav-menu-item' to='/dashboard/expense/categories'>
                  <div className='icon-wrapper' style={{backgroundColor: '#deebf5'}}>
                    <RiBubbleChartFill className='nav-menu-icon'/>
                  </div>
                  &nbsp;&nbsp;
                  {t('Categories')}
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          ) : ('')}
        </div>
      )}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a 
      className={
      window.location.pathname.includes('requests/my-requests') ||
      window.location.pathname.includes('requests/inAdvance') ||
      window.location.pathname.includes('suppliers/') ||
      window.location.pathname.includes('expense/') ? 'active-nav-tab ant-dropdown-link' : 'nav-tab ant-dropdown-link'} 
      onClick={e => e.preventDefault()}>
        {t('Requests')} &nbsp; <GoChevronDown style={{fontSize: '16px'}}/>
      </a>
    </Dropdown>
  );
};

export default RequestsMenu;