import React from 'react'

import './loader.css'

class loader extends React.Component {
    render() {
        return (
            <div id="demo-content">
                <div id="loader-wrapper">
                    <div id="loader"></div>
                </div>
            </div>
        )
    }
}

export default loader