import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './reimbursement.css';

const InAdvancePopUp = (props) => {
  const { t, i18n } = useTranslation();
  const [Lang, setLang] = useState(localStorage.getItem('i18nextLng'));

  React.useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = localStorage.getItem('i18nextLng');
      //console.log(theme);
      setLang(theme);
    });
  }, []);

  return (
    <div className="reimbursement pop-up">
      <div className="container-fluid">
        <div className="row align-items-center">
          {localStorage.getItem('i18nextLng') == 'ar' ? (
            <div className="col-md-7" style={{ textAlign: 'right' }}>
              <h1 className="mb-5">
                <b dangerouslySetInnerHTML={{ __html: t('howToGetInAd') }}></b>
              </h1>
              <ul className=" align-items-center">
                <li className=" align-items-center">
                    <img src="/images/icons8-atm.svg" alt="atm" />
                    <h5 style={{ display: 'inline' }}> </h5>{' '}
                    <span dangerouslySetInnerHTML={{ __html: t('step1InAd') }}></span>
                </li>
                <li className=" align-items-center">
                    <img src="/images/icons8-complaints.svg" alt="complaints" />
                    <h5 style={{ display: 'inline' }}> </h5>{' '}
                    <span dangerouslySetInnerHTML={{ __html: t('step2InAd') }}></span>
                </li>
                <li className=" align-items-center">
                  <img src="/images/icons8-screenshot.svg" alt="screenshot" />
                  <h5 style={{ display: 'inline' }}> </h5>
                  <span dangerouslySetInnerHTML={{ __html: t('step3InAd') }}></span>
                </li>
                <li className=" align-items-center">
                  <img src="/images/icons8-rules.svg" alt="rules" />
                  <h5 style={{ display: 'inline' }}> </h5>{' '}
                  <span dangerouslySetInnerHTML={{ __html: t('step4InAd') }}></span>
                </li>
              </ul>

              <div className="col-12 row">
                <div className="col-12 row">
                  <p className="mt-5 mr-4 col-6">{t('donthaver')}</p>{' '}
                  <p className=" mt-5 col-5 ml-2">{t('haver')}</p>
                </div>
                <a
                  className="btn btnPrimary  mr-1 col-6"
                  download
                  href="/PaymentReceipt.docx"
                >
                  {t('downr')}
                </a>{' '}
                <Link
                  className="btn btnPrimary  col-5"
                  to={`/dashboard/requests/reimbursment/request`}
                >
                  {t('newReq')}
                </Link>
              </div>
            </div>
          ) : (
            <div className="col-md-7">
              <h1 className="mb-5">
                <b dangerouslySetInnerHTML={{ __html: t('howToGetInAd') }}></b>
              </h1>
              <ul>
                <li className=" align-items-center">
                    <img src="/images/icons8-atm.svg" alt="atm" />
                    <h5 style={{ display: 'inline' }}> </h5>{' '}
                    <span dangerouslySetInnerHTML={{ __html: t('step1InAd') }}></span>
                </li>
                <li className=" align-items-center">
                    <img src="/images/icons8-complaints.svg" alt="complaints" />
                    <h5 style={{ display: 'inline' }}> </h5>{' '}
                    <span dangerouslySetInnerHTML={{ __html: t('step2InAd') }}></span>
                </li>
                <li className=" align-items-center">
                    <img src="/images/icons8-screenshot.svg" alt="screenshot" />
                    <h5 style={{ display: 'inline' }}> </h5>
                    <span dangerouslySetInnerHTML={{ __html: t('step3InAd') }}></span>
                </li>
                <li className=" align-items-center">
                    <img src="/images/icons8-rules.svg" alt="rules" />
                    <h5 style={{ display: 'inline' }}> </h5>{' '}
                    <span dangerouslySetInnerHTML={{ __html: t('step4InAd') }}></span>
                </li>
              </ul>

              <div className="col-12 row">
                <div className="col-12 row buttonsDiv">
                  <p className="mt-3 col-7">{t('donthaver')}</p>{' '}
                  <p className="mt-3 col-5 ml-2">{t('haver')}</p>
                </div>
                <a
                  className="btn btnPrimary  mr-1 col-6"
                  download
                  href="/PaymentReceipt.docx"
                  style={{fontSize: 'small'}}
                >
                  {t('downr')}
                </a>{' '}
                <Link
                  className="btn btnPrimary  col-5"
                  to={`/dashboard/requests/newInAdvanceRequest`}
                  style={{fontSize: 'small'}}
                >
                  {t('newReq')}
                </Link>
              </div>
            </div>
          )}
          <div className="col-md-5">
            <div className="reimbursement-image">
              <img
                src="/images/reimbursementImage.png"
                alt="reimbursementImage"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, {})(InAdvancePopUp);
