export const links = {
  fixedLinks: {
    home: "/dashboard/payroll/fixed",
    create: "/dashboard/payroll/fixed/create",
    detailsRoute: "/dashboard/payroll/fixed/details/:payrollID",
    editRoute: "/dashboard/payroll/fixed/edit/:payrollID",
    details: id => `/dashboard/payroll/fixed/details/${id}`,
    edit: id => `/dashboard/payroll/fixed/edit/${id}`
  },
  payAsYouGoLinks: {
    home: "/dashboard/payroll/payAsYouGo",
    create: "/dashboard/payroll/payAsYouGo/create",
    contacts: "/dashboard/payroll/payAsYouGo/contacts",
    contactsCreate: "/dashboard/payroll/payAsYouGo/contacts/create",
    detailsRoute: "/dashboard/payroll/payAsYouGo/details/:payrollID",
    editRoute: "/dashboard/payroll/payAsYouGo/edit/:payrollID",
    details: id => `/dashboard/payroll/payAsYouGo/details/${id}`,
    edit: id => `/dashboard/payroll/payAsYouGo/edit/${id}`
  },
  teams: "/dashboard/payroll/teams",
  insights: "/dashboard/payroll/insights",
  notFound: "/dashboard/payroll/not-found"
};

export const endpoints = {
  payroll: {
    lists: recurring =>
      `/payroll/fixed-rate?recurring=${recurring ? "true" : "false"}`,
    create: "/payroll/fixed-rate",
    delete: "/payroll/fixed-rate",
    find: payrollID => `/payroll/fixed-rate/${payrollID}`,
    update: payrollID => `/payroll/fixed-rate/${payrollID}`,
    activate: "/payroll/activate-fixed-rate",
    deactivate: "/payroll/deactivate-fixed-rate",
    enable: "/payroll/enable-fixed-rate",
    disable: "/payroll/disable-fixed-rate"
  },
  teams: {
    lists: "/payroll/team",
    create: "/payroll/team",
    edit: teamID => `/payroll/team/${teamID}`,
    delete: "/payroll/team"
  },
  employee: {
    update: empID => `/payroll/fixed-rate-employee/${empID}`,
    delete: "/payroll/fixed-rate-employee",
    advanceSalary: "/payroll/pay-inadvance-employee-fixed-rate",
    toggleState: "payroll/control-employee-fixed-rate"
  },
  contacts: {
    lists: "/payroll/whatsapp-users",
    find: contactID => `/payroll/whatsapp-users/${contactID}`,
    create: "/payroll/whatsapp-user",
    update: "/payroll/whatsapp-user",
    delete: "/payroll/whatsapp-user"
  }
};
