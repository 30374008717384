import React from "react";
import FixedPayrolls from "./FixedPayrolls";
import NavBar from "../../../components/NavBar/NavBar";
import axios from "../../../lib/axios";
import useAxios from "../../../hooks/useAxios";
import { endpoints } from "../../../utils/links";

const FixedRate = () => {
  const [response, error, loading] = useAxios({
    axiosInstance: axios,
    method: "get",
    url: endpoints.payroll.lists("fixed")
  });
  return (
    <div>
      <NavBar />
      <FixedPayrolls
        data={response?.data}
        isLoading={loading}
        error={error}
        isPayroll
        payrollType="fixed"
      />
    </div>
  );
};

export default FixedRate;
