import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { sendInvite, finishSetup } from '../../../../actions/companyActions';
import { clearErrors } from '../../../../actions/errorActions';

import '../pages.css';
import './adminRoles.css';
import { useTranslation } from 'react-i18next';

const AdminRoles = (props) => {
  const [hrMail, sethrMail] = useState('');
  const [memberMail, setmemberMail] = useState('');
  const [errorMsg, seterrorMsg] = useState(undefined);

  const { t } = useTranslation();

  const renderInvites = (role) => {
    let { invites } = props.company;
    let invitesHtml = [];
    let invitesRole = invites.filter((invite) => {
      return invite.role === role ? invite : undefined;
    });

    if (invitesRole.length > 2) {
      invitesRole.slice(0, 2).map((invite, index) => {
        return invite.role === role
          ? invitesHtml.push(
              <p className="check" key={index}>
                <i className="fas fa-check"></i> {invite.email}
              </p>
            )
          : '';
      });
    } else {
      invitesRole.map((invite, index) => {
        return invite.role === role
          ? invitesHtml.push(
              <p className="check" key={index}>
                <i className="fas fa-check"></i> {invite.email}
              </p>
            )
          : '';
      });
    }

    let number =
      invitesHtml.length > 0 ? invitesRole.length - invitesHtml.length : 0;

    return (
      <div className="col-md-3">
        {invitesHtml}
        {number > 0 ? (
          <p className="green-color">
            +{number} {t('more invited')}
          </p>
        ) : (
          ''
        )}
      </div>
    );
  };

  useEffect(() => {
    props.clearErrors();
  }, []);

  const sendInvite = (role, mail, e) => {
    const { invites } = props.company;
    let email;
    if (mail == 'hrMail') {
      email = hrMail;
    } else if (mail == 'memberMail') {
      email = memberMail;
    }
    if (!email || email === '') return seterrorMsg('Please add email');
    let emailFound = false;
    invites.forEach((invite) => {
      if (invite.email === email) {
        emailFound = true;
      }
    });
    if (emailFound) return seterrorMsg('This email already added');
    let data = { role, email };
    seterrorMsg(undefined);
    sethrMail('');
    setmemberMail('');
    props.sendInvite(data);
  };

  useEffect(() => {
    if (props.errors.id === 'SEND_INVITE_FAIL') {
      seterrorMsg(props.errors.msg);
    }
  }, [props.errors.id]);

  return (
    <div className="page adminRoles">
      <h1
        style={
          localStorage.getItem('i18nextLng') == 'ar'
            ? { width: '100%', textAlign: 'right' }
            : { width: '100%', textAlign: 'left' }
        }
      >
        {t('Admin Roles')}
      </h1>
      <p
        className="caption"
        style={
          localStorage.getItem('i18nextLng') == 'ar'
            ? { width: '100%', textAlign: 'right' }
            : { width: '100%', textAlign: 'left' }
        }
      >
        {t(
          "Now it's time to assign your administration team members Who Will be in charge of managing your Hollydesk account"
        )}
      </p>
      {errorMsg ? (
        <div className="row">
          <div className="col-md-6">
            <div className="alert alert-danger">{errorMsg}</div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="row first-row">
        <div className="col-md-1 text-center">
          <img
            className="img-fluid"
            src="/images/human.png"
            alt="human resources"
          />
        </div>
        <div className="col-md-8 text-left align-self-center">
          <h4
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {t('Human Reasourses (Adminstrators)')}
          </h4>
          <p
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {t(' in charge of inviting and managing all company team members')}
          </p>
        </div>
      </div>
      <p
        style={
          localStorage.getItem('i18nextLng') == 'ar'
            ? { width: '100%', textAlign: 'right' }
            : { width: '100%', textAlign: 'left' }
        }
      >
        {t('Invite By Email')}
      </p>
      <div className="row second-row">
        <div className="col-md-4">
          <input
            className="form-control"
            onChange={(e) => sethrMail(e.target.value)}
            value={hrMail}
            type="text"
            placeholder={t('enter business email address')}
          />
        </div>
        <div
          className="col-md-2 text-center"
          style={
            localStorage.getItem('i18nextLng') == 'ar'
              ? { width: '100%', textAlign: 'right' }
              : { width: '100%', textAlign: 'left' }
          }
        >
          <button
            className="btn btnInvite"
            onClick={(e) => sendInvite('hr', 'hrMail', e)}
          >
            {t('Send Invite')}
          </button>
        </div>
        {renderInvites('hr')}
      </div>
      <div className="row first-row mt-5">
        <div className="col-md-1 text-center">
          <img className="img-fluid" src="/images/member.png" alt="member" />
        </div>
        <div className="col-md-8 text-left align-self-center">
          <h4
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {' '}
            {t('Board Members')}
          </h4>
          <p
            style={
              localStorage.getItem('i18nextLng') == 'ar'
                ? { width: '100%', textAlign: 'right' }
                : { width: '100%', textAlign: 'left' }
            }
          >
            {t('Founders and shareholders of the company')}
          </p>
        </div>
      </div>
      <p
        style={
          localStorage.getItem('i18nextLng') == 'ar'
            ? { width: '100%', textAlign: 'right' }
            : { width: '100%', textAlign: 'left' }
        }
      >
        {t('Invite By Email')}
      </p>
      <div className="row second-row">
        <div className="col-md-4">
          <input
            className="form-control"
            value={memberMail}
            onChange={(e) => setmemberMail(e.target.value)}
            type="text"
            placeholder={t('enter business email address')}
          />
        </div>
        <div
          className="col-md-2 text-center"
          style={
            localStorage.getItem('i18nextLng') == 'ar'
              ? { width: '100%', textAlign: 'right' }
              : { width: '100%', textAlign: 'left' }
          }
        >
          <button
            className="btn btnInvite"
            onClick={() => sendInvite('member', 'memberMail')}
          >
            {t('Send Invite')}
          </button>
        </div>
        {renderInvites('member')}
      </div>
      <div className="row justify-content-center fifth-row">
        <div
          className="col-md-6"
          style={
            localStorage.getItem('i18nextLng') == 'ar'
              ? { width: '100%', textAlign: 'right' }
              : { width: '100%', textAlign: 'left' }
          }
        >
          <button
            className="btn btnPrimary mt-5 mb-5"
            onClick={() => {
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let id = params.get('id');
              let token = params.get('token');

              props.finishSetup(id, token);
            }}
          >
            {t('Finish Company Setup!')}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error,
    company: state.company,
  };
};

export default connect(mapStateToProps, {
  sendInvite,
  clearErrors,
  finishSetup,
})(AdminRoles);
