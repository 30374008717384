import {
  LIST_PENDING_REQUESTS,
  APPROVE_REQUESTS,
  DECLINE_REQUESTS,
  LIST_PENDING_INADVANCE_REQUESTS,
  APPROVE_INADCANCE_REQUESTS,
  DECLINE_INADCANCE_REQUESTS,
  APPROVE_RECONCILE_REQUESTS,
  DECLINE_RECONCILE_REQUESTS,
} from '../actions/types';

const INITIAL_STATE = {
  pendings: [],
  exel: [],
  pendingsInAdvance: []
};

let exel;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_PENDING_REQUESTS:
        exel = [];
        if (action.payload) {
            for (const iterator of action.payload) {
            exel = [...exel, ...iterator.pending.requests];
            }
            for (const iterator of exel) {
            iterator.name =
                iterator.from.firstName + ' ' + iterator.from.lastName;
            }
        }
        return {
            ...state,
            pendings: action.payload,
            exel: exel,
        };
    case APPROVE_REQUESTS:
        return {
            ...state,
        };
    case DECLINE_REQUESTS:
        return {
            ...state,
        };
    case LIST_PENDING_INADVANCE_REQUESTS:
        console.log(action.payload)
        exel = [];
        if (action.payload) {
            for (const iterator of action.payload) {
            exel = [...exel, ...iterator.pending.requests];
            }
            for (const iterator of exel) {
            iterator.name =
                iterator.from.firstName + ' ' + iterator.from.lastName;
            }
        };
        return {
            ...state,
            pendingsInAdvance: action.payload,
            exel: exel,
        };
    
    case APPROVE_INADCANCE_REQUESTS:
        return {
            ...state,
        };

    case DECLINE_INADCANCE_REQUESTS:
        return {
            ...state,
        };

    case APPROVE_RECONCILE_REQUESTS:
        return {
            ...state,
        };

    case DECLINE_RECONCILE_REQUESTS:
        return {
            ...state,
        };

    default:
        return state
  }
};
