import React from "react";

import LoginForm from "./loginForm/loginForm";
import LoginBanner from "./loginBanner/loginBanner";

import "./login.css";

const Login = () => {
  return (
    <section className="login-page-wrapper">
      <div className="login-page">
        <LoginForm />
        <LoginBanner />
      </div>
    </section>
  );
};

export default Login;
