import axios from "axios";
import { useState, useEffect } from "react";

const useAxios = configObj => {
  const { axiosInstance, method, url, requestConfig = {} } = configObj;

  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);

  const refetch = () => setReload(prev => prev + 1);

  useEffect(() => {
    let isMounted = true;
    const ourRequest = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await axiosInstance[method.toLowerCase()](url, {
          ...requestConfig,
          cancelToken: ourRequest.token
        });
        isMounted && setResponse(res.data);
      } catch (err) {
        isMounted && setError(err.message);
      } finally {
        isMounted && setLoading(false);
      }
    };

    // call the function
    fetchData();

    // useEffect cleanup function
    return () => {
      ourRequest.cancel();
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [reload]);

  return [response, error, loading, refetch];
};

export default useAxios;
