import React from "react";
import { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  approveRequests,
  declineRequests
} from "../../../../../../../actions/pendingRequestsActions";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { useTranslation } from "react-i18next";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import noReceipts from "../../../../../../../assets/images/noReceipts.png";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Avatar from "antd/lib/avatar/avatar";

import "./item-request.css";
import {
  editRequestCategory,
  editRequestCategoryInAdvance
} from "../../../../../../../actions/categoryActions";
import { useLocation } from "react-router-dom";

const ItemRequest = props => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const currency = useSelector(state => state.auth.user.currency[0]);
  const categories = useSelector(state => state.categories.categories);
  const cases = useSelector(state => state.categories.cases) || [];
  const user = useSelector(state => state.auth.user.role);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const location = useLocation();
  console.log(location);

  const [showModal, setShowModal] = React.useState(false);
  const [request, setRequest] = React.useState({});
  const [categoryId, setCategoryId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setRequest(props.request);
    setCategoryId(props.request?.category?._id);
    setCaseId(props.request?.case?._id);
  }, [props.request]);

  const onImageClick = () => {
    setShowModal(true);
  };

  const categoryOptions = categories.map(cat => {
    return (
      <option
        value={cat._id}
        selected={request && request.category?._id === cat.id}
      >
        {cat.name}
      </option>
    );
  });

  const caseOptions = cases.map(cat => {
    return (
      <option
        value={cat._id}
        selected={request && request.case?._id === cat.id}
      >
        {cat.name}
      </option>
    );
  });

  const toggleDrawer = (anchor, open) => event => {
    event.stopPropagation();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const statusColor = () => {
    if (props.request.status.toLowerCase().includes("approve")) {
      return {
        color: "#268c55",
        backgroundColor: "#e3fbee",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    } else if (props.request.status.toLowerCase().includes("declin")) {
      return {
        color: "#e32424",
        backgroundColor: "#ffebeb",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    } else if (props.request.status.toLowerCase().includes("pend")) {
      return {
        color: "#f4a800",
        backgroundColor: "#fff4dd",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    }
  };

  const renderImages = request => {
    const images = request.images;
    if (images && images.length > 0) {
      return images.map((image, index) => {
        return image.path.split(".").pop() === "pdf" ? (
          <div
            className="request-image d-inline-block"
            style={{ overflow: "auto" }}
          >
            <Document
              style={{ width: "100%" }}
              file={image.path}
              onLoadSuccess={onDocumentLoadSuccess}
              key={index}
            >
              <Page width={160} pageNumber={pageNumber} />
            </Document>
          </div>
        ) : (
          <div className="request-image d-inline-block">
            <img
              key={index}
              src={
                image.path.split(".").pop() == "pdf"
                  ? "/images/icons8-file-64.png"
                  : image.path
              }
              alt={image.filename}
              onClick={e => onImageClick(image.path, e)}
              className="img-fluid d-inline-block"
            />
          </div>
        );
      });
    } else {
      return (
        <div className="badge badge-secondary">
          {t("This request doesn't have images")}
        </div>
      );
    }
  };

  return (
    <React.Fragment key={"right"}>
      <div className="request">
        <div className="row align-items-center">
          <div
            className="col-md-6"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <div className="row align-items-center">
              <div className="col-md-12 req-card">
                <div className="req-owner-info">
                  {props.request ? (
                    props.request.from.userImage !== "" ? (
                      <Avatar
                        src={props.request.from.userImage}
                        shape="square"
                        size={40}
                      />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#d6edff",
                          verticalAlign: "middle",
                          color: "#405261"
                        }}
                        size="large"
                        shape="square"
                      >
                        {props.request ? props.request.from.firstName : ""}
                      </Avatar>
                    )
                  ) : (
                    ""
                  )}
                  <span
                    className="d-inline-block"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? {
                            width: "fit-content",
                            textAlign: "right",
                            margin: "0px 10px"
                          }
                        : {
                            width: "fit-content",
                            textAlign: "left",
                            margin: "0px 10px"
                          }
                    }
                  >
                    <b>{props.request.name}</b> <br />
                    <b style={{ color: "#a8acaf" }}>{props.request.number}</b>
                  </span>
                </div>
                <div className="d-inline-block request-images">
                  {renderImages(props.request)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row align-items-center">
              <div className="col-5 text-center">
                <span>
                  {t(currency)} {request.amount}
                </span>
              </div>
              <div className="col-3 text-center mr-5">
                <div className="badge badge-info p-1" style={statusColor()}>
                  {t(request.status)}
                </div>
              </div>
              <div className="col-1"></div>
              <div
                className="col-1"
                onClick={toggleDrawer("right", true)}
              ></div>
            </div>
          </div>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
          >
            <Modal.Body
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    {props.request ? (
                      props.request.images.length > 0 ? (
                        <div
                          id="carouselExampleIndicators"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <ol
                            class="carousel-indicators"
                            style={{ display: "none" }}
                          >
                            {props.request.images.map((obj, index) => {
                              return (
                                <li
                                  data-target="#carouselExampleIndicators"
                                  data-slide-to={index}
                                  class="active"
                                ></li>
                              );
                            })}
                          </ol>
                          <div class="carousel-inner">
                            {props.request.images.map((obj, index) => {
                              return (
                                <div
                                  className={
                                    index == 0
                                      ? "active carousel-item"
                                      : "carousel-item"
                                  }
                                >
                                  {obj.path.split(".").pop() == "pdf" ? (
                                    <div style={{ overflow: "auto" }}>
                                      <Document
                                        style={{ width: "100%" }}
                                        file={obj.path}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                      >
                                        <Page
                                          width={400}
                                          style={{ width: "100%" }}
                                          pageNumber={pageNumber}
                                        />
                                      </Document>
                                    </div>
                                  ) : (
                                    <img
                                      class="d-block w-100"
                                      src={obj.path}
                                      alt="First slide"
                                    />
                                  )}
                                  <a
                                    href={obj.path}
                                    download
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "120px",
                                      zIndex: "150"
                                    }}
                                  >
                                    <button className="btn btnPrimary">
                                      <GetAppRoundedIcon /> {t("Down")}
                                    </button>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                          <a
                            class="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      ) : (
                        <div className="no-receipt">
                          <img src={noReceipts} alt="no receipt" />
                          <div className="red-warning" style={{ width: "95%" }}>
                            <div className="warning-head">
                              <WarningRoundedIcon /> <p>{t("noReq1")}</p>
                            </div>
                            <p>{t("noReq2")}</p>
                          </div>
                        </div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="">
                      <h2>
                        {t("request")} #
                        {props.request ? props.request.number : ""}
                      </h2>
                      <div
                        className="req-owner-info"
                        style={{ width: "100%", justifyContent: "flex-start" }}
                      >
                        {props.request ? (
                          props.request.from.userImage !== "" ? (
                            <Avatar
                              src={props.request.from.userImage}
                              shape="square"
                              size={40}
                            />
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: "#d6edff",
                                verticalAlign: "middle",
                                color: "#405261"
                              }}
                              size="large"
                              shape="square"
                            >
                              {props.request
                                ? props.request.from.firstName
                                : ""}
                            </Avatar>
                          )
                        ) : (
                          ""
                        )}
                        <span
                          className="d-inline-block"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? {
                                  width: "fit-content",
                                  textAlign: "right",
                                  margin: "0px 10px"
                                }
                              : {
                                  width: "fit-content",
                                  textAlign: "left",
                                  margin: "0px 10px"
                                }
                          }
                        >
                          <b>{props.request.name}</b> <br />
                          <b style={{ color: "#a8acaf" }}>
                            {props.request.createdAt.split("T")[0]}
                          </b>
                        </span>
                      </div>
                      <p className="req-status" style={statusColor()}>
                        {props.request ? t(props.request.status) : ""}
                      </p>
                      <div className="data-row">
                        <div className="data-element">
                          <h5 className="label">{t("amount")}</h5>
                          <p className="value popup-data">
                            {props.request ? props.request.currency : ""}{" "}
                            {props.request ? props.request.amount : ""}
                          </p>
                        </div>
                        <div className="data-element">
                          <h5 className="label">{t("payToInAd")}</h5>
                          <p className="value popup-data">
                            {props.request ? props.request.paidTo : ""}
                          </p>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="data-element">
                          <h5 className="label">{t("createdAt")}</h5>
                          <p className="value popup-data">
                            {props.request
                              ? props.request.createdAt.split("T")[0]
                              : ""}
                          </p>
                        </div>
                        <div className="data-element">
                          <h5 className="label">{t("payDate")}</h5>
                          <p className="value popup-data">
                            {props.request
                              ? props.request.paidOn.split("T")[0]
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="paid-for">
                        <h5 className="label">{t("payForInAd")}</h5>
                        <p className="value popup-data">
                          {request ? request.paidFor : ""}
                        </p>
                      </div>
                      {user !== "accountant" && (
                        <div className="data-row">
                          <div className="data-element">
                            <h5 className="label">{t("Category")}</h5>
                            <p className="value popup-data">
                              {props.request?.category?.name === "" ||
                              !props.request?.category
                                ? t("Not selected")
                                : props.request?.category?.name}
                            </p>
                          </div>
                          <div className="data-element">
                            <h5 className="label">{t("Case")}</h5>
                            <p className="value popup-data">
                              {props.request?.case?.name === "" ||
                              !props.request?.case
                                ? t("Not selected")
                                : props.request?.case?.name}
                            </p>
                          </div>
                        </div>
                      )}
                      {user === "accountant" && (
                        <>
                          <div
                            className="form-row"
                            style={{ marginTop: "0px", marginBottom: "10px" }}
                          >
                            <div className="row-element">
                              <h5 className="label">{t("Category")}</h5>
                              <select
                                className="form-control"
                                onChange={e => setCategoryId(e.target.value)}
                              >
                                <option value="">{t("Select Category")}</option>
                                {categoryOptions}
                              </select>
                            </div>
                            <div className="row-element">
                              <h5 className="label">{t("Case")}</h5>
                              <select
                                className="form-control"
                                onChange={e => setCaseId(e.target.value)}
                              >
                                <option value="">{t("Select Case")}</option>
                                {caseOptions}
                              </select>
                            </div>
                          </div>
                          <button
                            className="btn btnPrimary"
                            onClick={() =>
                              location.pathname ===
                              "/dashboard/expense/reimbursement"
                                ? dispatch(
                                    editRequestCategory({
                                      requestID: props.request._id,
                                      categoryId,
                                      caseId
                                    })
                                  )
                                : dispatch(
                                    editRequestCategoryInAdvance({
                                      requestID: props.request._id,
                                      categoryId,
                                      caseId
                                    })
                                  )
                            }
                          >
                            {t("Change category & case")}
                          </button>
                        </>
                      )}
                    </div>
                  </Col>
                  <CloseOutlinedIcon
                    className="close-icon"
                    onClick={() => setShowModal(false)}
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { right: "auto", left: "5px" }
                        : { right: "5px", left: "auto" }
                    }
                  />
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { approveRequests, declineRequests })(ItemRequest);
