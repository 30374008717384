import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { clearErrors } from '../../../../actions/errorActions';
import { addSignature } from '../../../../actions/companyActions';
import { resetUpload } from '../../../../actions/uploadActions';
import Upload from '../../../website/components/upload/upload';
import history from '../../../../history';

import { Link } from 'react-router-dom';
import '../pages.css';
import './addSignature.css';

class AddSignature extends React.Component {
  state = {
    msg: undefined,
  };

  renderInput = ({ input, label, meta, type, placeholder }) => {
    return (
      <div className="inputComp">
        <label>{label}</label>
        <input
          className="form-control"
          placeholder={placeholder}
          {...input}
          type={type}
          autoComplete="off"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderError({ error, touched }) {
    if (error && touched) {
      return <div className="text-danger">{error}</div>;
    }
  }

  componentDidMount() {
    const { status } = this.props.company;
    if (status === 60) return history.push(`/create/bank`);
    if (status === 20) return history.push(`/create/company`);
    this.props.reset('addSignature');
    this.props.clearErrors();
    this.props.resetUpload();
  }

  onSubmit = (values) => {
    let { file, name } = this.props.upload;
    if (file && name === 'signature') {
      values = { ...values, file };
      this.props.addSignature(values);
    }
  };

  render() {
    return (
      <div className="page addSignature">
        <div className="row">
          <div className="col-md-6">
            <h1>E-Signature</h1>
            <p className="lead">
              Upload your financial E-Signature to approve financial statements
              with just a click later on!
            </p>
          </div>
        </div>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row forms">
            <div className="col-md-8">
              <Upload name="signature" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4">
              <Field
                label="Signatory name"
                name="name"
                type="text"
                required
                placeholder="Signatory Name"
                component={this.renderInput}
              />
              <div className="row mt-3">
                <div className="col-md-1">
                  <input
                    type="checkbox"
                    name="agreeTerms"
                    className="checkBox d-inline-block"
                  />
                </div>
                <div className="col-md-11">
                  <label className="checkLabel d-inline-block">
                    I agree I’m in charge of signature on financial documents
                    and statments.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <Link
                    className="btn btnSkip  float-right"
                    to={`/create/admin-roles`}
                  >
                    Skip step for now
                  </Link>
                </div>
                <div className="col-md-4">
                  <button className="btn btnPrimary" type="submit">
                    Continue to Admin roles
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.signature) {
    errors.name = 'Please add signature name';
  }
  if (!formValues.name) {
    errors.name = 'Please add signature name';
  }

  if (!formValues.agreeTerms) {
    errors.agreeTerms = 'Please agree';
  }
};

const mapStateToProps = (state) => {
  return {
    errors: state.error,
    company: state.company,
    upload: state.upload,
  };
};

const reduxMiddleware = reduxForm({
  form: 'addSignature',
  validate: validateForm,
  enableReinitialize: true,
})(AddSignature);

export default connect(mapStateToProps, {
  reset,
  clearErrors,
  addSignature,
  resetUpload,
})(reduxMiddleware);
