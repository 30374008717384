import api from '../apis/api';
import { returnErrors, clearErrors } from './errorActions';
import { setLoading, removeLoading } from './loadingActions';
import { tokenConfig, tokenConfigUpload } from './tokenActions';
import cogoToast from 'cogo-toast';
import history from '../history';
import {
  REGISTER_COMPANY,
  VERIFY_EMAIL,
  REGISTER_FAIL,
  VERIFY_FAIL,
  ADD_COMPANY_DETAILS,
  ADD_BANK,
  SKIP_BANK,
  ADD_SIGNATURE,
  START_ADD_COMPANY,
  RESEND_MAIL,
  RESEND_FAIL,
  SEND_INVITE,
  FINISH_SETUP,
  LIST_TRANSACTIONS
} from "./types";
import i18n from "../translations/i18n";


export const startAddCompany = () => {
  return {
    type: START_ADD_COMPANY
  };
};

export const registerCompany = data => async dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());
  let config = {
    headers: {

      'Content-Type': 'application/json',
    },
  };

  data = JSON.stringify(data);

  api
    .post("/register/register-manager", data, config)
    .then(res => {

      //console.log(res.data);
      if (res.data.status === 1) {
        dispatch({ type: REGISTER_COMPANY, payload: res.data.data });
        dispatch(removeLoading());
        history.push('/register-success');
      } else {
        //console.log(res.data.message, res.data.status, REGISTER_FAIL);
        cogoToast.error(i18n.t(res.data.message));
        dispatch(
          returnErrors(res.data.message, res.data.status, REGISTER_FAIL)
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const resendMail = (id, token) => async dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .get(`/register/resend-email?token=${token}&id=${id}`)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: RESEND_MAIL, payload: res.data.data });
        dispatch(removeLoading());
      } else {
        dispatch(returnErrors(res.data.message, res.data.status, RESEND_FAIL));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const verifyEmail = (id, token) => async dispatch => {
  dispatch(clearErrors());
  dispatch(setLoading());

  api
    .get(`/register/verify-email?token=${token}&id=${id}`)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: VERIFY_EMAIL, payload: res.data.data });
        dispatch(removeLoading());
        if (localStorage.getItem("i18nextLng") == "en") {
          cogoToast.success(i18n.t("Email Verified"));
        } else if (localStorage.getItem("i18nextLng") == "en") {
          cogoToast.success("تم تفعيل حسابك");

        }
      } else {
        dispatch(returnErrors(res.data.message, res.data.status, VERIFY_FAIL));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const addCompanyDetails = data => async (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  let config = {
    headers: {
      "Content-Type": "application/json"
    }

  };

  // let { id, token } = state().company
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  let token = params.get('token');
  localStorage.setItem('id', id);
  localStorage.setItem('token', token);

  data = JSON.stringify(data);

  api
    .post(`/register/register-company?id=${id}&token=${token}`, data, config)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: ADD_COMPANY_DETAILS, payload: res.data.data });
        dispatch(removeLoading());
        history.push(`/create/admin-roles?id=${id}&token=${token}`);
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, 'ADD_COMPANY_FAIL')
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const addBank = data => async (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  let config = {
    headers: {
      "Content-Type": "application/json"
    }

  };

  //let { id, token } = state().company;
  let id = localStorage.getItem('id');
  let token = localStorage.getItem('token');

  data = JSON.stringify(data);

  api
    .post(`/register/addbank?id=${id}&token=${token}`, data, config)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: ADD_BANK, payload: res.data.data });
        dispatch(removeLoading());
        history.push(`/create/signature`);
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, 'ADD_BANK_FAIL')
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};
// export const skipBank = (data) => async (dispatch, state) => {
//   dispatch(clearErrors());
//   dispatch(setLoading());

//   dispatch({ type: SKIP_BANK });
//   dispatch(removeLoading());
//   history.push(`/create/signature`);
// };

export const addSignature = data => async (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  let config = {
    headers: {
      "Content-Type": "application/json"
    }

  };

  // let { id, token } = state().company;
  let id = localStorage.getItem('id');
  let token = localStorage.getItem('token');
  data = JSON.stringify(data);

  api
    .post(`/register/add-signature?id=${id}&token=${token}`, data, config)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: ADD_SIGNATURE, payload: res.data.data });
        dispatch(removeLoading());
        history.push(`/create/admin-roles`);
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, 'ADD_SIGNATURE_FAIL')
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const sendInvite = data => (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  let config = {
    headers: {
      "Content-Type": "application/json"
    }

  };

  let id = localStorage.getItem('id');
  let token = localStorage.getItem('token');
  data = JSON.stringify(data);

  api
    .post(`/register/send-invite?id=${id}&token=${token}`, data, config)
    .then(res => {
      if (res.data.status === 1) {
        dispatch({ type: SEND_INVITE, payload: res.data.data });
        dispatch(removeLoading());
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, 'SEND_INVITE_FAIL')
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const finishSetup = (id, token) => (dispatch, state) => {
  dispatch(clearErrors());
  dispatch(setLoading());

  let config = {
    headers: {
      "Content-Type": "application/json"
    }

  };

  // let { id, token } = state().company;

  api
    .post(`/register/finish-setup?id=${id}&token=${token}`, {}, config)
    .then(res => {

      if (res.data.status === 1) {
        dispatch({ type: FINISH_SETUP, payload: res.data.data });
        dispatch(removeLoading());
        history.push(`/finish-setup`);
      } else {
        dispatch(
          returnErrors(res.data.message, res.data.status, 'FINISH_SETUP_FAIL')
        );
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const loadTransactions = () => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  api
    .get("/company/transations", tokenConfig(getState))
    .then(res => {

      if (res.data.status === 1) {
        dispatch({
          type: LIST_TRANSACTIONS,
          payload: res.data.data
        });
      } else {
        dispatch(returnErrors(res.data.message, res.data.status));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const loadCompanyData = () => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch(setLoading());
  api
    .get("/company/data", tokenConfig(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: "COMPANY_DATA",
          payload: res.data.data.company

        });
      } else {
        dispatch(returnErrors(res.data.message, res.data.status));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const loadCompanyCountries = () => async (dispatch, getState) => {
  console.log('hiiii');
  api
    .get("/register/countries", tokenConfig(getState))
    .then(res => {
      console.log(res.data);
      if (res.data.status === 1) {
        dispatch({
          type: "COUNTRIES",
          payload: res.data.data

        });
      } else {
        dispatch(returnErrors(res.data.message, res.data.status));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};

export const getSavedCards = () => async (dispatch, getState) => {
  api
    .get("/company/savedCards", tokenConfig(getState))
    .then(res => {
      console.log(res.data);
      if (res.data.status === 1) {
        dispatch({
          type: "GET_SAVED_CARDS",
          payload: res.data.data.cards

        });
      } else {
        dispatch(returnErrors(res.data.message, res.data.status));
      }
    })
    .catch(err => {
      dispatch(returnErrors(err, 0));
    });
};
