import {
  ADD_FILE,
  REMOVE_FILE,
  START_UPLOAD,
  UPLOAD_FINISHED,
  UPLOAD_SUPMITTED,
  ADD_FILES,
  REMOVE_LOADING,
  INITIAL_PICS
} from "./types";
import { returnErrors, clearErrors } from "./errorActions";
import { /* tokenConfig,*/ tokenConfigUpload } from "./tokenActions";
import api from "../apis/api";
import cogoToast from "cogo-toast";
import { loadRequestCount, loadInadvanceRequestCount } from "./authActions";
import i18n from "../translations/i18n";
export const removePic = index => async (dispatch, getState) => {
  dispatch(clearErrors());
  loadRequestCount();
  loadInadvanceRequestCount();

  dispatch({
    type: REMOVE_FILE,
    payload: {
      index: index
    }
  });
};

export const initialPics = pics => async (dispatch, getState) => {
  dispatch(clearErrors());
  loadRequestCount();
  loadInadvanceRequestCount();

  dispatch({
    type: INITIAL_PICS,
    payload: {
      pics: pics
    }
  });
};

export const uploadPicture = (data, name = "recharge") => async (
  dispatch,
  getState
) => {
  loadRequestCount();
  loadInadvanceRequestCount();

  dispatch(clearErrors());
  dispatch({ type: START_UPLOAD });

  api
    .post(`/upload/image?type=${name}`, data, tokenConfigUpload(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: ADD_FILE,
          payload: {
            name: name,
            file: res.data.data.location
          }
        });
      } else {
        dispatch({ type: UPLOAD_FINISHED });
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_FILE_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch({ type: UPLOAD_FINISHED });
      dispatch(returnErrors(err, 0));
    });
};

export const addPayroll = (data, name) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch({ type: START_UPLOAD });
  loadInadvanceRequestCount();

  //console.log(data);

  api
    .post("/upload/payroll?type=payroll", data, tokenConfigUpload(getState))
    .then(res => {
      if (res.data.status === 1) {
        cogoToast.success(i18n.t("uploaded"));
        dispatch({ type: REMOVE_LOADING });
      } else {
        dispatch({ type: UPLOAD_FINISHED });
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_FILES_FAIL")
        );
      }
    })
    .catch(err => {
      dispatch({ type: UPLOAD_FINISHED });
      dispatch(returnErrors(err, 0));
    });
};

export const uploadRecipt = (data, name) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch({ type: START_UPLOAD });

  api
    .post(
      "/upload/bankRechrge?type=requests",
      data,
      tokenConfigUpload(getState)
    )
    // ({
    //   url: '/upload/bankRechrge?type=requests',
    //   method: 'post',
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     'x-auth-token': `Bearer ${localStorage.getItem('token')}`,
    //     'Access-Control-Allow-Origin': '*',
    //   },
    //   data: data,
    // })
    .then(res => {
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      if (res.data.status === 1) {
        cogoToast.success(i18n.t("uploaded"));
        dispatch({
          type: ADD_FILES,
          payload: {
            name: name,
            files: res.data.data
          }
        });
      } else {
        dispatch({ type: UPLOAD_FINISHED });
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_FILES_FAIL")
        );
        dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
      }
    })
    .catch(err => {
      dispatch({ type: UPLOAD_FINISHED });
      dispatch(returnErrors(err, 0));
      dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
    });
};

export const uploadPictures = (data, name) => async (dispatch, getState) => {
  dispatch(clearErrors());
  dispatch({ type: START_UPLOAD });

  api
    .post("/upload/images?type=requests", data, tokenConfigUpload(getState))
    .then(res => {
      if (res.data.status === 1) {
        dispatch({
          type: ADD_FILES,
          payload: {
            name: name,
            files: res.data.data
          }
        });
      } else {
        dispatch({ type: UPLOAD_FINISHED });
        dispatch(
          returnErrors(res.data.message, res.data.status, "ADD_FILES_FAIL")
        );
      }
    })
    .catch(err => {
      cogoToast.error(i18n.t("This file type is not supported"));
      dispatch({ type: UPLOAD_FINISHED });
      dispatch(returnErrors(err, 0));
    });
};
// export const removePic = (index) => async (dispatch, getState) => {
//   dispatch(clearErrors());
//   dispatch({ type: START_UPLOAD });

//   dispatch({
//     type: REMOVE_FILE,
//     payload: {
//       index: index,
//     },
//   });
// };

// export const removePicture = (index) => async (dispatch) => {
//   dispatch(clearErrors());
//   dispatch({ type: START_UPLOAD });

//   api
//     .post('/upload/images?type=requests', data, tokenConfigUpload(getState))
//     .then((res) => {
//       if (res.data.status === 1) {
//         //console.log(res.data.data);
//         dispatch({
//           type: REMOVE_FILE,
//           payload: {
//             name: name,
//             files: res.data.data,
//           },
//         });
//       } else {
//       }
//     })
//     .catch((err) => {
//       dispatch({ type: REMOVE_FILE });
//       dispatch(returnErrors(err, 0));
//     });
//   return {
//     type: REMOVE_FILE,
//   };
// };

export const resetUpload = () => {
  return {
    type: UPLOAD_SUPMITTED
  };
};
