import {
    SET_LOADING,
    REMOVE_LOADING
} from '../actions/types'

let INITIAL_STATE = {
    loading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                loading: true
            }
        case REMOVE_LOADING:
            return {
                loading: false
            }
        default:
            return state
    }
}