export const TRANSLATIONS_AR = {
  why: "لماذا هولى ديسك",
  demo: "  تجربة مجانية",
  welcome: "اهلًا بك في هولي ديسك!  الصديق الخاص لمدفوعات الموظفين",
  welcomeP:
    "امنح  موظفيك الصلاحية لتقديم الأعمال الخاصة بالفواتير ليحصلوا على مستحقاتهم على الفور  مع الإحتفاظ بالسجلات المالية مطابقة للمعايير",
  logIn: "تسجيل الدخول",
  signUp: "انشاء حساب",
  signIn: "تسجيل الدخول لحسابك 🚀 ",
  trusted: "بثقة من",
  email: "البريد الالكترونى",
  "New Email": "البريد الالكترونى الجديد",
  password: "كلمة السر",
  forget1: "لا استطيع تذكر كلمة السر",
  forget2: "نسيت كلمة السر",
  contact1: "تواجه مشكلة في تسجيل الدخول، ",
  contact2: "تواصل معنا",
  dontHaveAccount: "ليس لديّ حساب، ",
  verification1: "نسيت كلمة السر",
  verification2:
    "لقد ارسلنا رسالة تحقق لبريدك الالكتروني للتأكد من شخصيتك، برجاء ادخال رمز التحقق او من الضغط على اعادة ضبط كلمة السر من العنوان المرفق في البريد الالكتروني",
  loginText1: "تابع مصاريف شركتك اليومية، ",
  loginText2: "من اي مكان وفي أي وقت",
  dontReceive: "لم يصلك البريد الالكتروني؟ ",
  "Didn't Recieve SMS?": "لم تصلك الرسالة النصية؟ ",
  Resend: "اعادة ارسال",
  session: "تنتهي الفترة خلال ",
  minutes: " دقيقة",
  verify: "التحقق من شخصي",
  teamReq: "طلبات الفريق",
  ExpenseClaims: "المدفوعات المطلوبة",
  dashboard: "لوحة التحكم",
  requests: "طلباتي",
  companyWallet: "محفظة الشركة",
  payroll: "المرتبات",
  changePassword: "تغير كلمة السر",
  logOut: "خروج",
  team: "فريق العمل",
  team__: "الفريق",
  team___: "فريق",
  home: "الرئيسية",
  invite: "دعوة موظف",
  pending: "الطلبات المؤجلة",
  approved: "الطلبات المقبولة",
  declined: "الطلبات المرفوضة",
  myRequest: "طلبات السداد",
  Reimbursement: "السداد",
  myReqInAdv: "طلبات الدفع مقدما",
  "In Advance": "الدفع مقدما",
  rr: "طلبات السداد",
  myWallet: "محفظتى",
  EGP: "جنيه مصرى",
  SAR: "ريال سعودي",
  EGPBalance: "الرصيد",
  "SAR Balance": "الرصيد",
  hello: "مرحبا",
  filter: "بحث",
  to: "إلى",
  companyExpenses: "مصاريف الشركة",
  type: "طريقة الدفع",
  amount: "المدفوع",
  ID: "الكود",
  name: "الاسم",
  level: "المسمّى الوظيفى",
  joined: "تاريخ الانضمام",
  status: "الحالة",
  activation: "التفعيل",
  action: "الاجرائات",
  title: "اللقب",
  setup: "قم بإعداد بيانات الشركة",
  getStart: "إبدأ!",
  companyDetails: "معلومات عن الشركة",
  companyDP: "اكتب معلومات شركتك و إملأ البيانات المطلوبة",
  adminRoles: "دور المسؤول",
  anyTroubles: "لديك مشكلة؟",
  contactUs: "تواصل معنا",
  getStartP: " مرحبا 👋, قبل إعداد شركتك للبدء على هولي ديسك، لنعرف عنك اكثر",

  fName: "الاسم الأول",
  fNamePH: "رامى",
  lName: "اسم العائلة",
  lNamePH: "صفوت",
  pass: "كلمة المرور",
  passPH: "ادخل كلمة المرور",
  team_: "ماهو حجم فريقك؟",
  teamPH: "500",
  bmail: "البريد الإلكتروني",
  bmailPH: "example@gmail.com",
  cPassword: "تأكيد كلمة المرور",
  cPasswordPH: "كرّر كلمة المرور",
  role_: "دورك في الشركة",
  rolePH: "مدير الشركة",
  budget: "كم تعتقد سوف تنفق شهريًا مع هولي ديسك؟",
  startUrCompany: "قم بتفعيل حسابك",
  mailV: "تحقّق من البريد الإلكتروني!",
  mailV2:
    "لقد ارسلنا لك بريدًا إلكترونيًا  يحتوى على رابط تأكيد، انقر عليه للتّأكد من هويتك!",
  resend: "اعادة ارسال ",
  howToGet: " كيف تحصل على <u>طلباتك</u>؟ ",
  howToGetInAd: " كيف تحصل على <u>طلباتك</u>؟ ",
  step1: "1- التقط صورة واضحة للإيصال وارفقها",
  step2: "2-املأ بيانات السداد (القيمة-الشركة-الوصف- الى آخره)",
  step3: "3- قدّم طلب التسديد",
  step4: "4- احصل على نقودك عندما يتم الموافقة عليه من المدير",
  step1InAd: "1- قدم طلب للدفع مقدما",
  step2InAd: "2- احصل على نقودك عندما يتم الموافقة عليه من المدير",
  step3InAd: "3- التقط صورة واضحة للإيصال و ارفقها",
  step4InAd: "4- قم بتسوية نقودك بما دفعته",
  donthaver: "ليس لديك فاتورة؟",
  haver: "لديك فاتورة",
  downr: "حمل نموزج للفاتورة",
  newReq: "اضافة طلب جديد",
  uploadRecipt: "اختار الفاتورة",

  fillRI: "2. املأ تفاصيل الطلب",
  fillRI4: "2. املأ تفاصيل الفاتورة",
  totalAmountDue: "المبلغ المستحق",
  currency: "العملة",
  companyPaidTo: "مدفوع لشركة",
  companyPaidToPH: "مكتبة سمير وعلي",
  paidOn: "تاريخ الدفع",
  paidOnPH: "يوم / شهر / سنة",
  paidFor: "تم الدفع من اجل",
  paidForPH: "شراءاوراق طباعة",
  submit: " ارسال الطلب",
  fillRI2: "1.ارفع الفاتورة صورة او ملف",
  fillRI3: "2.ارفع الفاتورة صورة او ملف",
  imgUploaded: "تم الرفع بنجاح",
  imgUploaded2: "يمكنك اضافة صورة اخرى",
  imgUploaded3: "ارفق فاتورتك هنا او قم بالتصفح",
  imgUploaded4: "ندعم PDF,PNG,JPG يمكنك رفع صورة حتى 10 ميجا",
  inviteUsers: "دعوة موظفين",
  jobTitle: "المسمّى الوظيفى",
  jobTitlePH: "ادخل المسمّى الوظيفى",

  emp: "موظف",
  manager: "مدير",
  role: "الدور",
  inviteBTN: "دعوة",
  addTeam: "اضافة فريق",
  Pleasechooseateam: "قم باختيار فريق",

  companyLegal: "الاسم القانوني للشركة",
  companyLegalP:
    "تأكد من كتابة الإسم القانوني للشركة كما هو مكتوب في اوراق التسجيل الرسمية",
  Country: "الدولة",
  CountryPH: "مصر ",
  companyLegalPH: "شركة اتصالات مصر",
  officeAddressPH: "6 شارع محمد صبحى - الجيزة",
  city: "المدينة",
  citPH: "القاهرة",
  pcode: "الرقم البريدي",
  website: "الموقع الالكترونى",
  officeAddress: "عنوان الشركة",
  phone: "رقم تليفون الشركة",
  countToAdmin: "التالى  ",

  cashout0: "الحصول على النقود لم يكن بهذه السهوله!",
  cashout1: "عمليه آمنه و مضمونة لسحب الأموال",
  cashout2:
    "إختار وسيلتك المفضلة للحصول على أموالك من احد المحافظ الالكترونية او بتحويل بنكي",
  cashout3: "تتبع السجلات الخاصة بك",

  cashout: "صرف المستحقات",
  cashoutP: "سحب  جزء من الرصيد",
  cashoutAll: "سحب الرصيد بالكامل",
  ProccedToCashOut: "متابعة الى عملية السحب",

  selectcashout: "اختر طريقة صرف المستحقات",
  cashoutinfo: "بيانات الصرف",
  fullName: "الاسم بالكامل",
  accNum: "رقم الحساب",
  bankName: "اسم البنك",
  bankAddress: "عنوان البنك",

  donthavereq: "ليس لديك طلبات",
  time: "التاريخ",

  suc1: " تم ارسال طلبك !",
  suc2: "يقوم مديرونك بمراجعة الطلب الان بمجرد الموافقه عليه ستحصل على اموالك",
  sucInAd:
    "اذا تمت الموافقة على طلب الدفع مقدما، سيظل طلبك مفتوح حتى تقوم تقوم برفع فواتيرك للتسوية",
  reqno: "رقم الطلب",
  backtohome_: "عوده الى الرئيسية",
  sucRec1: "تم ارسال تسوية طلبك للدفع مقدما !",
  sucRec2: "سيراجع المحاسب فواتيرك للموافقة و اغلاق الطلب او رفض التسوية",

  ver: "مفعل",
  pend: "مؤجل",
  Deactivate: "تعطيل",
  Activate: "تفعيل",
  latestUpdate: "اخر تحديث",
  recStatus: "حالة التسوية",

  credit: "كارت بنكى",
  payinadv: "الدفع مؤخرا",
  bankt: "تحويل بنكى",
  fees: "ستكلفك هذه الطريق 3% من المبلغ المدفوع",
  cardNumber: "رقم الكارت",
  exp: "تاريخ الانتهاء",
  con: "تأكيد الدفع",

  BND: "بيانات الحساب البنكى",
  fees2: "سنقوم بشحن رصيدكم بعد 3-5 ايام عمل بعد عملية الشحن الناجحه",
  recipt: "الفاتورة",
  sendRecipt: " ارسال الفاتورة",
  feeCalc: "حاسبة التكاليف",
  fee3: "السعر بعد اضافة 0.5% نسبة التشغيل ",
  fee4: " السعر بعد اضافة 14%  فاتورة ضريبية",
  reset: "اعادة تهيئة",
  bank: "البنك",
  Decline: "رفض",
  Approve: "قبول",
  sAt: "اضيف بتاريخ",
  max: "المتاح=",
  oldPass: "كلمة المرور القديمة",
  newPass: "كلمة المرور الجديدة",
  rNewPass: "كرر كلمة المرور الجديدة",
  "request approved and money sent to user":
    "تم الموافقة على الطلب و تم ارسال المستحقات",
  "request declind from accountant": "تم رفض الطلب من المحاسب",
  " approved for board member": "تم الموافقة لأعضاء الادارة",
  "pending manager approval": "فى انتظار موافقة مدير الفريق",
  "awaiting accountant approve": "فى انتظار موافقة المحاسب",
  "Declined from team manager": "تم رفض الطلب من مدير الفريق",
  ////////
  "more invited": "مدعوين",
  "Admin Roles": "دور المسئول",
  "Now it's time to assign your administration team members Who Will be in charge of managing your Hollydesk account":
    "الان حان الوقت لتعيين اعضاء فريق الاداره الذين سيكونوا مسئولين عن ادارة حسابك في  هولي ديسك",
  "Human Reasourses (Adminstrators)": "الموارد البشريه",
  " in charge of inviting and managing all company team members":
    "مسئول عن دعوة و ادارة اعضاء الشركه",
  "Board Members": "اعضاء مجلس الاداره",
  "Founders and shareholders of the company": "المؤسسين و المساهمين في الشركه",
  "Invite By Email": "دعوة عن طريق البريد الالكترونى",
  "Send Invite": "ارسال الدعوة",
  "Finish Company Setup!": "انهاء اعداد حساب الشركه",
  "enter business email address": "ادخل البريد الالكترونى ",
  "Your Company Account Is All Set Up!": "تم اعداد شركتك",
  "Congrats! Now it’s time to complete your work profile info!":
    "تهانينا! الان حان الوقت لاستكمال بيانات حسابك",
  "Complete My profile": "إنهاء حسابى",
  Edit: "تعديل",
  "Team Name": "اسم الفريق",
  all: "كل الطلبات",
  Approved: "مقبول",
  Pending: "معلق",
  Declined: "مرفوض",
  inAdvance: "طلبات الدفع مقدما",
  newRequest: "كيف انشأ طلب جدبد؟",
  Indebtedness: "المديونية",
  debit: "مدين",
  credit_: "دائن",
  compInd: "سجل مديونيات الشركة",
  noReq1: "سياسة التحميل في الطلبات المسبقة الدفع",
  noReq2:
    "تتطلب الطلبات المسبقة من الموظف تحميل الإيصالات بمجرد الموافقة على الطلب واستلام الإيصال لتسوية الطلب مع الشركة",
  noList: "ليس لديك اي طلبات سابقة",
  send: "1- ارسل طلب للدفع مقدما",
  payToInAd: "سيتم الدفع الى",
  payToInAdPH: "مثلا.. جو باص",
  payDate: "تاريخ الدفع",
  payForInAd: "سيتم الدفع من اجل",
  payForInAdPH: "السفر الى الاسكندرية",
  submitReq: " ارسال الطلب",
  warn3: "لدي فاتورة او ايصال",
  warn4:
    "إذا كان لديك بالفعل إيصال أو فاتورة تريد إرفاقها بهذا الطلب ، فأنت في نوع الطلب الخاطئ ويجب عليك",
  warn5: "التبديل إلى طلب السداد",
  request: "طلب",
  closeReq: "إغلاق مراجعة الطلب",
  Reconciled: "الطلبات المسواة",
  createdAt: "تاريخ الطلب",
  recRej: "رفض التسوية",
  recRej1: "اختار سبب رفض تسوية الطلب",
  recRej2: "صور الايصالات المرفوعة ليست واضحة",
  recRej3: "الايصالات المرفوعة ليست متعلقة ببيانات الطلب",
  recRej4: "هناك فرق بين المبلغ المطلوب و المبلغ المطلوب (اعادة التسوية)",
  recRej5: "المبلغ المثبت بالايصال",
  recRej6: "فرق التسوية في طلب الدفع المقدم",
  recRej7:
    "هناك تباين بين المبلغ المطلوب والمعتمد والمبلغ الفعلي المدفوع المبلغ عنه من قبل المدير. سيتم تسوية المبلغ بناءً على اختيارك للتسوية ، يمكن أن يكون إما تسوية نقدية أو تسوية تلقائية",
  recRej8: "تسوية نقدية",
  recRej9: "تسوية تلقائية",
  recRej10: "ارسال رد التسوية",
  "Request approved": "الطلب مقبول",
  "Reconciliation approved": "التسوية مقبولة",
  "Reconciliation declind": "التسوية مرفوضة",
  "Approved for board member": "مقبول لعضو مجلس الادارة",
  "Pending manager approval": "منتظر موافقة المدير",
  "Approved for accountant": "مقبول للمحاسب",
  "Pending accountant approval": "منتظر موافقة المحاسب",
  "This request doesn't have images": "لا توجد صور لهذا الطلب",
  "Request declind from accountant": "الطلب مرفوض من المحاسب",
  "pending account owner approval": "منتظر موافقة صاحب الحساب",
  view: "عرض",
  "Close Request Review": "إغلق عرض الطلب",
  "Reconcile Request": "تسوية الطلب",
  "Drafted Reconcilation": "تسوية محفوظة",
  "RECONCILIATION REVIEW": "التسوية قيد المراجعة",
  RECONCILED: "تمت التسوية",
  "N/A": "غير متاح",
  "Supplier Requests": "طلبات المورّد",
  "Pending Requests": "الطلبات المعلقة",
  "Approved Requests": "الطلبات المقبولة",
  "Declined Requests": "الطلبات المرفوضة",
  "Settled Requests": "الطلبات المسوّاة",
  Settled: "الطلبات المسوّاة",
  "Total Amount Due": "المبلغ المستحق",
  Due: "تاريخ الاستحقاق",
  paidOnS_: "تاريخ اصدار الفاتورة",
  Supplier: "الموّرد",
  "Add New Supplier Company": "إضافة شركة مورّدة جديدة",
  newSupWarning:
    "إضافة مورّد جديد لا يعني إنه سيتم إضافته بشكل تلقائي لقائمة مورّدين الشركة، يجب على  المستشار المالي عرض هذه الإستمارة و الإختيار ما بين إضافتها او تجاوزها",
  "1 - Supplier Company Information": "1- معلومات عن شركة المورد",
  "Company Legal Name (Arabic)": "الإسم القانوني للشركة (بالعربية)",
  "Arabic Legal Name": "الاسم القانوني بالعربي",
  "Company Legal Name (English)": "الإسم القانوني للشركة (بالإنجليزية)",
  "English Legal Name": "الاسم القانوني بالإنجليزية",
  "Enter Country": "اسم البلد",
  City: "المدينة",
  "Enter City": "اسم المدينة",
  "Company Industry": "مجال الشركة",
  "Company Website": "موقع الشركة",
  "Company Phone": "هاتف الشركة",
  supLegDoc: "الوثائق القانونية للمورد",
  CompanyRegestration: "تحميل المستندات القانونية للشركة",
  "Add Supplier to system": "إضافة مورّدة للشركة",
  Cancel: "إلغاء",
  "Supplier Request": "طلب المورّد",
  "1- Invoice Information": "1- معلومات السداد",
  "Total Amount Due": "إجمالي المبلغ المستحق",
  "Issued On": "الصادر في",
  "Service Paied For": "خدمة مدفوعة لـ",
  ForwardTo: "اعادة توجيه إلى",
  "2- Supplier Information": "2- معلومات المورّد",
  "Unregistered Supplier": "مورّد غير مسجل",
  "Registered Supplier": "مورّد مسجل",
  UnregSuppWar:
    "هذا المورد غير مسجل ومعتمد في قائمة الموردين للشركة ، لقد قدم الموظف جميع المستندات القانونية وملفات الدعم لتسجيل هذا المورد.",
  regSuppWar: "هذا المورد مسجل ومعتمد في قائمة الموردين للشركة ، ",
  "View Supplier Application": "عرض استمارة المورّد",
  "3- Invoices": "3- الفواتير",
  Down: "تحميل",
  Back: "رجوع",
  "Forward To Account Owner": "ارسال الى صاحب الحساب",
  "Move To Settled": "نقل الى المسوّاة",
  Suppliers: "المورّدين",
  "Approve & Close": "أقبل و أغلق",
  "Reject or Reconcile": "رفض او تسوية",
  Sending: "ارسال",
  Transfer: "تحويل",
  Confirm: "تأكيد",
  "Leave Note": "اترك ملحوظة",
  "Leave Note PH": "اكتب ملحوظتك او تعليقك",
  "Submit Reconcilation": "ارسال التسوية",
  "Draft Reconcilation": "حفظ التسوية",
  Note: "ملحوظة",
  "Hard Copy Document Recieved": "تم استلام نسخة ورقية من المستند",
  "Amount Transfared To Supplier Account": "تم تحويل المبلغ لحساب المورّد",
  Delete: "مسح",
  "Requests categories you have": "فئات الطلبات لديك",
  "Add New Category": "إضافة فئة جديدة",
  "Enter the Category name": "ادخل اسم الفئة",
  "Category edited name": "اسم الفئة المعدل",
  Categories: "الفئات",
  Category: "الفئة",
  Action: "أجراءات",
  Travelling: "السفر",
  Bills: "الفواتير",
  "Select category for the request please": "من فضلك اختر فئة للطلب",
  Change: "تغيير",
  "Submit New Supplier": "ارسال موّرد جديد",
  "Automatic Reconcilation": "التسوية الآلية",
  AutoRec1: "هذا المستخدم له",
  AutoRec2: " جنيه مصري لتسوية طلب الدفع مقدما رقم",
  AutoRec3: "،هل ترغب في سحب المبلغ للتسوية الان؟",
  AutoRec4: "هذا المستخدم عليه ",
  "Not Now": "ليس الان",
  "Withdraw Amount": "سحب المبلغ",
  "LATEST TRANSACTION": "التحويلات الاخيرة",
  Deposited: "سحب",
  Credited: "ايداع",
  "Charge Company Wallet": "شحن محفظة الشركة",
  " Select Preferred Method": " اختر الطريقة المفضلة",
  "Use Bank Card": "استخدم بطاقة بنكية",
  "Make Bank Transfer": "استخدم تحويل بنكي",
  "Payment Method Policy": "سياسة طريقة الدفع",
  policy1:
    "ستدفع 1.5٪ إضافية على معاملتك. سيتم خصم مبلغك تلقائيًا من محفظتك بعد المتابعة.",
  policy2:
    "ستدفع 0.5٪ إضافية على معاملتك. سيتم خصم المبلغ الخاص بك من محفظتك بعد تقديم إيصال الإيداع المصرفي وفي غضون 2-3 أيام من نجاح المعاملة.",
  policyOffer:
    "سيتم خصم المبلغ الخاص بك من محفظتك بعد تقديم إيصال الإيداع المصرفي وفي غضون 2-3 أيام من نجاح المعاملة.",
  policy3:
    "سيتم خصم مبلغك من محفظتك بعد تقديم إيصال الإيداع المصرفي وفي غضون 2-3 أيام عمل من نجاح المعاملة.",
  "Submit Deposit Receipt": "إرسال إيصال الإيداع",
  Checkout: "الدفع",
  "What You Get (EGP)": "سيصلك مبلغ (بالجنيه المصري)",
  "What You Get (SAR)": "سيصلك مبلغ (بالريال السعودي)",
  Year: "سنة",
  Month: "شهر",
  "Charge My Wallet": "اشحن محفظتي",
  "Your transaction is secured": "معاملتك مؤمنة",
  "Calculate What You Get": "احسب ما سيصلك",
  "Our Banking Info": "المعلومات البنكية",
  "Abu Dhabi Islamic Bank (ADIB)": "بنك ابو ظبي الإسلامي",
  "Alinma Bank": "مصرف الإنماء",
  "Account Beneficiary": "المستفيد من الحساب",
  "Upload Bank Deposit Transaction Receipt":
    "تحميل إيصال معاملة الإيداع البنكي",
  "Search for team member": "ابحث عن عضو بالفريق",
  Default: "تلقائي",
  Newest: "الاحدث",
  Oldest: "الاقدم",
  Active: "مفعل",
  Inactive: "غير مفعل",
  Sort: "تصنيف",
  level_: "الدرجة",
  Search: "بحث",
  METHOD: "الطريقة",
  TIMESTAMP: "الوقت",
  TO: "إلى",
  "REFERENCE NO": "الرقم المرجعي",
  TRANSACTION: "نوع التحويل",
  "Latest Wallet Transactions": "تحويلات المحفظة الاخيرة",
  "This employee has already received his money in cash":
    "هذا الموظف قد استلم نقوده نقدا",
  invite1: "لقد تمت دعوتك!",
  Activity: "التفعيل",
  "Create New Team": "إنشاء فريق جديد",
  "Team Information": "معلومات الفريق",
  "Team name": "اسم الفريق",
  "This team is created by": "تم إنشاء هذا الفريق بواسطة",
  "and the team manager is": "ومدير الفريق",
  "Team Members": "أعضاء الفريق",
  "Enter the new team name": "أدخل اسم الفريق الجديد",
  "Please confirm to delete": "يرجى التأكيد لحذف",
  "Create Team": "إنشاء فريق",
  "Send Invitation Link": "إرسل رابط الدعوة",
  "Send the company board invitation link for your employees to join Hollydesk":
    "أرسل رابط دعوة مجلس إدارة الشركة لموظفيك للانضمام إلى هولي ديسك",
  "Copy Invitation URL": "نسخ رابط الدعوة",
  "Send To Slack": "إرسال إلى Slack",
  "Joined By Link": "انضم عن طريق الرابط",
  "Invite By Email": "الدعوة عن طريق البريد الالكتروني",
  "Team Member": "عضو فريق",
  Remove: "حذف",
  "Invite Another Team Member": "قم بدعوة عضو آخر في الفريق",
  "Invite Members": "دعوة الأعضاء",
  "Invite Team Member": "دعوة عضو بالفريق",
  Invited: "تم دعوة",
  Members: "عضو",
  Invitees: "المدعوين",
  "Please confirm removing": "يرجى التأكيد لحذف",
  "Work Title (Optional)": "اللقب الوظيفي (اختياري)",
  "Work Title": "اللقب الوظيفي",
  "Save Edits": "حفظ التعديلات",
  Wallets: "المحفظات",
  "Recruitment Specialist": "أخصائي توظيف",
  Requests: "الطلبات",
  Teamwork: "فريق العمل",
  "Company Board": "منصة الشركة",
  "Edit Company Profile": "تعديل بيانات الشركة",
  "Set Company Policy": "ضبط سياسة الشركة",
  "Company Billing Plan": "خطة مصاريف الشركة",
  "Profile Settings": "إعدادات الملف الشخصي",
  "Account Settings": "إعدادات الحساب",
  "Notifications Settings": "إعدادات التنبيهات",
  "Logout From My Account": "الخروج من حسابي",
  "Office Phone Number (Optional)": "رقم هاتف الشركة (إختياري)",
  "Update Company Details": "تحديث بيانات الشركة",
  "Company Account Ownership": "ملكية حساب الشركة",
  "Account Owner": "مالك الحساب",
  "You can transfer the account ownership to another user":
    "يمكنك نقل ملكية الحساب إلى مستخدم آخر",
  "Transfer Account Ownership": "نقل ملكية الحساب",
  "Upload New Profile Picture": "تحميل صورة شخصية جديدة",
  "Delete Your Profile Picture": "مسح صورتك الشخصية",
  "Update Profile Changes": "تحديث التغييرات بملفك الشخصي",
  "Want to change your teamwork? Contact your HR Manager to change it for you":
    "تريد تغيير الفريق الخاص بك؟ اتصل بمدير الموارد البشرية الخاص بك لتغييره نيابة عنك",
  "Want to change your role level? Contact your HR Manager to change it for you":
    "هل تريد تغيير مستوى دورك؟ اتصل بمدير الموارد البشرية الخاص بك لتغييره نيابة عنك",
  "Your current job title": "المسمى الوظيفي الحالي الخاص بك",
  "Email Me When": "راسلني عند",
  "My reimburse request gets approved": "تمت الموافقة على طلب السداد الخاص بي",
  "My in advance request gets approved": "تمت الموافقة على طلب الدفع المسبق",
  "My supplier request gets approved": "تمت الموافقة على طلب المورد الخاص بي",
  "Any of my requests gets declined": "يتم رفض أي من طلباتي",
  "My in advance request reconciliation is due":
    "تسوية الطلب المسبق الدفع الخاصة بي مستحقة",
  "My manager writes a comment to my request": "مديري يكتب تعليقا على طلبي",
  Newsletter: "النشرة الإخبارية",
  "Subscribe to Hollydesk newsletter": "اشترك في نشرة Hollydesk الإخبارية ",
  "Subscribe to Hollydesk feature announcements":
    "اشترك في إعلانات Hollydesk المستقبلية",
  "Save Changes": "حفظ التغييرات",
  "Write your new email": "اكتب البريد الالكتروني الجديد",
  "Password (to change email)": "كلمة المرور (لتغيير البريد الإلكتروني)",
  "Write your password": "اكتب كلمة المرور",
  "Change Email": "تغيير البريد الالكتروني",
  "Verify New Email": "التحقق من البريد الإلكتروني الجديد",
  "We have sent you a verification code to your new email":
    "لقد أرسلنا لك رمز التحقق إلى بريدك الإلكتروني الجديد",
  "to verify it’s you": "للتحقق من هويتك",
  "Please enter the code": "الرجاء إدخال الرمز",
  "Verify My New Email": "التحقق من بريدي الإلكتروني الجديد",
  "Add Mobile Number": "أضف رقم الهاتف",
  "Your verified numbers": "أرقام هاتفك الموثقة",
  "You don't have verified mobile numbers": "لا يوجد لك أرقام هاتف موثقة",
  "We have sent you a verification code to your mobile number":
    "لقد أرسلنا لك رمز التحقق إلى رقم هاتفك المحمول",
  "Verify Mobile Number": "التحقق من رقم الهاتف",
  "Verify My Mobile Number": "التحقق من رقم هاتفي",
  "Supplier Display": "عرض المورد",
  "Company Display Name": "اسم الشركة التجاري",
  "Export Data": "تحميل البيانات",
  Notifications: "إشعارات",
  "mark all as read": "تحديد الكل كمقروء",
  "Has been submited by": "تم تقديمه من قبل",
  "Load more": "تحميل المزيد",
  "Suppliers List": "قائمة الموردين",
  Verify: "توثيق",
  More: "المزيد",
  "Pending Reconciliation": "التسويات المعلقة",
  "Reconciliation Review": "التسوبات قيد المراجعة",
  "Contact Number": "رقم التواصل",
  "Welcome again,": "اهلاً بك مجدداً",
  "Log in to your account": "سجل الدخول الى حسابك",
  "Your Email Address": "عنوان بريدك الإلكتروني",
  "Your Password": "رمزك السري",
  "Forgot Password!": "نسيت كلمة السر",
  "Log in to my account": "تسجيل الدخول الى حسابي",
  "I don’t have an account,": "ليس لدي حساب",
  "Register Your Company": "سجل شركتك",

  "Team deleted successfully": "تم حذف الفريق بنجاح",
  "Team added successfully": "تمت إضافة الفريق بنجاح",
  "already exsists": "موجود بالفعل",
  "User invited successfully": "تمت دعوة المستخدم بنجاح",
  Search: "بحث",
  "Uploaded successfully": "تم الرفع بنجاح ",
  "Please add receipt amount": "الرجاء إضافة مبلغ الإيصال",
  "Please add receipt image": "الرجاء إضافة صورة الإيصال",
  "Please add a valid receipt amount": "الرجاء إضافة مبلغ إيصال صالح",
  "Cashout done successfully": "تم السحب بنجاح",
  "Working Team": "فريق العمل ",
  "Convert Egyptian Pound to": "تحويل الجنية المصري الى",
  "Please complete all information": "الرجاء إكمال البيانات",
  "Ex: Mohamed Kamel": "مثال: محمد كامل",
  "Please select your bank name": "الرجاء اختيار اسم البنك الخاص بك",
  "Ex: San stefano alexandriaBE": "سان إسفانوا بنك الاسكندرية",
  "Phone Number": "رقم التليفون",
  "Full Name": "الاسم بالكامل",
  "Egptian Pounds": "جنيه مصري ",
  "Edited successfully": "تم التعديل بنجاح",
  "Please enter a valid mobile number": "الرجاء إدخال رقم جوال صحيح",
  "This field is required": " هذه الخانة مطلوبه",
  "Enter valid industry": "ادخل صناعة صحيحة",
  "Enter valid website": "أدخل موقع صالح",
  "Data saved": "تم حفظ البيانات",
  "Manual Request": "طلب يدوي",
  "Request approved successfully": "تمت الموافقة على الطلب بنجاح",
  "Request Created for user": "تم انشاء الطلب للمستخدم",
  "Please enter a valid amount": "يرجى إدخال مبلغ صحيح",
  "Pay date is required": "يرجى ادخال تريخ الدفع",
  "Paid to is required": "يرجى ادخال لمن سيتم الدفع",
  "Paid for is required": "ادخل من اجل من سيتم الدفع",
  "Select employee": "اختر موظف",
  "Declined from accountant": "تم الرفض من قبل المحاسب",
  "Request approved successfully": "تمت الموافقة على الطلب بنجاح",
  "Request declined successfully": "تم رفض الطلب بنجاح",
  "Request Declined": "تم رفض الطلب ",

  "Select category": "اختر الفئة",
  "No receipts to show": "لا توجد إيصالات للعرض",
  "Please upload image": "الرجاء تحميل صورة",
  "Please add currency": "الرجاء إضافة العملة",
  "Please add amount": "الرجاء إضافة المبلغ",
  "Please add date": "الرجاء إضافة التاريخ",
  "Please add paid to": "الرجاء إضافة المدفوع إلى",
  "Please add paid for": "الرجاء إضافة مدفوع مقابل",
  "Declined from account": "مرفوض من الحساب",
  "Category changed": "تم تغيير الفئة",
  Uncategorized: "غير مصنف",
  Office: "مكتب",
  "Ex: Hollydesk": "مثال: Hollydesk",
  "This field is required": "هذه الخانة مطلوبه",
  "Please select supplier": "الرجاء تحديد المورد",
  "Issue date is required": "مطلوب تاريخ الإصدار ",
  "Pay date is required": "تاريخ الدفع مطلوب",
  "Paid for is required": "مطلوب الدفع مقابل مذا",
  "Please upload file": "الرجاء تحميل الملف",
  "Request Forworded To General Manager": "تم تحويل الطلب لفريق المديرين",

  "Team deleted successfully": "تم حذف الفريق بنجاح",
  "Team added successfully": "تمت إضافة الفريق بنجاح",
  "already exsists": "موجود بالفعل",
  "User invited successfully": "تمت دعوة المستخدم بنجاح",
  Search: "بحث",
  "Uploaded successfully": "تم الرفع بنجاح ",
  "Please add receipt amount": "الرجاء إضافة مبلغ الإيصال",
  "Please add receipt image": "الرجاء إضافة صورة الإيصال",
  "Please add a valid receipt amount": " الرجاء إضافة مبلغ إيصال صالح",
  "Cashout done successfully": " تم السحب بنجاح",
  "Working Team": "فريق العمل ",
  "Convert Egyptian Pound to": "تحويل الجنية المصري الى",
  "Please complete all information": "الرجاء إكمال البيانات",
  "Ex: Mohamed Kamel": "مثال: محمد كامل",
  "Please select your bank name": "الرجاء اختيار اسم البنك الخاص بك",
  "Ex: San stefano alexandriaBE": "سان إسفانوا بنك الاسكندرية",
  "Phone Number": "رقم التليفون",
  "Full Name": "الاسم بالكامل",
  "Egptian Pounds": "جنيه مصري ",
  "Edited successfully": "تم التعديل بنجاح",
  "Please enter a valid mobile number": "الرجاء إدخال رقم جوال صحيح",
  "This field is required": "هذه الخانة مطلوبه",
  "Enter valid industry": "ادخل صناعة صحيحة",
  "Enter valid website": "أدخل موقع صالح",
  "Data saved": "تم حفظ البيانات",
  "Manual Request": "طلب يدوي",
  "Request Created for user": "تم انشاء الطلب للمستخدم",
  "Please enter a valid amount": "يرجى إدخال مبلغ صحيح",
  "Pay date is required": "يرجى ادخال تريخ الدفع",
  "Paid to is required": "يرجى ادخال لمن سيتم الدفع",
  "Paid for is required": "ادخل من اجل من سيتم الدفع",
  "Select employee": "اختر موظف",
  "Declined from accountant": "تم الرفض من قبل المحاسب",
  "Request approved successfully": "تمت الموافقة على الطلب بنجاح",
  "Request declined successfully": "تم رفض الطلب بنجاح",
  "Select category": "اختر الفئة",
  "No receipts to show": "لا توجد إيصالات للعرض",
  "Please upload image": "الرجاء تحميل صورة",
  "Please add currency": "الرجاء إضافة العملة",
  "Please add amount": "الرجاء إضافة المبلغ",
  "Please add date": "الرجاء إضافة التاريخ",
  "Please add paid to": "الرجاء إضافة المدفوع إلى",
  "Please add paid for": "الرجاء إضافة مدفوع مقابل",
  "Declined from account": "مرفوض من الحساب",
  "Category changed": " تم تغيير الفئة",
  Uncategorized: "غير مصنف",
  Office: "مكتب",
  "E-Wallet": "محفظة إلكترونية",
  "Ex: Hollydesk": "مثال: Hollydesk",
  "This field is required": "هذه الخانة مطلوبه",
  "Please select supplier": "الرجاء تحديد المورد",
  "Issue date is required": "مطلوب تاريخ الإصدار ",
  "Pay date is required": "تاريخ الدفع مطلوب",
  "Paid for is required": "مطلوب الدفع مقابل مذا ",
  "Please upload file": "الرجاء تحميل الملف",
  "Export CSV": "استخراج CSV",
  "Repeated password does not match the new password":
    "كلمة المرور المتكررة لا تتطابق مع كلمة المرور الجديدة"
};
