import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Menu, Dropdown, Avatar, Divider } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { RiLock2Fill } from "react-icons/ri";
import { RiMailUnreadFill } from "react-icons/ri";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { logout } from "../../../../../actions/authActions";

import "antd/dist/antd.css";
import "antd/dist/antd.css";

const UserAvatarMenu = () => {
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const userAvatar = () => {
    return user && user.image !== "" ? (
      <Avatar src={user.image} style={{ cursor: "pointer" }} />
    ) : (
      <Avatar src="/images/human.png" style={{ cursor: "pointer" }} />
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link className="nav-menu-item" to="/dashboard/profileSettings">
          <div className="icon-wrapper" style={{ backgroundColor: "#dedff5" }}>
            <FaUserCircle className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Profile Settings")}
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link className="nav-menu-item" to="/dashboard/accountSettings">
          <div className="icon-wrapper" style={{ backgroundColor: "#e7f8ef" }}>
            <RiLock2Fill className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Account Settings")}
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link className="nav-menu-item" to="/dashboard/notificationSettings">
          <div className="icon-wrapper" style={{ backgroundColor: "#fcf4e5" }}>
            <RiMailUnreadFill className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Notifications Settings")}
        </Link>
      </Menu.Item>

      <Divider />

      <Menu.Item key="2">
        <div className="nav-menu-item" onClick={() => dispatch(logout())}>
          <div className="icon-wrapper" style={{ backgroundColor: "#fcf4e5" }}>
            <RiLogoutBoxRLine className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Logout From My Account")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link nav-tab"
        onClick={(e) => e.preventDefault()}
      >
        {userAvatar()}
      </a>
    </Dropdown>
  );
};

export default UserAvatarMenu;
