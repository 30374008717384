import { WALLET } from '../actions/types';

const INITIAL_STATE = {
  wallet: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WALLET:
      if (action.payload === 'f') {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          wallet: action.payload,
        };
      }
    // if (!action.payload.totalAmount) {
    // } else {
    //   if (action.payload.totalAmount == 'f') {
    //   } else {
    //     return {
    //       ...state,
    //       wallet: action.payload.totalAmount,
    //     };
    //   }
    // }

    default:
      return state;
  }
};
