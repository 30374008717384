import api from '../apis/api';
import { returnErrors, clearErrors } from './errorActions';
import { setLoading, removeLoading } from './loadingActions';
import { tokenConfig, tokenConfigUpload } from './tokenActions';
import { LIST_INDEBTEDNESS, LIST_FILTER_INDEBTEDNESS, LIST_INDEBTEDNESS_USER } from './types';


export const listIndebtedness = () => async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
  
    api
        .get('/inadvance/get-credit', tokenConfig(getState))
        .then((res) => {
            console.log(res)
            if (res.data.status === 1) {
                console.log(res)
                dispatch({ type: LIST_INDEBTEDNESS, payload: res.data.requests });
                dispatch(removeLoading());
            } else {
                dispatch(
                    returnErrors(res.data.message, res.data.status, 'LIST_REQUESTS_FAIL')
                );
            }
        })
        .catch((err) => {
            dispatch(returnErrors(err, 0));
        });
};

export const listIndebtednessByUser = () => async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());
  
    api
        .get('/inadvance/get-credit-by-users', tokenConfig(getState))
        .then((res) => {
            console.log(res)
            if (res.data.status === 1) {
                console.log(res)
                dispatch({ type: LIST_INDEBTEDNESS_USER, payload: res.data.users });
                dispatch(removeLoading());
            } else {
                dispatch(
                    returnErrors(res.data.message, res.data.status, 'LIST_REQUESTS_FAIL')
                );
            }
        })
        .catch((err) => {
            dispatch(returnErrors(err, 0));
        });
};

export const filterIndebtedness = (data,type) => async (dispatch, getState) => {
    dispatch(clearErrors());
    dispatch(setLoading());

    let result;

    switch (type) {
      case 'all':
              dispatch({ type: LIST_FILTER_INDEBTEDNESS, payload: data });
          break;

      case 'user':
            // result = data.reduce((acc, d) => {
                
            // const found = acc.find(a => a.from._id === d.from._id);
            // const value = {  debit: d.debit,credit:d.credit }; 
            // if (!found) {
            //   acc.push({ ...d,debit:value.debit,credit:value.credit,number:''}) 
            // }
            // else {
            //   found.debit+= value.debit
            //   found.credit+= value.credit
            // }
            // return acc;
            // }, []);
              // dispatch({ type: LIST_INDEBTEDNESS_USER, payload: result });
            dispatch(listIndebtednessByUser());
            console.log(result);
          break;
            
      case 'month':
        result = data.reduce((acc, d) => {
          const found = acc.find(a => a.paidOn.slice(0, 6)=== d.paidOn.slice(0, 6));
          const value = {  debit: d.debit,credit:d.credit }; 
          if (!found) {
            acc.push({ ...d,month :d.paidOn.slice(0, 7),debit:value.debit,credit:value.credit,number:''}) 
          }
          else {
            found.debit+= value.debit
            found.credit+= value.credit

          }
          return acc;
        },[])

        dispatch({ type: LIST_FILTER_INDEBTEDNESS, payload: result });
        break;

      default:
        break;
    }
};

  