import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import api from "../../../../../apis/api";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { TiLockClosed } from "react-icons/ti";
import { Modal } from "react-bootstrap";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import WalletBanner from "./walletBanner/walletBanner";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import "./style.css";
import {
  uploadPictures,
  uploadRecipt,
} from "../../../../../actions/uploadActions";

const CompanyWallet = (props) => {
  const [Image, setImage] = useState([]);
  const [creditCardAmount, setCreditCardAmount] = useState(null);
  const [cardNumber, setcardNumber] = useState();
  const [cvv, setCvv] = useState();
  const [expMonth, setExpMonth] = useState();
  const [expYear, setExpYear] = useState();
  const [balance_, setBalance_] = useState(0);
  const [method, setMethod] = useState("bank");
  const [bankAmount, setBankAmount] = useState();
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(null);
  const [transactionID, setTransactionID] = useState("");

  const currency = useSelector((state) => state.auth.user.currency[0]);
  const spin = useSelector((state) => state.request.spin) || false;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // const checkoutHandler = () => {
  //   api({
  //     url: "/company/getTokenChargeWalletForm",
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
  //     },
  //     data: { amount: creditCardAmount },
  //   })
  //     .then((res) => {
  //       if (res.data.status === 1) {
  //         window.COWPAYOTPDIALOG.init();
  //         window.COWPAYOTPDIALOG.load(res.data.data.token);

  //         getBalance();
  //       } else {
  //         cogoToast.error("Transaction Faild");
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const submitCreditCard = (e) => {
    e.preventDefault();
    api({
      url: "/company/charge",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      data: { creditCardAmount, cardNumber, cvv, expMonth, expYear },
    })
      .then((res) => {
        if (res.data.status == "suc") {
          window.COWPAYOTPDIALOG.init();
          window.COWPAYOTPDIALOG.load(res.data.cowpayID);
          getBalance();
          setCreditCardAmount("");
          setcardNumber("");
          setCvv("");
        } else {
          cogoToast.error("Transaction Faild");
        }
      })
      .catch((err) => {});
  };

  const getBalance = () => {
    api({
      url: "/company/balance",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setBalance_(Number(res.data.credits["$numberDecimal"]));
        } else {
        }
      })
      .catch((err) => {});
  };

  const selectFileHandler = (files) => {
    const allowedFormats = ["jpg", "jpeg", "png", "svg", "pdf"];
    const fileFormat = files[0].name.split(".").pop();
    if (allowedFormats.includes(fileFormat)) {
      setImage(files);
    } else {
      cogoToast.warn("Reciept should be image or PDF file");
    }
  };

  const sendReciept = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (Image.length === 0) return cogoToast.warn("Please, add recipte image");
    if (amount === "") return cogoToast.warn("Please, add recipte amount");
    if (parseFloat(amount) <= 0)
      return cogoToast.warn("Recipte amount should be greater than zero");
    if (transactionID === "")
      return cogoToast.warn("Please, add the transaction ID");
    // const allowedFormats = ["jpg", "jpeg", "png", "svg", "pdf"];
    // const fileFormat = Image[0].name.split(".").pop();
    // if (allowedFormats.includes(fileFormat)) {
    dispatch({ type: "TOGGLE_BTN_SPIN", spin: true });
    formData.append("file", Array.from(Image)[0]);
    formData.append("currency", currency);
    formData.append("amount", amount);
    formData.append("transactionID", transactionID);
    props.uploadRecipt(formData, "re");
    // } else {
    //   cogoToast.warn("Reciept should be image or PDF file");
    // }
  };

  useEffect(() => {
    dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
    getBalance();
    setAmount("");
    setTransactionID("");
  }, []);

  useEffect(() => {
    setShowModal(spin);
  }, [spin]);

  const actualMoney = creditCardAmount * 0.985;

  const actualBankAmount =
    bankAmount - bankAmount * 0.005 - bankAmount * 0.005 * 0.14;

  return (
    <div>
      <WalletBanner />

      <div className="mainPage wallet">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12 mx-auto"
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              <h3>{t("Charge Company Wallet")}</h3>
              <div className="company-wallet">
                <ul
                  className="charge-options"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  <div className="form-group">
                    <h4>1. {t(" Select Preferred Method")}</h4>
                  </div>
                  <RadioGroup>
                    <label
                      className="option-label"
                      onClick={() => {
                        setMethod("card");
                      }}
                    >
                      <Radio
                        defaultChecked
                        checked={method === "card" ? true : false}
                        color="primary"
                        type="radio"
                        name="cashMethod"
                        value="credit"
                      />
                      <div className="cash-method">
                        <img
                          className="option-img"
                          src="/images/bank-card.png"
                          alt="label"
                          style={{}}
                        />{" "}
                        {t("Use Bank Card")}
                      </div>
                    </label>
                    <div
                      className="option-dialog"
                      style={{ display: method === "card" ? "block" : "none" }}
                    >
                      <h6>{t("Payment Method Policy")}</h6>
                      <p>{t("policy1")}</p>
                    </div>
                    <label
                      className="option-label"
                      onClick={() => {
                        setMethod("bank");
                      }}
                    >
                      <Radio
                        checked={method === "bank" ? true : false}
                        color="primary"
                        type="radio"
                        name="cashMethod"
                        value="transfer"
                      />
                      <div className="cash-method">
                        <img
                          className="option-img"
                          src={
                            currency === "EGP"
                              ? "/images/cib.jpg"
                              : "/images/alinma.png"
                          }
                          alt="label"
                          style={{}}
                        />{" "}
                        {t("Make Bank Transfer")}
                      </div>
                    </label>
                    <div
                      className="option-dialog"
                      style={{ display: method === "bank" ? "block" : "none" }}
                    >
                      <h6>{t("Payment Method Policy")}</h6>
                      <p>{t("policyOffer")}</p> {/* IN REPLACE OF policy2 */}
                      <button
                        className="btn"
                        onClick={() => {
                          setShowModal(true);
                          setImage([]);
                          setAmount("");
                          setTransactionID("");
                        }}
                      >
                        {t("Submit Deposit Receipt")}
                      </button>
                    </div>
                  </RadioGroup>
                </ul>

                <div className="spliter"></div>
                {/* End */}
                {/* Credit card form content */}
                <div className="option-content">
                  {/* credit card info*/}
                  <div
                    className="credit-card"
                    style={{ display: method === "card" ? "block" : "none" }}
                  >
                    <form role="form" onSubmit={submitCreditCard}>
                      <div className="form-group">
                        <h4
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          2. {t("Checkout")}
                        </h4>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label
                            htmlFor="username"
                            style={
                              localStorage.getItem("i18nextLng") == "ar"
                                ? { width: "100%", textAlign: "right" }
                                : { width: "100%", textAlign: "left" }
                            }
                          >
                            {t("amount")}
                          </label>
                          <input
                            type="text"
                            name="amount"
                            value={creditCardAmount}
                            onChange={(e) =>
                              setCreditCardAmount(e.target.value)
                            }
                            placeholder="100"
                            required
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label
                            htmlFor="username"
                            style={
                              localStorage.getItem("i18nextLng") == "ar"
                                ? { width: "100%", textAlign: "right" }
                                : { width: "100%", textAlign: "left" }
                            }
                          >
                            {t(
                              currency === "EGP"
                                ? "What You Get (EGP)"
                                : "What You Get (SAR)"
                            )}
                          </label>
                          <input
                            disabled
                            type="text"
                            name="amount"
                            value={actualMoney || 98.5}
                            onChange={(e) =>
                              setCreditCardAmount(e.target.value)
                            }
                            placeholder="500"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="username"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          {t("fullName")}
                        </label>
                        <input
                          type="text"
                          name="username"
                          placeholder="Jason Doe"
                          required
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="cardNumber"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          {t("cardNumber")}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="cardNumber"
                            value={cardNumber}
                            onChange={(e) => {
                              setcardNumber(e.target.value);
                            }}
                            placeholder="XXXX XXXX XXXX XXXX"
                            className="form-control"
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text text-muted">
                              <i className="fab fa-cc-visa mx-1" />
                              <i className="fab fa-cc-amex mx-1" />
                              <i className="fab fa-cc-mastercard mx-1" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group">
                            <label
                              style={
                                localStorage.getItem("i18nextLng") == "ar"
                                  ? { width: "100%", textAlign: "right" }
                                  : { width: "100%", textAlign: "left" }
                              }
                            >
                              <span className="hidden-xs">{t("exp")}</span>
                            </label>
                            <div className="input-group">
                              <select
                                type="number"
                                placeholder="MM"
                                value={expMonth}
                                onChange={(e) => {
                                  setExpMonth(e.target.value);
                                }}
                                name
                                className="form-control"
                                required
                              >
                                <option value="00" disabled selected>
                                  {t("Month")}
                                </option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                              <select
                                type="number"
                                placeholder="YY"
                                value={expYear}
                                onChange={(e) => {
                                  setExpYear(e.target.value);
                                }}
                                name
                                className="form-control"
                                required
                              >
                                <option value="00" disabled selected>
                                  {t("Year")}
                                </option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-4">
                            <label
                              style={
                                localStorage.getItem("i18nextLng") == "ar"
                                  ? { width: "100%", textAlign: "right" }
                                  : { width: "100%", textAlign: "left" }
                              }
                              data-toggle="tooltip"
                              title="Three-digits code on the back of your card"
                            >
                              CVV
                              <i className="fa fa-question-circle" />
                            </label>
                            <input
                              value={cvv}
                              type="text"
                              onChange={(e) => {
                                setCvv(e.target.value);
                              }}
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className=" btn btnPrimary  rounded-pill shadow-sm"
                      >
                        {t("Charge My Wallet")}
                      </button>
                    </form>
                    <div className="secure">
                      <TiLockClosed className="lock" />
                      {t("Your transaction is secured")}
                    </div>
                  </div>
                  {/* End */}
                  {/* bank transfer info */}
                  <div
                    className="bank-transfer"
                    style={{ display: method === "bank" ? "block" : "none" }}
                  >
                    <div className="form-group">
                      <h4
                        style={
                          localStorage.getItem("i18nextLng") == "ar"
                            ? { width: "100%", textAlign: "right" }
                            : { width: "100%", textAlign: "left" }
                        }
                      >
                        2. {t("Calculate What You Get")}
                      </h4>
                    </div>
                    <div className="row form-group">
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="username"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          {t("amount")}
                        </label>
                        <input
                          type="text"
                          name="amount"
                          value={bankAmount}
                          onChange={(e) => setBankAmount(e.target.value)}
                          placeholder="10000"
                          required
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="username"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? { width: "100%", textAlign: "right" }
                              : { width: "100%", textAlign: "left" }
                          }
                        >
                          {t(
                            currency === "EGP"
                              ? "What You Get (EGP)"
                              : "What You Get (SAR)"
                          )}
                        </label>
                        <input
                          disabled
                          type="text"
                          name="amount"
                          // value={actualBankAmount || 9943}
                          value={bankAmount}
                          placeholder="10000"
                          required
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <h4
                        style={
                          localStorage.getItem("i18nextLng") == "ar"
                            ? { width: "100%", textAlign: "right" }
                            : { width: "100%", textAlign: "left" }
                        }
                      >
                        3. {t("Our Banking Info")}
                      </h4>
                    </div>
                    <div className="banking">
                      <div className="banking-head">
                        <img
                          className="bank-logo"
                          src={
                            currency === "EGP"
                              ? "/images/cib.jpg"
                              : "/images/alinma.png"
                          }
                          alt="bank-logo"
                        />
                        <div className="banking-spacer"></div>
                        <div>
                          <h5 style={{ color: "inherit" }}>
                            {t(
                              currency === "EGP"
                                ? "CIB | Commercial International Bank Egypt"
                                : "Alinma Bank"
                            )}
                          </h5>
                          <h6 style={{ color: "inherit" }}>CIBEEGCX097</h6>
                        </div>
                      </div>
                      <div className="banking-info">
                        <div>
                          <h5>{t("Account Beneficiary")}</h5>
                          <p>{currency === "EGP" ? "Swell" : "Hollydesk"}</p>
                        </div>
                        <div>
                          <h5>{t(currency === "EGP" ? "accNum" : "IBAN")}</h5>
                          <p>
                            {currency === "EGP" ? 100050075391 : "xxxxxxxxxxxx"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
      >
        <Modal.Body
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <form className="wallet-upload-modal">
            <h3>{t("Upload Bank Deposit Transaction Receipt")}</h3>
            <div className="red-warning" style={{ fontSize: "medium" }}>
              <div className="warning-head">
                <WarningRoundedIcon /> <p>{t("Payment Method Policy")}</p>
              </div>
              <p>{t("policy3")}</p>
            </div>
            <div style={{ width: "100%", margin: "20px auto" }}>
              {Image && Image.length !== 0 ? (
                <label
                  for="fileUpload"
                  className="file-upload file-upload-wallet"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { textAlign: "right", cursor: "auto" }
                      : { textAlign: "left", cursor: "auto" }
                  }
                >
                  <img src="/images/icons8-checkmark.svg" alt="upload" />
                  <div>
                    <h4 className="mb-3">{t("imgUploaded")} </h4>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => setImage([])}
                    >
                      {t("Delete uploaded file")}{" "}
                    </span>
                  </div>
                </label>
              ) : (
                <label
                  for="fileUpload"
                  className="file-upload file-upload-wallet"
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { textAlign: "right" }
                      : { textAlign: "left" }
                  }
                >
                  <img src="/images/icons8-pictures-folder.svg" alt="upload" />
                  <div>
                    <h4 className="mb-3">{t("imgUploaded3")} </h4>
                    <span style={{ color: "#6c757d" }}>
                      {t("imgUploaded4")}{" "}
                    </span>
                    <input
                      id="fileUpload"
                      type="file"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      onChange={(e) => selectFileHandler(e.target.files)}
                    />
                  </div>
                </label>
              )}
            </div>
            <div className="reciept-amount">
              <div className="row-element">
                <label>Amount in the receipt</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="00"
                  min="1"
                  step="0.01"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="row-element">
                <label>Transaction ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="xxxxxxxxxxxx"
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                />
              </div>
            </div>
            {spin ? (
              <button type="button" disabled className="btn btnPrimary">
                <LoadingOutlined />
              </button>
            ) : (
              <button
                type="button"
                className="btn btnPrimary"
                onClick={(e) => {
                  sendReciept(e);
                }}
              >
                {t("Submit Deposit Receipt")}
              </button>
            )}
          </form>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => {
              setShowModal(false);
              dispatch({ type: "TOGGLE_BTN_SPIN", spin: false });
            }}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "5px" }
                : { right: "5px", left: "auto" }
            }
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    request: state.request,
    errors: state.error,
    auth: state.auth,
    lang: state.lang.lang,
  };
};
export default connect(mapStateToProps, { uploadPictures, uploadRecipt })(
  CompanyWallet
);
