import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import UploadImages from "../../../../components/requests/UploadImage/uploadImages";
import {
  addRequestSupplier,
  toggleBtnSpin
} from "../../../../../../actions/requestsActions";
import { withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import api from "../../../../../../apis/api";
import CreatableSelect from "react-select/creatable";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { Badge, Avatar } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Modal } from "react-bootstrap";
import NewSupplier from "../newSupplier/newSupplier";
import i18n from "../../../../../../translations/i18n";

import "./request.css";

const Request = React.memo(props => {
  const [msg, setMsg] = useState(undefined);
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [showSupplierError, setShowSupplierError] = useState(false);
  const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);

  const currency = useSelector(state => state.auth.user.currency[0]);
  const spin = useSelector(state => state.request.spin) || false;

  const { register, errors, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onSubmit = values => {
    if (!supplier.value) {
      setShowSupplierError(true);
      return;
    }
    let { files, name } = props.upload;
    if (name === "requests" && files.length > 0) {
      dispatch(toggleBtnSpin(true));
      let images = [];
      files.forEach(file => {
        images.push(file._id);
      });
      values["images"] = images;
      values["supplier"] = supplier.value;

      props.addRequestSupplier(values, files);
    } else {
      cogoToast.error(t("please upload image"));
    }
  };

  const getData = () => {
    api({
      url: "/supplier/get-supplier",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        console.log(res.data.Suppliers);
        let suppliers_ = [];

        suppliers_.push({
          value: "",
          label: (
            <div className="create-supplier-option">Create New Supplier</div>
          ),
          id: -1
        });

        for (const iterator of res.data.Suppliers) {
          suppliers_.push({
            value: iterator._id,
            label: (
              <div>
                <Badge
                  count={
                    <CheckCircleFilled
                      style={{
                        color: iterator?.verified ? "#0c6c2b" : "#808080"
                      }}
                    />
                  }
                  className="mx-3 my-3"
                >
                  <Avatar
                    shape="square"
                    size="large"
                    src={iterator?.companyLogo?.path}
                  />
                </Badge>
                {iterator.nameEnglish}
              </div>
            )
          });
        }
        console.log(suppliers_);
        setSuppliers(suppliers_);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleChange = (newValue, actionMeta) => {
    setShowSupplierError(false);
    if (actionMeta.action == "create-option" || newValue.id == -1)
      setModalTwoIsOpen(true);
    else if (actionMeta.action == "select-option") setSupplier(newValue);
  };

  const handleInputChange = (inputValue, actionMeta) => {
    console.groupEnd();
  };

  useEffect(() => {
    if (props.errors.id === "ADD_BANK_FAIL") {
      setMsg(props.errors.msg);
    }
    getData();
  }, []);

  const closeModalHandler = value => {
    setModalTwoIsOpen(value);
  };

  return (
    <div className="mainPage request">
      <div className="container-fluid">
        <div className="row align-items-start">
          <div className="col-md-6">
            <UploadImages name="requests" />
          </div>
          <div className="col-md-6">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="md-form"
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              <h2
                className="mb-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : { width: "100%", textAlign: "left" }
                }
              >
                {t("fillRI4")}
              </h2>

              <div className="form-row">
                <div className="row-element">
                  <label>{t("amount")}</label>
                  <input
                    type="number"
                    placeholder="00"
                    step="0.01"
                    min="0"
                    autoComplete="off"
                    className="form-control"
                    name="amount"
                    ref={register({
                      required: true,
                      min: {
                        value: 1,
                        message: "Amount must be positive value"
                      }
                    })}
                  />
                  {errors.amount && <p>{t("Please enter a valid amount")}</p>}
                </div>
                <div className="row-element">
                  <label>{t("currency")}</label>
                  <select
                    className="form-control"
                    name="currency"
                    ref={register({ required: true })}
                  >
                    <option value={currency} selected>
                      {t(currency)}
                    </option>
                  </select>
                  {errors.currency && <p>Currency is required</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="row-element">
                  <label>{t("Due")}</label>
                  <input
                    className="form-control"
                    type="date"
                    name="due"
                    min={new Date().toISOString().split("T")[0]}
                    ref={register({ required: true })}
                  />
                  {errors.due && <p>{t("Pay date is required")}</p>}
                </div>
                <div className="row-element">
                  <label>{t("paidOnS_")}</label>
                  <input
                    className="form-control"
                    type="date"
                    name="date"
                    max={new Date().toISOString().split("T")[0]}
                    ref={register({ required: true })}
                  />
                  {errors.date && <p>{t("Issue date is required")}</p>}
                </div>
              </div>

              <div className="form-row wide">
                <label>{t("paidFor")}</label>
                <input
                  className="form-control"
                  autoComplete="off"
                  placeholder={t("paidForPH")}
                  name="paidFor"
                  ref={register({ required: true })}
                />
                {errors.paidFor && <p>{t("Paid for is required")}</p>}
              </div>

              <div className=" inputComp ">
                <label> {t("Supplier")}</label>
                <CreatableSelect
                  createOptionPosition="first"
                  isMulti={false}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  formatCreateLabel={e => `+ Add "${e}" as a new supplier`}
                  maxMenuHeight="200px"
                  options={suppliers}
                  value={supplier}
                  styles={{
                    control: styles => ({
                      ...styles,
                      backgroundColor: "white"
                    }),
                    option: styles => ({
                      ...styles,
                      cursor: "pointer",
                      color: "#000"
                    })
                  }}
                />
                <div
                  className="text-danger"
                  style={{
                    visibility: showSupplierError ? "visible" : "hidden"
                  }}
                >
                  {t("Pleas select supplier")}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-5">
                  {spin ? (
                    <button type="button" disabled className="btn btnPrimary">
                      <LoadingOutlined />
                    </button>
                  ) : (
                    <button className="btn btnPrimary">{t("submit")}</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        // style={{ maxWidth: "1200px", width: "100%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalTwoIsOpen}
      >
        <Modal.Body
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <NewSupplier
            closeModalHandler={closeModalHandler}
            getData={getData}
          />
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setModalTwoIsOpen(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "5px" }
                : { right: "5px", left: "auto" }
            }
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

const renderInput = function({
  input,
  label,
  meta,
  type,
  placeholder,
  id,
  value,
  defaultValue
}) {
  return (
    <div className="inputComp">
      <label
        className={
          localStorage.getItem("i18nextLng") == "ar" ? "arLabel" : "enLabel"
        }
      >
        {label}
      </label>
      {type == "date" ? (
        id == "min" ? (
          <input
            className="form-control"
            placeholder={placeholder}
            {...input}
            type={type}
            autoComplete="off"
            defaultValue=""
            min={new Date().toISOString().slice(0, 10)}
          />
        ) : (
          <input
            className="form-control"
            placeholder={placeholder}
            {...input}
            type={type}
            autoComplete="off"
            defaultValue=""
            max={new Date().toISOString().slice(0, 10)}
          />
        )
      ) : (
        <input
          className="form-control"
          placeholder={placeholder}
          {...input}
          type={type}
          defaultValue=""
          autoComplete="off"
        />
      )}

      {renderError(meta)}
    </div>
  );
};

const renderSelectField = function({
  meta,
  label,
  input,
  children,
  placeholder
}) {
  const className = `inputComp ${
    meta.touched && meta.error ? "has-error" : ""
  }`;
  return (
    <div className={className}>
      <label
        className={
          localStorage.getItem("i18nextLng") == "ar" ? "arLabel" : "enLabel"
        }
      >
        {label}
      </label>
      <select className="form-control" {...input} placeholder={placeholder}>
        {children}
      </select>
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="text-danger">{error}</div>;
  }
};
const validateForm = formValues => {
  const errors = {};

  console.log(formValues);

  if (!formValues.amount || !/^\d*\.?\d*$/.test(formValues.amount)) {
    errors.amount = i18n.t("Please add valid amount");
  }

  if (!formValues.currency || formValues.currency == -1) {
    errors.currency = i18n.t("Please add currency");
  }

  if (!formValues.paidTo) {
    errors.paidTo = i18n.t("Please add paid To");
  }

  if (
    !formValues.due ||
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(
      formValues.due
    )
  ) {
    errors.due = i18n.t("Please add due date");
  }
  if (
    !formValues.date ||
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(
      formValues.date
    )
  ) {
    errors.date = i18n.t("Please add date");
  }

  if (!formValues.paidFor || !/\S/.test(formValues.paidFor)) {
    errors.paidFor = i18n.t("Please add paid For");
  }

  if (!formValues.supplier || formValues.supplier == -1) {
    errors.supplier = i18n.t("Please select supplier");
  }

  return errors;
};

const mapStateToProps = state => {
  return {
    upload: state.upload,
    errors: state.error,
    lang: state.lang.lang
  };
};

const reduxMiddleware = reduxForm({
  form: "addRequest",
  validate: validateForm,
  enableReinitialize: true
})(Request);

export default connect(mapStateToProps, { addRequestSupplier })(
  withRouter(reduxMiddleware)
);
