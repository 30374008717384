import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { loadTeams, deleteMember } from "../../../../../../actions/teamActions";
import EditEmployee from "../editEmployee/editEmployee";
import Modal from "react-modal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { FcCheckmark } from "react-icons/fc";
import notification from "antd/lib/notification";

import "./invite.css";
import "antd/dist/antd.css";
import cogoToast from "cogo-toast";
import api from "../../../../../../apis/api";

const Invite = () => {
  const [joinedUsers, setJoinedUsers] = useState([]);
  const [counter, setCounter] = useState(0);
  const [indexes, setIndexes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUSer] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [btnState, setBtnState] = useState("invite");
  const [invitees, setInvitees] = useState([]);

  const { register, errors, handleSubmit } = useForm();

  const teams = useSelector(state => state.team.teams);
  const id = useSelector(state => state.auth.user.company.id);
  const inviteLink = `${window.location.origin}/invitation?companyId=${id}&type=createProfile`;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getInvitedUsersByLink = () => {
    api({
      url: "/teams/getInvitedUsersByLink",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(res => {
        setJoinedUsers(res.data.data.filter(user => !user.deleted));
      })
      .catch(err => {});
  };

  useEffect(() => {
    dispatch(loadTeams());
    getInvitedUsersByLink();
    addFriend();
  }, []);

  const copyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    cogoToast.success("Invitation link copied");
  };

  const copyToSlack = () => {
    navigator.clipboard.writeText(inviteLink);
    cogoToast.success("Invitation link copied");
    setTimeout(() => {
      window.open("https://slack.com/intl/en-eg/");
    }, 100);
  };

  const renderTeams = () => {
    if (teams && teams.length > 0) {
      let options = (
        <option value="noteam" disabled>
          {t("Pleasechooseateam")}
        </option>
      );
      const filteredTeams = teams.filter(team => team.name !== "All");
      return (options = [
        options,
        ...filteredTeams.map(team => {
          if (team.active)
            return (
              <option value={team._id} disabled={team.name === "All"}>
                {team.name}
              </option>
            );
        })
      ]);
    } else {
      return <option value="noteam">No teams found</option>;
    }
  };

  const onSubmit = data => {
    const filteredData = data.users.filter(user => user);
    api({
      url: "/user/invite",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`
      },
      data: { users: filteredData }
    })
      .then(res => {
        if (res.data.status === 1) {
          dispatch({ type: "SEND_INVITE", payload: res.data.data });
          cogoToast.success("User invited successfully");
          setBtnState("invited");
          const users = [];
          data.users.forEach(user => users.push(user.email));
          setInvitees(users);
          resetFields();
          addFriend();
          setTimeout(() => {
            setBtnState("invite");
          }, 5000);
        } else {
          cogoToast.error(res.data.message);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const checkEmailHandler = email => {
    email !== "" &&
      api({
        url: `user/check-email-existing?email=${email}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`
        }
      })
        .then(res => {
          const args = {
            description: `${email} already exists`,
            duration: 0,
            style: {
              backgroundColor: "#FECFD9",
              borderRadius: "20px"
            }
          };
          res.data.status !== 1 && notification.open(args);
        })
        .catch(err => {});
  };

  const addFriend = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeFriend = index => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setCounter(prevCounter => prevCounter - 1);
  };

  const resetFields = () => {
    setCounter(0);
    setIndexes([]);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const removeEmployee = id => {
    dispatch(deleteMember(id));
    setTimeout(() => {
      getInvitedUsersByLink();
    }, 1000);
    setModalIsOpen(false);
  };

  const customStyle = {
    content: {
      borderRadius: "20px",
      width: "50%",
      height: "fit-content",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    overlay: {
      backgroundColor: "rgba(150, 150, 150, 0.7)"
    }
  };

  return (
    <div className="invite-container">
      <div className="link-container">
        <div className="invite-by-link">
          <img src="/images/inviteByLink.png" alt="inviteByLink" />
          <h2>
            <b>{t("Send Invitation Link")}</b>
          </h2>
          <p>
            {t(
              "Send the company board invitation link for your employees to join Hollydesk"
            )}
            .
          </p>
          <div className="inviteActions">
            <button className="btn btnURL" onClick={() => copyLink()}>
              {t("Copy Invitation URL")}
            </button>
            <button className="btn btnSlack" onClick={() => copyToSlack()}>
              <img src="/images/slackLogo.png" alt="SlackLogo" />{" "}
              {t("Send To Slack")}
            </button>
          </div>
        </div>
        <div
          className="joined-by-link"
          style={
            localStorage.getItem("i18nextLng") == "en"
              ? { textAlign: "left" }
              : { textAlign: "right" }
          }
        >
          <h3>
            {t("Joined By Link")} ({joinedUsers ? joinedUsers.length : 0})
          </h3>
          {joinedUsers && joinedUsers.length > 0 ? (
            joinedUsers.map(user => {
              return (
                <div className="user" key={user._id}>
                  <div className="joined-user">
                    <img src="/images/member.png" alt="member" />
                    <div className="joined-user-info">
                      <h5>
                        {user.firstName} {user.lastName}
                      </h5>
                      <p>
                        {user.team.name} Team - {user.role}
                      </p>
                    </div>
                  </div>
                  <div className="userActions">
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#1292ee",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setSelectedUSer(user);
                        showDrawer();
                      }}
                    >
                      {t("Edit")}
                    </p>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#e32424",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setSelectedUSer(user);
                        setModalIsOpen(true);
                      }}
                    >
                      {t("Delete")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ textAlign: "center", color: "#314351" }}>
              {t("No members joined yet")}
            </p>
          )}
          <Drawer
            title={t("Edit team member")}
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
          >
            <EditEmployee
              selectedUser={selectedUser}
              refreshUsers={getInvitedUsersByLink}
              closeDeawer={onClose}
            />
          </Drawer>
        </div>
      </div>
      <div
        className="mainPage emailInvitation"
        style={
          localStorage.getItem("i18nextLng") == "en"
            ? { textAlign: "left" }
            : { textAlign: "right" }
        }
      >
        <div className="forms-side">
          <h3>{t("Invite By Email")}</h3>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ position: "relative" }}
          >
            {indexes.map((index, i) => {
              const fieldName = `users[${index}]`;
              return (
                <fieldset name={fieldName} key={fieldName}>
                  <div className="form-row">
                    <h5 style={{ marginBottom: "-20px", marginTop: "15px" }}>
                      {t("Team Member")}
                    </h5>
                    <button
                      type="button"
                      className="btn btnSec remove-member"
                      onClick={removeFriend(index)}
                      style={
                        i == 0 ? { display: "hidden" } : { display: "block" }
                      }
                    >
                      {t("Remove")}
                    </button>
                  </div>
                  <div className="form-row">
                    <input
                      className="form-control element30"
                      autoComplete="off"
                      name={`${fieldName}.firstName`}
                      placeholder={t("fName")}
                      ref={register({ required: true })}
                    />
                    {errors.firstName && <p>First name is required</p>}
                    <input
                      className="form-control element30"
                      autoComplete="off"
                      name={`${fieldName}.lastName`}
                      placeholder={t("lName")}
                      ref={register({ required: true })}
                    />
                    {errors.lastName && <p>Last name is required</p>}
                    <select
                      className="form-control element30"
                      name={`${fieldName}.team`}
                      ref={register({ required: true })}
                    >
                      {renderTeams()}
                    </select>
                    {errors.team && <p>Team is required</p>}
                  </div>
                  <div className="form-row">
                    <select
                      className="form-control element30"
                      name={`${fieldName}.role`}
                      ref={register({ required: true })}
                    >
                      <option value="user" selected>
                        {t("emp")}
                      </option>
                      <option value="team-manager">{t("manager")}</option>
                    </select>
                    {errors.team && <p>Team is required</p>}
                    <input
                      type="text"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      title="Enter in the format: example@email.com"
                      className="form-control element30"
                      autoComplete="off"
                      name={`${fieldName}.email`}
                      placeholder={t("bmailPH")}
                      onBlur={e => checkEmailHandler(e.target.value)}
                      ref={register({ required: true })}
                    />
                    {errors.email && <p>Email is required</p>}
                    <input
                      type="text"
                      className="form-control element30"
                      autoComplete="off"
                      name={`${fieldName}.jobTitle`}
                      placeholder={t("title")}
                      ref={register({ required: true })}
                    />
                    {errors.jobTitle && <p>Job title is required</p>}
                  </div>
                </fieldset>
              );
            })}
            <div className="form-row">
              <p className="add-team row-element" onClick={() => addFriend()}>
                + {t("Invite Another Team Member")}
              </p>
              {btnState === "invite" ? (
                <button
                  type="submit"
                  className="btn row-element"
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    alignItems: "center"
                  }}
                >
                  {t("Invite Members")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn row-element"
                  style={{
                    backgroundColor: "#c5ced6",
                    color: "#253642",
                    alignItems: "center"
                  }}
                >
                  {t("Invited")} {counter} {t("Members")} ! 🎉
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="invited-side">
          <h4>{t("Invitees")}</h4>
          {invitees.length > 0
            ? invitees.map(email => {
                return (
                  <div className="invitees-element">
                    <FcCheckmark /> &nbsp; &nbsp;{" "}
                    <p style={{ marginBottom: "0px" }}>{email}</p>
                  </div>
                );
              })
            : ""}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyle}
      >
        <h4>
          {t("Please confirm removing")} {selectedUser.fullname}
        </h4>
        <button
          className="btn btnPrimary btn-new-req"
          onClick={() => removeEmployee(selectedUser._id)}
        >
          {t("Confirm")}
        </button>
        <CloseOutlinedIcon
          className="close-icon"
          onClick={() => setModalIsOpen(false)}
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { right: "auto", left: "15px", top: "15px" }
              : { right: "15px", left: "auto", top: "15px" }
          }
        />
      </Modal>
    </div>
  );
};

export default Invite;
