import React, { useEffect } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import GettingStarted from './components/gettingStarted/gettingStarted';
import CompanyDetails from './components/companyDetails/companyDetails';
import AddSignature from './components/addSignature/addSignature';
import AdminRoles from './components/admin-roles/adminRoles';

import Loader from '../website/components/loader/loader';
import Sidebar from './components/sidebar/sidebar';
import history from '../../history';

const Create = (props) => {
  const render = () => {
    let { loading } = props;
    let routes = (
      <Router history={history}>
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div className="col-md-10 pr-0 pl-0">
              {loading === true ? <Loader /> : ''}
              <Switch>
                <Route path="/create" exact component={GettingStarted} />
                <Route
                  path="/create/company"
                  exact
                  component={CompanyDetails}
                />
                <Route
                  path="/create/signature"
                  exact
                  component={AddSignature}
                />
                <Route
                  path="/create/admin-roles"
                  exact
                  component={AdminRoles}
                />
                <Redirect to="/create/" />
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    );
    return routes;
  };
  return <div>{render()}</div>;
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps, {})(Create);
