import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { setLang } from '../../../../actions/langActions';

import { useTranslation } from 'react-i18next';
import i18n from '../../../../translations/i18n';

import './mainNav.css';
import egFlag from '../../../../assets/images/eg-flag.png'
import usFlag from '../../../../assets/images/us-flag.webp'

const MainNav = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
    localStorage.setItem('i18nextLng', localStorage.getItem('lang'));
    const element = document.getElementById('body');
    // localStorage.getItem('lang') == 'ar'
    //   ? element.setAttribute('style', "font-family: 'Almarai';")
    //   : element.setAttribute('style', "font-family: 'Almarai';");
  }, []);

  return (
    <div className="row mainNav">
      <section class="h-100 w-100 col-2 black ">
        <header class="container h-100 w-100 black">
          <div class="d-flex align-items-center justify-content-center black h-100 w-100">
            <div class="d-flex flex-column black" style={{ fontSize: '50px' }}>
              <img
                src="/images/l.png"
                className="col-12 h-100 d-flex flex-column m-3 black"
              ></img>
            </div>
          </div>
        </header>
      </section>

      <nav className="navbar navbarWeb navbar-expand-lg container-fluid col-10">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={localStorage.getItem('i18nextLng') == 'ar' ? "navbar-nav mr-auto" : "navbar-nav ml-auto"}>
              <li className="nav-item">
                {/* <Link className="nav-link"> */}
                <a href="https://www.about.hollydesk.com/">{t('why')}</a>
                {/* </Link> */}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#"></Link>
              </li>
              <li className="nav-item">
                {/* <Link className="nav-link" to="#"> */}
                <a href="https://www.about.hollydesk.com/contact">
                  {t('demo')}
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#"></Link>
              </li>

              <li className="nav-item">
                {/* <Link className="nav-link" to="#"> */}
                <Link
                  onClick={() => {
                    props.setLang(localStorage.getItem('i18nextLng'));
                    localStorage.setItem(
                      'lang',
                      localStorage.getItem('i18nextLng')
                    );

                    // document.body.style.direction =
                    //             localStorage.getItem('i18nextLng')
                    //  == 'ar' ? 'rtl' : 'ltr';
                  }}
                >
                {/*localStorage.getItem('i18nextLng') == 'en' ? '🇪🇬' : '🇺🇸'*/}
                  {localStorage.getItem('i18nextLng') == 'en' ? <img className="flag" src={egFlag} alt='flag'/> : <img className="flag" src={usFlag} alt='flag'/>}
                </Link>
                {/* </Link> */}
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="#">
                  Contact us
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
const mapStateToProps = (state) => ({
  lang: state.lang.lang,
});
export default connect(mapStateToProps, { setLang })(MainNav);
