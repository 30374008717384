import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import Upload from "../../../../components/requests/UploadImage/upload";
import cogoToast from "cogo-toast";
import {
  addSupplier,
  toggleBtnSpin
} from "../../../../../../actions/requestsActions";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { useForm } from "react-hook-form";
import countries from "../countries";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import "./my-requests.css";

const MyRequests = props => {
  const [registration, setRegistration] = useState({});
  const [license, setLicense] = useState({});
  const [VAT, setVAT] = useState({});
  const [logo, setLogo] = useState({});
  const [country, setCountry] = useState("Egypt");
  const [mobile, setMobile] = useState({
    short: "EG",
    phone: ""
  });
  const [phoneErr, setPhoneErr] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  // const [phoneError, setPhoneError] = useState(false);

  const spin = useSelector(state => state.request.spin) || false;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const registrationHandler = value => setRegistration(value);
  const licenseHandler = value => setLicense(value);
  const VATHandler = value => setVAT(value);
  const logoHandler = value => setLogo(value);

  const onSubmit = values => {
    console.log(values);
    let { files, name } = props.upload;
    let values_ = { ...values };
    console.log(name);
    values_.registration = registration;
    values_.license = license;
    values_.vat = VAT;
    values_.logo = logo;

    let correctedMobile = mobile.phone;
    let sentNumber = mobile.code + correctedMobile;
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length !== 10 &&
      mobile.phone.length !== 11
    )
      return cogoToast.error("Please, Enter valid mobile number");
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = mobile.code + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 11
    ) {
      correctedMobile = mobile.phone.substring(1);
      sentNumber = mobile.code + correctedMobile;
    }
    values_.phone = sentNumber;

    dispatch(toggleBtnSpin(true));

    const callback = () => {
      props.closeModalHandler();
      props.getData();
      setMobile({ short: "EG", phone: "" });
    };
    props.addSupplier(values_, callback);
  };

  return (
    <div className="mainPage">
      <div className="container-fluid">
        <form className="md-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row justify-content-center align-self-center">
            <div className="col-8 row ">
              <p
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : {
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "900",
                        fontSize: "23px"
                      }
                }
              >
                {t("Add New Supplier Company")}
              </p>
              <div className="Rectangle-54 col-12 p-2">
                <p
                  style={{ fontSize: "12px", textAlign: "center" }}
                  className="m-0"
                >
                  {t("newSupWarning")}
                </p>
              </div>

              <h4
                className="mb-4 mt-4"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { width: "100%", textAlign: "right" }
                    : {
                        width: "100%",
                        textAlign: "left",

                        fontWeight: "900",
                        fontSize: "23px"
                      }
                }
              >
                {t("1 - Supplier Company Information")}
              </h4>
              <br />

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Legal Name (Arabic)")}
                    </label>
                    <input
                      placeholder={t("Arabic Legal Name")}
                      name="nameArabic"
                      ref={register({
                        required: true,
                        pattern: /\S/
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.nameArabic && (
                      <span className="text-danger">
                        {t("This field is required")}
                      </span>
                    )}
                  </div>
                  <br />
                </div>

                <div className="col-md-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Legal Name (English)")}
                    </label>
                    <input
                      placeholder={t("English Legal Name")}
                      name="nameEnglish"
                      ref={register({
                        required: true,
                        pattern: /\S/
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.nameEnglish && (
                      <span className="text-danger">
                        {" "}
                        {t("This field is required")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Country")}
                    </label>

                    <select
                      placeholder={t("Enter Country")}
                      name="country"
                      ref={register({
                        required: true
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      onChange={e => setCountry(e.target.value)}
                    >
                      {Object.keys(countries).map(country => (
                        <option value={country}>{country}</option>
                      ))}
                    </select>
                    {errors.country && (
                      <span className="text-danger">Enter Valid Country</span>
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("City")}
                    </label>
                    <select
                      placeholder={t("Enter City")}
                      name="city"
                      ref={register({
                        required: true,
                        pattern: /[A-Za-z]/
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    >
                      {countries[country].map(country => (
                        <option value={country}>{country}</option>
                      ))}
                    </select>
                    {errors.city && (
                      <span className="text-danger">Enter Valid City</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Industry")}
                    </label>

                    <input
                      placeholder={t("Company Industry")}
                      name="industry"
                      ref={register({
                        required: true,
                        pattern: /\S/
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.industry && (
                      <span className="text-danger">
                        {t("Enter Valid Industry")}
                      </span>
                    )}
                  </div>
                  <br />
                </div>

                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Website")}
                    </label>

                    <input
                      placeholder="https://www.example.com"
                      name="website"
                      ref={register({
                        required: true,
                        pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
                      })}
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.website && (
                      <span className="text-danger">
                        {t("Enter Valid Website")}
                      </span>
                    )}
                  </div>
                  <br />
                </div>
                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") == "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Phone")}
                    </label>
                    {/* <input
                      placeholder="0100000000"
                      name="phone"
                      ref={register({
                        required: true,
                        pattern: /^01[0-2]{1}[0-9]{8}/,
                      })}
                      defaultValue="01"
                      type="number"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.phone && (
                      <span className="text-danger">Enter Valid Phone</span>
                    )} */}
                    <ConfigProvider
                      locale={en}
                      areaMapper={area => {
                        return {
                          ...area,
                          emoji: (
                            <span
                              className={`fp ${area.short.toLowerCase()}`}
                            />
                          )
                        };
                      }}
                    >
                      <CountryPhoneInput
                        defaultValue={{ short: "EG" }}
                        id="mobile-input"
                        type="number"
                        placeholder="1234567890"
                        value={mobile}
                        className="form-control"
                        onChange={v => {
                          setPhoneErr(false);
                          setMobile(v);
                        }}
                      />
                    </ConfigProvider>
                  </div>
                  <br />
                </div>

                <br />
              </div>
              <br />

              <br />

              <br />
              <div className="col-12">
                <hr />
              </div>
              <hr />
              <h4>2- {t("supLegDoc")}</h4>
              <br />
            </div>
            <div className="col-12"></div>
            <hr />
            <div className="col-1"></div>

            <div className="col-11 justify-content-center align-self-center">
              <div className="row">
                <Upload
                  name="Regestration"
                  extractedFile={registrationHandler}
                  acceptedFormats={["jpg", "jpeg", "png", "svg", "pdf"]}
                  label={
                    localStorage.getItem("i18nextLng") === "en"
                      ? "Upload company registration"
                      : "تحميل البطاقة الضريبية للشركة"
                  }
                />
                <Upload
                  name="Regestration"
                  extractedFile={licenseHandler}
                  acceptedFormats={["jpg", "jpeg", "png", "svg", "pdf"]}
                  label={
                    localStorage.getItem("i18nextLng") === "en"
                      ? "Upload Commercial License"
                      : "تحميل السجل التجاري للشركة"
                  }
                />
                <Upload
                  name="Regestration"
                  extractedFile={VATHandler}
                  acceptedFormats={["jpg", "jpeg", "png", "svg", "pdf"]}
                  label={
                    localStorage.getItem("i18nextLng") === "en"
                      ? "Upload VAT certificate"
                      : "تحميل القيمة المضافة للشركة"
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <br />
            </div>

            <div className="col-md-4"></div>
            <div className="col-md-4">
              <hr />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-1"></div>
            <div className="col-md-8">
              <br />
              <h4>3- {t("Supplier Display")}</h4>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="inputComp">
                    <label
                      className={
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "arLabel"
                          : "enLabel"
                      }
                    >
                      {t("Company Display Name")}
                    </label>

                    <input
                      ref={register({
                        required: true,
                        pattern: /\S/
                      })}
                      name="displayName"
                      placeholder="ex. HollyDesk"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                    />
                    {errors.displayName && (
                      <span className="text-danger">
                        {t("This field is required")}
                      </span>
                    )}
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-12 justify-content-center align-self-center"></div>
            <div className="col-4"></div>
            <Upload
              name="Regestration"
              extractedFile={logoHandler}
              acceptedFormats={["jpg", "jpeg", "png", "svg"]}
              label={
                localStorage.getItem("i18nextLng") === "en"
                  ? "Upload Supplier Logo"
                  : "تحميل صورة شعار المورد"
              }
            />
            <div className="col-4"></div>

            <div className="col-md-4">
              <hr />
            </div>
            <div className="col-md-12"></div>

            <div className="col-md-4">
              {spin ? (
                <button type="button" disabled className="btn btnPrimary">
                  <LoadingOutlined />
                </button>
              ) : (
                <button className="btn btnPrimary" type="submit">
                  {t("Submit New Supplier")}
                </button>
              )}
              <p
                className="btn btnSec"
                // onClick={() => history.goBack()}
                onClick={() => props.closeModalHandler(false)}
                style={{ marginLeft: "0px" }}
              >
                {t("Cancel")}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    request: state.request,
    errors: state.error,
    lang: state.lang.lang,
    upload: state.upload
  };
};

const reduxMiddleware = reduxForm({
  form: "addRequest",

  enableReinitialize: true
})(MyRequests);

export default connect(mapStateToProps, { addSupplier })(
  withRouter(reduxMiddleware)
);
