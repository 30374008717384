import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, Dropdown } from "antd";
import { GoChevronDown } from "react-icons/go";
import { RiBuilding4Fill } from "react-icons/ri";
import { RiFilePaper2Fill } from "react-icons/ri";
import { SiCashapp } from "react-icons/si";

import "antd/dist/antd.css";

const CompanyBoardMenu = () => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link className="nav-menu-item" to="/dashboard/companyProfile">
          <div className="icon-wrapper" style={{ backgroundColor: "#deebf5" }}>
            <RiBuilding4Fill className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Edit Company Profile")}
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link className="nav-menu-item" to="/dashboard/pageUnderConstruction">
          <div className="icon-wrapper" style={{ backgroundColor: "#eef0f3" }}>
            <RiFilePaper2Fill className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Set Company Policy")}
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link className="nav-menu-item" to="/dashboard/billingPlan">
          <div className="icon-wrapper" style={{ backgroundColor: "#e7f8ef" }}>
            <SiCashapp className="nav-menu-icon" />
          </div>
          &nbsp;&nbsp;
          {t("Company Billing Plan")}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className={
          window.location.pathname.includes("dashboard/companyProfile") ||
          window.location.pathname.includes("/dashboard/billingPlan")
            ? "active-nav-tab ant-dropdown-link"
            : "nav-tab ant-dropdown-link"
        }
        onClick={(e) => e.preventDefault()}
      >
        {t("Company Board")} &nbsp;{" "}
        <GoChevronDown style={{ fontSize: "16px" }} />
      </a>
    </Dropdown>
  );
};

export default CompanyBoardMenu;
