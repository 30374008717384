import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setLang } from "../../../../actions/langActions";
import egFlag from "../../../../assets/images/eg-flag.png";
import ksaFlag from "../../../../assets/images/ksa-flag.svg";
import usFlag from "../../../../assets/images/us-flag.webp";
import Drawer from "antd/lib/drawer";
import { GiHamburgerMenu } from "react-icons/gi";

import RequestsMenu from "./dropDownMenus/requestsMenu";
import WalletsMenu from "./dropDownMenus/walletsMenu";
import TeamWorkMenu from "./dropDownMenus/teamWorkMenu";
import CompanyBoardMenu from "./dropDownMenus/companyBoardMenu";
import HelpMenu from "./dropDownMenus/helpMenu";
import UserAvatarMenu from "./dropDownMenus/userAvatarMenu";
import NotificationsMenu from "./dropDownMenus/notificationsMenu";
import DrawerMenu from "./drawerMenu/drawerMenu";

import "./mainNavbar.css";

const MainNavbar = props => {
  const role = useSelector(state => state.auth.user.role);
  const teamName = useSelector(state => state.auth.user.team.name);
  const currency = useSelector(state => state.auth.user.currency[0]);
  const [visible, setVisible] = useState(false);

  const arFlag = currency === "EGP" ? egFlag : ksaFlag;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="navbar-background">
      <div className="mainNavbar">
        <div className="left-nav">
          <img src="/images/l.png" alt="HollyDesk Logo" />
          <div className="nav-menus">
            {role === "user" || role === "team-manager" || teamName === "HR" ? (
              ""
            ) : (
              <Link
                className={
                  window.location.pathname === "/dashboard/team"
                    ? "active-nav-tab"
                    : "nav-tab"
                }
                to="/dashboard/team"
              >
                {t("dashboard")}
              </Link>
            )}
            <RequestsMenu />
            <WalletsMenu />
            {role === "accountant" ||
            teamName === "HR" ||
            role === "manager" ? (
              <TeamWorkMenu />
            ) : (
              ""
            )}
            {role === "accountant" ||
            role === "manager" ||
            teamName === "HR" ? (
              <CompanyBoardMenu />
            ) : (
              ""
            )}
            {/* payroll role
             */}
            {role === "manager" ||
            role === "accountant" ||
            teamName === "HR" ? (
              <Link
                className={
                  window.location.pathname === "/dashboard/payroll"
                    ? "active-nav-tab"
                    : "nav-tab"
                }
                to="/dashboard/payroll"
              >
                {t("payroll")}
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="right-nav">
          <img
            className="flag nav-action"
            src={localStorage.getItem("i18nextLng") === "en" ? arFlag : usFlag}
            onClick={() =>
              dispatch(setLang(localStorage.getItem("i18nextLng")))
            }
          />
          <NotificationsMenu />
          <HelpMenu />
          <UserAvatarMenu />
        </div>
        <div className="mobile-menu">
          <GiHamburgerMenu onClick={showDrawer} className="nav-action" />
          <Drawer title="Menu" onClose={onClose} visible={visible}>
            <DrawerMenu />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default MainNavbar;
