import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { FaMobileAlt } from "react-icons/fa";
import { GoCreditCard } from "react-icons/go";
import { RiBankFill } from "react-icons/ri";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Modal } from "react-bootstrap";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { useForm } from "react-hook-form";

import {
  addCashoutMethods,
  deleteCashoutMethods,
  editCashoutMethods,
  getCashoutMethods
} from "../../../../../../actions/userActions";

import "./cashoutData.css";
import "react-phone-input-2/lib/style.css";
import cogoToast from "cogo-toast";

const CashoutData = ({
  handleChange,
  method,
  selectData,
  banksNames,
  selectedData,
  setName
}) => {
  const cashoutData = useSelector(state => state.auth.cashoutData);

  const [filteredMethods, setFilteredMethods] = useState([]);
  const [mode, setMode] = useState("add");
  const [showModal, setShowModal] = useState(false);
  const [mobile, setMobile] = useState({
    short: "EG",
    phone: ""
  });
  const [phoneErr, setPhoneErr] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCashoutMethods());
  }, []);

  useEffect(() => {
    setMobile({
      short: "EG",
      phone: selectedData.phoneNumber
    });
  }, [selectedData]);

  useEffect(() => {
    setFilteredMethods(
      cashoutData?.filter(data =>
        method === "BANK" ? data.type === "BANK" : data.type === "EWALLET"
      )
    );
  }, [method, cashoutData]);

  const editHandler = () => {
    setMode("edit");

    setShowModal(true);
  };

  const deleteHandler = id => {
    selectData({
      phone: { code: "20", short: "EG", phone: "" },
      bankName: "Arab African International Bank"
    });
    setName("");
    dispatch(deleteCashoutMethods({ id }));
  };

  const onSubmit = values => {
    console.log(values);
    let correctedMobile = mobile.phone;
    let sentNumber = correctedMobile;
    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length !== 10 &&
      mobile.phone.length !== 11
    ) {
      return cogoToast.error(t("Please, Enter valid mobile number"));
    }

    if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 10
    ) {
      correctedMobile = mobile.phone;
      sentNumber = "0" + correctedMobile;
    } else if (
      Object.keys(mobile).length !== 0 &&
      mobile.short === "EG" &&
      mobile.phone.length === 12
    ) {
      sentNumber = mobile.phone;
      correctedMobile = mobile.phone.substring(1);
      sentNumber = correctedMobile;
    }

    if (mode == "edit") values.id = selectedData._id;
    values.phone = sentNumber;
    values.name = values.bankAccountName;
    values.accountNumber = +values.bankAccountNumber;
    values.bankAddress = values.bankBranchAddress;
    values.bank = values.bankName;

    values.method = method;
    console.log(values);
    try {
      dispatch(
        mode == "add"
          ? addCashoutMethods(values, setShowModal, setMobile, selectData)
          : editCashoutMethods(values, setShowModal, setMobile, selectData)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="saved-data">
      <h4
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { textAlign: "right" }
            : { textAlign: "left" }
        }
      >
        {t("selectcashout")}
      </h4>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup
          className="saved-cashout-methods"
          aria-label="method"
          name="method1"
          value={method}
          onChange={e => handleChange(e.target.value)}
        >
          <FormControlLabel
            className="saved-method-option"
            value="EWALLET"
            label={t("E-Wallet")}
            control={<Radio color="primary" />}
          />
          <FormControlLabel
            className="saved-method-option"
            value="BANK"
            label={t("bankt")}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </FormControl>

      <h4
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { width: "100%", textAlign: "right", marginBottom: "25px" }
            : { width: "100%", textAlign: "left", marginBottom: "25px" }
        }
      >
        {t(method === "BANK" ? "Select bank account" : "Select E-wallet")}
      </h4>

      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup aria-label="accounts" name="accounts1" value={selectedData}>
          {filteredMethods?.map((meth, i) => (
            <FormControlLabel
              className="wallet-option"
              value={meth}
              control={<Radio color="primary" />}
              label={
                method === "BANK" ? (
                  <div
                    key={i}
                    className="wallet-option-card"
                    onClick={() => {
                      console.log(meth);
                      selectData({
                        ...meth,
                        phone: {
                          code: "20",
                          phone: meth.phoneNumber,
                          short: "EG"
                        }
                      });
                    }}
                  >
                    <div className="wallet-option-content">
                      <p>
                        <GoPerson className="card-info-icon" />
                        {meth.bankAccountName}
                      </p>
                      <p>
                        <GoCreditCard className="card-info-icon" />
                        {meth.bankAccountNumber}
                      </p>
                      <p>
                        <RiBankFill className="card-info-icon" />
                        {meth.bankName}
                      </p>
                    </div>
                    <div className="wallet-option-actions">
                      <BiEditAlt
                        className="card-edit-icon"
                        onClick={e => {
                          editHandler(meth);
                        }}
                      />
                      <MdDeleteForever
                        className="card-delete-icon"
                        onClick={e => {
                          e.stopPropagation();
                          deleteHandler(meth._id);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    key={i}
                    className="wallet-option-card"
                    onClick={() => {
                      selectData({
                        ...meth,
                        phone: {
                          code: "20",
                          phone: meth.phoneNumber,
                          short: "EG"
                        }
                      });
                    }}
                  >
                    <div className="wallet-option-content">
                      <p>
                        <GoPerson className="card-info-icon" />
                        {meth.bankAccountName}
                      </p>
                      <p>
                        <FaMobileAlt className="card-info-icon" />
                        {meth.phoneNumber}
                      </p>
                    </div>
                    <div className="wallet-option-actions">
                      <BiEditAlt
                        className="card-edit-icon"
                        onClick={e => {
                          editHandler(meth);
                        }}
                      />
                      <MdDeleteForever
                        className="card-delete-icon"
                        onClick={e => {
                          e.stopPropagation();
                          deleteHandler(meth._id);
                        }}
                      />
                    </div>
                  </div>
                )
              }
            />
          ))}
        </RadioGroup>
      </FormControl>

      <button
        className="btn btnGray"
        onClick={() => {
          setMode("add");

          setShowModal(true);
        }}
      >
        {t(method === "BANK" ? "Add bank account data" : "Add E-wallet data")}
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        className="wallet-confirm"
      >
        <Modal.Body>
          <div className="confirm-modal">
            <h3
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right", marginTop: "0px" }
                  : { textAlign: "left", marginTop: "0px" }
              }
            >
              {t(mode === "add" ? "Add new cashout data" : "Edit cashout data")}
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="row-element">
                  <label>{t("name")}</label>
                  <input
                    type="text"
                    placeholder="Ex: Ahmed Mohamed"
                    autoComplete="off"
                    className="form-control"
                    name="bankAccountName"
                    defaultValue={selectedData.bankAccountName}
                    ref={register({ required: true })}
                  />
                  {errors.bankAccountName && (
                    <p style={{ color: "red" }}>{t("Name is required")}</p>
                  )}
                </div>
                <div className="row-element">
                  <label>{t("Phone")}</label>
                  <ConfigProvider
                    locale={en}
                    ref={register({ required: true })}
                    areaMapper={area => {
                      return {
                        ...area,
                        emoji: (
                          <span className={`fp ${area.short.toLowerCase()}`} />
                        )
                      };
                    }}
                  >
                    <CountryPhoneInput
                      defaultValue={{
                        short: "EG",
                        phone: selectedData.phoneNumber
                      }}
                      id="mobile-input"
                      type="number"
                      placeholder="1234567890"
                      ref={register({ required: true })}
                      value={mobile}
                      className="form-control"
                      onChange={v => {
                        setPhoneErr(false);
                        setMobile(v);
                      }}
                    />
                  </ConfigProvider>

                  {errors.phoneNumber && <p>Phone is required</p>}
                  {phoneErr && (
                    <p style={{ color: "red" }}>Phone is required</p>
                  )}
                </div>
              </div>
              {method === "BANK" && (
                <>
                  <div className="form-row">
                    <div className="row-element">
                      <label>{t("accNum")}</label>
                      <input
                        type="number"
                        placeholder="12345678909843352"
                        autoComplete="off"
                        className="form-control"
                        name="bankAccountNumber"
                        defaultValue={selectedData.bankAccountNumber}
                        ref={register({
                          required: true,
                          maxLength: {
                            value: 20,
                            message: "Account number should be 20 digits"
                          },
                          minLength: {
                            value: 12,
                            message: "Account number should be 16 digits"
                          }
                        })}
                      />
                      {errors.bankAccountNumber && (
                        <p style={{ color: "red" }}>
                          {t("Please enter a valid account number")}
                        </p>
                      )}
                    </div>
                    <div className="row-element">
                      <label>{t("bank")}</label>
                      <select
                        className="form-control"
                        name="bankName"
                        ref={register({ required: true })}
                      >
                        <option value="">Please, Select your bank name</option>
                        {banksNames.map(name => {
                          return (
                            <option
                              value={name}
                              selected={selectedData.bankName == name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.bankName && (
                        <p style={{ color: "red" }}>
                          {t("Bank name is required")}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-row wide">
                    <label>{t("bankAddress")}</label>
                    <input
                      className="form-control"
                      autoComplete="off"
                      placeholder={t("Cairo")}
                      name="bankBranchAddress"
                      defaultValue={selectedData.bankBranchAddress}
                      ref={register({ required: true })}
                    />
                    {errors.bankBranchAddress && (
                      <p style={{ color: "red" }}>
                        {t("Bank branch address is required")}
                      </p>
                    )}
                  </div>
                </>
              )}
              <button className="btn btnGray" type="submit">
                {t("Submit")}
              </button>
            </form>
          </div>
          <CloseOutlinedIcon
            className="close-icon"
            onClick={() => setShowModal(false)}
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { right: "auto", left: "5px" }
                : { right: "5px", left: "auto" }
            }
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CashoutData;
