import React, { useEffect, useState } from "react";
import fileUploaded from "../../../../../assets/images/fileUploaded.png";
import Loader from "react-loader-spinner";
import api from "../../../../../apis/api";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";

import "./uploadImages.css";

const Upload = props => {
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("upload");

  const { t } = useTranslation();

  useEffect(() => {
    return props.srcFile && (setFile(props.srcFile), setStatus("exist"));
  }, [props.srcFile]);

  const deleteImage = () => {
    props.edit && setFile({});
    props.extractedFile({});
  };

  const handleChange = files => {
    const fileFormat = files[0].name.split(".").pop();
    if (!props.acceptedFormats.includes(fileFormat))
      return cogoToast.error(t("File format not supported"));
    if (files.length === 1) {
      let formData = new FormData();
      Array.from(files).forEach(file => {
        formData.append("file", file);
      });
      setLoading(true);

      api({
        url: "/upload/images?type=requests",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": `Bearer ${localStorage.getItem("token")}`
        },
        data: formData
      })
        .then(res => {
          if (res.data.status === 1) {
            setFile(res.data.data[0]);
            props.extractedFile(res.data.data[0]);
            setLoading(false);
          } else {
            cogoToast.error(t(res.data.message));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <div className="col-4">
      <div className="uploadImages col-12">
        <div className="row">
          <div className="image-upload_ text-center col-12">
            {loading ? (
              <div className="loading">
                <Loader type="Rings" />
              </div>
            ) : !loading && Object.keys(file).length !== 0 ? (
              <React.Fragment>
                <label
                  htmlFor="file-input-success"
                  className="align-self-center"
                >
                  <img
                    src={file.path}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = fileUploaded;
                    }}
                    className="col-12"
                  />

                  <p className="mb-3 col-12">
                    {status === "exist"
                      ? props.name
                      : localStorage.getItem("i18nextLng") === "en"
                      ? "Images uploaded successfully!"
                      : "تم رفع الصور بنجاح"}
                  </p>
                </label>
                {props.edit && (
                  <button
                    className="remove-image"
                    onClick={() => {
                      deleteImage();
                    }}
                    style={{ display: "inline", zIndex: "100" }}
                  >
                    &#215;
                  </button>
                )}
                {props.isViewSupplier && (
                  <a
                    style={{ color: "green" }}
                    className="col-3"
                    href={props.srcFile?.path}
                    download
                  >
                    {t("Down")}
                  </a>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label
                  htmlFor={props.label}
                  className="col-12"
                  style={{ cursor: "pointer" }}
                >
                  <img src="/images/icons8-pictures-folder.svg" alt="upload" />
                  <br />
                  <br />

                  <p
                    className=" col-12"
                    style={{ fontSize: "14px", padding: "0px" }}
                  >
                    {props.label}
                  </p>
                  <span
                    className="text-muted"
                    style={{ fontSize: "10px", padding: "0px" }}
                  >
                    {localStorage.getItem("i18nextLng") == "en"
                      ? "Supports jpg, png, pdf, . File size up to 10 MB"
                      : "ندعم PNG,JPG يمكنك رفع صورة حتى 10 ميجا"}
                  </span>
                </label>
                <input
                  id={props.label}
                  multiple={false}
                  type="file"
                  name="image"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={e => handleChange(e.target.files)}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
