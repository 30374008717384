import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import { BiHide } from "react-icons/bi";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import api from "../../../../apis/api";
import { useHistory } from "react-router-dom";
import i18n from "../../../../translations/i18n";

// import './invitation.css'

const BoardMemberInvitation = props => {
  const [companyName, setCompanyName] = useState("");
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRPass, setShowRPass] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const companyId = params.get("companyId");
  const userId = params.get("userId");

  const { register, errors, handleSubmit } = useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const content = (
    <ul>
      <li>8 Letters min length</li>
      <li>Include capital Letter</li>
      <li>Include small Letter</li>
      <li>Include Number</li>
      <li>Include special character</li>
    </ul>
  );

  const getCompanyAndTeams = () => {
    api
      .post("teams/getCompanyAndTeams", { id: companyId })
      .then(res => {
        if (res.data.status === 1) {
          setCompanyName(res.data.data.company.name);
        } else {
          cogoToast.error(i18n.t("Can not get company data"));
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getCompanyAndTeams();
  }, []);

  const submitMember = data => {
    api
      .post("user/boardMemberInvitation", data)
      .then(res => {
        if (res.data.status === 1) {
          history.replace("/login");
          // setTimeout(() => {
          //   window.open("https://www.hollydesk.com/login");
          // }, 500);
        } else cogoToast.error(i18n.t(res.data.message));
      })
      .catch(err => console.log(err));
  };

  const onSubmit = data => {
    data.company = companyId;
    data.userId = userId;
    if (data.password === data.rpassword) {
      submitMember(data);
    } else {
      cogoToast.error(i18n.t("Passwords don't match"));
    }
  };

  return (
    <div className="invitation">
      <div className="invitation-side">
        <img src="/images/l.png" alt="logo" />
        <h3>{t("Manage Your Work Expenses!")}</h3>
        <img src="/images/mobile.png" alt="logo" />
      </div>
      <div className="page gettingStarted invitation-content">
        <h1>{t("invite1")}</h1>
        <h5>
          {t("invite2")} 👋, {companyName} {t("invite3")}
        </h5>
        <h5>{t("invite4")}</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="row-element">
              <label>{t("fName")}</label>
              <input
                type="text"
                placeholder={t("fNamePH")}
                autoComplete="off"
                className="form-control"
                name="firstName"
                ref={register({ required: true })}
              />
              {errors.firstName && <p>First name is required</p>}
            </div>
            <div className="row-element">
              <label>{t("lName")}</label>
              <input
                type="text"
                placeholder={t("lNamePH")}
                autoComplete="off"
                className="form-control"
                name="lastName"
                ref={register({ required: true })}
              />
              {errors.lastName && <p>Last name is required</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="row-element" style={{ position: "relative" }}>
              <label>{t("pass")}</label>
              <input
                type={showNewPass ? "text" : "password"}
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
                placeholder={t("passPH")}
                autoComplete="off"
                className="form-control"
                name="password"
                minLength="8"
                ref={register({ required: true })}
              />
              {errors.password && <p>Password is required</p>}
              <BiHide
                className="passShowHide"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "10px", top: "42px" }
                    : { right: "10px", top: "42px" }
                }
                onClick={() => setShowNewPass(!showNewPass)}
              />
              <span
                className="pass-requirements"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "40px", top: "35px" }
                    : { right: "40px", top: "35px" }
                }
              >
                <Popover
                  content={content}
                  title="Password must be:"
                  trigger="click"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </div>
            <div className="row-element" style={{ position: "relative" }}>
              <label>{t("cPassword")}</label>
              <input
                type={showRPass ? "text" : "password"}
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                title="Password must contain at least 8 characters, 1 capital letter, 1 lowercase letter, 1 number, and one special character"
                placeholder={t("cPasswordPH")}
                autoComplete="off"
                className="form-control"
                name="rpassword"
                minLength="8"
                ref={register({ required: true })}
              />
              {errors.rpassword && <p>Please, Confirm Password</p>}
              <BiHide
                className="passShowHide"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "10px", top: "42px" }
                    : { right: "10px", top: "42px" }
                }
                onClick={() => setShowRPass(!showRPass)}
              />
              <span
                className="pass-requirements"
                style={
                  localStorage.getItem("i18nextLng") == "ar"
                    ? { left: "40px", top: "35px" }
                    : { right: "40px", top: "35px" }
                }
              >
                <Popover
                  content={content}
                  title="Password must be:"
                  trigger="click"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </div>
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t("Work Title (Optional)")}</label>
              <input
                type="text"
                placeholder={t("Recruitment Specialist")}
                autoComplete="off"
                className="form-control"
                name="jobTitle"
                ref={register({ required: false })}
              />
            </div>
            <div className="row-element">
              <label style={{ visibility: "hidden" }}>{t("pass")}</label>
              <button
                className="btn btnPrimary"
                type="submit"
                style={{ marginTop: "0px" }}
              >
                {t("Join Company Account")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BoardMemberInvitation;
