import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { HiCreditCard } from "react-icons/hi";
import { MdAccountBalanceWallet } from "react-icons/md";
import Divider from "antd/lib/divider";
import Switch from "antd/lib/switch";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router";

import "./planConfiguration.css";

const PlanConfiguration = (props) => {
  const plans = useSelector((state) => state.billing.plans);
  const currency = useSelector((state) => state.auth.user.currency[0]);

  const [method, setMethod] = useState("card");
  const [isChecked, setIsChecked] = useState(true);
  const [checkedPlan, setCheckedPlan] = useState("");

  const selectedPlanId = props.match.params.id;
  const currentPlan = plans.filter((plan) => plan._id === selectedPlanId);
  const paymentCards = [
    {
      number: 9876,
      _id: "123",
    },
    {
      number: 9090,
      _id: "456",
    },
  ];

  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    setCheckedPlan(selectedPlanId);
    if (paymentCards.length > 0) setMethod(paymentCards[0]._id);
  }, []);

  const addCreditCard = () => {
    api({
      url: "/company/getTokenBillingForm",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      // data: { amount: creditCardAmount },
    })
      .then((res) => {
        if (res.data.status === 1) {
          window.COWPAYOTPDIALOG.init();
          window.COWPAYOTPDIALOG.load(res.data.data.confirmation_form_id);
        } else {
          cogoToast.error("Transaction Faild");
        }
      })
      .catch((err) => {});
  };

  const checkoutHandler = () => {
    api({
      url: "/plans",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
      // data: { plan: checkedPlan, card: method, auto: isChecked },
      data: {
        plan: checkedPlan,
        card: "6165caeda225c10b7fb10ca5",
        auto: isChecked,
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          history.push("/dashboard/billingPlan");
          cogoToast.success("Subscribed Successfully");
        } else {
          cogoToast.error("Subcription Faild");
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="mainPage plan-upgrade">
      <h1 id={`PlanConfiguration-Title`} style={{ width: "fit-content" }}>
        {t("Configure your suitable plan")}
      </h1>
      <h5
        id={`PlanConfiguration-SubTitle`}
        style={{ width: "fit-content", color: "#74767A" }}
      >
        {t("Get additional power and control on a large scale")}
      </h5>
      <div className="upgrade-container">
        <div className="payment-details">
          <h3 id={`PlanConfiguration-paymentDetailsTitle`}>
            {t("Payment Details")}
          </h3>
          <div className="addCard-configuration">
            <h5 id={`PlanConfiguration-payWithCard`}>{t("Saved cards")}</h5>
            <button
              className="btn btn-outline-success"
              onClick={() => addCreditCard()}
            >
              {t("Add New Card")}
            </button>
          </div>
          <RadioGroup>
            {paymentCards.length > 0 ? (
              paymentCards.map((card, i) => {
                return (
                  <label id={`PlanConfiguration-label-${i + 30}`} key={i}>
                    <Radio
                      id={`PlanConfiguration-label-${i + 400}`}
                      key={i + 50}
                      checked={method === card._id}
                      color="primary"
                      type="radio"
                      name="cashMethod"
                      value="credit"
                      onClick={() => {
                        setMethod(card._id);
                      }}
                    />
                    <div className="cash-method">
                      <span id={`PlanConfiguration-method-${i + 330}`}>
                        <HiCreditCard
                          className="updgrade-icon"
                          style={{
                            color: method === card._id ? "#56C288" : "#BDBDBD",
                          }}
                        />
                        &nbsp; &nbsp;
                        {`**** ${card.number}`}
                      </span>
                    </div>
                  </label>
                );
              })
            ) : (
              <p>You do not have saced cards</p>
            )}
            <h5
              id={`PlanConfiguration-payWithWallet`}
              style={{ marginTop: "30px" }}
            >
              {t("Pay with wallet")}
            </h5>
            <label
              id={`PlanConfiguration-label`}
              onClick={() => {
                // setMethod("wallet");
              }}
            >
              <Radio
                id={`PlanConfiguration-radio`}
                // checked={method === "wallet" ? true : false}
                color="primary"
                type="radio"
                name="cashMethod"
                value="transfer"
                disabled={true}
              />
              <div className="cash-method">
                <span id={`PlanConfiguration-method`}>
                  <MdAccountBalanceWallet
                    className="updgrade-icon"
                    style={{
                      color: method === "wallet" ? "#56C288" : "#BDBDBD",
                    }}
                  />
                  &nbsp; &nbsp;
                  {t("Wallet")}
                </span>
              </div>
            </label>
          </RadioGroup>
          <Divider />
          <div className="billing-switch">
            <Switch
              id={`PlanConfiguration-switch`}
              defaultChecked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            &nbsp; &nbsp; &nbsp;
            <p id={`PlanConfiguration-switch1`}>
              {t("Turn on")}{" "}
              <span
                id={`PlanConfiguration-switch2`}
                style={{ color: "#1292ee" }}
              >
                {t("Automatic Renewal")}
              </span>
              .{" "}
              <span
                id={`PlanConfiguration-switch3`}
                style={{ color: "#748494" }}
              >
                {t("You can cancel anytime, terms and conditions applied")}
              </span>
            </p>
          </div>
        </div>
        <div className="plans-to-upgrade">
          <h3 id={`PlanConfiguration-plansTitle`}>{t("Your Plan")}</h3>
          {plans.map((plan, i) => {
            return (
              <RadioGroup>
                <label
                  id={`PlanConfiguration-plansLabel-${i + 20}`}
                  className="upgrade-option-label"
                  onClick={() => {
                    // setMethod("card");
                  }}
                >
                  <Radio
                    id={`PlanConfiguration-plansRadio-${i + 20}`}
                    color="primary"
                    type="radio"
                    name="planToUpgrade"
                    value="credit"
                    checked={plan._id === checkedPlan}
                    onChange={() => setCheckedPlan(plan._id)}
                  />
                  <div className="upgrade-option">
                    <h6
                      id={`PlanConfiguration-planNameLabel-${i + 20}`}
                      style={{ fontWeight: "bold" }}
                    >
                      {plan.name}
                    </h6>
                    <span id={`PlanConfiguration-planPriceLabel-${i + 20}`}>
                      {t(currency)} {plan.price} &nbsp;
                      {t("Per User / Month, Billed Monthly")}
                    </span>
                  </div>
                </label>
              </RadioGroup>
            );
          })}
          <Divider />
          <div
            className="plan-control-element"
            style={{ width: "100%", border: "none" }}
          >
            <div className="row-element">
              <p
                id={`PlanConfiguration-employees`}
                className="control-element-title"
              >
                {t("Employees")}
              </p>
              <p
                id={`PlanConfiguration-employeesNumber`}
                className="control-element-content"
              >
                10
              </p>
            </div>
            <p
              id={`PlanConfiguration-planPeriod`}
              className="control-element-title"
            >
              {t(currency)} {currentPlan.price} &nbsp;
              {t("Per User / Month")}
            </p>
          </div>
          <div className="search-group">
            <input
              id={`PlanConfiguration-promocode`}
              className="form-control"
              type="text"
              placeholder={t("Promo code")}
            />
            <button
              id={`PlanConfiguration-promoButton`}
              className="btn btnPrimary"
            >
              {t("Apply")}
            </button>
          </div>
          <div className="billingCashoutDetails">
            <div
              className="discountDetails form-row"
              style={{ margin: "auto" }}
            >
              <p
                id={`PlanConfiguration-discountTitle`}
                style={{ marginBottom: "0px" }}
              >
                {t("Discount")}
              </p>
              <span id={`PlanConfiguration-discountValue`}>{currency} 15</span>
            </div>
            <Divider />
            <div
              className="form-row"
              style={{ margin: "auto", alignItems: "center" }}
            >
              <h5
                id={`PlanConfiguration-totalTitle`}
                style={{ fontSize: "large", fontWeight: "bold" }}
              >
                {t("Total")}
              </h5>
              <div className="row-element priceBeforeAfter">
                <span
                  id={`PlanConfiguration-priceBefore`}
                  className="billingPriceBefore"
                >
                  {currency} 130
                </span>
                <span
                  id={`PlanConfiguration-priceAfter`}
                  className="billingPriceAfter"
                >
                  {currency} 115
                </span>
                <span
                  id={`PlanConfiguration-taxTitle`}
                  style={{ color: "#979797" }}
                >
                  + {t("Applicable tax")}
                </span>
              </div>
            </div>
          </div>
          <button
            id={`PlanConfiguration-checkoutButton`}
            className="btn btnPrimary"
            onClick={() => checkoutHandler()}
          >
            {t("Checkout")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanConfiguration;
