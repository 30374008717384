import { SET_LOADING, REMOVE_LOADING } from './types';
import { loadRequestCount,loadInadvanceRequestCount } from './authActions';

export const setLoading = () => (dispatch) => {
  loadRequestCount();
  loadInadvanceRequestCount()

  dispatch({
    type: SET_LOADING,
  });
};

export const removeLoading = () => {
  loadRequestCount();
  loadInadvanceRequestCount()

  return {
    type: REMOVE_LOADING,
  };
};
