import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import UploadImages from "../../../../components/requests/UploadImage/uploadImages";
import {
  addRequest,
  toggleBtnSpin
} from "../../../../../../actions/requestsActions";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import "./request.css";
import {
  getCases,
  getCategories
} from "../../../../../../actions/categoryActions";

const Request = React.memo(props => {
  const currency = useSelector(state => state.auth.user.currency[0]);
  const categories = useSelector(state => state.categories.categories) || [];
  const cases = useSelector(state => state.categories.cases) || [];
  const spin = useSelector(state => state.request.spin) || false;
  const [msg, setMsg] = useState(undefined);

  const { register, errors, handleSubmit } = useForm();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onSubmit = values => {
    let { files, name } = props.upload;
    if (name === "requests" && files.length > 0) {
      dispatch(toggleBtnSpin(true));
      let images = [];
      files.forEach(file => {
        images.push(file._id);
      });
      values["images"] = images;
      values.received ? (values.received = true) : (values.received = false);
      values.categoryId === "" && (values.categoryId = null);
      values.caseId === "" && (values.caseId = null);
      props.addRequest(values, files);
    } else {
      cogoToast.error(t("please upload image"));
    }
  };

  useEffect(() => {
    if (props.errors.id === "ADD_BANK_FAIL") {
      setMsg(props.errors.msg);
    }
    dispatch(getCategories());
    dispatch(getCases());
  }, []);

  return (
    <div className="mainPage request request-inadvance">
      <div className="reconcile-upload">
        <UploadImages name="requests" />
      </div>
      <div className="spliter"></div>
      <div className="inadvance-form">
        <form onSubmit={handleSubmit(onSubmit)} className="md-form">
          <h2
            className="mb-4"
            style={
              localStorage.getItem("i18nextLng") == "ar"
                ? { width: "100%", textAlign: "right" }
                : { width: "100%", textAlign: "left" }
            }
          >
            {t("fillRI")}
          </h2>

          <div>
            <div className="form-row">
              <div className="row-element">
                <label>{t("amount")}</label>
                <input
                  type="number"
                  placeholder="00"
                  step="0.01"
                  autoComplete="off"
                  className="form-control"
                  name="amount"
                  ref={register({
                    required: true,
                    min: {
                      value: 1,
                      message: "Amount must be positive value"
                    }
                  })}
                />
                {errors.amount && <p>{t("Please enter a valid amount")}</p>}
              </div>
              <div className="row-element">
                <label>{t("currency")}</label>
                <select
                  className="form-control"
                  name="currency"
                  ref={register({ required: true })}
                >
                  <option value={currency} selected>
                    {t(currency)}
                  </option>
                </select>
                {errors.currency && <p>Currency is required</p>}
              </div>
            </div>

            <div className="form-row">
              <div className="row-element">
                <label>{t("companyPaidTo")}</label>
                <input
                  className="form-control"
                  autoComplete="off"
                  name="paidTo"
                  placeholder={t("companyPaidToPH")}
                  ref={register({ required: true })}
                />
                {errors.paidTo && <p>{t("Paid to is required")}</p>}
              </div>
              <div className="row-element">
                <label>{t("paidOn")}</label>
                <input
                  className="form-control"
                  type="date"
                  name="date"
                  max={new Date().toISOString().split("T")[0]}
                  ref={register({ required: true })}
                />
                {errors.date && <p>{t("Pay date is required")}</p>}
              </div>
            </div>

            <div className="form-row wide">
              <label>{t("paidFor")}</label>
              <input
                className="form-control"
                autoComplete="off"
                placeholder={t("paidForPH")}
                name="paidFor"
                ref={register({ required: true })}
              />
              {errors.paidFor && <p>{t("Paid for is required")}</p>}
            </div>
            {/* <div hidden>
              <Field
                label="لقد استلمت المبلغ نقدا لحالة طارئة"
                type="checkbox"
                name="received"
                component={renderCheckField}
              />
            </div> */}
          </div>

          <div className="form-row">
            <div className="row-element">
              <label>{t("Category")}</label>
              <select
                className="form-control"
                name="categoryId"
                ref={register({ required: false })}
              >
                <option value="">{t("Select Category")}</option>
                {categories.map(cat => {
                  return <option value={cat._id}>{cat.name}</option>;
                })}
              </select>
            </div>
            <div className="row-element">
              <label>{t("Case")}</label>
              <select
                className="form-control"
                name="caseId"
                ref={register({ required: false })}
              >
                <option value="">Select Case</option>
                {cases.map(cat => {
                  return <option value={cat._id}>{cat.name}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {spin ? (
                <button type="button" disabled className="btn btnPrimary">
                  <LoadingOutlined />
                </button>
              ) : (
                <button className="btn btnPrimary">{t("submit")}</button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});

const mapStateToProps = state => {
  return {
    upload: state.upload,
    errors: state.error,
    lang: state.lang.lang
  };
};

export default connect(mapStateToProps, { addRequest })(withRouter(Request));
