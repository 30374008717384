import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REQUEST_COUNT,
  MY_REQUEST_COUNT,
  INADVANC_REQUEST_COUNT,
  MY_INADVANCE_REQUEST_COUNT
} from "../actions/types";

const INITIAL_STATE = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: false,
  user: null,
  message: null,
  count: 0,
  inadvanceCount: 0,
  myCount: 0,
  myInAdvanceRequestCount: 0,
  verificationEmail: "",
  cashoutData: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        message: null
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        message: null
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
        message: null
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        user: null,
        message: null
      };

    case REQUEST_COUNT:
      return { ...state, count: action.payload };
    case INADVANC_REQUEST_COUNT:
      return { ...state, inadvanceCount: action.payload || 0 };
    case MY_REQUEST_COUNT:
      return { ...state, myCount: action.payload };
    case MY_INADVANCE_REQUEST_COUNT:
      return { ...state, myInAdvanceRequestCount: action.payload };
    case "VERIFICATION_EMAIL":
      return { ...state, verificationEmail: action.email.email };
    case "CHANGE_USER_EMAIL":
      return {
        ...state,
        user: {
          ...state.user,
          email: action.email
        }
      };
    case "ADD_PHONE_NUMBER":
      return {
        ...state,
        user: {
          ...state.user,
          phone: state.user.phone.concat(action.phone)
        }
      };
    case "REMOVE_PHONE_NUMBER":
      return {
        ...state,
        user: {
          ...state.user,
          phone: state.user.phone.filter(number => number !== action.phone)
        }
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        user: action.user
      };
    case "EWALLETS_OPTIONS":
      return {
        ...state,
        user: {
          ...state.user,
          ewallets: action.payload
        }
      };
    case "GET_USER_INFO":
      return {
        ...state,
        user: action.userInfo
      };
    case "GET_CASHOUT_METHODS":
      return {
        ...state,
        cashoutData: action.payload
      };

    default:
      return state;
  }
};
