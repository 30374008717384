import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import api from "../../../../../../apis/api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cogoToast from "cogo-toast";
import {
  getCases,
  getCategories
} from "../../../../../../actions/categoryActions";

const EditRequestInAdvance = props => {
  const { register, errors, handleSubmit } = useForm();

  const history = useHistory();

  const { t } = useTranslation();

  const inAdvanceRequests = useSelector(
    state => state.request.requestsInadvance
  );
  const categories = useSelector(state => state.categories.categories) || [];
  const cases = useSelector(state => state.categories.cases) || [];

  const editedRequest = inAdvanceRequests.find(
    req => req.id === props.match.params.id
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCases());
  }, []);

  const onSubmit = data => {
    data.id = props.match.params.id;
    data.categoryId === "" && (data.categoryId = null);
    data.caseId === "" && (data.caseId = null);

    console.log(data);
    api({
      url: "/inadvance/editRequest",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*"
      },
      data: data
    })
      .then(res => {
        if (res.data.status === 1) {
          console.log(res);
          console.log(res.data.data.number);
          history.replace(
            `/dashboard/requests/newInAdvanceRequest/seccess?num=${res.data.data.number}`
          );
        } else {
          cogoToast.error("Failed editing the request");
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="mainPage request-inadvance">
      <div
        className="inadvance-form"
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { textAlign: "right" }
            : { textAlign: "left" }
        }
      >
        <h2>{t("send")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="row-element">
              <label>{t("amount")}</label>
              <input
                type="number"
                placeholder="00"
                step="0.01"
                defaultValue={editedRequest.amount}
                autoComplete="off"
                className="form-control"
                name="amount"
                ref={register({ required: true })}
              />
              {errors.amount && <p>Amount is required</p>}
            </div>
            <div className="row-element">
              <label>{t("currency")}</label>
              <select
                className="form-control"
                name="currency"
                ref={register({ required: true })}
              >
                <option selected>EGP</option>
              </select>
              {errors.currency && <p>Currency is required</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t("payToInAd")}</label>
              <input
                className="form-control"
                autoComplete="off"
                name="paidTo"
                defaultValue={editedRequest.paidTo}
                placeholder={t("payToInAdPH")}
                ref={register({ required: true })}
              />
              {errors.paidTo && <p>{t("Paid to is required")}</p>}
            </div>
            <div className="row-element">
              <label>{t("payDate")}</label>
              <input
                className="form-control"
                type="date"
                name="date"
                defaultValue={moment(editedRequest.paidOn).format("YYYY-MM-DD")}
                min={new Date().toISOString().split("T")[0]}
                ref={register({ required: true })}
              />
              {errors.date && <p>{t("Pay date is required")}</p>}
            </div>
          </div>
          <div className="form-row wide">
            <label>{t("payForInAd")}</label>
            <input
              className="form-control"
              autoComplete="off"
              placeholder={t("payForInAdPH")}
              name="paidFor"
              defaultValue={editedRequest.paidFor}
              ref={register({ required: true })}
            />
            {errors.paidFor && <p>{t("Paid for is required")}</p>}
          </div>
          <div className="form-row">
            <div className="row-element">
              <label>{t("Category")}</label>
              <select
                className="form-control"
                name="categoryId"
                ref={register({ required: false })}
              >
                <option value="">{t("Select Category")}</option>
                {categories.map(cat => {
                  return <option value={cat._id}>{cat.name}</option>;
                })}
              </select>
            </div>
            <div className="row-element">
              <label>{t("Case")}</label>
              <select
                className="form-control"
                name="caseId"
                ref={register({ required: false })}
              >
                <option value="">Select Case</option>
                {cases.map(cat => {
                  return <option value={cat._id}>{cat.name}</option>;
                })}
              </select>
            </div>
          </div>
          <button className="btn btnPrimary">{t("submitReq")}</button>
        </form>
      </div>
      <div className="spliter"></div>
      <div
        className="reconcile-upload"
        style={
          localStorage.getItem("i18nextLng") == "ar"
            ? { textAlign: "right" }
            : { textAlign: "left" }
        }
      >
        <h2>{t("fillRI3")}</h2>
        <div className="red-warning" style={{ fontSize: "medium" }}>
          <div className="warning-head">
            <WarningRoundedIcon /> <p>{t("noReq1")}</p>
          </div>
          <p>{t("noReq2")}</p>
        </div>
        <div className="blue-warning" style={{ fontSize: "medium" }}>
          <div className="warning-head">
            <WarningRoundedIcon />
            <p>{t("warn3")}</p>
          </div>
          <p>
            {t("warn4")}{" "}
            <Link
              to="/dashboard/requests/reimbursment/request"
              style={{ color: "#1292ee" }}
            >
              {t("warn5")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditRequestInAdvance;
