import {
  LIST_DECLINED_REQUESTS,
  LIST_DECLINED_INADVANCE_REQUESTS,
} from '../actions/types';

const INITIAL_STATE = {
  declineds: [],
  declinedsInAdvance: [],
  exel: [],
};

let exel;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_DECLINED_REQUESTS:
      exel = [];
      if (action.payload) {
        for (const iterator of action.payload) {
          exel = [...exel, ...iterator.pending.requests];
        }
        for (const iterator of exel) {
          iterator.name =
            iterator.from.firstName + ' ' + iterator.from.lastName;
        }
      }
      return {
        ...state,
        declineds: action.payload,
        exel: exel,
      };

      case LIST_DECLINED_INADVANCE_REQUESTS:
      exel = [];
      if (action.payload) {
        for (const iterator of action.payload) {
          exel = [...exel, ...iterator.pending.requests];
        }
        for (const iterator of exel) {
          iterator.name =
            iterator.from.firstName + ' ' + iterator.from.lastName;
        }
      }
      return {
        ...state,
        declinedsInAdvance: action.payload,
        exel: exel,
      };

    default:
      return state;
  }
};
