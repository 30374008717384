import React from "react";
import { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  loadingInAdvanceRequests,
  declineInAdvanceRequests
} from "../../../../../../../actions/pendingRequestsActions";
import {
  editRequestCategoryInAdvance,
  getCategories
} from "../../../../../../../actions/categoryActions";
import api from "../../../../../../../apis/api";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import noReceipts from "../../../../../../../assets/images/noReceipts.png";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import Avatar from "antd/lib/avatar/avatar";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import "./item-request.css";
import cogoToast from "cogo-toast";
import { returnErrors } from "../../../../../../../actions/errorActions";
import { removeLoading } from "../../../../../../../actions/loadingActions";

const ItemRequest = props => {
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalCon, setShowModalCon] = useState(false);
  const [mony, setMony] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [request, setRequest] = useState(undefined);
  const [numPages, setNumPages] = useState(null);
  const [spin, setSpin] = useState(false);

  const categories = useSelector(state => state.categories.categories);
  const cases = useSelector(state => state.categories.cases) || [];
  const user = useSelector(state => state.auth.user.role);
  const currency = useSelector(state => state.auth.user.currency[0]);

  const dispatch = useDispatch();

  const pageNumber = 1;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const { t } = useTranslation();

  const approveInAdvanceRequests = (requests, categoryID, caseID) => {
    let requestsIDs = requests.map(req => req._id);
    let mony;
    let data;

    let url = "";
    if (props.auth.user.role === "team-manager") {
      url = "approve-manager";
      data = { requestsIDs, mony };
    } else if (props.auth.user.role === "accountant") {
      url = "approve-accountant";
      mony = requests.map(req => req.mony);
      data = { requestsIDs, mony, categoryID, caseID };
    }

    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let token = localStorage.getItem("token");

    if (token) {
      config.headers["x-auth-token"] = token;
    }

    api
      .post(`/inadvance/${url}`, JSON.stringify(data), config)
      .then(res => {
        if (res.data.status === 1) {
          // dispatch({
          //   type: "APPROVE_INADCANCE_REQUESTS",
          //   payload: res.data.data,
          // });
          dispatch(removeLoading());
          dispatch(loadingInAdvanceRequests());
          cogoToast.success("Request Approved successfully");
          setSpin(false);
        } else if (res.data.status === 0) {
          dispatch(removeLoading());
          dispatch(loadingInAdvanceRequests());
          cogoToast.error(res.data.message);
          setSpin(false);
        } else {
          dispatch(
            returnErrors(
              res.data.message,
              res.data.status,
              "APPROVE_REQUESTS_FAIL"
            )
          );
          setSpin(false);
        }
      })
      .catch(err => {
        setSpin(false);
      });
  };

  const submitReq = () => {
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    let token = localStorage.getItem("token");

    if (token) {
      config.headers["x-auth-token"] = token;
    }

    api
      .post(`/inadvance/returned-mony`, { user: request.from.id }, config)
      .then(res => {
        if (res.data.mony !== 0) {
          console.log(res.data.mony);
          setMony(res.data.mony);

          setShowModal(false);
          setShowModalCon(true);
        } else {
          approveInAdvanceRequests(
            [{ _id: request._id, mony: false }],
            categoryId,
            caseId
          );
          setShowModalTwo(false);
        }
      })
      .catch(err => {});
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    setRequest(props.request);
  }, [request]);

  const onImageClick = () => {
    setShowModal(true);
  };

  const statusColor = () => {
    if (props.request.status.toLowerCase().includes("approve")) {
      return {
        color: "#268c55",
        backgroundColor: "#e3fbee",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    } else if (props.request.status.toLowerCase().includes("declin")) {
      return {
        color: "#e32424",
        backgroundColor: "#ffebeb",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    } else if (props.request.status.toLowerCase().includes("pend")) {
      return {
        color: "#f4a800",
        backgroundColor: "#fff4dd",
        width: "fit-content",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "15px"
      };
    }
  };

  const categoryOptions = categories.map(cat => {
    return (
      <option value={cat._id} selected={cat.id === props.request.category?._id}>
        {cat.name}
      </option>
    );
  });

  const caseOptions = cases.map(cat => {
    return (
      <option value={cat._id} selected={cat.id === props.request.case?._id}>
        {cat.name}
      </option>
    );
  });

  const renderImages = request => {
    const images = request.images;
    if (images && images.length > 0) {
      return images.map((image, index) => {
        return image.path.split(".").pop() === "pdf" ? (
          <div
            className="request-image d-inline-block"
            style={{ overflow: "auto" }}
          >
            <Document
              style={{ width: "100%" }}
              file={image.path}
              onLoadSuccess={onDocumentLoadSuccess}
              key={index}
            >
              <Page width={160} pageNumber={pageNumber} />
            </Document>
          </div>
        ) : (
          <div className="request-image d-inline-block">
            <img
              key={index}
              src={
                image.path.split(".").pop() == "pdf"
                  ? "/images/icons8-file-64.png"
                  : image.path
              }
              alt={image.filename}
              onClick={e => onImageClick(image.path, e)}
              className="img-fluid d-inline-block"
            />
          </div>
        );
      });
    } else {
      return (
        <div className="badge badge-secondary">
          {t("This request doesn't have images")}
        </div>
      );
    }
  };

  return (
    <React.Fragment key={"right"}>
      <div className="request">
        <div className="row align-items-center">
          <div
            className="col-md-5"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <div className="row align-items-center">
              <div className="col-md-12 req-card">
                <div className="req-owner-info">
                  {props.request ? (
                    props.request.from.userImage !== "" ? (
                      <Avatar
                        src={props.request.from.userImage}
                        shape="square"
                        size={40}
                      />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#d6edff",
                          verticalAlign: "middle",
                          color: "#405261"
                        }}
                        size="large"
                        shape="square"
                      >
                        {props.request ? props.request.from.firstName : ""}
                      </Avatar>
                    )
                  ) : (
                    ""
                  )}
                  <span
                    className="d-inline-block"
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? {
                            width: "fit-content",
                            textAlign: "right",
                            margin: "0px 10px"
                          }
                        : {
                            width: "fit-content",
                            textAlign: "left",
                            margin: "0px 10px"
                          }
                    }
                  >
                    <b>{props.request.name}</b> <br />
                    <b style={{ color: "#a8acaf" }}>{props.request.number}</b>
                  </span>
                </div>
                <div className="d-inline-block request-images">
                  {renderImages(props.request)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="row align-items-center">
              <div className="col-md-2 text-center">
                <span>
                  {t(currency)} {request ? request.amount : ""}
                </span>
              </div>
              <div className="col-md-4 text-center">
                <div className="badge badge-info p-1" style={statusColor()}>
                  {request ? t(request.status) : ""}
                </div>
              </div>
              {props.auth.user.role && props.auth.user.role === "manager" ? (
                ""
              ) : (
                <div className="col-md-6">
                  <button
                    className="btn float-center btnSec col-5 mr-3"
                    onClick={() =>
                      props.declineInAdvanceRequests(
                        [{ _id: request._id }],
                        categoryId,
                        caseId,
                        dispatch(
                          editRequestCategoryInAdvance({
                            requestID: request._id,
                            categoryId,
                            caseId
                          })
                        )
                      )
                    }
                  >
                    {t("Decline")}
                  </button>
                  {spin ? (
                    <button
                      type="button"
                      disabled
                      className="btn btnPrimary col-5"
                    >
                      <LoadingOutlined />
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-success float-center btnSecondary col-5"
                      onClick={() => {
                        return props.auth.user.role === "accountant"
                          ? setShowModalTwo(true)
                          : (setSpin(true),
                            approveInAdvanceRequests(
                              [{ _id: request._id }],
                              categoryId,
                              caseId
                            ));
                      }}
                    >
                      {t("Approve")}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
          >
            <Modal.Body
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    {props.request ? (
                      props.request.images.length > 0 ? (
                        <div
                          id="carouselExampleIndicators"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <ol
                            class="carousel-indicators"
                            style={{ display: "none" }}
                          >
                            {props.request.images.map((obj, index) => {
                              return (
                                <li
                                  data-target="#carouselExampleIndicators"
                                  data-slide-to={index}
                                  class="active"
                                ></li>
                              );
                            })}
                          </ol>
                          <div class="carousel-inner">
                            {props.request.images.map((obj, index) => {
                              return (
                                <div
                                  className={
                                    index == 0
                                      ? "active carousel-item"
                                      : "carousel-item"
                                  }
                                >
                                  {obj.path.split(".").pop() == "pdf" ? (
                                    <div style={{ overflow: "auto" }}>
                                      <Document
                                        style={{ width: "100%" }}
                                        file={obj.path}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                      >
                                        <Page
                                          width={400}
                                          style={{ width: "100%" }}
                                          pageNumber={pageNumber}
                                        />
                                      </Document>
                                    </div>
                                  ) : (
                                    <img
                                      class="d-block w-100"
                                      src={obj.path}
                                      alt="First slide"
                                    />
                                  )}
                                  <a
                                    href={obj.path}
                                    download
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "120px",
                                      zIndex: "150"
                                    }}
                                  >
                                    <button className="btn btnPrimary">
                                      <GetAppRoundedIcon /> {t("Down")}
                                    </button>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                          <a
                            class="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      ) : (
                        <div className="no-receipt">
                          <img src={noReceipts} alt="no receipt" />
                          <div className="red-warning" style={{ width: "95%" }}>
                            <div className="warning-head">
                              <WarningRoundedIcon /> <p>{t("noReq1")}</p>
                            </div>
                            <p>{t("noReq2")}</p>
                          </div>
                        </div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="">
                      <h2>
                        {t("request")} #{request ? request.number : ""}
                      </h2>
                      <div
                        className="req-owner-info"
                        style={{ width: "100%", justifyContent: "flex-start" }}
                      >
                        {props.request ? (
                          props.request.from.userImage !== "" ? (
                            <Avatar
                              src={props.request.from.userImage}
                              shape="square"
                              size={40}
                            />
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: "#d6edff",
                                verticalAlign: "middle",
                                color: "#405261"
                              }}
                              size="large"
                              shape="square"
                            >
                              {props.request
                                ? props.request.from.firstName
                                : ""}
                            </Avatar>
                          )
                        ) : (
                          ""
                        )}
                        <span
                          className="d-inline-block"
                          style={
                            localStorage.getItem("i18nextLng") == "ar"
                              ? {
                                  width: "fit-content",
                                  textAlign: "right",
                                  margin: "0px 10px"
                                }
                              : {
                                  width: "fit-content",
                                  textAlign: "left",
                                  margin: "0px 10px"
                                }
                          }
                        >
                          <b>{props.request.name}</b> <br />
                          <b style={{ color: "#a8acaf" }}>
                            {props.request.createdAt.split("T")[0]}
                          </b>
                        </span>
                      </div>
                      <p className="req-status" style={statusColor()}>
                        {request ? t(request.status) : ""}
                      </p>
                      <div className="data-row">
                        <div className="data-element">
                          <h5 className="label">{t("amount")}</h5>
                          <p className="value popup-data">
                            {request ? request.currency : ""}{" "}
                            {request ? request.amount : ""}
                          </p>
                        </div>
                        <div className="data-element">
                          <h5 className="label">{t("payToInAd")}</h5>
                          <p className="value popup-data">
                            {request ? request.paidTo : ""}
                          </p>
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="data-element">
                          <h5 className="label">{t("createdAt")}</h5>
                          <p className="value popup-data">
                            {request ? request.createdAt.split("T")[0] : ""}
                          </p>
                        </div>
                        <div className="data-element">
                          <h5 className="label">{t("payDate")}</h5>
                          <p className="value popup-data">
                            {request ? request.paidOn.split("T")[0] : ""}
                          </p>
                        </div>
                      </div>
                      <div className="paid-for">
                        <h5 className="label">{t("payForInAd")}</h5>
                        <p className="value popup-data">
                          {request ? request.paidFor : ""}
                        </p>
                      </div>
                      {user !== "accountant" && (
                        <div className="data-row">
                          <div className="data-element">
                            <h5 className="label">{t("Category")}</h5>
                            <p className="value popup-data">
                              {props.request?.category?.name === "" ||
                              !props.request?.category
                                ? t("Not selected")
                                : props.request?.category?.name}
                            </p>
                          </div>
                          <div className="data-element">
                            <h5 className="label">{t("Case")}</h5>
                            <p className="value popup-data">
                              {props.request?.case?.name === "" ||
                              !props.request?.case
                                ? t("Not selected")
                                : props.request?.case?.name}
                            </p>
                          </div>
                        </div>
                      )}
                      {user === "accountant" && (
                        <div
                          className="data-row"
                          style={{ alignItems: "center", marginBottom: "10px" }}
                        >
                          <h5 className="label" style={{ marginBottom: "0px" }}>
                            {t("Category")}
                          </h5>
                          <select
                            style={{ width: "49%" }}
                            className="form-control"
                            onChange={e => setCategoryId(e.target.value)}
                          >
                            <option value="">Select Category</option>
                            {categoryOptions}
                          </select>
                        </div>
                      )}
                      {user === "accountant" && (
                        <div
                          className="data-row"
                          style={{ alignItems: "center" }}
                        >
                          <h5 className="label" style={{ marginBottom: "0px" }}>
                            {t("Case")}
                          </h5>
                          <select
                            style={{ width: "49%" }}
                            className="form-control"
                            onChange={e => setCaseId(e.target.value)}
                          >
                            <option value="">Select Case</option>
                            {caseOptions}
                          </select>
                        </div>
                      )}
                      {props.auth.user.role &&
                      props.auth.user.role === "manager" ? (
                        ""
                      ) : (
                        <div className="mt-1  p-1 row">
                          <button
                            className="btn float-center btnSec col-5 mr-5"
                            onClick={() => {
                              props.declineInAdvanceRequests(
                                [{ _id: request._id }],
                                categoryId,
                                caseId,
                                dispatch(
                                  editRequestCategoryInAdvance({
                                    requestID: request._id,
                                    categoryId,
                                    caseId
                                  })
                                )
                              );
                              setShowModal(false);
                            }}
                          >
                            {t("Decline")}
                          </button>
                          {spin ? (
                            <button
                              type="button"
                              disabled
                              className="btn btnPrimary"
                            >
                              <LoadingOutlined />
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-success float-center btnSecondary col-5"
                              onClick={() => {
                                return props.auth.user.role === "accountant"
                                  ? (setSpin(true), submitReq())
                                  : (setSpin(true),
                                    approveInAdvanceRequests(
                                      [{ _id: request._id }],
                                      categoryId,
                                      caseId
                                    ));
                              }}
                            >
                              {t("Approve")}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                  <CloseOutlinedIcon
                    className="close-icon"
                    onClick={() => setShowModal(false)}
                    style={
                      localStorage.getItem("i18nextLng") == "ar"
                        ? { right: "auto", left: "5px" }
                        : { right: "5px", left: "auto" }
                    }
                  />
                </Row>
              </Container>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModalTwo}
          >
            <Modal.Body
              style={
                localStorage.getItem("i18nextLng") == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              <Container>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <h4>
                    {t("Select category and case for the request please")}
                  </h4>
                  {/* <div
                    className="data-row"
                    style={{
                      alignItems: "center",
                      width: "40%",
                      margin: "20px auto",
                    }}
                  >
                    <h5 className="label" style={{ marginBottom: "0px" }}>
                      {t("Category")}
                    </h5>
                    <select
                      style={{ width: "49%" }}
                      className="form-control"
                      onChange={(e) => setCategoryId(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {categoryOptions}
                    </select>
                  </div> */}
                  <div className="form-row">
                    <div className="row-element">
                      <label>{t("Category")}</label>
                      <select
                        className="form-control"
                        name="categoryId"
                        onChange={e => setCategoryId(e.target.value)}
                      >
                        <option value="">Select Category</option>
                        {categoryOptions}
                      </select>
                    </div>
                    <div className="row-element">
                      <label>{t("Case")}</label>
                      <select
                        className="form-control"
                        name="caseId"
                        onChange={e => setCaseId(e.target.value)}
                      >
                        <option value="">Select Case</option>
                        {caseOptions}
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btnPrimary btn-new-req"
                    onClick={() => {
                      setShowModalTwo(false);
                      submitReq();
                    }}
                  >
                    {t("Confirm")}
                  </button>
                </div>
                <CloseOutlinedIcon
                  className="close-icon"
                  onClick={() => setShowModalTwo(false)}
                  style={
                    localStorage.getItem("i18nextLng") == "ar"
                      ? { right: "auto", left: "15px", top: "15px" }
                      : { right: "15px", left: "auto", top: "15px" }
                  }
                />
              </Container>
            </Modal.Body>
          </Modal>

          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModalCon}
          >
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={11} md={11} lg={11}></Col>
                  <a
                    href="#"
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={() => {
                      setShowModalCon(false);
                    }}
                  >
                    X
                  </a>
                  <Col xs={12} md={12} lg={12}>
                    <div className=" row container  justify-content-center p-0 m-0 ">
                      <h4 className=" col-12 " style={{ textAlign: "center" }}>
                        {t("Automatic Reconcilation")}
                      </h4>
                      {+mony > 0 ? (
                        <h6
                          className=""
                          style={{
                            textAlign: "center",
                            color: "darkgray",
                            fontSize: "14px"
                          }}
                        >
                          {t("AutoRec1")}
                          {Math.abs(+mony)}
                          {t("AutoRec2")}
                          {props.request.number}
                          {t("AutoRec3")}
                        </h6>
                      ) : (
                        <h6
                          className=""
                          style={{
                            textAlign: "center",
                            color: "darkgray",
                            fontSize: "14px"
                          }}
                        >
                          {t("AutoRec4")}
                          {Math.abs(+mony)}
                          {t("AutoRec2")}
                          {props.request.number}
                          {t("AutoRec3")}
                        </h6>
                      )}
                    </div>
                    <div className="mt-1   row justify-content-center">
                      <button
                        className="btn  float-center btnSecondary col-4 m-2"
                        onClick={() => {
                          approveInAdvanceRequests(
                            [{ _id: request._id, mony: false }],
                            categoryId,
                            caseId
                          );
                        }}
                      >
                        {t("Not Now")}
                      </button>
                      <button
                        className="btn  btnPrimary float-center  col-4 m-2"
                        onClick={() => {
                          approveInAdvanceRequests(
                            [{ _id: request._id, mony: true }],
                            categoryId,
                            caseId
                          );
                          setShowModalCon(false);
                        }}
                      >
                        {t("Withdraw Amount")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapToStateProps = state => {
  return {
    pendings: state.pending.pendings,
    exel: state.pending.exel,
    lang: state.lang.lang,
    auth: state.auth
  };
};

export default connect(mapToStateProps, {
  loadingInAdvanceRequests,
  declineInAdvanceRequests
})(ItemRequest);
