import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../../../actions/authActions";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { verifyEmail } from "../../../../../actions/userActions";
import { withRouter } from "react-router-dom";

import "../forgetPassword.css";

const ForgetPasswordForm = (props) => {
  const [errorMsg, seterrorMsg] = useState(undefined);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    props.forgetPassword(values);
  };

  return (
    <div className="loginForm-wrapper">
      <form className="loginForm" onSubmit={props.handleSubmit(onSubmit)}>
        {localStorage.getItem("i18nextLng") == "ar" ? (
          <h3 className="section-title loginform-title">اعادة ضبط كلمة السر</h3>
        ) : (
          <h3 className="section-title loginform-title">Reset Password</h3>
        )}
        <span className="border-b"></span>
        {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : ""}
        {localStorage.getItem("i18nextLng") == "ar" ? (
          <div className="form-element-full">
            <label>البريد الالكترونى</label>
            <Field
              name="email"
              type="email"
              component={renderInputAR}
              placeHolder="ادخل بريد الكتروني لارسال رمز التحقق"
            />
            <button className="btn btnPrimary login-button">
              أرسل كلمة مرور جديدة
            </button>
          </div>
        ) : (
          <div className="form-element-full">
            <label>Email</label>
            <Field
              name="email"
              type="email"
              component={renderInput}
              placeHolder="enter email to send verification code"
            />
            <button className="btn btnPrimary login-button">
              Reset Password
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="text-danger">{error}</div>;
  }
};

const renderInput = ({ input, label, meta, type, placeHolder }) => {
  return (
    <div className="form-group" style={{ width: "100%" }}>
      <input
        type={type}
        className="form-control input-lg"
        autoComplete="off"
        placeholder={placeHolder}
        {...input}
      />
      {renderError(meta)}
    </div>
  );
};

const renderInputAR = ({ input, label, meta, type, placeHolder }) => {
  return (
    <div className="form-group" style={{ width: "100%" }}>
      <input
        type={type}
        className="form-control input-lg"
        autoComplete="off"
        placeholder={placeHolder}
        {...input}
      />
      {renderError(meta)}
    </div>
  );
};

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "Please add email";
  }

  if (!formValues.password) {
    errors.password = "Please add password";
  }

  return errors;
};

const reduxMiddleware = reduxForm({
  form: "login",
  validate: validateForm,
})(ForgetPasswordForm);

const mapStateToProps = (state) => {
  return {
    errors: state.error,
    lang: state.lang.lang,
  };
};

export default connect(mapStateToProps, { forgetPassword, verifyEmail })(
  withRouter(reduxMiddleware)
);
