import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FcCheckmark } from "react-icons/fc";
import { ImFire } from "react-icons/im";

import "./billingPlanCard.css";

const BillingPlanCard = (props) => {
  const currency = useSelector((state) => state.auth.user.currency[0]);

  const { t } = useTranslation();

  const history = useHistory();

  const subscribe = (id) => history.push(`/dashboard/billingPlane/${id}`);

  const saveAmount =
    props.plan.duration === 6 ? 10 : props.plan.duration === 12 ? 20 : 0;

  const billTime = () =>
    props.plan.duration === 1 ? (
      <h6>{t("Per active user/ Billed monthly")}</h6>
    ) : props.plan.duration === 6 ? (
      <h6>{t("Per active user/ Billed every 6 month")}</h6>
    ) : props.plan.duration === 12 ? (
      <h6>{t("Per active user/ Billed every year")}</h6>
    ) : (
      ""
    );

  return (
    <div className="plan-card">
      <span className="name-save">
        <h5
          id={`PlanCard-name-${props.id}`}
          style={{ fontWeight: "bold", marginBottom: "0px" }}
        >
          {props.plan.name}
        </h5>
        <p
          style={{ display: saveAmount === 0 ? "none" : "block" }}
          id={`PlanCard-mostPopular-${props.id}`}
          className="most-popular"
        >
          {t("Save")} {saveAmount}%
        </p>
      </span>
      <h3 id={`PlanCard-price-${props.id}`} style={{ fontWeight: "900" }}>
        {t(currency)} {props.plan.price}{" "}
        <span className="month">/ {t("Month")}</span>
      </h3>
      {billTime()}
      <p
        id={`PlanCard-desc-${props.id}`}
        style={{ fontSize: "small", color: "#6B7280", marginTop: "20px" }}
      >
        {props.plan.discription}
      </p>
      {props.plan.utilities.map((benf, i) => {
        return (
          <span
            id={`PlanCard-benifit-${props.id}-${i}`}
            className="benifit"
            key={props.plan.utilities.indexOf(benf)}
            style={{ color: "#6B7280" }}
          >
            <FcCheckmark style={{ fontSize: "20px" }} />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <p
              id={`PlanCard-benifit-${props.id}-${i + 100}`}
              style={{ marginBottom: "0px" }}
            >
              {benf}
            </p>
          </span>
        );
      })}
      {props.subscribed ? (
        <button
          id={`PlanCard-button-${props.id}`}
          disabled={props.currentPlan.price === props.plan.price}
          className={
            props.plan.isBest ? "btn btnPrimary" : "btn btnPrimaryHovered"
          }
          onClick={() =>
            props.currentPlan.price > props.plan.price
              ? history.push(`/dashboard/billingPlan/upgrade/${props.plan.id}`)
              : ""
          }
        >
          {props.currentPlan.price === props.plan.price
            ? "Current Plan"
            : props.currentPlan.price > props.plan.price
            ? `Upgrade to ${props.plan.name}`
            : "Subscribe"}
        </button>
      ) : (
        <button
          id={`PlanCard-button-${props.id}`}
          className={
            props.plan.isBest ? "btn btnPrimary" : "btn btnPrimaryHovered"
          }
          onClick={() => subscribe(props.plan.id)}
        >
          {t("Get Started")}
        </button>
      )}
      <div
        className="most-popular"
        style={{ display: props.plan.isBest ? "flex" : "none" }}
      >
        <ImFire /> &nbsp;{" "}
        <p id={`PlanCard-mostPopular-${props.id}`}>{t("Most Popular")}</p>
      </div>
    </div>
  );
};

export default BillingPlanCard;
