import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import { forgetPassword, verifyCode } from "../../../../../actions/authActions";
import AuthCode from "react-auth-code-input";

import "../verificationCode.css";

const VerificationCodeForm = () => {
  const [style, setStyle] = useState({
    color: "#268c55",
    pointerEvents: "none",
    cursor: "not-allowed",
  });
  const [code, setCode] = useState("");

  const email = useSelector((state) => state.auth.verificationEmail);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return (
      <span>
        {minutes} : {seconds}
      </span>
    );
  };

  const clockRef = useRef();
  const handleStart = () => clockRef.current.start();

  return (
    <div className="loginForm-wrapper">
      <div className="login-form">
        <div
          className="row justify-content-center"
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <div className="col-md-9 mb-4">
            <h3 className="section-title loginform-title">
              {t("verification1")}
            </h3>
          </div>
          <div className="col-md-9 mb-4">
            <p>{t("verification2")}</p>
          </div>
          <div className="col-md-9 mb-4">
            <AuthCode
              characters={4}
              onChange={(auth) => setCode(parseInt(auth))}
              containerClassName="container"
              inputClassName="input"
            />
          </div>
        </div>

        <div
          className="row justify-content-center"
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <div className="col-md-9">
            <p>
              {t("session")}
              {
                <Countdown
                  ref={clockRef}
                  date={Date.now() + 600000}
                  renderer={renderer}
                  onComplete={() =>
                    setStyle({
                      color: "#268c55",
                      pointerEvents: "auto",
                      cursor: "pointer",
                    })
                  }
                />
              }{" "}
              {t("minutes")}
            </p>
          </div>
        </div>

        <div
          className="row justify-content-center"
          style={
            localStorage.getItem("i18nextLng") == "ar"
              ? { textAlign: "right" }
              : { textAlign: "left" }
          }
        >
          <div className="col-md-9">
            <p>
              {t("dontReceive")}
              <Link
                to="/verificate-code"
                style={style}
                onClick={() => {
                  setStyle({
                    color: "#268c55",
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  });
                  dispatch(forgetPassword({ email }));
                  handleStart();
                }}
              >
                {t("Resend")}
              </Link>
            </p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-9 text-center">
            <button
              className="btn btnPrimary login-button"
              onClick={() => dispatch(verifyCode({ email, code }))}
            >
              {t("verify")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationCodeForm;
