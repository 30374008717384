import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import api from "../../../../../../apis/api";
import cogoToast from "cogo-toast";
import { HiBadgeCheck } from "react-icons/hi";

const SuppliersList = () => {
  const [suppliers, setSuppliers] = useState([]);

  const { t } = useTranslation();
  const history = useHistory();

  const getSuppliers = () => {
    api({
      url: "/supplier/get-supplier",
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.Suppliers);
        setSuppliers(res.data.Suppliers);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  const verifySupplier = (id) => {
    api({
      url: `supplier/verify-supplier`,
      method: "post",
      data: { supplierID: id },
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.status === 1) {
          getSuppliers();
          cogoToast.success("Supplier verified successfully");
        } else {
          cogoToast.error("Can not get data");
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      field: "nameDisplay",
      headerName: t("Supplier"),
      width: 200,
      cellClassName: "MuiDataGrid-cellCenter",
      renderCell: (params) => {
        return (
          <div
            style={
              localStorage.getItem("i18nextLng") === "ar"
                ? {
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    textAlign: "left",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    textAlign: "right",
                  }
            }
          >
            <p style={{ marginBottom: "0px" }}>
              <b>
                {params.row.nameDisplay
                  ? params.row.nameDisplay
                  : params.row.nameEnglish}
              </b>
            </p>{" "}
            &nbsp;{" "}
            <HiBadgeCheck
              style={
                params.row.verified === true
                  ? {
                      fontSize: "1rem",
                      color: "#268c55",
                    }
                  : {
                      fontSize: "1rem",
                      color: "#93a1b0",
                    }
              }
            />
          </div>
        );
      },
    },
    {
      field: "industry",
      headerName: t("Company Industry"),
      sortable: true,
      width: 200,
      cellClassName: "MuiDataGrid-cellCenter MuiDataGrid-colCellCenter",
    },
    {
      field: "country",
      headerName: t("officeAddress"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
      valueGetter: (params) => `${params.row.country} / ${params.row.city}`,
    },
    {
      field: "phone",
      headerName: t("Company Phone"),
      width: 150,
      cellClassName: "MuiDataGrid-cellCenter",
    },
    {
      field: "ctreatedAt",
      headerName: t("joined"),
      width: 120,
      cellClassName: `MuiDataGrid-cellCenter MuiDataGrid-colCellCenter`,
      valueGetter: (params) => params.row.createdAt.split("T")[0],
    },
    {
      field: "verified",
      headerName: t("status"),
      width: 120,
      cellClassName: "MuiDataGrid-cellCenter",
      valueGetter: (params) =>
        params.row.verified ? t("Verified") : t("Unverified"),
    },
    {
      field: "action",
      headerName: t("action"),
      width: 250,
      cellClassName: "MuiDataGrid-cellCenter",
      renderCell: (params) => {
        return (
          <div>
            {params.row.verified ? (
              <button
                className="btn btnPrimary"
                style={{
                  width: "5rem",
                  margin: "auto 10px",
                  cursor: "not-allowed",
                }}
                disabled
              >
                {t("Verify")}
              </button>
            ) : (
              <button
                className="btn btnPrimary"
                style={{ width: "5rem", margin: "auto 10px" }}
                onClick={() => {
                  verifySupplier(params.row.id);
                }}
              >
                {t("Verify")}
              </button>
            )}
            <button
              className="btn btnThrd"
              style={{ width: "5rem", margin: "auto 10px" }}
              onClick={() => {
                history.push(
                  `/dashboard/suppliers/view-supplier?id=${params.row.id}`
                );
              }}
            >
              {t("Edit")}
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="mainPage"
      style={
        localStorage.getItem("i18nextLng") === "ar"
          ? { textAlign: "right" }
          : { textAlign: "left" }
      }
    >
      <h3>{t("Suppliers List")}</h3>
      {suppliers && suppliers.length > 0 ? (
        <div style={{ height: 450, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={suppliers}
            columns={columns}
            pageSize={10}
            disableColumnMenu
            // hideFooter
            onRowClick={(params) => {}}
          />
        </div>
      ) : localStorage.getItem("i18nextLng") == "en" ? (
        <section class="h-100 w-100">
          <header class="container h-100 w-100">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div
                class="d-flex flex-column"
                style={{ fontSize: "50px", marginTop: "100px" }}
              >
                You don't have any supplier
              </div>
            </div>
          </header>
        </section>
      ) : (
        <section class="h-100 w-100">
          <header class="container h-100 w-100">
            <div class="d-flex align-items-center justify-content-center h-100 w-100">
              <div
                class="d-flex flex-column"
                style={{ fontSize: "50px", marginTop: "100px" }}
              >
                لا يوجد لديك موردين
              </div>
            </div>
          </header>
        </section>
      )}
    </div>
  );
};

export default SuppliersList;
