import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import MyRequests from "./pages/newStr/ReimbursementRequests/my-requests/my-requests";
import Reimbursement from "./pages/newStr/ReimbursementRequests/reimbursement/reimbursement";
import MyWallet from "./pages/newStr/UserWallet/myWallet";
import Request from "./pages/newStr/ReimbursementRequests/request/request";
import Success from "./pages/newStr/ReimbursementRequests/success/success";
import history from "../../history";
import { useTranslation } from "react-i18next";
import InAdvance from "./pages/newStr/InAdvanceRequests/inAdvance/inAdvance";
import InadvanceRequest from "./pages/newStr/InAdvanceRequests/inadvanceRequest/inadvanceRequest";
import SuccessInAdvance from "./pages/newStr/InAdvanceRequests/success/successInAdvance";
import Reconcile from "./pages/newStr/InAdvanceRequests/reconcile/reconcile";
import SuccessReconcile from "./pages/newStr/InAdvanceRequests/success/successReconcile";
import EditRequestInAdvance from "./pages/newStr/InAdvanceRequests/editRequestInAdvance/editRequestInAdvance";
import EditRequest from "./pages/newStr/ReimbursementRequests/editRequest/editRequest";
import CashOut from "./pages/newStr/UserWallet/cashout";

const RequestsPage = (props) => {
  const { t } = useTranslation();

  let routes = (
    <Router history={history}>
      <div className="col-md-12">
        <Switch>
          <Route
            path="/dashboard/requests/reimbursment"
            exact
            component={Reimbursement}
          />
          <Route
            path="/dashboard/requests/reimbursment/request"
            exact
            component={Request}
          />
          <Route
            path="/dashboard/requests/reimbursment/success"
            exact
            component={Success}
          />
          <Route
            path="/dashboard/requests/my-requests"
            exact
            component={MyRequests}
          />
          <Route
            path="/dashboard/requests/myWallet"
            exact
            component={MyWallet}
          />
          <Route
            path="/dashboard/requests/myWallet/cashoout/:method/:amount"
            exact
            component={CashOut}
          />
          <Route
            path="/dashboard/requests/inAdvance"
            exact
            component={InAdvance}
          />
          <Route
            path="/dashboard/requests/newInAdvanceRequest"
            exact
            component={InadvanceRequest}
          />
          <Route
            path="/dashboard/requests/newInAdvanceRequest/seccess"
            exact
            component={SuccessInAdvance}
          />
          <Route
            path="/dashboard/requests/inAdvanceRequest/reconcile"
            exact
            component={Reconcile}
          />
          <Route
            path="/dashboard/requests/inAdvanceRequest/reconcile/success"
            exact
            component={SuccessReconcile}
          />
          <Route
            path="/dashboard/requests/reimbursment/editRequest/:id"
            exact
            component={EditRequest}
          />
          <Route
            path="/dashboard/requests/inAdvanceRequest/editRequest/:id"
            exact
            component={EditRequestInAdvance}
          />
          <Redirect to="/dashboard/requests/my-requests" />
        </Switch>
      </div>
    </Router>
  );
  return <div>{routes}</div>;
};

export default connect(null, {})(RequestsPage);
