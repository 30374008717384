import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import errorReducer from "./errorReducer";
import loadingReducer from "./loadingReducer";
import uploadReducer from "./uploadReducer";
import requestReducer from "./requestReducer";
import pendingReducer from "./pendingReducer";
import teamReducer from "./teamReducer";
import walletReducer from "./walletReducer";
import approvedReducer from "./approveReducer";
import userReucer from "./userReducer";
import declinedReducer from "./declinedReduser";
import payrollReducer from "./payrollReducer";
import langReducer from "./langReducer";
import indebtednessReducer from "./indebtednessReducer";
import categoryReducer from "./categoryReducer";
import notificationsReducer from "./notificationsReducer";
import billingPlansReducer from "./billingPlansReducer";

import { LOGOUT_SUCCESS, START_ADD_COMPANY } from "../actions/types";

import { LOCAL_STORAGE_NAME } from "../PersistedStore";

const appReducer = combineReducers(
  Object.assign(
    {
      auth: authReducer,
      wallet: walletReducer,
      user: userReucer,
      lang: langReducer,
      form: formReducer,
      error: errorReducer,
      loading: loadingReducer,
      company: companyReducer,
      upload: uploadReducer,
      pending: pendingReducer,
      approved: approvedReducer,
      declined: declinedReducer,
      payroll: payrollReducer,
      team: teamReducer,
      request: requestReducer,
      indebtedness: indebtednessReducer,
      categories: categoryReducer,
      notifications: notificationsReducer,
      billing: billingPlansReducer,
    },
    { Intl }
  )
);

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS || action.type === START_ADD_COMPANY) {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem(LOCAL_STORAGE_NAME);

    state = undefined;
  }
  return appReducer(state, action);
};
